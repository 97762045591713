import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import Select from 'react-select';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Components/Buttons/ButtonsStyle';
import { CloseIconStyle } from '../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { StyleInstrumentSelect } from '../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  ButtonAreaAdmin,
  InputArea,
  RequiredInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../Styles/Styles';
import { useCreateUserPermission } from '../../../data/hooks/admin-permission/use-create-user-permission';
import { useEditPermission } from '../../../data/hooks/admin-permission/use-edit-permission';
import { useListCompanyRelatedUsersAndStructures } from '../../../data/hooks/admin-permission/use-list-company-related-users-and-structures';
import { useListModulesByStructureId } from '../../../data/hooks/admin-permission/use-list-modules-by-structureId';
import { useListModulesRole } from '../../../data/hooks/admin-permission/use-list-modules-role.query';
import { useListPermissionsUsers } from '../../../data/hooks/admin-permission/use-list-users-permission';
import {
  Mandatory,
  PermissionsActive,
  PermissionsData,
  __initialMandatory,
  __initialPermissionsActive,
  __initialPermissionsData
} from './PermissionsInterface';
import { useQuery } from '@apollo/client';
import {
  ListUsersDocument,
  ListUsersQuery,
  ListUsersQueryVariables
} from '../../../data/graphql/query/generated/listUsers.query';
import InputSelectSearch from '../../../Components/V2/Molecules/InputSelectSearch/InputSelectSearch';

export function PermissionModal({
  setShowModal,
  edit,
  setEdit,
  permissionData,
  setPermissionData,
  companies
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  permissionData: PermissionsData;
  setPermissionData: Dispatch<SetStateAction<PermissionsData>>;
  companies: { id: string; label: string; value: string }[];
}) {
  const { t } = useTranslation();
  const [active, setActive] = useState<PermissionsActive>(
    __initialPermissionsActive
  );
  const [structures, setStructures] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [modules, setModules] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [modulesRole, setModulesRoles] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const { createUserPermission } = useCreateUserPermission();
  const { editPermission } = useEditPermission();
  const { data: listStructures } = useListCompanyRelatedUsersAndStructures(
    permissionData.companyId
  );
  const { data: listModules } = useListModulesByStructureId(
    permissionData.structureId
  );
  const { data: listModulesRole } = useListModulesRole();
  const { data: listPermissions } = useListPermissionsUsers();

  const { data: usersList } = useQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    {
      variables: {
        data: {
          listAllUsers: true
        }
      }
    }
  );

  const usersOptions =
    usersList?.listUsers.map((user) => ({
      id: user.id,
      label: user.name,
      value: user.id
    })) || [];

  const [mandatorys, setMandatorys] = useState<Mandatory>(__initialMandatory);

  const mandatoryInputs = () => {
    const checkEmptyFields = (data: PermissionsData): string[] => {
      const emptyFields: string[] = [];

      if (!data.companyId) emptyFields.push('companyId');
      if (!data.moduleId) emptyFields.push('moduleId');
      if (!data.moduleRoleId) emptyFields.push('moduleRoleId');
      if (!data.structureId) emptyFields.push('structureId');
      if (!data.userId) emptyFields.push('userId');

      return emptyFields;
    };

    const emptyFields = checkEmptyFields(permissionData);

    for (const item of emptyFields) {
      setMandatorys((prev) => ({
        ...prev,
        [item]: false
      }));
    }
  };

  const SendPermission = async () => {
    if (
      permissionData.companyId &&
      permissionData.moduleId &&
      permissionData.moduleRoleId &&
      permissionData.userId &&
      permissionData.structureId
    ) {
      const createResponse = await createUserPermission({
        variables: {
          data: {
            companyId: permissionData.companyId,
            moduleId: permissionData.moduleId,
            moduleRoleId: permissionData.moduleRoleId,
            structureId: permissionData.structureId,
            userId: permissionData.userId
          }
        }
      });
      toastifyLoading(`${t('registering')} ${t('Permission')}...`);
      if (createResponse.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(`${t('Permission')} ${t('registeredSuccessfully')}!`);
        setPermissionData(__initialPermissionsData);
        setActive(__initialPermissionsActive);
        setShowModal(false);
        setEdit({ status: false, data: {} });
      } else if (createResponse.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          createResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const EditPermission = async () => {
    if (permissionData.moduleRoleId) {
      const permission = listPermissions?.listUsersPermissions.find(
        (permission) =>
          permission.structureUserPermissionId ===
            edit.data.structureUserPermissionId &&
          permission.moduleAssignmentId === edit.data.moduleAssignmentId
      );
      if (
        permission?.moduleAssignmentId &&
        permission?.structureUserPermissionId
      ) {
        const createResponse = await editPermission({
          variables: {
            data: {
              moduleAssignmentId: permission?.moduleAssignmentId,
              moduleRoleId: permissionData.moduleRoleId,
              structureUserPermissionId: permission?.structureUserPermissionId
            }
          }
        });
        toastifyLoading(`${t('editing')} ${t('Permission')}...`);
        if (createResponse.data) {
          toastfyDimiss('toastLoading');
          toastfySuccess(`${t('Permission')} ${t('editedSuccessfully')}!`);
          setPermissionData(__initialPermissionsData);
          setActive(__initialPermissionsActive);
          setShowModal(false);
          setEdit({ status: false, data: {} });
        } else if (createResponse.errors) {
          toastfyDimiss('toastLoading');
          toastfyError(
            createResponse.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
        }
      }
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  useEffect(() => {
    if (edit.status && edit.data) {
      const roles =
        edit.data.permission.charAt(0).toLowerCase() +
        edit.data.permission.slice(1);

      setActive((prev) => ({
        ...prev,
        moduleRole: false
      }));
      setPermissionData((prev) => ({
        ...prev,
        moduleRoleId: (listModulesRole?.listModulesRoles.find(
          (role) => role.role === roles
        )?.id ?? '') as string
      }));
      setShowModal(true);
    }

    if (listStructures) {
      const structures =
        listStructures.listCompanyRelatedUsersAndStructures.structures.map(
          (structure: any) => ({
            id: structure.id,
            label: structure.name,
            value: structure.id
          })
        );
      setStructures(structures);
    }

    if (listModules) {
      const modules = listModules.listModulesByStructureId.map(
        (modules: any) => ({
          id: t(modules.id),
          label: t(modules.name),
          value: t(modules.id)
        })
      );

      setModules(modules);
    }

    if (listModulesRole) {
      const moduleRoles = listModulesRole.listModulesRoles.map(
        (modules: any) => ({
          id: modules.id,
          label: modules.role.charAt(0).toUpperCase() + modules.role.slice(1),
          value: modules.id
        })
      );

      setModulesRoles(moduleRoles);
    }
  }, [listStructures, listModules, listModulesRole]);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '0'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Register')} ${t('Permissions')}`}</RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
              setPermissionData(__initialPermissionsData);
            }}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          {!edit.status && (
            <>
              <RequiredInput style={{ position: 'relative', width: '50%' }}>
                <Select
                  styles={{
                    ...StyleInstrumentSelect,
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      borderBottom: mandatorys.companyId
                        ? `1px solid ${Color.Brown1}`
                        : '1px solid red',
                      borderRadius: 'none',
                      boxSizing: 'border-box',
                      boxShadow: 'none',
                      padding: 'none',
                      fontSize: '11pt',
                      cursor: 'pointer',
                      marginBottom: '13px',
                      userSelect: 'none',
                      background: 'none',
                      ':hover': {
                        borderBottom: mandatorys.companyId
                          ? `1px solid ${Color.Brown1}`
                          : '1px solid red'
                      }
                    })
                  }}
                  isSearchable={false}
                  placeholder={t('Company')}
                  hideSelectedOptions
                  options={companies}
                  onChange={(e: any) => {
                    setPermissionData((prev) => ({
                      ...prev,
                      companyId: e.id
                    }));
                    setActive((prev) => ({
                      ...prev,
                      structure: false
                    }));
                  }}
                  noOptionsMessage={() => t('NoOptions')}
                  maxMenuHeight={200}
                  value={companies.find(
                    (company: any) => company.id === permissionData.companyId
                  )}
                />
              </RequiredInput>
              <RequiredInput style={{ position: 'relative', width: '50%' }}>
                <Select
                  styles={{
                    ...StyleInstrumentSelect,
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      borderBottom: mandatorys.structureId
                        ? `1px solid ${Color.Brown1}`
                        : '1px solid red',
                      borderRadius: 'none',
                      boxSizing: 'border-box',
                      boxShadow: 'none',
                      padding: 'none',
                      fontSize: '11pt',
                      cursor: 'pointer',
                      marginBottom: '13px',
                      userSelect: 'none',
                      background: 'none',
                      ':hover': {
                        borderBottom: mandatorys.structureId
                          ? `1px solid ${Color.Brown1}`
                          : '1px solid red'
                      }
                    })
                  }}
                  isSearchable={false}
                  placeholder={t('Structure')}
                  hideSelectedOptions
                  options={structures}
                  onChange={(e: any) => {
                    setPermissionData((prev) => ({
                      ...prev,
                      structureId: e.id
                    }));
                    setActive((prev) => ({
                      ...prev,
                      user: false
                    }));
                  }}
                  noOptionsMessage={() => t('NoOptions')}
                  maxMenuHeight={200}
                  value={structures.find(
                    (structure: any) =>
                      structure.id === permissionData.structureId
                  )}
                  isDisabled={active.structure}
                />
              </RequiredInput>
              <InputSelectSearch
                name="user"
                label=""
                placeholder={t('User')}
                width="290px"
                disabled={active.user}
                options={usersOptions}
                value={usersOptions?.find(
                  (user) => user.id === permissionData.userId
                )}
                onChange={(event) => {
                  setPermissionData((prev) => ({
                    ...prev,
                    userId: event.value.toString()
                  }));
                  setActive((prev) => ({
                    ...prev,
                    module: false
                  }));
                }}
              />
              <span
                style={{
                  display: 'flex',
                  color: Color.Brown1,
                  width: '100%',
                  justifyContent: 'center',
                  padding: '15px 0',
                  fontWeight: '800'
                }}
              >
                {t('module')}
              </span>
              <RequiredInput style={{ position: 'relative', width: '50%' }}>
                <Select
                  styles={{
                    ...StyleInstrumentSelect,
                    control: (provided) => ({
                      ...provided,
                      border: 'none',
                      borderBottom: mandatorys.moduleId
                        ? `1px solid ${Color.Brown1}`
                        : '1px solid red',
                      borderRadius: 'none',
                      boxSizing: 'border-box',
                      boxShadow: 'none',
                      padding: 'none',
                      fontSize: '11pt',
                      cursor: 'pointer',
                      marginBottom: '13px',
                      userSelect: 'none',
                      background: 'none',
                      ':hover': {
                        borderBottom: mandatorys.moduleId
                          ? `1px solid ${Color.Brown1}`
                          : '1px solid red'
                      }
                    })
                  }}
                  isSearchable={false}
                  placeholder={t('module')}
                  hideSelectedOptions
                  options={modules}
                  onChange={(e: any) => {
                    setPermissionData((prev) => ({
                      ...prev,
                      moduleId: e.id
                    }));
                    setActive((prev) => ({
                      ...prev,
                      moduleRole: false
                    }));
                  }}
                  noOptionsMessage={() => t('NoOptions')}
                  maxMenuHeight={200}
                  value={modules.find(
                    (module: any) => module.id === permissionData.moduleId
                  )}
                  isDisabled={active.module}
                />
              </RequiredInput>
            </>
          )}
          <RequiredInput style={{ position: 'relative', width: '50%' }}>
            <Select
              styles={{
                ...StyleInstrumentSelect,
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  borderBottom: mandatorys.moduleRoleId
                    ? `1px solid ${Color.Brown1}`
                    : '1px solid red',
                  borderRadius: 'none',
                  boxSizing: 'border-box',
                  boxShadow: 'none',
                  padding: 'none',
                  fontSize: '11pt',
                  cursor: 'pointer',
                  marginBottom: '13px',
                  userSelect: 'none',
                  background: 'none',
                  ':hover': {
                    borderBottom: mandatorys.moduleRoleId
                      ? `1px solid ${Color.Brown1}`
                      : '1px solid red'
                  }
                })
              }}
              isSearchable={false}
              placeholder={t('Permission')}
              hideSelectedOptions
              options={modulesRole}
              onChange={(e: any) => {
                setPermissionData((prev) => ({
                  ...prev,
                  moduleRoleId: e.id
                }));
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={modulesRole.find(
                (module: any) => module.id === permissionData.moduleRoleId
              )}
              isDisabled={active.moduleRole}
            />
          </RequiredInput>
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
              setPermissionData(__initialPermissionsData);
              setActive(__initialPermissionsActive);
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {!edit.status && (
            <AdvanceButtonStyled onClick={() => SendPermission()}>
              {' '}
              {t('Save').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
          {edit.status && (
            <AdvanceButtonStyled onClick={() => EditPermission()}>
              {' '}
              {t('Edit').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
