import React, { useState } from 'react';
import { Container, DivName, FileItem, FileListUl } from './FileList.styles';
import { IoCloseSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { FileType } from '../FileViewer/FileViewer.interfaces';
import Icon from '../../Atoms/Icon/Icon';
import FileViewer from '../FileViewer/FileViewer';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import { FileListProps } from './FileList.interfaces';

const FileList = ({ files, onDelete, canDelete = true }: FileListProps) => {
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);
  const [selectedDelete, setSelectedDelete] = useState<FileType | null>(null);
  const { t } = useTranslation();

  const handleCloseViewer = () => {
    setSelectedFile(null);
  };

  const handleView = (file: FileType) => {
    setSelectedFile(file);
  };

  const handleDelete = (file: FileType) => {
    onDelete(file);
    setSelectedDelete(null);
  };

  return (
    <Container>
      <FileListUl>
        {files.map((file: FileType, index) => (
          <FileItem key={index}>
            <DivName onClick={() => handleView(file)}>
              <u>{file.name}</u>
            </DivName>
            {canDelete && (
              <Icon
                Icon={IoCloseSharp}
                onClick={() =>
                  file.id ? setSelectedDelete(file) : onDelete(file)
                }
              />
            )}
          </FileItem>
        ))}
      </FileListUl>

      <FileViewer
        showModal={!!selectedFile}
        files={files}
        selectedFile={selectedFile}
        onClose={handleCloseViewer}
      />

      <ModalConfirm
        showModal={!!selectedDelete}
        title={`${t('Delete')} ${t('File')}`}
        text={`${t('ConfirmDeleteFile')}`}
        subtitle={`${t('ActionCannotBeUndone')}`}
        onClose={() => setSelectedDelete(null)}
        onConfirm={() => handleDelete(selectedDelete!)}
      />
    </Container>
  );
};

export default FileList;
