import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveStructureMaterialMutationVariables = Types.Exact<{
  data: Types.SaveStructureMaterialInput;
}>;

export type SaveStructureMaterialMutation = {
  __typename?: 'Mutation';
  saveStructureMaterial: string;
};

export const SaveStructureMaterialDocument = gql`
  mutation SaveStructureMaterial($data: SaveStructureMaterialInput!) {
    saveStructureMaterial(data: $data)
  }
`;

export type SaveStructureMaterialMutationFn = Apollo.MutationFunction<
  SaveStructureMaterialMutation,
  SaveStructureMaterialMutationVariables
>;

export type SaveStructureMaterialMutationResult =
  Apollo.MutationResult<SaveStructureMaterialMutation>;

export type SaveStructureMaterialMutationOptions = Apollo.BaseMutationOptions<
  SaveStructureMaterialMutation,
  SaveStructureMaterialMutationVariables
>;
