import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface InputFileProps {
  $error?: boolean;
}

export const InputFileStyled = styled.div<InputFileProps>`
  display: flex;
  flex-direction: column;
  padding: 15px;
  display: flex;
  gap: 10px;
  box-sizing: border-box;

  input[type='file'] {
    cursor: pointer;
    border: ${(props) =>
      props.$error ? `1px solid ${Color.Red1}` : `1px solid ${Color.Brown1}`};
    padding: 0 15px 0 0;
    border-radius: 25px;
    color: ${(props) => (props.$error ? Color.Red1 : Color.Brown1)};
  }

  input[type='file']::file-selector-button {
    cursor: pointer;
    background: ${Color.Brown1};
    color: ${Color.Gray1};
    padding: 8px;
    border-radius: 25px;
    border-end-end-radius: 0;
    border-start-end-radius: 0;
    border: 0;
  }
`;

export const ErrorStyled = styled.div`
  color: ${Color.Red1};
  font-size: ${FontSize.Xsmall};
  height: 20px;
  margin-bottom: 5px;
`;
