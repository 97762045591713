import styled from 'styled-components';

interface TableRowContentProps {
  $hover: boolean;
}

export const TableRowContent = styled.tr<TableRowContentProps>`
  &:hover {
    background-color: #f1f1f1;
  }

  cursor: ${(props) => (props.$hover ? 'pointer' : 'default')};
`;
