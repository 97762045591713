import { z } from 'zod';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputText from '../../../../Molecules/InputText/InputText';
import DatepickerInput from '../../../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../../../Molecules/InputSelectSearch/InputSelectSearch';
import Button from '../../../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  DivButton,
  DivContainer,
  DivInput,
  DviContent
} from './FilterReadingsCMGPage.styles.ts';

const FilterReadingsCMGPageSchema = z.object({
  instrumentName: z.string().optional().nullable(),
  readingDate: z.date().optional().nullable(),
  status: z
    .object({
      value: z.string().optional().nullable(),
      label: z.string().optional().nullable()
    })
    .optional()
    .nullable(),
  hasReading: z
    .object({
      value: z.string().optional().nullable(),
      label: z.string().optional().nullable()
    })
    .optional()
    .nullable()
});

export type FilterReadingsCMGPage = z.infer<typeof FilterReadingsCMGPageSchema>;

const FilterReadingsCMGPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t: translate } = useTranslation();

  const statusOptions = [
    { value: 'alert', label: translate('Alert') },
    { value: 'attention', label: translate('Attention') },
    { value: 'emergency', label: translate('Emergency') }
  ];

  const hasReadingOptions = [
    { value: 'true', label: translate('Yes') },
    { value: 'false', label: translate('No') },
    { value: 'all', label: translate('all') }
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FilterReadingsCMGPage>({
    resolver: zodResolver(FilterReadingsCMGPageSchema),
    defaultValues: {
      instrumentName: searchParams.get('instrumentName') || '',
      readingDate: searchParams.get('readingDate')
        ? new Date(searchParams.get('readingDate')!)
        : undefined,
      status: searchParams.get('status')
        ? statusOptions.find(
            (option) => option.value === searchParams.get('status')
          )
        : null,
      hasReading: searchParams.get('hasReading')
        ? hasReadingOptions.find(
            (option) => option.value === searchParams.get('hasReading')
          )
        : null
    }
  });

  const handleFilters = (data: FilterReadingsCMGPage) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      data.instrumentName &&
        newParams.set('instrumentName', data.instrumentName);
      data.readingDate &&
        newParams.set('readingDate', data.readingDate.toISOString());
      data.status?.value && newParams.set('status', data.status.value);
      data.hasReading?.value &&
        newParams.set('hasReading', data.hasReading.value);
      newParams.delete('filter');
      return newParams;
    });
  };

  const handleClearFilters = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete('instrumentName');
      newParams.delete('readingDate');
      newParams.delete('status');
      newParams.delete('hasReading');
      return newParams;
    });
    reset({
      instrumentName: '',
      readingDate: null,
      status: null,
      hasReading: null
    });
  };

  return (
    <DivContainer>
      <DviContent
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(handleFilters)();
          }
        }}
      >
        <DivInput>
          <InputText
            width="300px"
            label="Instrument"
            type="text"
            control={control}
            name="instrumentName"
            error={!!errors.instrumentName}
            errorMessage={errors.instrumentName?.message}
          />
        </DivInput>
        <DivInput>
          <DatepickerInput
            label="ReadingDate"
            control={control}
            name="readingDate"
            placeholder="NoInformated"
            time={false}
            error={!!errors.readingDate}
            errorMessage={errors.readingDate?.message}
          />
        </DivInput>
        <DivInput>
          <InputSelectSearch
            width="300px"
            label="status"
            placeholder="select"
            options={statusOptions}
            control={control}
            name="status"
            error={!!errors.status}
            errorMessage={errors.status?.message}
          />
        </DivInput>
        <DivInput>
          <InputSelectSearch
            width="300px"
            label="hasReading"
            placeholder="select"
            options={hasReadingOptions}
            control={control}
            name="hasReading"
            error={!!errors.hasReading}
            errorMessage={errors.hasReading?.message}
          />
        </DivInput>
      </DviContent>
      <DivButton>
        <Button
          variant="primary"
          size="medium"
          text="clean"
          onClick={handleClearFilters}
        />
        <Button
          variant="primary"
          size="medium"
          text="Filter"
          onClick={handleSubmit(handleFilters)}
        />
      </DivButton>
    </DivContainer>
  );
};

export default FilterReadingsCMGPage;
