import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { z, ZodIssueCode } from 'zod';
import {
  CreatePiezometerMutation,
  CreatePiezometerMutationVariables,
  CreatePiezometerDocument
} from '../../../../data/graphql/generated/addPiezometerInstrument';
import Enums from '../../../../utils/enumns';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import {
  ListInstrumentTypesQuery,
  ListInstrumentTypesQueryVariables,
  ListInstrumentTypesDocument
} from '../../../../data/graphql/query/generated/listIntrumentsTypes.query';
import {
  MainContainer,
  BorderContainer,
  BorderContainerSpecificInfos,
  Footer,
  HolderAlertLevel,
  HolderCommonInfos,
  HolderContainerTitle,
  HolderSpecificInfoInstrumentTitle,
  HolderSpecificsInfo,
  HolderTitle,
  DivDrained
} from './RegisterPiezometer.styles';
import Text from '../../Atoms/Text/Text';
import { DivInputs } from '../EditPiezometerAndInaPage/EditPiezometerAndInaPage.styles';
import InputText from '../../Molecules/InputText/InputText';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputFileWithList from '../../Organisms/InputFileWithList/InputFileWithList';
import Button from '../../Atoms/Button/Button';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import {
  ListSectionsByStructureQuery,
  ListSectionsByStructureQueryVariables,
  ListSectionsByStructureDocument
} from '../../../../data/graphql/query/generated/listSectionsByStructure.query';
import { TiDelete, TiEdit } from 'react-icons/ti';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import EditAlertLevelModal from '../../Organisms/EditANMAlertLevels/EditAnmAlertLevels';
import { createAlertLevelSchema, AlertLevelType } from './piezometerSchemas';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import { ListPiezometersByStructureDocument } from '../../../../data/graphql/query/generated/listPiezometersByStructure.query';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import { ListInstrumentsByStructurePagDocument } from '../../../../data/graphql/query/generated/listInstrumentsByStructurePag.query';

const alertLevelSchema = createAlertLevelSchema();

type alertLevelType = z.infer<typeof alertLevelSchema>;

const drainedSchema = z
  .object({
    value1: z.string().nullable(),
    signal1: z.object({
      value: z.string().nullable(),
      label: z.string().nullable()
    }),
    fs: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .refine((val) => val.value !== '', {
        message: 'fillRequiredFields'
      }),
    signal2: z.object({
      value: z.string().nullable(),
      label: z.string().nullable()
    }),
    value2: z.string().nullable()
  })
  .superRefine((data, ctx) => {
    const { signal1, value1, signal2, value2 } = data;

    if (signal1.value && !value1) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['value1'],
        message: 'fillValueWhenSignalProvided'
      });
    }

    if (!signal1.value && value1) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['signal1'],
        message: 'fillSignalWhenValueProvided'
      });
    }

    if (signal2.value && !value2) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['value2'],
        message: 'fillValueWhenSignalProvided'
      });
    }

    if (!signal2.value && value2) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['signal2'],
        message: 'fillSignalWhenValueProvided'
      });
    }
  });

type drainedType = z.infer<typeof drainedSchema>;

const instrumentInfosSchema = z
  .object({
    name: z.string().min(1, 'fieldIsRequired'),
    coordinateE: z.string().min(1, 'fieldIsRequired'),
    coordinateN: z.string().min(1, 'fieldIsRequired'),
    isUTM: z.boolean(),
    installationSite: z.object({
      value: z.string().min(1, { message: 'fieldIsRequired' }),
      label: z.string()
    }),
    status: z.boolean(),
    installationDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    activationDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    referenceDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    typeOfReading: z.object({
      value: z.string().min(1, { message: 'fieldIsRequired' }),
      label: z.string()
    }),
    docs: z.array(z.any()).optional(),
    longitudinalSection: z
      .object({
        value: z.string(),
        label: z.string(),
        id: z.string()
      })
      .optional()
      .nullable(),
    crossSection: z
      .object({
        value: z.string(),
        label: z.string(),
        id: z.string()
      })
      .optional()
      .nullable(),
    topCote: z.string().min(1, 'fieldIsRequired'),
    bottomCote: z.string().min(1, 'fieldIsRequired'),
    alertLevelOption: z.object({
      value: z.string().min(1, { message: 'fieldIsRequired' }),
      label: z.string()
    }),
    alertLevels: z.array(alertLevelSchema)
  })
  .superRefine((data, ctx) => {
    const {
      installationDate,
      activationDate,
      coordinateE,
      coordinateN,
      isUTM,
      topCote,
      bottomCote,
      alertLevels,
      alertLevelOption
    } = data;
    const coordN = parseFloat(coordinateN);
    const coordE = parseFloat(coordinateE);

    if (parseFloat(bottomCote) > parseFloat(topCote)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['bottomCote'],
        message: 'topQuotaSmallerThanBottomQuota'
      });
    }

    if (
      activationDate &&
      installationDate &&
      activationDate < installationDate
    ) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['activationDate'],
        message: 'dateActivationIsLessThanInstallationDate'
      });
    }

    if (!isUTM && (coordE > 90 || coordE < -90)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateE'],
        message: 'ValidationCoordinateE'
      });
    }

    if (!isUTM && (coordN > 180 || coordN < -180)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateN'],
        message: 'ValidationCoordinateN'
      });
    }

    if (isUTM && coordE < 0) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateE'],
        message: 'coordinateUtmCannotBeNegative'
      });
    }

    if (isUTM && coordN < 0) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateN'],
        message: 'coordinateUtmCannotBeNegative'
      });
    }

    if (alertLevelOption.value === 'Other' && alertLevels.length === 0) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['alertLevels'],
        message: 'addAtLeastOneAlertLevel'
      });
      toastfyError('addAtLeastOneAlertLevel');
    }
  });

type InstrumentInfosType = z.infer<typeof instrumentInfosSchema>;

const RegisterPiezometer = () => {
  const { t: translate } = useTranslation();
  const [files, setFiles] = useState<FileType[]>([]);
  const { structureId, instrumentType } = useParams();
  const navigate = useNavigate();
  const { parseErrorMessage } = useErrorsTreatment();
  const [selectedAlertLevelIndex, setSelectedAlertLevelIndex] = useState<
    number | null
  >(null);
  const { TypeOfInstallation, TypeOfReading, instrumentsEnum } = Enums();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [alertLevelANMData, setAlertLevelANMData] =
    useState<AlertLevelType | null>(null);

  const signals = [
    { label: 'N/A', id: '', value: '' },
    { label: '≥', id: '≥', value: '≥' },
    { label: '≤', id: '≤', value: '≤' },
    { label: '>', id: '>', value: '>' },
    { label: '<', id: '<', value: '<' }
  ];

  const resolutionANM = [
    {
      name: 'ATENÇÃO',
      drained: '1.30 ≤ FS < 1.50',
      undrained: '1.20 ≤ FS < 1.30',
      coteValue: '0'
    },
    {
      name: 'ALERTA',
      drained: '1.10 ≤ FS < 1.30',
      undrained: '1.00 ≤ FS < 1.20',
      coteValue: '0'
    },
    {
      name: 'EMERGÊNCIA',
      drained: '1.10',
      undrained: '1.00',
      coteValue: '0'
    }
  ];

  const alertLevelOptions = [
    {
      label: `${translate('Resolution')} ANM`,
      value: 'ResolutionANM'
    },
    {
      label: translate('customAlertLevel'),
      value: 'Other'
    }
  ];

  const {
    handleSubmit: handleSubmitLevel,
    control: controlLevel,
    reset: alertReset,
    formState: { errors: errorsLevel },
    setValue
  } = useForm<AlertLevelType>({
    resolver: zodResolver(alertLevelSchema)
  });

  const {
    handleSubmit: handleSubmitDrained,
    control: controlDrained,
    reset: drainedReset,
    formState: { errors: errorsDrained }
  } = useForm<drainedType>({
    resolver: zodResolver(drainedSchema),
    defaultValues: {
      value1: '',
      signal1: {
        label: '',
        value: ''
      },
      fs: {
        label: '',
        value: ''
      },
      signal2: {
        label: '',
        value: ''
      },
      value2: ''
    }
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    setValue: setInstrumentValue
  } = useForm<InstrumentInfosType>({
    resolver: zodResolver(instrumentInfosSchema),
    defaultValues: {
      installationDate: undefined,
      activationDate: undefined,
      referenceDate: undefined,
      status: true,
      isUTM: true,
      installationSite: {
        value: '',
        label: ''
      },
      typeOfReading: {
        value: '',
        label: ''
      },
      alertLevelOption: {
        value: '',
        label: ''
      },
      alertLevels: []
    }
  });

  const {
    fields: alertLevelsData,
    append,
    remove,
    replace,
    update
  } = useFieldArray({
    control,
    name: 'alertLevels'
  });

  const watchedAlertLevels = watch('alertLevels');
  const { data: listInstruments } = useQuery<
    ListInstrumentTypesQuery,
    ListInstrumentTypesQueryVariables
  >(ListInstrumentTypesDocument, {
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const { data: listSections } = useQuery<
    ListSectionsByStructureQuery,
    ListSectionsByStructureQueryVariables
  >(ListSectionsByStructureDocument, {
    variables: {
      structureId: structureId!
    }
  });

  const sectionLongitudinalOptions = listSections?.listSectionsByStructure
    .filter((item) => item.type === 'Longitudinal')
    .map((longitude) => ({
      id: longitude.id,
      value: longitude.id,
      label: longitude.name
    }));

  const sectionCrossOptions = listSections?.listSectionsByStructure
    .filter((item) => item.type === 'Cross')
    .map((transversal) => ({
      id: transversal.id,
      value: transversal.id,
      label: transversal.name
    }));

  const piezometerTypeId = listInstruments?.listInstrumentTypes.find(
    (type) =>
      instrumentType &&
      type.instrumentType ===
        instrumentsEnum[instrumentType as keyof typeof instrumentsEnum]
  );

  const formattedAlertLevelsData = watchedAlertLevels.map((alert) => ({
    ...alert,
    name: alert.name || '-',
    drained: alert.drained || '-',
    undrained: alert.undrained || '-',
    coteValue: alert.coteValue || '-'
  }));

  const [createPiezometer] = useMutation<
    CreatePiezometerMutation,
    CreatePiezometerMutationVariables
  >(CreatePiezometerDocument);

  const handleDrained = (dataFromForm: drainedType) => {
    const { value1, signal1, value2, signal2, fs } = dataFromForm;
    if (!value1 && !signal1.value && !value2 && !signal2.value) {
      if (fs?.value === `FS ${translate('Drained')}`) {
        setValue('drained', '');
        return;
      }

      if (fs?.value === `FS ${translate('NotDrained')}`) {
        setValue('undrained', '');
        return;
      }
    }

    const levelValue =
      `${value1 || ''} ${signal1.value || ''} FS ${signal2.value || ''} ${value2 || ''}`
        .replace(/\s+/g, ' ')
        .trim();

    if (fs.value === `FS ${translate('Drained')}`) {
      setValue('drained', levelValue);
    }

    if (fs.value === `FS ${translate('NotDrained')}`) {
      setValue('undrained', levelValue);
    }
  };

  const handleAddAlertLevel = (data: alertLevelType) => {
    append(data);
    alertReset();
    drainedReset();
  };

  const handleSaveAlertLevel = (
    updatedAlertLevel: AlertLevelType,
    index: number
  ) => {
    update(index, updatedAlertLevel);
    setIsEditModalOpen(false);
  };

  const handleEditAlertLevel = (alertLevelANM: alertLevelType) => {
    const indexByANMAlertLevel = resolutionANM.findIndex(
      (item) => item.name === alertLevelANM.name
    );
    setAlertLevelANMData(alertLevelANM);
    setSelectedAlertLevelIndex(indexByANMAlertLevel);
    setIsEditModalOpen(true);
  };

  const handleRemoveAlertLevel = (index: number) => {
    remove(index);
  };

  const handleFileChange = (newFiles: FileList) => {
    const updatedFiles = Array.from(newFiles).map((file) => ({
      id: null,
      name: file.name,
      file: file,
      url: URL.createObjectURL(file)
    }));
    setFiles((prev) => [...prev, ...updatedFiles]);
  };

  const handleDeleteFile = (fileToDelete: FileType) => {
    const updatedFiles = files.filter(
      (file) => file.file !== fileToDelete.file
    );
    setFiles(updatedFiles);
  };

  const handleAlertLevelOptionChange = (selectedOption: OptionType) => {
    setInstrumentValue(
      'alertLevelOption',
      selectedOption as {
        value: string;
        label: string;
      }
    );
    if (selectedOption.value === 'ResolutionANM') {
      const deepCopiedResolutionANM = resolutionANM.map((item) => ({
        ...item
      }));
      replace(deepCopiedResolutionANM);
    } else if (selectedOption.value === 'Other') {
      replace([]);
    }
  };

  const handleRegisterPiezometer = (data: InstrumentInfosType) => {
    const operationalStatus = data.status
      ? InstrumentStatusEnum.Active
      : InstrumentStatusEnum.Inactive;

    const piezometerGeneralInfo = {
      typeId: piezometerTypeId?.id as string,
      name: data.name,
      coordinateE: parseFloat(data.coordinateE.toString()),
      coordinateN: parseFloat(data.coordinateN.toString()),
      installationDate: new Date(data.installationDate),
      activationDate: new Date(data.activationDate),
      isUTM: data.isUTM,
      operationalStatus: operationalStatus,
      readingType: data.typeOfReading.value as InstrumentReadingTypeEnum,
      installLocation: data.installationSite.value,
      docs: files.map((file) => file.file),
      sectionsId: [data.crossSection?.id, data.longitudinalSection?.id].filter(
        (id) => id
      ) as string[]
    };

    const piezoemeterAlertLevels = alertLevelsData.map((alertLevel) => ({
      name: alertLevel.name,
      coteValue: parseFloat(alertLevel.coteValue),
      drained: alertLevel.drained!.toString(),
      undrained: parseFloat(alertLevel.undrained!).toString()
    }));
    createPiezometer({
      variables: {
        structureInfo: {
          structureId: structureId!,
          associatedStructureId: null
        },
        generalInfo: piezometerGeneralInfo,
        alertLevels: piezoemeterAlertLevels,
        specificInfo: {
          bottomCote: parseFloat(data.bottomCote),
          topCote: parseFloat(data.topCote),
          referenceDate: new Date(data.referenceDate)
        }
      },
      refetchQueries: [
        {
          query: ListInstrumentsByStructurePagDocument,
          variables: {
            instrumentType:
              instrumentType === 'ina'
                ? InstrumentTypeEnum.Ina
                : InstrumentTypeEnum.Piezometer,
            structureId: structureId!,
            pageInfo: {
              limit: 10,
              page: 1
            },
            filters: {}
          }
        },
        {
          query: ListPiezometersByStructureDocument,
          variables: {
            structureInfo: {
              structureId: structureId!,
              associatedStructureId: null
            }
          }
        }
      ],
      onCompleted: () => {
        toastfySuccess('successRegisterInstrument');
        navigate(`/${structureId}/monitoring/instrument/${instrumentType}`);
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <>
      <MainContainer>
        <HolderCommonInfos>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('basicInfos')}
            </Text>
          </HolderContainerTitle>
          <BorderContainer>
            <DivInputs>
              <InputText
                width="300px"
                control={control}
                label={translate('name')}
                name={'name'}
                type={'text'}
                error={!!errors.name}
                errorMessage={errors?.name?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputText
                width="300px"
                control={control}
                label={translate('coordinateN')}
                name={'coordinateN'}
                type={'text'}
                error={!!errors.coordinateN}
                errorMessage={errors?.coordinateN?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputText
                width="300px"
                control={control}
                label={translate('coordinateE')}
                name={'coordinateE'}
                type={'text'}
                error={!!errors.coordinateE}
                errorMessage={errors?.coordinateE?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputCheckbox
                register={register}
                label={translate('coordinateUTM')}
                name={'isUTM'}
                value={watch('isUTM')}
              />
              <InputCheckbox
                register={register}
                label={translate('Active')}
                name={'status'}
                value={watch('status')}
              />
            </DivInputs>
            <DivInputs>
              <InputSelectSearch
                control={control}
                placeholder={translate('installationSite')}
                width="300px"
                label={translate('installationSite')}
                options={TypeOfInstallation}
                name="installationSite"
                error={!!errors.installationSite}
                errorMessage={errors?.installationSite?.message}
                isClearable
              />
            </DivInputs>
            <DivInputs>
              <InputSelectSearch
                control={control}
                placeholder={translate('typeOfReading')}
                width="300px"
                label={translate('typeOfReading')}
                options={TypeOfReading}
                name="typeOfReading"
                error={!!errors.typeOfReading}
                errorMessage={errors?.typeOfReading?.message}
                isClearable
              />
            </DivInputs>
            <DivInputs>
              <DatepickerInput
                control={control}
                label={translate('installationDate')}
                name="installationDate"
                placeholder={translate('NoInformated')}
                time={false}
                error={!!errors.installationDate}
                errorMessage={errors?.installationDate?.message}
              />
            </DivInputs>
            <DivInputs>
              <DatepickerInput
                control={control}
                label={translate('activationDate')}
                name="activationDate"
                placeholder={translate('NoInformated')}
                time={false}
                error={!!errors.activationDate}
                errorMessage={errors?.activationDate?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputFileWithList
                name="docs"
                accept=".xlsx,.pdf,.docx,.xls,.xlsx,.doc"
                label={translate('File')}
                files={files}
                multiple
                onChange={(event) => {
                  if (event.target.files) {
                    handleFileChange(event.target.files);
                  }
                }}
                canDelete
                onDelete={(file) => handleDeleteFile(file)}
              />
            </DivInputs>
            <Footer>
              <Button
                variant="primary"
                onClick={handleSubmit(handleRegisterPiezometer)}
                text={translate('RegisterInstrument')}
              />
            </Footer>
          </BorderContainer>
        </HolderCommonInfos>
        <HolderSpecificsInfo>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('instrumentInformation')}
            </Text>
          </HolderContainerTitle>
          <BorderContainerSpecificInfos>
            <HolderSpecificInfoInstrumentTitle>
              <Text color="black" type="span" weight="bold">
                {translate('controlCotes')}
              </Text>
            </HolderSpecificInfoInstrumentTitle>
            <DivInputs>
              <InputText
                width="300px"
                control={control}
                label={translate('topCote')}
                name={'topCote'}
                type={'text'}
                error={!!errors.topCote}
                errorMessage={errors?.topCote?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputText
                width="300px"
                control={control}
                label={translate('bottomCote')}
                name={'bottomCote'}
                type={'text'}
                error={!!errors.bottomCote}
                errorMessage={errors?.bottomCote?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputSelectSearch
                control={control}
                placeholder={translate('longitudinalSection')}
                width="300px"
                label={translate('longitudinalSection')}
                options={sectionLongitudinalOptions || []}
                name="longitudinalSection"
                error={!!errors.longitudinalSection}
                errorMessage={errors?.longitudinalSection?.message}
                isClearable
              />
            </DivInputs>
            <DivInputs>
              <InputSelectSearch
                control={control}
                placeholder={translate('crossSection')}
                width="300px"
                label={translate('crossSection')}
                options={sectionCrossOptions || []}
                name="crossSection"
                error={!!errors.crossSection}
                errorMessage={errors?.crossSection?.message}
                isClearable
              />
            </DivInputs>
            <DivInputs>
              <InputSelectSearch
                control={control}
                name="alertLevelOption"
                placeholder={translate('AlertLevel')}
                width="300px"
                label={translate('AlertLevel')}
                options={alertLevelOptions}
                error={!!errors.alertLevelOption}
                errorMessage={errors?.alertLevelOption?.message}
                onChange={(option: OptionType) => {
                  handleAlertLevelOptionChange(option);
                }}
              />
            </DivInputs>
            <DivInputs>
              <DatepickerInput
                control={control}
                label={translate('ReferenceDate')}
                name="referenceDate"
                placeholder={translate('NoInformated')}
                time={false}
                error={!!errors.referenceDate}
                errorMessage={errors?.referenceDate?.message}
              />
            </DivInputs>
            {watch('alertLevelOption.value') === 'Other' && (
              <>
                <HolderSpecificInfoInstrumentTitle>
                  <Text color="black" type="span" weight="bold">
                    {translate('AlertLevel')}
                  </Text>
                </HolderSpecificInfoInstrumentTitle>
                <DivDrained>
                  <InputText
                    width="100px"
                    control={controlDrained}
                    label={translate('value')}
                    name={'value1'}
                    type={'number'}
                    error={!!errorsDrained.value1}
                    errorMessage={errorsDrained?.value1?.message}
                  />
                  <InputSelectSearch
                    control={controlDrained}
                    placeholder={translate('signal')}
                    width="100px"
                    label={translate('signal')}
                    options={signals}
                    name="signal1"
                    error={!!errorsDrained.signal1}
                    errorMessage={errorsDrained?.signal1?.message}
                  />
                  <InputSelectSearch
                    control={controlDrained}
                    placeholder={translate('Drained')}
                    width="180px"
                    label={translate('Drained')}
                    options={[
                      {
                        label: `FS ${translate('Drained')}`,
                        value: `FS ${translate('Drained')}`
                      },
                      {
                        label: `FS ${translate('NotDrained')}`,
                        value: `FS ${translate('NotDrained')}`
                      }
                    ]}
                    name="fs"
                    error={!!errorsDrained.fs}
                    errorMessage={errorsDrained?.fs?.message}
                  />
                  <InputSelectSearch
                    control={controlDrained}
                    placeholder={translate('signal')}
                    width="100px"
                    label={translate('signal')}
                    options={signals}
                    name="signal2"
                    error={!!errorsDrained.signal2}
                    errorMessage={errorsDrained?.signal2?.message}
                  />
                  <InputText
                    width="100px"
                    control={controlDrained}
                    label={translate('value')}
                    name={'value2'}
                    type={'number'}
                    error={!!errorsDrained.value2}
                    errorMessage={errorsDrained?.value2?.message}
                  />
                </DivDrained>
                <Footer>
                  <Button
                    size="medium"
                    variant="secondary"
                    onClick={handleSubmitDrained(handleDrained)}
                    text={translate('addSecurityFactor')}
                  />
                </Footer>
                <DivInputs>
                  <InputText
                    width="300px"
                    control={controlLevel}
                    label={translate('name')}
                    name={'name'}
                    type={'text'}
                    error={!!errorsLevel.name}
                    errorMessage={errorsLevel?.name?.message}
                  />
                </DivInputs>
                <DivInputs>
                  <InputText
                    width="300px"
                    control={controlLevel}
                    label={translate('coteValue')}
                    name={'coteValue'}
                    type={'number'}
                    error={!!errorsLevel.coteValue}
                    errorMessage={errorsLevel?.coteValue?.message}
                  />
                </DivInputs>
                <DivInputs>
                  <InputText
                    width="300px"
                    control={controlLevel}
                    label={`FS ${translate('Drained')}`}
                    name={'drained'}
                    type={'text'}
                    error={!!errorsLevel.drained}
                    errorMessage={errorsLevel?.drained?.message}
                    disabled
                  />
                </DivInputs>
                <DivInputs>
                  <InputText
                    width="300px"
                    control={controlLevel}
                    label={`FS ${translate('NotDrained')}`}
                    name={'undrained'}
                    type={'text'}
                    error={!!errorsLevel.undrained}
                    errorMessage={errorsLevel?.undrained?.message}
                    disabled
                  />
                </DivInputs>

                <Footer>
                  <Button
                    variant="primary"
                    onClick={handleSubmitLevel(handleAddAlertLevel)}
                    text={translate('newAlertLevel')}
                  />
                </Footer>
              </>
            )}
            <HolderAlertLevel>
              <HolderTitle>
                <Text color="white" type="span" weight="default">
                  {translate('alertLevels')}
                </Text>
              </HolderTitle>
              <TableComponent
                tableData={formattedAlertLevelsData || []}
                columns={[
                  { key: 'name', label: translate('name') },
                  { key: 'drained', label: translate('Drained') },
                  { key: 'undrained', label: translate('NotDrained') },
                  { key: 'coteValue', label: translate('cote') }
                ]}
                actions={[
                  {
                    name: translate('Edit'),
                    icon: <TiEdit />,
                    onClick: (alertLevel) => handleEditAlertLevel(alertLevel),
                    canShow: ''
                  },
                  {
                    name: translate('Delete'),
                    icon: <TiDelete />,
                    onClick: (index) => handleRemoveAlertLevel(index),
                    canShow: ''
                  }
                ]}
              />
            </HolderAlertLevel>
          </BorderContainerSpecificInfos>
        </HolderSpecificsInfo>
      </MainContainer>

      <ViewModal
        showModal={
          isEditModalOpen &&
          alertLevelANMData !== null &&
          selectedAlertLevelIndex !== null
        }
        width="600px"
        title={translate('EditAlertLevel')}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        component={
          <EditAlertLevelModal
            onSave={handleSaveAlertLevel}
            alertLevel={alertLevelANMData!}
            index={selectedAlertLevelIndex!}
          />
        }
      />
    </>
  );
};

export default RegisterPiezometer;
