import { PropsWithChildren } from 'react';
import { TableRowProps } from './TableRow.interface';
import { TableRowContent } from './TableRow.styles';

export const TableRow = ({
  children,
  onClick,
  hover
}: PropsWithChildren<TableRowProps>) => {
  return (
    <TableRowContent onClick={onClick} $hover={hover}>
      {children}
    </TableRowContent>
  );
};
