import React, { ReactNode } from 'react';

import {
  CardInfoNewAto,
  HolderButtonLabel,
  HolderCardHeader
} from './CardInfoStyles';
import Text from '../../Atoms/Text/Text';
import Button from '../../Atoms/Button/Button';
type CardProps = {
  title?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  children: ReactNode;
  cardTitle?: string;
  canAdd?: boolean;
};

const CardAtoInfo: React.FC<CardProps> = ({
  title,
  buttonLabel,
  onButtonClick,
  children,
  cardTitle,
  canAdd = true
}) => {
  return (
    <CardInfoNewAto>
      <HolderCardHeader>
        {cardTitle && (
          <Text weight="bold" size="medium" type="h5" color="black">
            {cardTitle}
          </Text>
        )}
        {onButtonClick && title !== undefined && buttonLabel !== undefined && (
          <HolderButtonLabel>
            <Text weight="bold" size="medium" type="h5" color="black">
              {title}
            </Text>
            {canAdd === true ? (
              <Button
                variant="secondary"
                onClick={onButtonClick}
                text={buttonLabel}
              />
            ) : (
              <></>
            )}
          </HolderButtonLabel>
        )}
      </HolderCardHeader>
      {children}
    </CardInfoNewAto>
  );
};

export default CardAtoInfo;
