import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../utils/StringManipulattor';
import Icon from '../Icon/Icon';
import { ButtonProps } from './Button.interfaces';
import {
  ButtonDangerStyled,
  ButtonPrimaryStyled,
  ButtonSecondaryStyled
} from './Button.styles';

const setVariant = {
  primary: ButtonPrimaryStyled,
  secondary: ButtonSecondaryStyled,
  danger: ButtonDangerStyled
};

const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  onClick,
  disabled,
  size = 'medium',
  error,
  icon,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const ButtonComponent = setVariant[variant];
  return (
    <ButtonComponent
      $error={error}
      onClick={onClick}
      disabled={disabled}
      size={size}
      {...props}
    >
      {text && capitalizeFirstLetter(translate(text))}
      {icon && <Icon Icon={icon} variant={'small'} />}
    </ButtonComponent>
  );
};

export default Button;
