import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveGeneralCommentsFilesMutationVariables = Types.Exact<{
  data: Types.SaveInspectionGeneralCommentsFilesInput;
}>;


export type SaveGeneralCommentsFilesMutation = { __typename?: 'Mutation', saveGeneralCommentsFiles: string };


export const SaveGeneralCommentsFilesDocument = gql`
    mutation SaveGeneralCommentsFiles($data: SaveInspectionGeneralCommentsFilesInput!) {
  saveGeneralCommentsFiles(data: $data)
}
    `;
export type SaveGeneralCommentsFilesMutationFn = Apollo.MutationFunction<SaveGeneralCommentsFilesMutation, SaveGeneralCommentsFilesMutationVariables>;
export type SaveGeneralCommentsFilesMutationResult = Apollo.MutationResult<SaveGeneralCommentsFilesMutation>;
export type SaveGeneralCommentsFilesMutationOptions = Apollo.BaseMutationOptions<SaveGeneralCommentsFilesMutation, SaveGeneralCommentsFilesMutationVariables>;