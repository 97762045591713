import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const Container = styled.div`
  background-color: ${Color.White};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding: 20px 0;
  box-sizing: border-box;
`;

export const DivContent = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: start;
  gap: 10px;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;
`;

export const DivHeader = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DivContainerSkeleton = styled.div`
  width: 100%;
  padding-left: 5%;
`;
