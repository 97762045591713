import { useTranslation } from 'react-i18next';
import { TABLE_IDS } from '../../../../../utils/TableConsts';
import Paginator from '../../../Molecules/Paginator/Paginator';
import TableDraggableComponent from '../../../Molecules/TableDraggable/TableDraggableComponent';
import { DivContainer } from './AnomaliesCMGPage.styles';

const AnomaliesCMGPage = () => {
  const { t: translate } = useTranslation();

  const anomaliesColumns = [
    { key: 'inspectionId', label: translate('Inspection') },
    { key: 'date', label: translate('date') },
    { key: 'responsible', label: translate('responsible') },
    { key: 'anomalies', label: translate('Anomalies') },
    { key: 'status', label: translate('response') }
  ];

  const anomaliesTableData = [
    {
      inspectionId: '001',
      date: '2025-01-01',
      responsible: 'João',
      anomalies: 'Trinca',
      status: 'Deficiente'
    },
    {
      inspectionId: '002',
      date: '2025-01-15',
      responsible: 'Maria',
      anomalies: 'Corrosão',
      status: 'Crítico'
    },
    {
      inspectionId: '003',
      date: '2025-02-10',
      responsible: 'Carlos',
      anomalies: 'Fissura',
      status: 'Moderado'
    },
    {
      inspectionId: '004',
      date: '2025-03-05',
      responsible: 'Ana',
      anomalies: 'Deslocamento',
      status: 'Grave'
    },
    {
      inspectionId: '005',
      date: '2025-04-20',
      responsible: 'Pedro',
      anomalies: 'Oxidação',
      status: 'Moderado'
    },
    {
      inspectionId: '006',
      date: '2025-05-25',
      responsible: 'Fernanda',
      anomalies: 'Deformação',
      status: 'Crítico'
    },
    {
      inspectionId: '007',
      date: '2025-06-18',
      responsible: 'Lucas',
      anomalies: 'Desgaste',
      status: 'Deficiente'
    },
    {
      inspectionId: '008',
      date: '2025-07-30',
      responsible: 'Clara',
      anomalies: 'Falta de vedação',
      status: 'Moderado'
    },
    {
      inspectionId: '009',
      date: '2025-08-12',
      responsible: 'Rafael',
      anomalies: 'Falta de fixação',
      status: 'Grave'
    },
    {
      inspectionId: '010',
      date: '2025-09-22',
      responsible: 'Beatriz',
      anomalies: 'Alinhamento irregular',
      status: 'Crítico'
    }
  ];

  const optionsPaginator = [
    {
      value: 10,
      label: '10'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    }
  ];

  return (
    <DivContainer>
      <TableDraggableComponent
        tableId={TABLE_IDS.CMG_ANOMALIES_TABLE}
        title="Anomalies"
        columnsArray={anomaliesColumns}
        tableData={anomaliesTableData || []}
        columnSelect
        showCountColumn
        maxHeight="calc(100vh - 250px)"
        fullScreenOption={true}
        paginator={
          <Paginator options={optionsPaginator} totalPages={1} showInUrl />
        }
      />
    </DivContainer>
  );
};

export default AnomaliesCMGPage;
