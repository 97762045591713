import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { FindGeneralInfoDocument } from '../../../../data/graphql/query/generated/findAtoGeneralInfo.query';
import { FindDataByRecordDocument } from '../../../../data/graphql/query/generated/findDataByRecord';

import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { AddVideoAtoFormProps } from './AddVideoAtoForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddVideoAtoForm.styles';
import { useMutation } from '@apollo/client';
import {
  SaveAtoRecordVideoDocument,
  SaveAtoRecordVideoMutation,
  SaveAtoRecordVideoMutationVariables
} from '../../../../data/graphql/generated/saveRecordVideo';
import {
  UpdateRecordVideoObservationMutation,
  UpdateRecordVideoObservationMutationVariables,
  UpdateRecordVideoObservationDocument
} from '../../../../data/graphql/generated/updateRecordVideoObservation';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const oneMegaByte = 1048576;

const AddVideoAtoForm = ({
  setShowModal,
  recordId,
  editVideo
}: AddVideoAtoFormProps) => {
  const { t: translate } = useTranslation();
  const { atoId } = useParams();
  const [description, setDescription] = useState<string>(
    editVideo?.description || ''
  );
  const [files, setFiles] = useState<FileType[]>([]);
  const { parseErrorMessage } = useErrorsTreatment();
  const [updateRecordVideoObservation] = useMutation<
    UpdateRecordVideoObservationMutation,
    UpdateRecordVideoObservationMutationVariables
  >(UpdateRecordVideoObservationDocument, {});

  const [saveRecordVideo] = useMutation<
    SaveAtoRecordVideoMutation,
    SaveAtoRecordVideoMutationVariables
  >(SaveAtoRecordVideoDocument);

  const addVideoAtoFormSchema = z
    .object({
      description: z.string().min(1, {
        message: translate('fieldIsRequired')
      }),
      files: z.array(z.instanceof(File)).optional()
    })
    .superRefine((data, ctx) => {
      if (files.length === 0) {
        ctx.addIssue({
          path: ['files'],
          message: translate('fieldIsRequired'),
          code: z.ZodIssueCode.custom
        });
      }
    });

  type AddVideoAtoFormSchemaType = z.infer<typeof addVideoAtoFormSchema>;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<AddVideoAtoFormSchemaType>({
    resolver: zodResolver(addVideoAtoFormSchema),
    defaultValues: {
      description: editVideo ? editVideo?.description : ''
    }
  });

  useEffect(() => {
    if (editVideo) {
      setFiles([
        {
          id: editVideo?.id,
          name: editVideo?.name,
          url: editVideo?.url
        }
      ]);
    }
  }, []);

  const handleSubmitAddVideoAtoForm = async (
    data: AddVideoAtoFormSchemaType
  ) => {
    const { description } = data;
    const isInvalid = files.length === 0;
    if (isInvalid) {
      setValue('files', []);
      return;
    }

    try {
      if (editVideo?.id) {
        await updateRecordVideoObservation({
          variables: {
            data: {
              id: editVideo.id,
              observation: description
            }
          },
          refetchQueries: [
            {
              query: FindDataByRecordDocument,
              variables: { recordId: recordId }
            },
            {
              query: FindGeneralInfoDocument,
              variables: { atoId: atoId }
            }
          ],
          onCompleted: () => {
            toastfySuccess(translate('editedSuccessfully'));
          },
          onError: (error) => {
            parseErrorMessage(error);
          }
        });
      } else {
        await saveRecordVideo({
          variables: {
            data: {
              recordId: recordId,
              observation: description,
              video: files[0].file
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: FindDataByRecordDocument,
              variables: { recordId }
            },
            {
              query: FindGeneralInfoDocument,
              variables: { atoId }
            }
          ],
          onCompleted: () => {
            toastfySuccess(translate('registeredSuccessfully'));
          },
          onError: (error) => {
            parseErrorMessage(error);
          }
        });
      }

      setShowModal(false);
    } catch (error) {
      toastfyError(translate('unexpectedError'));
      console.error(error);
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    const fileSize = selectedFiles[0].size;
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = files
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('files', storedStateFiles);
    if (fileSize <= 100 * oneMegaByte) {
      setFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <InputTextarea
          errorMessage={errors.description?.message}
          error={!!errors.description}
          height="100px"
          label={translate('description')}
          name="description"
          onChange={(event) => setDescription(event.target.value)}
          register={register}
          value={description}
          width="500px"
        />
        <InputFileWithList
          register={register}
          canDelete={editVideo && false}
          name="files"
          errorMessage={errors.files?.message}
          error={!!errors.files}
          accept=".avi, .mp4, .mkv, .wmv"
          label=""
          multiple={false}
          disabled={files.length > 0}
          files={files}
          onChange={(event) => handleFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteImage(file)}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={translate('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={translate('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAddVideoAtoForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddVideoAtoForm;
