import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveMassiveStructureShoulderMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  data: Types.SaveMassiveStructureShoulderDataInput;
}>;

export type SaveMassiveStructureShoulderMutation = {
  __typename?: 'Mutation';
  saveMassiveStructureShoulder: {
    __typename?: 'CreatedMassiveStructureShoulderDataType';
    genericData: Array<{
      __typename?: 'MssGenericDataType';
      id: string;
      title: Types.MssGenericDataTitleEnum;
      subTitle?: string | null;
      answer: Types.AnswerEnum;
      observation?: string | null;
      topic?: string | null;
    }>;
    identifiedCracks: Array<{
      __typename?: 'MssIdentifiedCracksType';
      id: string;
      name: string;
      length: string;
      opening: string;
      longitudinalTransverse: string;
      observation?: string | null;
    }>;
    identifiedDeformations: Array<{
      __typename?: 'MssIdentifiedDeformationsType';
      identifiedAnomaly: string;
      id: string;
      local: string;
      observation?: string | null;
    }>;
    identifiedWaterLeaks: Array<{
      __typename?: 'MssIdentifiedWaterLeaksType';
      id: string;
      name: string;
      local: string;
      visibleSolidTransport: string;
      observation?: string | null;
    }>;
  };
};

export const SaveMassiveStructureShoulderDocument = gql`
  mutation SaveMassiveStructureShoulder(
    $inspectionId: String!
    $data: SaveMassiveStructureShoulderDataInput!
  ) {
    saveMassiveStructureShoulder(inspectionId: $inspectionId, data: $data) {
      genericData {
        id
        title
        subTitle
        answer
        observation
        topic
      }
      identifiedCracks {
        id
        name
        length
        opening
        longitudinalTransverse
        observation
      }
      identifiedDeformations {
        identifiedAnomaly
        id
        local
        observation
      }
      identifiedWaterLeaks {
        id
        name
        local
        visibleSolidTransport
        observation
      }
    }
  }
`;

export type SaveMassiveStructureShoulderMutationFn = Apollo.MutationFunction<
  SaveMassiveStructureShoulderMutation,
  SaveMassiveStructureShoulderMutationVariables
>;

export type SaveMassiveStructureShoulderMutationResult =
  Apollo.MutationResult<SaveMassiveStructureShoulderMutation>;

export type SaveMassiveStructureShoulderMutationOptions =
  Apollo.BaseMutationOptions<
    SaveMassiveStructureShoulderMutation,
    SaveMassiveStructureShoulderMutationVariables
  >;
