import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { client } from '../../../../data/apollo.client';
import {
  DeleteRecordActivitiesImageDocument,
  DeleteRecordActivitiesImageMutation,
  DeleteRecordActivitiesImageMutationVariables
} from '../../../../data/graphql/generated/deleteRecordActivitiesImage';
import {
  DeleteRecordActivitiesVideoDocument,
  DeleteRecordActivitiesVideoMutation,
  DeleteRecordActivitiesVideoMutationVariables
} from '../../../../data/graphql/generated/deleteRecordActivitiesVideo';
import {
  SaveRecordActivitiesDocument,
  SaveRecordActivitiesMutation,
  SaveRecordActivitiesMutationVariables
} from '../../../../data/graphql/generated/saveRecordActivities';
import {
  UpdateRecordActivitiesDocument,
  UpdateRecordActivitiesMutation,
  UpdateRecordActivitiesMutationVariables
} from '../../../../data/graphql/generated/updateRecordActivities';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { API_URL } from '../../../../utils/const';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { AddActivityFormProps } from './AddActivityForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddActivityForm.styles';
import {
  SaveActivityImagesMutation,
  SaveActivityImagesMutationVariables,
  SaveActivityImagesDocument
} from '../../../../data/graphql/generated/saveAtoRecordImageActivity';
import {
  SaveAtoRecordActivityVideoMutation,
  SaveAtoRecordActivityVideoMutationVariables,
  SaveAtoRecordActivityVideoDocument
} from '../../../../data/graphql/generated/saveAtoRecordVideoActivity';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const oneMegaByte = 1048576;

const addActivityFormSchema = z.object({
  activitiesCarriedOut: z
    .string()
    .min(1, {
      message: 'fieldIsRequired'
    })
    .refine((val) => val !== '', {
      message: 'fieldIsRequired'
    }),
  imageFiles: z.any().optional(),
  videoFiles: z.any().optional()
});

type AddActivityFormSchemaType = z.infer<typeof addActivityFormSchema>;

const AddActivityForm = ({
  setEdit,
  recordId,
  editActivity
}: AddActivityFormProps) => {
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();

  const [activitiesCarriedOut, setActivitiesCarriedOut] = useState<string>(
    editActivity?.description ?? ''
  );
  const [imageFiles, setImageFiles] = useState<FileType[]>([]);
  const [videoFiles, setVideoFiles] = useState<FileType[]>([]);

  const [saveRecordActivities] = useMutation<
    SaveRecordActivitiesMutation,
    SaveRecordActivitiesMutationVariables
  >(SaveRecordActivitiesDocument);

  const [updateRecordActivities] = useMutation<
    UpdateRecordActivitiesMutation,
    UpdateRecordActivitiesMutationVariables
  >(UpdateRecordActivitiesDocument);

  const [deleteRecordActivitiesImage] = useMutation<
    DeleteRecordActivitiesImageMutation,
    DeleteRecordActivitiesImageMutationVariables
  >(DeleteRecordActivitiesImageDocument);

  const [deleteRecordActivitiesVideo] = useMutation<
    DeleteRecordActivitiesVideoMutation,
    DeleteRecordActivitiesVideoMutationVariables
  >(DeleteRecordActivitiesVideoDocument);

  const [saveActivityImage] = useMutation<
    SaveActivityImagesMutation,
    SaveActivityImagesMutationVariables
  >(SaveActivityImagesDocument);

  const [saveActivityVideo] = useMutation<
    SaveAtoRecordActivityVideoMutation,
    SaveAtoRecordActivityVideoMutationVariables
  >(SaveAtoRecordActivityVideoDocument);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<AddActivityFormSchemaType>({
    resolver: zodResolver(addActivityFormSchema),
    defaultValues: {
      activitiesCarriedOut: editActivity?.description,
      imageFiles: editActivity?.id
        ? [
            ...(imageFiles || []),
            ...editActivity.images.map((image) => {
              const file = new File([image.image], image.image, {
                type: 'image'
              });
              return file;
            })
          ]
        : undefined,
      videoFiles: editActivity
        ? [
            ...(videoFiles || []),
            ...editActivity.videos.map((video) => {
              const file = new File([video.video], video.video, {
                type: 'video'
              });
              return file;
            })
          ]
        : undefined
    }
  });

  useEffect(() => {
    if (editActivity?.id) {
      const editImageFiles: FileType[] = [
        ...(imageFiles || []),
        ...editActivity.images.map((image) => ({
          id: image.id,
          name: image.image,
          url: `${API_URL}/${image.image}`
        }))
      ];

      const editVideoFiles: FileType[] = [
        ...(videoFiles || []),
        ...editActivity.videos.map((video) => ({
          id: video.id,
          name: video.video,
          url: `${API_URL}/${video.video}`
        }))
      ];

      setImageFiles(editImageFiles);
      setValue(
        'imageFiles',
        editImageFiles.map((file) => file.file!)
      );
      setVideoFiles(editVideoFiles);
      setValue(
        'videoFiles',
        editVideoFiles.map((file) => file.file!)
      );
    }
  }, []);

  const handleSubmitAddActivityForm = (data: AddActivityFormSchemaType) => {
    const { activitiesCarriedOut } = data;

    let activityId = editActivity?.id;

    if (editActivity?.id) {
      updateRecordActivities({
        variables: {
          data: {
            id: activityId!,
            description: activitiesCarriedOut
          }
        },
        onCompleted: () => {
          handleUploadFiles(activityId!);
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
    } else {
      saveRecordActivities({
        variables: {
          description: activitiesCarriedOut,
          recordId: recordId
        },
        onCompleted: (response) => {
          activityId = response.saveRecordActivities;
          handleUploadFiles(activityId!);
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
    }
  };

  const handleUploadFiles = (activityId: string) => {
    const uploadPromises = [];
    if (imageFiles.length > 0) {
      const newImageFiles = imageFiles.filter((file) => !file.id);

      if (newImageFiles.length > 0) {
        uploadPromises.push(
          new Promise<void>((resolve, reject) => {
            saveActivityImage({
              variables: {
                data: {
                  activityId: activityId,
                  files: newImageFiles.map((file) => file.file!)
                }
              },
              onCompleted: () => {
                resolve();
              },
              onError: (error) => {
                parseErrorMessage(error);
                reject(error);
              }
            });
          })
        );
      }
    }

    if (videoFiles.length > 0) {
      const newVideoFiles = videoFiles.filter((file) => !file.id);
      if (newVideoFiles.length > 0) {
        uploadPromises.push(
          new Promise<void>((resolve, reject) => {
            saveActivityVideo({
              variables: {
                data: {
                  activityId: activityId,
                  video: newVideoFiles[0].file
                }
              },
              onCompleted: () => {
                resolve();
              },
              onError: (error) => {
                parseErrorMessage(error);
                reject(error);
              }
            });
          })
        );
      }
    }

    if (uploadPromises.length > 0) {
      Promise.all(uploadPromises)
        .then(() => {
          finalizeSuccess();
        })
        .catch(() => {});
    } else {
      finalizeSuccess();
    }
  };

  const finalizeSuccess = () => {
    client.refetchQueries({
      include: [
        {
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          awaitRefetchQueries: true
        }
      ]
    });

    toastfySuccess(
      editActivity?.id
        ? translate('editedSuccessfully')
        : translate('registeredSuccessfully')
    );
    setEdit({ show: false, activity: null });
  };

  const handleImageFileChange = (selectedFiles: File[]) => {
    const fileSize = imageFiles.reduce((acc, file) => {
      if (file && file.file) {
        return acc + file.file.size;
      }

      return acc;
    }, 0);
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = imageFiles
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('imageFiles', storedStateFiles);
    if (fileSize <= 100 * oneMegaByte) {
      setImageFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = imageFiles.filter(
      (file) => file.id !== fileSelect.id
    );
    setImageFiles(updatedFiles);

    setValue(
      'imageFiles',
      updatedFiles.map((file) => file.file!)
    );

    if (editActivity?.id) {
      const fileId = fileSelect.id?.split('.')[0] || '';

      deleteRecordActivitiesImage({
        variables: {
          deleteRecordActivitiesImageId: fileId
        },
        onCompleted: () => {
          toastfySuccess('deletedSuccessfully');
        },
        onError: (error) => {
          parseErrorMessage(error);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              activities: existingData.findDataByRecord.activities.map(
                (item) =>
                  item.id === editActivity?.id
                    ? {
                        ...item,
                        images: item.images.filter(
                          (image) => image.id !== fileId
                        )
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  const handleVideoFileChange = (selectedFiles: File[]) => {
    const fileSize = videoFiles.reduce((acc, file) => {
      if (file && file.file) {
        return acc + file.file.size;
      }

      return acc;
    }, 0);
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = videoFiles
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('videoFiles', storedStateFiles);
    if (fileSize <= 100 * oneMegaByte) {
      setVideoFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteVideo = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = videoFiles.filter(
      (file) => file.id !== fileSelect.id
    );
    setVideoFiles(updatedFiles);

    setValue(
      'videoFiles',
      updatedFiles.map((file) => file.file!)
    );

    if (editActivity?.id) {
      const fileId = fileSelect.id?.split('.')[0] || '';

      deleteRecordActivitiesVideo({
        variables: {
          deleteRecordActivitiesVideoId: fileId
        },
        onCompleted: () => {
          toastfySuccess('deletedSuccessfully');
        },
        onError: (error) => {
          parseErrorMessage(error);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              activities: existingData.findDataByRecord.activities.map(
                (item) =>
                  item.id === editActivity?.id
                    ? {
                        ...item,
                        videos: item.videos.filter(
                          (video) => video.id !== fileId
                        )
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  return (
    <HolderForm>
      <InputTextarea
        errorMessage={errors.activitiesCarriedOut?.message}
        error={!!errors.activitiesCarriedOut}
        height="100px"
        label={translate('ActivitiesCarriedOut')}
        name="activitiesCarriedOut"
        onChange={(event) => setActivitiesCarriedOut(event.target.value)}
        register={register}
        value={activitiesCarriedOut}
        width="500px"
      />
      <DivInputsArea>
        <InputFileWithList
          register={register}
          name="imageFiles"
          errorMessage={errors.imageFiles?.message as string}
          error={!!errors.imageFiles}
          accept=".png, .jpg, .jpeg"
          label={translate('images')}
          multiple={true}
          files={imageFiles}
          onChange={(event) => handleImageFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteImage(file)}
        />
        <InputFileWithList
          register={register}
          name="videoFiles"
          errorMessage={errors.videoFiles?.message as string}
          error={!!errors.videoFiles}
          accept=".avi, .mp4, .mkv, .wmv"
          label={translate('video')}
          multiple={true}
          files={videoFiles}
          onChange={(event) => handleVideoFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteVideo(file)}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={translate('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setEdit({ show: false, activity: null });
          }}
        />
        <Button
          text={translate('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAddActivityForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddActivityForm;
