import { useTranslation } from 'react-i18next';
import { TABLE_IDS } from '../../../../../utils/TableConsts';
import MapGoogle from '../../../../Map/MapGoogle';
import Text from '../../../Atoms/Text/Text';
import SquareCard from '../../../Molecules/SquareCard/SquareCard';
import TableDraggableComponent from '../../../Molecules/TableDraggable/TableDraggableComponent';
import {
  DivButton,
  DivContentColumn,
  DivGraphBar,
  DivInfo,
  DivTable,
  DviContent,
  MapArea
} from './MonitoringDashboard.styles';
import Plot from 'react-plotly.js';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FindPluviometerReadingDataChartToGmcDocument,
  FindPluviometerReadingDataChartToGmcQuery,
  FindPluviometerReadingDataChartToGmcQueryVariables
} from '../../../../../data/graphql/query/generated/findPluviometerReadingDataChartToGMC.query';
import { useQuery } from '@apollo/client';
import {
  FindInstrumentsInfoToGmcDocument,
  FindInstrumentsInfoToGmcQuery,
  FindInstrumentsInfoToGmcQueryVariables
} from '../../../../../data/graphql/query/generated/findInstrumentsInfoToGMC.query';
import Button from '../../../Atoms/Button/Button';
import useColorsStatus from '../../../../../Hooks/useColorsStatus/useColorsStatus';
import SimpleContentTemplate from '../../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import useErrorsTreatment from '../../../../../Hooks/useErrorsTreatment';

const MonitoringDashboard = () => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const { getColorStatus } = useColorsStatus();
  const navigate = useNavigate();
  const { parseErrorMessage } = useErrorsTreatment();

  const { data: pluviometerDataChart } = useQuery<
    FindPluviometerReadingDataChartToGmcQuery,
    FindPluviometerReadingDataChartToGmcQueryVariables
  >(FindPluviometerReadingDataChartToGmcDocument, {
    variables: {
      structureId: structureId!,
      days: 30
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const pluviometerData =
    pluviometerDataChart?.findPluviometerReadingDataChartToGMC.map((item) => ({
      date: new Date(item.date).toLocaleDateString(),
      reading: item.reading
    })) || [];

  const { data: instrumentsInfo, loading } = useQuery<
    FindInstrumentsInfoToGmcQuery,
    FindInstrumentsInfoToGmcQueryVariables
  >(FindInstrumentsInfoToGmcDocument, {
    variables: {
      structureId: structureId!
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const {
    alertCount,
    attentionCount,
    emergencyCount,
    instruments,
    instrumentsCount
  } = instrumentsInfo?.findInstrumentsInfoToGMC || {
    alertCount: 0,
    attentionCount: 0,
    emergencyCount: 0,
    instruments: [],
    instrumentsCount: 0
  };

  const markersData = instruments.map((instrument) => ({
    id: instrument.instrumentId,
    title: instrument.instrumentName,
    lat: instrument.lat,
    lng: instrument.lon,
    color: getColorStatus(translate(instrument.situation || 'normal')),
    type: instrument.instrumentType,
    infoData: [
      { key: 'Type', value: translate(instrument.instrumentType) },
      {
        key: 'status',
        value: translate(instrument.operationalStatus || 'normal')
      },
      {
        key: 'ReadingDate',
        value: instrument.readingDate
          ? new Date(instrument.readingDate).toLocaleString().replace(',', ' -')
          : '-'
      },
      { key: 'reading', value: instrument.reading }
    ]
  }));

  const readingColumns = [
    { key: 'instrumentName', label: translate('Instrument') },
    { key: 'readingDate', label: translate('dateAndTime') },
    { key: 'status', label: translate('AlertLevel') }
  ];

  const readingTableData = instruments.map((instrument) => ({
    ...instrument,
    readingDate: instrument.readingDate
      ? new Date(instrument.readingDate).toLocaleString().replace(',', ' -')
      : '-',
    status: translate(instrument.situation || 'normal')
  }));

  const equipamentHealthColumns = [
    { key: 'instrumentName', label: translate('Instrument') },
    { key: 'readingDate', label: translate('dateAndTime') },
    { key: 'lat', label: translate('Latitute') },
    { key: 'lon', label: translate('Longitude') },
    { key: 'instrumentType', label: translate('Type') },
    { key: 'status', label: translate('situation') },
    { key: 'battery', label: translate('battery') },
    { key: 'auxBattery', label: translate('auxiliaryBattery') },
    { key: 'loggerTemperature', label: translate('temperature') },
    { key: 'signalStrength', label: translate('signalStrength') },
    { key: 'thermistor', label: translate('thermistor') }
  ];

  const equipamentHealthData = instruments.map((instrument) => ({
    ...instrument,
    readingDate: instrument.readingDate
      ? new Date(instrument.readingDate).toLocaleString().replace(',', ' -')
      : '-',
    status: translate(instrument.situation || 'normal'),
    lat: instrument.lat.toFixed(6),
    lon: instrument.lon.toFixed(6)
  }));

  return (
    <SimpleContentTemplate
      loading={loading}
      title={<Text type="h3">{translate('Monitoring')}</Text>}
      button={<></>}
      content={
        <DviContent>
          <DivContentColumn>
            <DivInfo>
              <SquareCard
                label={`${translate('Instruments')}`}
                value={instrumentsCount?.toString()}
              />
              <SquareCard
                label={`${translate('Attention')}`}
                value={attentionCount?.toString()}
              />
              <SquareCard
                label={`${translate('Alert')}`}
                value={alertCount?.toString()}
              />
              <SquareCard
                label={`${translate('Emergency')}`}
                value={emergencyCount?.toString()}
              />
            </DivInfo>
            <DivTable>
              <TableDraggableComponent
                tableId={TABLE_IDS.MONITORING_DASHBOARD_READING_TABLE}
                title="readings"
                columnsArray={readingColumns}
                tableData={readingTableData.slice(0, 5) || []}
                columnSelect
                showCountColumn
                maxHeight="225px"
                tableAction={() => {
                  const url = `/${structureId}/monitoring/cmg/readings`;
                  const windowFeatures =
                    'width=1200,height=800,top=100,left=100,resizable=yes,scrollbars=yes';
                  window.open(url, '_blank', windowFeatures);
                }}
              />
            </DivTable>
            <DivTable>
              <TableDraggableComponent
                tableId={TABLE_IDS.MONITORING_DASHBOARD_EQUIPAMENT_HEALTH_TABLE}
                title="equipmentHealth"
                columnsArray={equipamentHealthColumns}
                tableData={equipamentHealthData.slice(0, 5) || []}
                columnSelect
                showCountColumn
                maxHeight="225px"
                tableAction={() => {
                  const url = `/${structureId}/monitoring/cmg/equipmenthealth`;
                  const windowFeatures =
                    'width=1200,height=800,top=100,left=100,resizable=yes,scrollbars=yes';
                  window.open(url, '_blank', windowFeatures);
                }}
              />
            </DivTable>
          </DivContentColumn>
          <DivContentColumn>
            <DivButton>
              <Button
                text="Instruments"
                onClick={() =>
                  navigate(`/${structureId}/monitoring/instrument/piezometer`)
                }
                variant="primary"
              />
              <Button
                text="readings"
                onClick={() =>
                  navigate(`/${structureId}/monitoring/readings/piezometer`)
                }
                variant="primary"
              />
              <Button
                text="graphs"
                onClick={() => navigate(`/${structureId}/monitoring/graphs`)}
                variant="primary"
              />
              <Button
                text="sectionGraphs"
                onClick={() => {
                  navigate(`/${structureId}/monitoring/sectionGraphs`);
                }}
                variant="primary"
              />
              <Button
                variant="primary"
                onClick={() => {
                  navigate(`/${structureId}/monitoring/map`);
                }}
                text="ExpandMap"
              />
            </DivButton>
            <MapArea>
              <MapGoogle markersData={markersData} />
            </MapArea>
            <DivGraphBar>
              <Plot
                data={[
                  {
                    x: pluviometerData.map((item) => item.date),
                    y: pluviometerData.map((item) => item.reading),
                    type: 'bar',
                    marker: { color: 'blue' }
                  }
                ]}
                layout={{
                  title: `${translate('pluviometer')}`,
                  yaxis: { title: translate('quantity') },
                  margin: { l: 40, r: 20, t: 50, b: 40 }
                }}
                config={{
                  responsive: true,
                  scrollZoom: true,
                  displayModeBar: true,
                  displaylogo: false,
                  locale: 'pt-BR'
                }}
                style={{ width: '100%', height: '100%' }}
              />
            </DivGraphBar>
          </DivContentColumn>
        </DviContent>
      }
    />
  );
};

export default MonitoringDashboard;
