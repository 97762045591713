import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import {
  AccordionButton,
  AccordionItemContainer,
  AccordionPanel,
  AccordionPlusMinusContainer,
  DivAccordionContainer
} from './Accordion.styles';
import { AccordionProps } from './Accordion.interfaces';

const Accordion = ({ title, component }: AccordionProps) => {
  const [active, setActive] = useState<boolean>(false);

  return (
    <DivAccordionContainer>
      <AccordionButton onClick={() => setActive(!active)}>
        <AccordionItemContainer>
          <Text type="span" size="large">
            {title}
          </Text>
        </AccordionItemContainer>
        <AccordionPlusMinusContainer>
          {active ? (
            <Icon Icon={FaMinus} variant="small" />
          ) : (
            <Icon Icon={FaPlus} variant="small" />
          )}
        </AccordionPlusMinusContainer>
      </AccordionButton>
      <AccordionPanel $active={active}>{component}</AccordionPanel>
    </DivAccordionContainer>
  );
};

export default Accordion;
