import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ExportData from '../../../../../Controllers/Export/ExportData';
import useErrorsTreatment from '../../../../../Hooks/useErrorsTreatment';
import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import {
  FindAllActionPlansDocument,
  FindAllActionPlansQuery,
  FindAllActionPlansQueryVariables
} from '../../../../../data/graphql/query/generated/findAllActionPlans.query';
import {
  HolderCheckbox,
  HolderCheckboxLis,
  HolderExports,
  HolderFooter
} from './ExportTableActionPlan.styles';
import InputCheckbox from '../../../Molecules/InputCheckbox/InputCheckbox';
import Button from '../../../Atoms/Button/Button';

export const ExportActionPlan = () => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const { ExportActionPlanList } = ExportData();
  const { parseErrorMessage } = useErrorsTreatment();

  const exportActionPlanColumns = [
    { key: 'code', label: translate('code') },
    { key: 'type', label: translate('type') },
    { key: 'responsibleName', label: translate('responsible') },
    { key: 'situation', label: translate('situation') },
    { key: 'status', label: translate('status') },
    { key: 'timeFrame', label: translate('timeFrame') },
    { key: 'priority', label: translate('priority') },
    { key: 'teamMembersName', label: translate('team') }
  ];

  const handleCheckboxChange = (checked: boolean, key: string) => {
    setSelectedColumns((prevSelected) =>
      checked
        ? [...prevSelected, key]
        : prevSelected.filter((colKey) => colKey !== key)
    );
  };

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    exportActionPlanColumns.map((col) => col.key)
  );

  const [getAllActionPlansExport] = useLazyQuery<
    FindAllActionPlansQuery,
    FindAllActionPlansQueryVariables
  >(FindAllActionPlansDocument);

  const handleExport = async () => {
    await getAllActionPlansExport({
      variables: {
        structureId: structureId!
      },
      onCompleted: ({ findAllActionPlans }) => {
        ExportActionPlanList(findAllActionPlans, selectedColumns);
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <HolderExports>
      <HolderCheckboxLis>
        {exportActionPlanColumns.map(({ key, label }) => (
          <HolderCheckbox key={key}>
            <InputCheckbox
              label={label}
              name={key}
              value={selectedColumns.includes(key)}
              onChange={(newValue) => handleCheckboxChange(newValue, key)}
            />
          </HolderCheckbox>
        ))}
      </HolderCheckboxLis>
      <HolderFooter>
        <Button
          text={translate('ExportTable')}
          variant="primary"
          onClick={handleExport}
        />
      </HolderFooter>
    </HolderExports>
  );
};
