import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import {
  UpdateRecordImageObservationDocument,
  UpdateRecordImageObservationMutation,
  UpdateRecordImageObservationMutationVariables
} from '../../../../data/graphql/generated/updateRecordImageObservation';
import { FindDataByRecordDocument } from '../../../../data/graphql/query/generated/findDataByRecord';

import { useParams } from 'react-router-dom';
import { FindGeneralInfoDocument } from '../../../../data/graphql/query/generated/findAtoGeneralInfo.query';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { AddPhotoAtoFormProps } from './AddPhotoAtoForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddPhotoAtoForm.styles';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import {
  SaveRecordImageMutation,
  SaveRecordImageMutationVariables,
  SaveRecordImageDocument
} from '../../../../data/graphql/generated/saveRecordImage';

const oneMegaByte = 1048576;

const AddPhotoAtoForm = ({
  setShowModal,
  recordId,
  editPhoto
}: AddPhotoAtoFormProps) => {
  const { t: translate } = useTranslation();
  const { atoId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const [description, setDescription] = useState<string>(
    editPhoto?.description || ''
  );
  const [files, setFiles] = useState<FileType[]>([]);
  const [updateRecordImageObservation] = useMutation<
    UpdateRecordImageObservationMutation,
    UpdateRecordImageObservationMutationVariables
  >(UpdateRecordImageObservationDocument, {});

  const [saveRecordImage] = useMutation<
    SaveRecordImageMutation,
    SaveRecordImageMutationVariables
  >(SaveRecordImageDocument, {});

  const addPhotoAtoFormSchema = z
    .object({
      description: z.string().min(1, {
        message: translate('fieldIsRequired')
      }),
      files: z.array(z.instanceof(File)).optional()
    })
    .superRefine((data, ctx) => {
      if (files.length === 0) {
        ctx.addIssue({
          path: ['files'],
          message: translate('fieldIsRequired'),
          code: z.ZodIssueCode.custom
        });
      }
    });

  type AddPhotoAtoFormSchemaType = z.infer<typeof addPhotoAtoFormSchema>;
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<AddPhotoAtoFormSchemaType>({
    resolver: zodResolver(addPhotoAtoFormSchema),
    defaultValues: {
      description: editPhoto ? editPhoto?.description : ''
    }
  });

  useEffect(() => {
    if (editPhoto) {
      setFiles([
        {
          id: editPhoto?.id,
          name: editPhoto?.name,
          url: editPhoto?.url
        }
      ]);
    }
  }, []);

  const handleSubmitAddPhotoAtoForm = async (
    data: AddPhotoAtoFormSchemaType
  ) => {
    const { description } = data;
    const isInvalid = files.length === 0;
    if (isInvalid) {
      setValue('files', []);
      return;
    }

    try {
      if (editPhoto?.id) {
        await updateRecordImageObservation({
          variables: {
            data: {
              id: editPhoto.id,
              observation: description
            }
          },
          refetchQueries: [
            {
              query: FindDataByRecordDocument,
              variables: { recordId: recordId }
            },
            {
              query: FindGeneralInfoDocument,
              variables: { atoId: atoId }
            }
          ],
          onCompleted: () => {
            toastfySuccess(translate('editedSuccessfully'));
          },
          onError: (error) => {
            parseErrorMessage(error);
          }
        });
      } else {
        await saveRecordImage({
          variables: {
            data: {
              recordId: recordId,
              observation: description,
              image: files[0].file
            }
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: FindDataByRecordDocument,
              variables: { recordId }
            },
            {
              query: FindGeneralInfoDocument,
              variables: { atoId }
            }
          ],
          onCompleted: () => {
            toastfySuccess(translate('registeredSuccessfully'));
          },
          onError: (error) => {
            parseErrorMessage(error);
          }
        });
      }

      setShowModal(false);
    } catch (error) {
      toastfyError(translate('unexpectedError'));
      console.error(error);
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles.length > 0) {
      const fileSize = selectedFiles[0].size;
      const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
        (file) => ({
          id: '',
          name: file.name,
          url: URL.createObjectURL(file),
          file: file
        })
      );

      const storedStateFiles = newselectedFiles.map(
        (file) => file.file
      ) as File[];

      setValue('files', storedStateFiles);
      if (fileSize <= 5 * oneMegaByte) {
        setFiles(newselectedFiles);
      } else {
        toastfyError(translate('veryBigFile'));
      }
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <InputTextarea
          errorMessage={errors.description?.message}
          error={!!errors.description}
          height="100px"
          label={translate('description')}
          name="description"
          onChange={(event) => setDescription(event.target.value)}
          register={register}
          value={description}
          width="500px"
        />
        <InputFileWithList
          register={register}
          canDelete={editPhoto && false}
          name="files"
          errorMessage={errors.files?.message}
          error={!!errors.files}
          accept=".png, .jpg, .jpeg"
          label=""
          multiple={false}
          disabled={files.length > 0}
          files={files}
          onChange={(event) => handleFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteImage(file)}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={translate('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={translate('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAddPhotoAtoForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddPhotoAtoForm;
