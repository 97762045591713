import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputStyled = styled.div`
  display: flex;
`;

export const ErrorStyled = styled.div`
  color: ${Color.Red1};
  font-size: ${FontSize.Xsmall};
  height: 15px;
  margin-bottom: 5px;
`;

const CustomStyleSelect: StylesConfig = {
  valueContainer: (provided, state) => ({
    ...provided,
    justifyItems: 'start',
    width: `${state.selectProps.width || '300px'}`,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer'
  }),

  multiValue: (provided) => ({
    ...provided,
    backgroundColor: Color.Brown3,
    color: Color.Brown1
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    color: Color.Brown1,

    ':hover': {
      color: Color.Red1
    }
  }),

  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',

    border: 'none',
    borderBottom: state.isDisabled
      ? `1px solid ${Color.Disabled}`
      : state.selectProps.error
        ? `1px solid ${Color.Red1}`
        : `1px solid ${Color.Brown1}`,
    borderRadius: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    padding: 'none',
    fontSize: FontSize.Small,
    marginBottom: '0',
    userSelect: 'none',
    ':hover': {
      borderBottom: `1px solid ${Color.Brown1}`
    }
  }),

  indicatorSeparator: () => ({}),

  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? Color.Disabled
      : state.selectProps.error
        ? Color.Red1
        : Color.Brown1
  }),

  input: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? Color.Disabled : Color.Brown2,
    backgroundColor: 'transparent',
    textShadow: 'none',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    height: '20px',
    ':hover': {
      border: `1px solid ${state.isDisabled ? Color.Disabled : 'transparent'}`
    }
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? Color.Disabled : Color.Brown1,
    ':hover': {
      color: state.isDisabled ? Color.Disabled : Color.Brown1
    }
  }),
  noOptionsMessage: () => ({
    fontSize: '10pt',
    backgroundColor: Color.Brown3,
    padding: '10px 5px 10px 5px',
    marginTop: '-5px'
  }),

  option: (provided) => ({
    ...provided,
    fontSize: '10pt',
    backgroundColor: Color.Brown3,
    padding: '10px 5px 10px 5px',
    marginTop: '-5px',
    ':hover': {
      backgroundColor: Color.Gray1
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? Color.Disabled : Color.Brown1
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: 1001
  })
};

export const StyleSelect = styled(Select).attrs((props) => ({
  styles: CustomStyleSelect,
  isDisabled: props.isDisabled
}))``;
