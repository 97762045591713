import styled from 'styled-components';

export const DivOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 100%;
  gap: 30px;
  box-sizing: border-box;
`;

export const DivFlexColumns = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivOptionsSelectChart = styled.div`
  padding: 20px;
`;

export const DivOptionsButtonChart = styled.div`
  padding: 20px;
`;

export const DivGraphContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const DivOptionsChart = styled.div`
  padding: 20px;
  overflow-y: auto;
`;

export const DivOptionsMargin = styled.div`
  margin-right: 50px;
  width: 150px;
`;

export const DivOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DivInputsContainer = styled.div`
  margin: 20px 0;
`;
