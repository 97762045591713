import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { FaCirclePlay } from 'react-icons/fa6';
import { IoIosArrowBack } from 'react-icons/io';
import { MdPhotoCamera } from 'react-icons/md';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  AtoFilterInput,
  SortDirection
} from '../../../../data/graphql/base-schema';
import {
  FindAllRecordsPagDocument,
  FindAllRecordsPagQuery,
  FindAllRecordsPagQueryVariables
} from '../../../../data/graphql/query/generated/findAllAtoRecordsPag';
import Icon from '../../Atoms/Icon/Icon';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import AtoRecordFilterComponent from '../../Organisms/AtoRecordsFIlterComponent/AtoRecordFilterComponent';
import {
  DivTable,
  HolderButtons,
  HolderIconAndValueTable
} from './AtoRelatorio.styles';
import { useTranslation } from 'react-i18next';
import Button from '../../Atoms/Button/Button';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import Text from '../../Atoms/Text/Text';

const AtoRelatorios = () => {
  const { atoId, structureId } = useParams<{
    atoId: string;
    structureId: string;
  }>();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const startDateParam = searchParams.get('startDate');
  const endDateParam = searchParams.get('endDate');
  const recordNumberParam = searchParams.get('recordNumber');

  const filters: AtoFilterInput = {
    startDate: startDateParam ? new Date(startDateParam) : undefined,
    endDate: endDateParam ? new Date(endDateParam) : undefined,
    recordNumber: recordNumberParam || undefined
  };

  const { data: getAllAtoRecords, loading } = useQuery<
    FindAllRecordsPagQuery,
    FindAllRecordsPagQueryVariables
  >(FindAllRecordsPagDocument, {
    variables: {
      atoId: atoId ? atoId : '',
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters: filters || {},
      sortInfo: [
        {
          direction:
            searchParams.get('sortDirection') === 'ascending'
              ? SortDirection.ASC
              : SortDirection.DESC,
          field: searchParams.get('sortKey') || 'date'
        }
      ]
    }
  });

  const totalPages =
    getAllAtoRecords?.findAllRecordsPag.pageInfo.totalPages ?? 0;

  const sortedAtoRecords = getAllAtoRecords?.findAllRecordsPag.nodes
    ? [...getAllAtoRecords.findAllRecordsPag.nodes].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )
    : [];

  const listAllAtoRecords = sortedAtoRecords?.map((record) => ({
    ...record,
    date: new Date(record.date).toLocaleDateString(),
    number: record.recordNumber,
    imagesCount: (
      <HolderIconAndValueTable>
        <MdPhotoCamera /> {record.recordImagesCount}
      </HolderIconAndValueTable>
    ),
    videosCount: (
      <HolderIconAndValueTable>
        <FaCirclePlay /> {record.recordVideosCount}
      </HolderIconAndValueTable>
    ),
    status: t(record.situation.toLowerCase())
  }));

  const dataTable = [
    { key: 'date', label: t('date'), sortType: 'text' },
    { key: 'number', label: t('Number') },
    { key: 'status', label: t('status') },
    { key: 'imagesCount', label: t('Photos') },
    { key: 'videosCount', label: t('video') }
  ];

  const navigateToVideos = () => {
    navigate(`/${structureId}/ato/${atoId}/videos`);
  };

  const navigateToPhotos = () => {
    navigate(`/${structureId}/ato/${atoId}/fotos`);
  };

  const navigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}`);
  };

  const handlePushAtoIdToUrl = (recordId: string) => {
    navigate(`/${structureId}/ato/${atoId}/view/${recordId}`);
  };

  return (
    <SimpleContentTemplate
      icon={<Icon Icon={IoIosArrowBack} onClick={navigateBack} />}
      title={<Text type="h2">{translate('records')}</Text>}
      button={
        <HolderButtons>
          <Button
            text={translate('video')}
            variant="primary"
            onClick={() => navigateToVideos()}
          />
          <Button
            text={translate('images')}
            variant="primary"
            onClick={() => navigateToPhotos()}
          />
        </HolderButtons>
      }
      content={
        <DivTable>
          <TableComponent
            tableData={listAllAtoRecords || []}
            columns={dataTable}
            onRow={(record) => handlePushAtoIdToUrl(record.id)}
            showInUrl={true}
            paginator={true}
            totalPages={totalPages}
            filterComponent={<AtoRecordFilterComponent />}
          />
        </DivTable>
      }
      loading={loading}
    />
  );
};

export default AtoRelatorios;
