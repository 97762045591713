import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';
import { IconProps } from 'react-toastify';

interface IconStyledProps extends IconProps {
  variant?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

const variantMap: { [key: string]: string } = {
  small: '20px',
  medium: '26px',
  large: '35px'
};

export const StyledIcon = styled.div<IconStyledProps>`
  display: inline-flex;
  font-size: ${({ variant = 'medium' }) => variantMap[variant]};
  color: ${Color.Brown1};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  user-select: none;

  &:hover {
    transform: ${({ onClick }) => (onClick ? 'scale(1.1)' : '')};
  }
`;
