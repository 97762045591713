import styled from 'styled-components';

export const CardInfoNewAto = styled.div`
  color: black;
  width: 100%;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const HolderCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const HolderCardChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px 0px 50px;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const HolderButtonLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;
