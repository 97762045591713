import styled from 'styled-components';

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DivImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
`;

export const DivContainerImages = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
