import React, { useContext, useState } from 'react';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useDeleteInstrumen } from '../../../../data/hooks/use-delete-instrument';
import Text from '../../Atoms/Text/Text';
import { capitalizeFirstLetter } from '../../../../utils/StringManipulattor';
import { useQuery } from '@apollo/client';
import Enums from '../../../../utils/enumns';
import { ProjectContext } from '../../../../Context/ContextAPI';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { DivContainerTable } from '../../../../Modules/Monitoring/MonitoringScreenStyle';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import { TABLE_IDS } from '../../../../utils/TableConsts';
import {
  ListInstrumentsByStructureFilterInput,
  InstrumentStatusEnum,
  InputMaybe,
  InstrumentReadingTypeEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import {
  ListInstrumentsByStructurePagQuery,
  ListInstrumentsByStructurePagQueryVariables,
  ListInstrumentsByStructurePagDocument
} from '../../../../data/graphql/query/generated/listInstrumentsByStructurePag.query';
import Paginator from '../../Molecules/Paginator/Paginator';
import ListInstrumentsFilter from '../../Organisms/ListInstrumentsFilter/ListInstrumentsFilter';
import ExportTableInstruments from '../../Organisms/ExportTableInstruments/ExportTableInstruments';

const WaterLevelListPage = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { structureId, instrumentType } = useParams();
  const { deleteInstruments } = useDeleteInstrumen();
  const { UserRoles } = Enums();
  const { parseErrorMessage } = useErrorsTreatment();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');

  const [idDelete, setIdDelete] = useState<string>('');
  const [searchParams] = useSearchParams();
  const waterLevelFilters: ListInstrumentsByStructureFilterInput = {
    name: searchParams.get('name') ?? undefined,
    activationDate: searchParams.get('activationDate')
      ? new Date(searchParams.get('activationDate')!)
      : undefined,
    deactivationDate: searchParams.get('deactivationDate')
      ? new Date(searchParams.get('deactivationDate')!)
      : undefined,
    coordinateE: searchParams.get('coordinateE')
      ? parseFloat(searchParams.get('coordinateE')!)
      : undefined,
    coordinateN: searchParams.get('coordinateN')
      ? parseFloat(searchParams.get('coordinateN')!)
      : undefined,
    operationalStatus: searchParams.get('operationalStatus')
      ? (searchParams.get('operationalStatus') as InstrumentStatusEnum)
      : undefined,
    installationDate: searchParams.get('installationDate')
      ? new Date(searchParams.get('installationDate')!)
      : undefined,
    installLocation: searchParams.get('installLocation') ?? undefined,
    readingType: searchParams.get('readingType')
      ? (searchParams.get(
          'readingType'
        ) as InputMaybe<InstrumentReadingTypeEnum>)
      : undefined
  };

  const { data: listAllWaterLevelsPage, loading } = useQuery<
    ListInstrumentsByStructurePagQuery,
    ListInstrumentsByStructurePagQueryVariables
  >(ListInstrumentsByStructurePagDocument, {
    variables: {
      instrumentType: InstrumentTypeEnum.WaterLevel,
      structureId: structureId!,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters: waterLevelFilters
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  const totalPages =
    listAllWaterLevelsPage?.listInstrumentsByStructurePag.pageInfo.totalPages ||
    1;

  const actionsTable: AvailableContextActionsProps<any>[] = [
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (instrument) => {
        setIdDelete(instrument.id);
      },
      canShow: 'canDelete'
    }
  ];

  const handleClickTableRow = (row: any) => {
    const currentPath = window.location.pathname;
    userRole !== UserRoles.Viewer
      ? navigate(`${currentPath}/edit/${row.id}`)
      : navigate(`${currentPath}/view/${row.id}`);
  };

  const handleDeleteInstrument = async (instrumentId: string) => {
    const response = await deleteInstruments({
      variables: {
        structureId: structureId!,
        instrumentId: instrumentId
      }
    });
    toastifyLoading('Deletando Instrumento...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Instrumento Deletada Com Sucesso');
      toastfyDimiss('toastfySuccess');
      setIdDelete('');
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message ||
          translate(ToastifyModel().toastifyMessage.error)
      );
      setIdDelete('');
    }
  };

  const instrumentfilteredData =
    listAllWaterLevelsPage?.listInstrumentsByStructurePag.nodes;

  const rowsInstrumentData = instrumentfilteredData?.map((instrument) => {
    return {
      ...instrument,
      operationalStatus: translate(instrument.operationalStatus),
      readingType: translate(instrument.readingType),
      installationDate: instrument.installationDate
        ? new Date(instrument.installationDate).toLocaleDateString()
        : '-',
      deactivationDate: instrument.deactivationDate
        ? new Date(instrument.deactivationDate).toLocaleDateString()
        : '-',
      activationDate: instrument.activationDate
        ? new Date(instrument.activationDate).toLocaleDateString()
        : '-',
      installLocation: instrument.installLocation
        ? translate(instrument.installLocation)
        : '-'
    };
  });

  const WaterLevelColumns = [
    { key: 'name', label: translate('name') },
    { key: 'operationalStatus', label: translate('operationalStatus') },
    { key: 'coordinateE', label: translate('coordinateE') },
    { key: 'coordinateN', label: translate('coordinateN') },
    { key: 'readingType', label: translate('readingType') },
    { key: 'installLocation', label: translate('installLocation') },
    { key: 'installationDate', label: translate('installationDate') },
    { key: 'activationDate', label: translate('activationDate') },
    { key: 'deactivationDate', label: translate('deactivationDate') }
  ];

  return (
    <>
      <SimpleContentTemplate
        loading={loading}
        title={
          <Text type="h1" size={'large'}>
            {translate(capitalizeFirstLetter(instrumentType!))}
          </Text>
        }
        button={
          userRole !== UserRoles.Viewer && (
            <Button
              variant={'primary'}
              text={translate('RegisterInstrument')}
              onClick={() => {
                navigate(
                  `/${structureId}/monitoring/instrument/${instrumentType}/register`
                );
              }}
            />
          )
        }
        content={
          <>
            <DivContainerTable>
              <TableDraggableComponent
                tableId={TABLE_IDS.WATER_LEVEL_LIST_TABLE}
                columnsArray={WaterLevelColumns}
                tableData={rowsInstrumentData || []}
                handleClickRow={handleClickTableRow}
                maxHeight="550px"
                actions={
                  userRole !== UserRoles.Viewer ? actionsTable : undefined
                }
                sortUrl
                paginator={
                  <Paginator
                    options={optionsPaginatorDefault}
                    totalPages={totalPages}
                    showInUrl
                  />
                }
                exportTable={
                  <ExportTableInstruments
                    columns={WaterLevelColumns.map((column) => ({
                      key: column.key,
                      label: column.label
                    }))}
                  />
                }
                filterComponent={<ListInstrumentsFilter />}
                searchUrl
                columnSelect
                showCountColumn
              />
            </DivContainerTable>
          </>
        }
      />
      <ModalConfirm
        showModal={!!idDelete}
        title={translate('Deletar Instrumento')}
        text={translate('ConfirmDelete')}
        subtitle={translate('ActionCannotBeUndone')}
        onClose={() => setIdDelete('')}
        onConfirm={() => {
          handleDeleteInstrument(idDelete);
        }}
      />
    </>
  );
};

export default WaterLevelListPage;
