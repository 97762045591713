import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindSectionSlideFileQueryVariables = Types.Exact<{
  sectionId: Types.Scalars['String']['input'];
}>;

export type FindSectionSlideFileQuery = {
  __typename?: 'Query';
  findSectionSlideFile: {
    __typename?: 'SectionSlideFileType';
    id: string;
    slideFile: string;
    slideFileName: string;
  };
};

export const FindSectionSlideFileDocument = gql`
  query FindSectionSlideFile($sectionId: String!) {
    findSectionSlideFile(sectionId: $sectionId) {
      id
      slideFile
      slideFileName
    }
  }
`;

export type FindSectionSlideFileQueryResult = Apollo.QueryResult<
  FindSectionSlideFileQuery,
  FindSectionSlideFileQueryVariables
>;
