import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveAtoRecordVideoMutationVariables = Types.Exact<{
  data: Types.SaveRecordVideoInput;
}>;


export type SaveAtoRecordVideoMutation = { __typename?: 'Mutation', saveAtoRecordVideo: string };


export const SaveAtoRecordVideoDocument = gql`
    mutation SaveAtoRecordVideo($data: SaveRecordVideoInput!) {
  saveAtoRecordVideo(data: $data)
}
    `;
export type SaveAtoRecordVideoMutationFn = Apollo.MutationFunction<SaveAtoRecordVideoMutation, SaveAtoRecordVideoMutationVariables>;
export type SaveAtoRecordVideoMutationResult = Apollo.MutationResult<SaveAtoRecordVideoMutation>;
export type SaveAtoRecordVideoMutationOptions = Apollo.BaseMutationOptions<SaveAtoRecordVideoMutation, SaveAtoRecordVideoMutationVariables>;