import Skeleton from 'react-loading-skeleton';
import {
  DivContainerSquareCardList,
  DivContainerTable
} from '../../Pages/ActionPlan/ActionPlan.styled';
import { CardTableTemplateProps } from './CardTableTemplate.interface';
import {
  Container,
  DivContent,
  DivHeader,
  DivTitle
} from './CardTableTemplate.styles';

export function CardTableTemplate({
  loading,
  title,
  button,
  cards,
  content
}: CardTableTemplateProps) {
  return loading ? (
    <Container>
      <DivHeader>
        <DivTitle>
          <Skeleton width="100px" height="50px" />
        </DivTitle>
        <div>
          <Skeleton width="150px" height="50px" />
        </div>
      </DivHeader>
      <DivContent>
        <DivContainerSquareCardList>
          <Skeleton height="120px" width="120px" />
          <Skeleton height="120px" width="120px" />
          <Skeleton height="120px" width="120px" />
          <Skeleton height="120px" width="120px" />
          <Skeleton height="120px" width="120px" />
          <Skeleton height="120px" width="120px" />
        </DivContainerSquareCardList>
      </DivContent>
      <DivContent>
        <DivContainerTable>
          <Skeleton count={10} height={40} />
        </DivContainerTable>
      </DivContent>
    </Container>
  ) : (
    <Container>
      <DivHeader>
        <DivTitle>{title}</DivTitle>
        <div>{button}</div>
      </DivHeader>
      <DivContent>
        <DivContainerSquareCardList>{cards}</DivContainerSquareCardList>
      </DivContent>
      <DivContent>
        <DivContainerTable>{content}</DivContainerTable>
      </DivContent>
    </Container>
  );
}

export default CardTableTemplate;
