import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GraphicStatusEnum,
  SortDirection,
  StabilityAnalysisFilterInput
} from '../../../../../data/graphql/base-schema';
import {
  FindAllStabilityAnalysisGraphicPagDocument,
  FindAllStabilityAnalysisGraphicPagQuery,
  FindAllStabilityAnalysisGraphicPagQueryVariables
} from '../../../../../data/graphql/query/generated/findAllStabilityAnalysisGraphicPag';
import { ColumnsProps } from '../../../Molecules/TableComponent/TableComponent.interface';
import SeeMoreTemplate from '../../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import Icon from '../../../Atoms/Icon/Icon';
import ViewModal from '../../../Molecules/ViewModal/ViewModal';
import GraphicStability from '../../../Organisms/StabilityAnalysis/GraphicStability/GraphicStability';
import StabilityHistoryTableFilter from '../../../Organisms/StabilityAnalysis/StabilityHistoryTableFilter/StabilityHistoryTableFilter';
import TableDraggableComponent from '../../../Molecules/TableDraggable/TableDraggableComponent';
import Paginator from '../../../Molecules/Paginator/Paginator';
import { TABLE_IDS } from '../../../../../utils/TableConsts';

const StabilityHistoryPage = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { structureId, stabilityId } = useParams();

  const analysisDate = searchParams.get('analysisDate');
  const graphicStatus = searchParams.get('graphicStatus');
  const sectionName = searchParams.get('sectionName');
  const slideFileName = searchParams.get('slideFileName');

  const filters: StabilityAnalysisFilterInput = {
    analysisDate: analysisDate ? new Date(analysisDate) : undefined,
    graphicStatus:
      GraphicStatusEnum[graphicStatus as keyof typeof GraphicStatusEnum] ||
      undefined,
    sectionName: sectionName || undefined,
    slideFileName: slideFileName || undefined
  };
  const { data: findAllStabilityAnalysisGraphic } = useQuery<
    FindAllStabilityAnalysisGraphicPagQuery,
    FindAllStabilityAnalysisGraphicPagQueryVariables
  >(FindAllStabilityAnalysisGraphicPagDocument, {
    variables: {
      structureId: structureId!,
      filters: filters,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      sortInfo: [
        {
          direction:
            searchParams.get('sortDirection') === 'ascending'
              ? SortDirection.ASC
              : SortDirection.DESC || SortDirection.DESC,
          field: searchParams.get('sortKey') || 'createdAt'
        }
      ]
    }
  });
  const totalPages =
    findAllStabilityAnalysisGraphic?.findAllStabilityAnalysisGraphicPag.pageInfo
      .totalPages ?? 0;

  const listAllStabilityAnalysis =
    findAllStabilityAnalysisGraphic?.findAllStabilityAnalysisGraphicPag.nodes?.map(
      (graphic) => ({
        ...graphic,
        createdAt: new Date(graphic.createdAt).toLocaleDateString(),
        id: graphic.graphicId,
        name: graphic.graphicName,
        sectionName: graphic.sectionName,
        slideFileName: graphic.slideFileName,
        status: translate(graphic.graphicStatus),
        date: new Date(graphic.analysisDate).toLocaleDateString()
      })
    );

  const tableColumns: ColumnsProps[] = [
    { key: 'date', label: translate('Date') },
    { key: 'name', label: translate('name') },
    { key: 'sectionName', label: translate('Section') },
    { key: 'slideFileName', label: translate('slideFile') },
    { key: 'status', label: translate('Status') },
    { key: 'createdAt', label: translate('createdAt'), sortType: 'text' }
  ];

  return (
    <>
      <SeeMoreTemplate
        icon={
          <Icon
            Icon={IoIosArrowBack}
            onClick={() => navigate(`/${structureId}/stabilityanalysis`)}
          />
        }
        title={translate('history')}
        header={null}
        content={
          <TableDraggableComponent
            tableId={TABLE_IDS.STABILITY_HISTORY_TABLE}
            tableData={listAllStabilityAnalysis ?? []}
            columnsArray={tableColumns}
            handleClickRow={(stability) => {
              navigate({
                pathname: `/${structureId}/stabilityanalysis/history/${stability.id}`,
                search: searchParams.toString()
              });
            }}
            filterComponent={<StabilityHistoryTableFilter />}
            paginator={
              <Paginator
                options={[
                  { label: '10', value: '10' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' }
                ]}
                totalPages={totalPages}
                showInUrl
              />
            }
            sortUrl
          />
        }
        loading={false}
      />

      <ViewModal
        width={'100%'}
        height={'80%'}
        showModal={!!stabilityId}
        title={translate('history')}
        onClose={() => {
          navigate({
            pathname: `/${structureId}/stabilityanalysis/history`,
            search: searchParams.toString()
          });
        }}
        component={<GraphicStability />}
      />
    </>
  );
};

export default StabilityHistoryPage;
