// piezometerSchemas.ts
import { z, ZodIssueCode } from 'zod';

export const createAlertLevelSchema = () => {
  return z
    .object({
      name: z.string().min(1, 'fillRequiredFields'),
      drained: z.string().optional(),
      undrained: z.string().optional(),
      coteValue: z.string().min(1, 'fillRequiredFields')
    })
    .superRefine((data, ctx) => {
      if (!data.drained && !data.undrained) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['drained'],
          message: 'atLeastOneFactorRequired'
        });
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['undrained'],
          message: 'atLeastOneFactorRequired'
        });
      }
    });
};

export type AlertLevelType = z.infer<ReturnType<typeof createAlertLevelSchema>>;
