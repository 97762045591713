/* eslint-disable id-length */
import { useState } from 'react';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { useTranslation } from 'react-i18next';
import logo from '../../../../Images/logoGS3transparent.svg';
import { useParams } from 'react-router-dom';
import { useListInstrumentsByStructure } from '../../../../data/hooks/use-list-instuments-by-structure.query';
import { MenuItemType } from '../../Molecules/RecursiveMenu/RecursiveMenu.interface';
import { useListReadingByPiezometerLazy } from '../../../../data/hooks/piezometer/use-list-reading-by-piezometer';
import { useListReadingByPluviometerLazy } from '../../../../data/hooks/pluviometer/use-list-reading-by-pluviometer.query';
import { useListReadingByWaterLevelsLazy } from '../../../../data/hooks/water-level/use-list-reading-by-water-levels';
import { useListSurfaceMarkerReadingLazy } from '../../../../data/hooks/surface-marker/use-list-surface-marker-reading';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import { getRandomColor } from '../../../../utils/getRandomColor';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import Text from '../../Atoms/Text/Text';
import DrawerMenuTemplate from '../../Templates/DrawerMenuTemplate/DrawerMenuTemplate';
import { DataChatProps } from './InstrumentGraphPage.interface';
import { DivContainer, DivData } from './InstrumentGraphPage.styles';
import RecursiveMenu from '../../Molecules/RecursiveMenu/RecursiveMenu';

const InstrumentGraphPage = () => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const [selected, setSelected] = useState<MenuItemType[]>([]);
  const [dataChat, setDataChat] = useState<DataChatProps[]>([]);
  const dataChatLayout: Partial<Layout> = {
    title: {
      text: translate('Instruments')
    },
    xaxis: {
      title: {
        text: translate('time')
      },
      type: 'date'
    },
    yaxis: {
      title: {
        text: translate('PiezometricLevel(m)')
      },
      side: 'left',
      position: 0
    },
    yaxis2: {
      title: {
        text: translate('RainGauge(mm)')
      },
      side: 'right',
      overlaying: 'y',
      position: 0
    },
    yaxis3: {
      title: {
        text: translate('ReservoirWaterLevel(m)')
      },
      side: 'left',
      overlaying: 'y',
      position: 0.1
    },
    yaxis4: {
      title: {
        text: translate('AccumulatedDisplacement(mm)')
      },
      side: 'right',
      overlaying: 'y',
      position: 0.1
    },
    yaxis5: {
      title: {
        text: translate('Elevation(m)')
      },
      side: 'left',
      overlaying: 'y',
      position: 0.2
    },
    images: [
      {
        source: logo,
        x: 0.5,
        y: 0.5,
        xanchor: 'center',
        yanchor: 'middle',
        xref: 'paper',
        yref: 'paper',
        sizex: 2,
        sizey: 2,
        opacity: 0.8,
        layer: 'below'
      }
    ],
    legend:
      dataChat.length < 5
        ? {
            orientation: 'h',
            y: -0.2,
            x: 0.5,
            xanchor: 'center',
            yanchor: 'top'
          }
        : {}
  };

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 365);

  const [startDate, setStartDate] = useState<Date>(thirtyDaysAgo);
  const [endDate, setEndDate] = useState<Date>(today);

  const { data: instrumentsListReturn, loading } =
    useListInstrumentsByStructure(structureId!);

  const instrumentsListForType =
    instrumentsListReturn?.listInstrumentsByStructure[0].typesList.map(
      (type) => ({
        type: type.type.toLocaleLowerCase(),
        instruments: type.instruments.map((instrument) => instrument)
      })
    );

  const sectionsList =
    instrumentsListReturn?.listInstrumentsByStructure[0].sectionsList.map(
      (section) => ({
        section: section.name,
        instruments: section.typesList.map((type) => ({
          type: type.type.toLocaleLowerCase(),
          instruments: type.instruments.map((instrument) => instrument)
        }))
      })
    );

  const menuInstrumentData = {
    id: '0',
    name: 'Instruments',
    children: instrumentsListForType?.map((item, index) => {
      if (item.instruments.length === 0) {
        return null;
      }

      return {
        id: index.toString(),
        name: item.type,
        identifier: item.type,
        children: item.instruments.map((instrument) => ({
          id: instrument.id,
          name: instrument.name,
          identifier: item.type,
          children: [] as MenuItemType[]
        }))
      };
    })
  };

  const menuSectionData = {
    id: '1',
    name: 'Sections',
    children: sectionsList?.map((item, index) => {
      return {
        id: index,
        name: item.section,
        identifier: item.section,
        children: item.instruments.map((item2) => {
          if (item2.instruments.length === 0) {
            return null;
          }

          return {
            id: item2.type,
            name: item2.type,
            identifier: item.section,
            children: item2.instruments.map((instrument) => {
              if (instrument.id === '') {
                return null;
              }

              return {
                id: instrument.id,
                name: instrument.name,
                identifier: item2.type,
                children: [] as MenuItemType[]
              };
            })
          };
        })
      };
    })
  };

  const menuData = [menuInstrumentData, menuSectionData] as MenuItemType[];

  const { listReadingByPiezometer } = useListReadingByPiezometerLazy();
  const { listReadingByPluviometer } = useListReadingByPluviometerLazy();
  const { listReadingByWaterLevels } = useListReadingByWaterLevelsLazy();
  const { listReadingSurfaceMarker } = useListSurfaceMarkerReadingLazy();

  const updateDataChat = (
    instrument: Omit<MenuItemType, 'children'>,
    newData: Data[]
  ) => {
    setDataChat((prev) => {
      const updated = prev.map((item) => {
        if (item.instrument.id === instrument.id) {
          return {
            ...item,
            data: [...item.data, ...newData]
          };
        }

        return item;
      });
      if (!prev.some((item) => item.instrument.id === instrument.id)) {
        updated.push({
          instrument: {
            id: instrument.id,
            name: instrument.name,
            type: instrument.identifier
          },
          data: newData
        });
      }

      return updated;
    });
  };

  const setDataChatPiezometer = (
    newItem: MenuItemType[],
    startDate: Date,
    endDate: Date
  ) => {
    toastifyLoading(translate('loading'));

    listReadingByPiezometer({
      variables: {
        instrumentIds: newItem.map((item) => item.id),
        startDate: startDate,
        endDate: endDate
      },
      onCompleted: ({ listReadingByPiezometer }) => {
        setDataChat((prevDataChat) => {
          const currentPiezometers = prevDataChat.filter(
            (item) => item.instrument.type === 'piezometer'
          );

          const updatedDataChat = [...prevDataChat];

          listReadingByPiezometer.forEach((instrument) => {
            const cotes = instrument.cotes;
            const readings = instrument.readings;

            const newDataChat: Data[] = [];

            // Adicionar níveis de alerta apenas se houver 1 piezômetro no total
            if (
              currentPiezometers.length === 0 &&
              listReadingByPiezometer.length === 1
            ) {
              instrument.alertLevels.forEach((alertLevel) => {
                const alertColor =
                  alertLevel.name === 'EMERGÊNCIA'
                    ? 'red'
                    : alertLevel.name === 'ATENÇÃO'
                      ? 'yellow'
                      : alertLevel.name === 'ALERTA'
                        ? 'orange'
                        : getRandomColor('random')!;

                newDataChat.push({
                  x: [startDate, endDate],
                  y: [alertLevel.coteValue, alertLevel.coteValue],
                  type: 'scatter',
                  mode: 'text+lines',
                  text: [
                    alertLevel.coteValue.toFixed(2).toString(),
                    alertLevel.coteValue.toFixed(2).toString()
                  ],
                  textposition: 'top center',
                  name: `${instrument.name} - ${alertLevel.name} (m)`,
                  line: {
                    color: alertColor,
                    width: 2
                  },
                  yaxis: 'y1',
                  hovertemplate: '%{y}<extra></extra>'
                });
              });
            }

            // Adicionar cota de topo
            newDataChat.push({
              x: [startDate, endDate],
              y: [cotes[0]?.topCote, cotes[0]?.topCote],
              type: 'scatter',
              mode: 'text+lines',
              text: [
                cotes[0]?.topCote.toFixed(2).toString(),
                cotes[0]?.topCote.toFixed(2).toString()
              ],
              textposition: 'top center',
              name: `${instrument.name} - Cota de topo (m)`,
              line: {
                color: 'black',
                width: 2,
                dash: 'dash'
              },
              yaxis: 'y1',
              hovertemplate: `<b>Cota de topo: %{y}<extra></extra> <br> ${instrument.name}</b><br>`
            });

            // Adicionar cota de base
            newDataChat.push({
              x: [startDate, endDate],
              y: [cotes[0]?.bottomCote, cotes[0]?.bottomCote],
              type: 'scatter',
              mode: 'text+lines',
              text: [
                cotes[0]?.bottomCote.toFixed(2).toString(),
                cotes[0]?.bottomCote.toFixed(2).toString()
              ],
              textposition: 'top center',
              name: `${instrument.name} - Cota de base (m)`,
              line: {
                color: 'black',
                width: 5,
                dash: 'dash'
              },
              yaxis: 'y1',
              hovertemplate: `<b>Cota de base: %{y}<extra></extra> <br> ${instrument.name}</b><br>`
            });

            // Adicionar nível piezométrico
            newDataChat.push({
              x: readings.map((reading) =>
                new Date(reading.date).toISOString()
              ),
              y: readings.map((reading) => reading.cote),
              type: 'scatter',
              mode: 'text+lines+markers',
              text: readings.map((reading) => reading.cote.toFixed(2)),
              textposition: 'top center',
              name: `${instrument.name} - Nível piezométrico (m)`,
              yaxis: 'y1',
              hovertemplate: '%{y}<extra></extra>'
            });

            // Atualiza o estado com os novos dados
            const existingIndex = updatedDataChat.findIndex(
              (item) => item.instrument.id === instrument.instrumentId
            );

            if (existingIndex > -1) {
              updatedDataChat[existingIndex].data = newDataChat;
            } else {
              updatedDataChat.push({
                instrument: {
                  id: instrument.instrumentId,
                  name: instrument.name,
                  type: 'piezometer'
                },
                data: newDataChat
              });
            }
          });

          // Se houver mais de um piezômetro, remova os níveis de alerta
          if (currentPiezometers.length > 0) {
            updatedDataChat.forEach((item) => {
              if (item.instrument.type === 'piezometer') {
                item.data = item.data.filter(
                  (data) =>
                    !data.name?.includes('EMERGÊNCIA') &&
                    !data.name?.includes('ATENÇÃO') &&
                    !data.name?.includes('ALERTA')
                );
              }
            });
          }

          return updatedDataChat;
        });

        toastfyDimiss(translate('loading'));
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ message }) => {
        toastfyDimiss(translate('loading'));
        toastfyError(message);
      }
    });
  };

  const getRandomBlue = () => {
    const red = Math.floor(Math.random() * 70);
    const green = Math.floor(Math.random() * 70);
    const blue = Math.floor(Math.random() * (255 - 128) + 128);

    return `rgb(${red}, ${green}, ${blue})`;
  };

  const setDataChatPluviometer = (
    newItem: MenuItemType[],
    startDate: Date,
    endDate: Date
  ) => {
    toastifyLoading(translate('loading'));
    listReadingByPluviometer({
      variables: {
        instrumentIds: newItem.map((item) => item.id),
        startDate: startDate,
        endDate: endDate
      },
      onCompleted: ({ listReadingByPluviometer }) => {
        listReadingByPluviometer.map((instrument) => {
          const rainfallData = instrument.readings;
          const newDataChat: Data[] = [];

          newDataChat.push({
            x: rainfallData.map((reading) =>
              new Date(reading.date).toISOString()
            ),
            y: rainfallData.map((reading) => reading.rainfall),
            type: 'bar',
            mode: 'text+lines+markers',
            text: rainfallData.map((reading) => reading.rainfall.toFixed(2)),
            textposition: 'top center',
            name: `${instrument.name} - Pluviometria (mm)`,
            line: {
              color: getRandomBlue(),
              width: 2
            },
            marker: {
              color: getRandomBlue(),
              size: 6
            },
            yaxis: 'y2',
            hovertemplate: '%{y}<extra></extra>'
          });

          updateDataChat(
            {
              id: instrument.instrumentId,
              name: instrument.name,
              identifier: 'pluviometer'
            },
            newDataChat
          );
        });

        toastfyDimiss(translate('loading'));
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ message }) => {
        toastfyDimiss(translate('loading'));
        toastfyError(message);
      }
    });
  };

  const setDataChatWaterLevels = (
    newItem: MenuItemType[],
    startDate: Date,
    endDate: Date
  ) => {
    toastifyLoading(translate('loading'));
    listReadingByWaterLevels({
      variables: {
        instrumentIds: newItem.map((item) => item.id),
        startDate: startDate,
        endDate: endDate,
        structureId: structureId!
      },
      onCompleted: ({ listReadingByWaterLevel }) => {
        listReadingByWaterLevel.map((instrument) => {
          const rainfallData = instrument.readings;
          const newDataChat: Data[] = [];

          newDataChat.push({
            x: rainfallData.map((reading) =>
              new Date(reading.date).toISOString()
            ),
            y: rainfallData.map((reading) => reading.value),
            type: 'scatter',
            mode: 'text+lines',
            text: rainfallData.map((reading) => reading.value.toFixed(2)),
            textposition: 'top center',
            name: `${instrument.name} - Nível d'água do reservatório ( m )`,
            line: {
              color: getRandomColor('primary'),
              width: 2
            },
            marker: {
              color: getRandomColor('random'),
              size: 6
            },
            yaxis: 'y3',
            hovertemplate: '%{y}<extra></extra>'
          });

          updateDataChat(
            {
              id: instrument.instrumentId,
              name: instrument.name,
              identifier: 'waterlevel'
            },
            newDataChat
          );
        });

        toastfyDimiss(translate('loading'));
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ message }) => {
        toastfyDimiss(translate('loading'));
        toastfyError(message);
      }
    });
  };

  const setDataChatSurfaceMarkers = (
    newItem: MenuItemType[],
    startDate: Date,
    endDate: Date
  ) => {
    toastifyLoading(translate('loading'));
    listReadingSurfaceMarker({
      variables: {
        instrumentIds: newItem.map((item) => item.id),
        startDate: startDate,
        endDate: endDate
      },
      onCompleted: ({ listReadingBySurfaceMarkers }) => {
        listReadingBySurfaceMarkers.map((instrument) => {
          const displacementElevationData =
            instrument.displacementElevationData;
          const elevationData = instrument.elevationData;
          const newDataChat: Data[] = [];

          newDataChat.push({
            x: displacementElevationData.map((reading) =>
              new Date(reading.date).toISOString()
            ),
            y: displacementElevationData.map(
              (reading) => reading.displacement
            ) as number[],
            type: 'scatter',
            mode: 'text+lines',
            text: displacementElevationData.map((reading) =>
              (reading.displacement ?? 0).toFixed(2)
            ),
            textposition: 'top center',
            name: `${instrument.name} - Deslocamento acumulado (mm)`,
            line: {
              color: getRandomColor('primary'),
              width: 2
            },
            marker: {
              color: getRandomColor('random'),
              size: 6
            },
            yaxis: 'y4',
            hovertemplate: '%{y}<extra></extra>'
          });

          newDataChat.push({
            x: elevationData.map((reading) =>
              new Date(reading.date).toISOString()
            ),
            y: elevationData.map((reading) => reading.value) as number[],
            type: 'scatter',
            mode: 'text+lines',
            text: elevationData.map((reading) =>
              (reading.value ?? 0).toFixed(2)
            ),
            textposition: 'top center',
            name: `${instrument.name} - Elevação (m)`,
            line: {
              color: getRandomColor('primary'),
              width: 2
            },
            marker: {
              color: getRandomColor('random'),
              size: 6
            },
            yaxis: 'y5',
            hovertemplate: '%{y}<extra></extra>'
          });

          updateDataChat(
            {
              id: instrument.instrumentId,
              name: instrument.name,
              identifier: 'surfaceMarker'
            },
            newDataChat
          );
        });

        toastfyDimiss(translate('loading'));
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ message }) => {
        toastfyDimiss(translate('loading'));
        toastfyError(message);
      }
    });
  };

  const handleChange = (newItem: MenuItemType[]) => {
    const hasInstrumentInDataChat = dataChat.some((item) =>
      newItem.some((newItem) => newItem.id === item.instrument.id)
    );

    if (hasInstrumentInDataChat) {
      setDataChat((prev) =>
        prev.filter((item) =>
          newItem.some((newItem) => newItem.id !== item.instrument.id)
        )
      );
      return;
    }

    handleFetchData({ newItem, startDate, endDate });
  };

  const handleSelctAll = (newItem: MenuItemType[] | string) => {
    if (typeof newItem === 'string') {
      dataChat.map((item) => {
        if (item.instrument.type === newItem) {
          setDataChat((prev) =>
            prev.filter((item) => item.instrument.type !== newItem)
          );
        }
      });
      return;
    }

    const hasInstrumentInDataChat = dataChat.some((item) =>
      newItem.some((newItem) => newItem.id === item.instrument.id)
    );

    if (hasInstrumentInDataChat) {
      newItem.map((item) => {
        setDataChat((prev) =>
          prev.filter((item2) => item2.instrument.id !== item.id)
        );
      });
    }

    handleFetchData({ newItem, startDate, endDate });
  };

  const handleFetchData = ({
    newItem,
    startDate,
    endDate
  }: {
    newItem: MenuItemType[];
    startDate: Date;
    endDate: Date;
  }) => {
    const mapInstrumentType: { [key: string]: () => void } = {
      piezometer: () => setDataChatPiezometer(newItem, startDate, endDate),
      pluviometer: () => setDataChatPluviometer(newItem, startDate, endDate),
      waterlevel: () => setDataChatWaterLevels(newItem, startDate, endDate),
      surfacemarker: () =>
        setDataChatSurfaceMarkers(newItem, startDate, endDate),
      ina: () => setDataChatPiezometer(newItem, startDate, endDate)
    };

    mapInstrumentType[newItem[0].identifier!]();
  };

  return (
    <DrawerMenuTemplate
      loading={loading}
      title={
        <Text type="h3">{`${translate('graphs')} ${translate('Instruments')}`}</Text>
      }
      titleMenu="Menu"
      menu={
        <RecursiveMenu
          items={menuData}
          onClick={(newItem: MenuItemType[]) => handleChange(newItem)}
          selectAll={(newItem: MenuItemType[] | string) =>
            handleSelctAll(newItem)
          }
          selected={selected}
          setSelected={setSelected}
          checkbox={true}
        />
      }
      content={
        <DivContainer>
          <DivData>
            <DatepickerInput
              name="startDate"
              placeholder={translate('select')}
              label={translate('startDate')}
              dateValue={startDate}
              onChange={(date) => {
                if (!date) return;
                if (date > endDate) {
                  toastfyError(translate('validationDate'));
                  return;
                }

                if (date > new Date()) {
                  toastfyError(translate('DateNotGreaterThanCurrentDate'));
                  return;
                }

                setStartDate(date);
                if (dataChat.length > 0) {
                  const updateDataChat = dataChat.map((item) => ({
                    id: item.instrument.id,
                    name: item.instrument.name,
                    identifier: item.instrument.type,
                    children: []
                  }));
                  setDataChat([]);
                  handleFetchData({
                    newItem: updateDataChat,
                    startDate: date,
                    endDate: endDate
                  });
                }
              }}
              time={false}
            />
            <DatepickerInput
              name="endDate"
              placeholder={translate('select')}
              label={translate('EndDate')}
              dateValue={endDate}
              onChange={(date) => {
                if (!date) return;
                if (date < startDate) {
                  toastfyError(translate('validationDate'));
                  return;
                }

                if (date > new Date()) {
                  toastfyError(translate('DateNotGreaterThanCurrentDate'));
                  return;
                }

                setEndDate(date);
                if (dataChat.length > 0) {
                  const updateDataChat = dataChat.map((item) => ({
                    id: item.instrument.id,
                    name: item.instrument.name,
                    identifier: item.instrument.type,
                    children: []
                  }));
                  setDataChat([]);
                  handleFetchData({
                    newItem: updateDataChat,
                    startDate: startDate,
                    endDate: date
                  });
                }
              }}
              time={false}
            />
          </DivData>
          <Plot
            data={dataChat.map((data) => data.data).flat()}
            layout={dataChatLayout}
            style={{ width: '90%', height: '85%' }}
            config={{
              scrollZoom: true,
              displayModeBar: true,
              toImageButtonOptions: { format: 'jpeg' },
              displaylogo: false,
              locale: 'pt-BR'
            }}
          />
        </DivContainer>
      }
    />
  );
};

export default InstrumentGraphPage;
