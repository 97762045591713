import { z } from 'zod';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toastfySuccess } from '../../../Toastify';
import InputText from '../../Molecules/InputText/InputText';
import Button from '../../Atoms/Button/Button';
import {
  Form,
  FormContent,
  FormFooter
} from './AlertLevelSurfaceMarkerForm.styles';
import { AlertLevelSurfaceMarkerFormProps } from './AlertLevelSurfaceMarkerForm.interface';
import {
  AddSurfaceMarkerAlertLevelsMutation,
  AddSurfaceMarkerAlertLevelsMutationVariables,
  AddSurfaceMarkerAlertLevelsDocument
} from '../../../../data/graphql/generated/addSurfaceMarkerAlertLevels.mutation';
import {
  EditSurfaceMarkerAlertLevelMutation,
  EditSurfaceMarkerAlertLevelMutationVariables,
  EditSurfaceMarkerAlertLevelDocument
} from '../../../../data/graphql/generated/editSurfaceMarkerAlertLevel';
import { ListAlertsLevelBySurfaceMarkerPagDocument } from '../../../../data/graphql/query/generated/listSurfaceMarkerAlertLevels';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import { ListAlertLevelsDataProps } from '../../Pages/EditSurfaceMarker/EditSurfaceMarker.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const AlertLevelSurfaceMarkerForm = ({
  alertLevel,
  setShowModal
}: AlertLevelSurfaceMarkerFormProps<ListAlertLevelsDataProps>) => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const alertLevelSchema = z.object({
    name: z.string().min(1, translate('fillRequiredFields')),
    elevation: z.string().min(1, translate('fillRequiredFields')),
    coordinateE: z.string().min(1, translate('fillRequiredFields')),
    coordinateN: z.string().min(1, translate('fillRequiredFields')),
    date: z.date()
  });

  type formAlertLevelSchemaType = z.infer<typeof alertLevelSchema>;
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<formAlertLevelSchemaType>({
    resolver: zodResolver(alertLevelSchema),
    defaultValues: async () => {
      if (alertLevel?.id) {
        return {
          name: alertLevel?.name,
          elevation: alertLevel?.elevation.toString() ?? '',
          coordinateE: alertLevel?.coordinateE.toString() ?? '',
          coordinateN: alertLevel?.coordinateN?.toString() ?? '',
          date: new Date(alertLevel.date)
        };
      } else {
        return {
          name: '',
          elevation: '',
          coordinateE: '',
          coordinateN: '',
          date: new Date()
        };
      }
    }
  });

  const [editSurfaceMarkerAlertLevels] = useMutation<
    EditSurfaceMarkerAlertLevelMutation,
    EditSurfaceMarkerAlertLevelMutationVariables
  >(EditSurfaceMarkerAlertLevelDocument, {});

  const [addSurfaceMarkerAlertLevels] = useMutation<
    AddSurfaceMarkerAlertLevelsMutation,
    AddSurfaceMarkerAlertLevelsMutationVariables
  >(AddSurfaceMarkerAlertLevelsDocument, {});

  const handleUpdateAlertLevel = async (data: formAlertLevelSchemaType) => {
    const alertLevelData = {
      alertId: alertLevel?.id ?? '',
      instrumentId: instrumentId!,
      name: data.name,
      elevation: parseInt(data.elevation),
      coordinateE: parseInt(data.coordinateE),
      coordinateN: parseInt(data.coordinateN),
      date: data.date ? new Date(data.date) : new Date()
    };
    editSurfaceMarkerAlertLevels({
      variables: {
        structureInfo: {
          structureId: structureId!
        },
        data: alertLevelData
      },
      awaitRefetchQueries: true,
      refetchQueries: [ListAlertsLevelBySurfaceMarkerPagDocument],
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updateAlertLevels'));
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleNewAlertLevel = async (data: formAlertLevelSchemaType) => {
    const alertLevelData = {
      instrumentId: instrumentId!,
      name: data.name,
      elevation: parseInt(data.elevation),
      coordinateE: parseInt(data.coordinateE),
      coordinateN: parseInt(data.coordinateN),
      date: data.date
    };
    return addSurfaceMarkerAlertLevels({
      variables: {
        structureId: structureId!,
        data: alertLevelData
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('registeredSuccessfully'));
      },
      awaitRefetchQueries: true,
      refetchQueries: [ListAlertsLevelBySurfaceMarkerPagDocument],
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <Form>
      <FormContent>
        <InputText
          width="315px"
          control={control}
          label={translate('name')}
          name={'name'}
          type={'text'}
          error={!!errors.name}
          errorMessage={errors?.name?.message}
        />
        <InputText
          width="315px"
          control={control}
          label={translate('elevation')}
          name={'elevation'}
          type={'number'}
          error={!!errors.elevation}
          errorMessage={errors?.elevation?.message}
        />
        <InputText
          width="315px"
          control={control}
          label={translate('coordinateE')}
          name={'coordinateE'}
          type={'number'}
          error={!!errors.coordinateE}
          errorMessage={errors?.coordinateE?.message}
        />
        <InputText
          width="315px"
          control={control}
          label={translate('coordinateN')}
          name={'coordinateN'}
          type={'number'}
          error={!!errors.coordinateN}
          errorMessage={errors?.coordinateN?.message}
        />
        <DatepickerInput
          control={control}
          label={translate('date')}
          name="date"
          placeholder={translate('date')}
          time={false}
          error={!!errors.date}
          errorMessage={errors?.date?.message}
        />
      </FormContent>
      <FormFooter>
        <Button
          size="medium"
          text={
            alertLevel
              ? `${translate('editAlertlevel')}`
              : `${translate('newAlertLevel')}`
          }
          onClick={
            alertLevel
              ? handleSubmit(handleUpdateAlertLevel)
              : handleSubmit(handleNewAlertLevel)
          }
          variant={'primary'}
        />
      </FormFooter>
    </Form>
  );
};

export default AlertLevelSurfaceMarkerForm;
