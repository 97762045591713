import styled from 'styled-components';

export const MainModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 5px;
  overflow: auto;
  box-sizing: border-box;
`;

export const HolderInfosData = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  gap: 20px;
`;

export const DivInfo = styled.div`
  width: min(100%, 315px);
  min-height: 70px;
`;

export const HolderImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin-top: 30px;
  box-sizing: border-box;
`;
