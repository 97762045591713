import styled from 'styled-components';

export const HolderCards = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const DivContainerSquareCardList = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
`;

export const DivContainerTable = styled.div`
  width: 90%;
`;

export const DivContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 20px;
`;

export const DivContainerGraph = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`;

export const DivGraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 280px;
`;

export const DivTable = styled.div`
  width: 100%;
  height: auto;
`;
