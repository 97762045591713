import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteGeneralCommentsFileMutationVariables = Types.Exact<{
  fileId: Types.Scalars['String']['input'];
}>;


export type DeleteGeneralCommentsFileMutation = { __typename?: 'Mutation', deleteGeneralCommentsFile: string };


export const DeleteGeneralCommentsFileDocument = gql`
    mutation DeleteGeneralCommentsFile($fileId: String!) {
  deleteGeneralCommentsFile(fileId: $fileId)
}
    `;
export type DeleteGeneralCommentsFileMutationFn = Apollo.MutationFunction<DeleteGeneralCommentsFileMutation, DeleteGeneralCommentsFileMutationVariables>;
export type DeleteGeneralCommentsFileMutationResult = Apollo.MutationResult<DeleteGeneralCommentsFileMutation>;
export type DeleteGeneralCommentsFileMutationOptions = Apollo.BaseMutationOptions<DeleteGeneralCommentsFileMutation, DeleteGeneralCommentsFileMutationVariables>;