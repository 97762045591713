import { useTranslation } from 'react-i18next';
import { Color } from '../../../../Styles/Styles';
import Text from '../../Atoms/Text/Text';
import { WorkScheduleTableProps } from './WorkScheduleTable.interface';
import {
  LabelTdATOBold,
  TableATO,
  TableCellATOFour,
  TableCellATOOutput,
  TableHeaderATO,
  TableRowATO
} from './WorkScheduleTable.styles';
import { BiTrash } from 'react-icons/bi';
import Icon from '../../Atoms/Icon/Icon';

const WorkScheduleTable = ({
  workShift,
  onClickDelete
}: WorkScheduleTableProps) => {
  const { t: translate } = useTranslation();
  return (
    <>
      {onClickDelete && (
        <Icon
          Icon={BiTrash}
          onClick={onClickDelete}
          tooltip={translate('deleteWorkShift')}
        />
      )}
      <TableATO>
        <TableRowATO
          style={{ backgroundColor: Color.Brown3, color: Color.Brown1 }}
        >
          <TableHeaderATO>
            <Text type="p">{translate('turn')}</Text>
          </TableHeaderATO>
          <TableHeaderATO>
            <Text type="p">{translate('beguing')}</Text>
          </TableHeaderATO>
          <TableHeaderATO>
            <Text type="p">{translate('end')}</Text>
          </TableHeaderATO>
          <TableHeaderATO>
            <Text type="p">{translate('workedHours')}</Text>
          </TableHeaderATO>
        </TableRowATO>
        <TableRowATO>
          <TableCellATOFour
            style={{
              backgroundColor: Color.Brown3,
              color: Color.Brown1,
              fontWeight: 'bold'
            }}
          >
            <Text type="p">
              <LabelTdATOBold>{translate('entryExit')}</LabelTdATOBold>
            </Text>
          </TableCellATOFour>
          <TableCellATOOutput>{workShift?.entry}</TableCellATOOutput>
          <TableCellATOOutput>{workShift?.exit}</TableCellATOOutput>
          <TableCellATOOutput rowSpan={2}>
            {workShift?.totalHours}
          </TableCellATOOutput>
        </TableRowATO>
        <TableRowATO>
          <TableCellATOFour
            style={{
              backgroundColor: Color.Brown3,
              color: Color.Brown1,
              fontWeight: 'bold'
            }}
          >
            <Text type="p">
              <LabelTdATOBold>{translate('interval')}</LabelTdATOBold>
            </Text>
          </TableCellATOFour>
          <TableCellATOOutput>{workShift?.entryInterval}</TableCellATOOutput>
          <TableCellATOOutput>{workShift?.exitInterval}</TableCellATOOutput>
        </TableRowATO>
      </TableATO>
    </>
  );
};

export default WorkScheduleTable;
