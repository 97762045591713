import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { AtoEquipmentLaborTypeEnum } from '../../../../data/graphql/base-schema';
import {
  DeleteRecordEquipmentAndLaborDocument,
  DeleteRecordEquipmentAndLaborMutation,
  DeleteRecordEquipmentAndLaborMutationVariables
} from '../../../../data/graphql/generated/deleteRecordEquipmentAndLabor';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AtoQuantityDescriptionForm from '../AtoQuantityDescriptionForm/AtoQuantityDescriptionForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoEquipmentsProps,
  EquipmentDataType,
  EquipmentsItemType,
  RowToEditType
} from './CardAtoInfoEquipments.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import { EditProps } from '../CardAtoInfoLabor/CardAtoInfoLabor.interface';

export const CardAtoInfoEquipments = ({
  equipmentData,
  canAdd
}: CardAtoInfoEquipmentsProps) => {
  const { t: translate } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const [updateDelete, setUpdateDelete] = useState(false);
  const [edit, setEdit] = useState<EditProps>({
    show: false,
    equipmentAndLabor: null
  });
  const [idForDelete, setIdForDelete] = useState<string>('');

  const [deleteRecordEquipmentAndLabor] = useMutation<
    DeleteRecordEquipmentAndLaborMutation,
    DeleteRecordEquipmentAndLaborMutationVariables
  >(DeleteRecordEquipmentAndLaborDocument);

  const columnsData = [
    { key: 'indexId', label: '' },
    { key: 'quantity', label: translate('quantity') },
    { key: 'description', label: translate('description') }
  ];

  const tableData = equipmentData.map((item: EquipmentDataType, index) => ({
    ...item,
    indexId: index + 1
  }));

  const actions = [
    {
      name: translate('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => handleEditRow(row),
      canShow: 'Edit'
    },
    {
      name: translate('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'Delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordEquipmentAndLabor({
      variables: {
        data: {
          type: 'Equipment' as AtoEquipmentLaborTypeEnum,
          id: id
        }
      },
      onCompleted: () => {
        toastfySuccess('deletedSuccessfully');
        setUpdateDelete(!updateDelete);
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            equipments: existingData.findDataByRecord.equipments.filter(
              (item: EquipmentsItemType) => item.id !== id
            )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setEdit({
      show: true,
      equipmentAndLabor: row
    });
  };

  const handleCloseEdi = () => {
    setEdit({
      show: false,
      equipmentAndLabor: null
    });
  };

  const handleAddForm = () => {
    setEdit({
      show: true,
      equipmentAndLabor: null
    });
  };

  return (
    <>
      <CardAtoInfo
        title={translate('equipments')}
        canAdd={canAdd}
        buttonLabel={translate('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={columnsData}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>

      <ViewModal
        showModal={edit.show}
        width={40}
        height={50}
        title={
          edit.equipmentAndLabor?.id
            ? translate('editEquipments')
            : translate('addEquipments')
        }
        onClose={handleCloseEdi}
        component={
          <AtoQuantityDescriptionForm
            setEdit={setEdit}
            recordId={recordId?.toString() || ''}
            type={'Equipment' as AtoEquipmentLaborTypeEnum}
            editQuantityDescription={{
              id: edit.equipmentAndLabor?.id || '',
              quantity: edit.equipmentAndLabor?.quantity || 0,
              description: edit.equipmentAndLabor?.description || ''
            }}
          />
        }
      />

      <ModalConfirm
        showModal={!!idForDelete}
        title={`${translate('Delete')} ${translate('Activity')}`}
        text={translate('ConfirmDelete')}
        subtitle={translate('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};
