import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toastfyError } from '../Components/Toastify';

const useErrorsTreatment = () => {
  const { t: translate } = useTranslation();

  const parseErrorMessage = (error: ApolloError) => {
    if (!error) return toastfyError(translate('error.unknown'));

    if (error.message) {
      const parts = error.message.split('=');
      if (parts.length >= 3) {
        const key = parts[0].trim();
        const value = parts[2].trim();
        return toastfyError(translate(`${key} - ${translate(value)}`));
      }

      return toastfyError(translate(error.message || 'error.generic'));
    }

    if (error.networkError) {
      const { networkError } = error;

      if ('statusCode' in networkError) {
        const errorStrategies: { [key: number]: string } = {
          401: 'error.unauthorized',
          403: 'error.forbidden',
          500: 'error.generic',
          503: 'error.unavailable'
        };

        const errorMessageKey =
          errorStrategies[networkError.statusCode] || 'error.generic';
        return toastfyError(translate(errorMessageKey));
      }

      return toastfyError(translate('error.network'));
    }

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      return error.graphQLErrors
        .map((err) => toastfyError(translate(err.message) || err.message))
        .join(', ');
    }

    return toastfyError(translate('error.unknown'));
  };

  return { parseErrorMessage };
};

export default useErrorsTreatment;
