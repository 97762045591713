import styled from 'styled-components';
import { Color, FontSize } from '../../Styles/Styles';

export const InspectionsContainer = styled.div`
  flex: 1;
  margin: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${Color.White};
  padding: 25px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Color.Title2};
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
`;

export const InspectionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  justify-content: flex-end;
`;

export const RegisterArea = styled.div`
  width: 100%;
  height: 80%;
  background-color: #ffff;
  padding: 5px;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Color.Text1};

  span {
    font-size: 18px;
    font-weight: 700;
    color: ${Color.Brown1};
  }
`;

export const CustomRegisterParticipantArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: 85%;
  }

  p {
    color: ${Color.Text1};
  }
`;

export const TextButtonInspection = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${Color.Brown1};
  width: 100%;
`;

export const ContainerFirstStep = styled.div`
  height: auto;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(2, 40%);
  row-gap: 100px;
  column-gap: 0px;
  justify-items: center;
  justify-content: center;
  color: ${Color.Text1};
  padding-bottom: 140px;
  overflow: auto;
`;

export const ContainerThirdStep = styled.div`
  height: auto;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 40%);
  row-gap: 100px;
  column-gap: 0px;
  justify-items: center;
  justify-content: center;
  color: ${Color.Text1};
  padding-top: 140px;
  padding-bottom: 140px;
`;

export const ContainerSecondStep = styled.div`
  height: auto;
  overflow: auto;
  padding-bottom: 140px;
`;

export const ContainerSixthStep = styled.div`
  height: auto;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-items: center;
  justify-content: center;
  color: ${Color.Text1};
  padding-top: 140px;
  padding-bottom: 140px;
`;

export const ContainerSeventhStep = styled.div`
  height: auto;
  overflow: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-auto-flow: column; */
  justify-items: center;
  justify-content: center;
  color: ${Color.Text1};
  padding-bottom: 140px;

  h4 {
    display: flex;
    justify-content: center;
    width: 239px;
  }
`;

export const InspectionInputsArea = styled.div`
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .second-changes {
    display: flex;
    flex-wrap: wrap;
    color: ${Color.Title2};
    margin-top: 20px;
    margin-bottom: 20px;
    label {
      margin: 10px 20px 10px 20px;
      cursor: pointer;
    }
    span {
      margin: 10px;
      p {
        margin: 0;
        margin-left: 5px;
        padding: 0;
        font-size: ${FontSize.Small};
        color: ${Color.Title2};
      }
      input:not([type='checkbox']) {
        margin: 0;
        padding: 5px;
      }
    }
  }
`;

export const InspectionTextArea = styled.textarea`
  width: 90%;
  height: 80%;
  resize: none;
  box-sizing: border-box;
  background-color: transparent;
  color: ${Color.Title2};
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin-top: 15px;
  &:focus {
    outline: none !important;
    border: 1px solid ${Color.Title2};
  }
`;

export const SectionSevenStep = styled.section`
  font-size: ${FontSize.Small};
  display: flex;
  align-items: center;
  flex-direction: column;

  .selected {
    background-color: ${Color.Brown1};
    color: ${Color.White};
  }

  div {
    height: 150px;
    width: 239px;
    border: 1px solid rgba(41, 41, 41, 1);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
    cursor: pointer;

    input[type='radio'] {
      opacity: 0;
      height: 100%;
      display: none;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
`;

export const PopUpContainerStyle = styled.div`
  position: absolute;
  background-color: ${Color.Brown3};
  border: 1px solid ${Color.Brown2};
  color: black;
  z-index: 4;
  width: 177px;
  height: 136px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  overflow: hidden;
  word-wrap: break-word;
  border-radius: 8px;
  border-bottom-right-radius: 0;
`;

export const DivContaineDate = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

export const DivButtonCMG = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;
