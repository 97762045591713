import styled from 'styled-components';

export const HolderForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const DivInputsArea = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DivButtonArea = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 12.8px;
`;
