import styled from 'styled-components';

interface MyComponentProps {
  $isDrawerOpen: boolean;
  width: number;
}

export const PageWrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const ContentWrapper = styled.div<MyComponentProps>`
  flex: 1;
  width: 100%;
  transition: margin-left 0.3s ease-in-out;
  overflow: auto;
`;

export const ToggleButton = styled.div<MyComponentProps>`
  position: absolute;
  top: 10px;
  padding: 5px;
  left: ${(props) => (props.$isDrawerOpen ? `${props.width - 50}px` : '5px')};
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const Container = styled.div<{ $isOpen: boolean; width: number }>`
  display: flex;
  transition: width 0.3s ease-in-out;
  width: ${(props) => (props.$isOpen ? `${props.width}px` : '0')};
`;

export const DrawerWrapper = styled.div<{ $isOpen: boolean; width: number }>`
  height: 100%;
  padding: 15px 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$isOpen ? `${props.width}px` : '0')};
  transition: width 0.3s ease-in-out;
  overflow: auto;
`;
