import { z } from 'zod';
import { ReadingPzAndInaProps } from './ReadingPiezometerAndIna.interface';
import { useMutation, useQuery } from '@apollo/client';
import {
  ListPiezometersByStructureQuery,
  ListPiezometersByStructureQueryVariables,
  ListPiezometersByStructureDocument
} from '../../../../data/graphql/query/generated/listPiezometersByStructure.query';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { HolderFooter, HolderForm } from './ReadingPiezometerAndIna.styles';
import InputText from '../../Molecules/InputText/InputText';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import { useTranslation } from 'react-i18next';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import {
  CreatePiezometerReadingMutation,
  CreatePiezometerReadingMutationVariables,
  CreatePiezometerReadingDocument
} from '../../../../data/graphql/generated/createPiezometerReading.mutation';
import { ListReadingByPiezometersPagDocument } from '../../../../data/graphql/query/generated/listReadingByPiezometerPag.query';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import {
  EditPiezometerReadingMutation,
  EditPiezometerReadingMutationVariables,
  EditPiezometerReadingDocument
} from '../../../../data/graphql/generated/editPiezometerReading.mutation';
import { DivInput } from '../ReadingSurfaceMarkerForm/ReadingSurfaceMarker.styles';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const formReadingSchema = z
  .object({
    instrument: z
      .object({
        value: z.string().min(1, 'fieldIsRequired'),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    type: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    cote: z.string().nullable(),
    reading: z.string().nullable(),
    isDry: z.boolean().nullable(),
    date: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    observation: z.string().nullable()
  })
  .refine(
    (data) => {
      if (data.type?.value === 'cote') {
        return data.cote && data.cote.trim() !== '';
      } else if (data.type?.value === 'reading') {
        return data.reading && data.reading.trim() !== '';
      } else if (data.type?.value === 'isDry') {
        return data.isDry !== undefined;
      }

      return false;
    },
    {
      message: 'fieldIsRequired',
      path: ['cote']
    }
  );

type FormReadingSchemaType = z.infer<typeof formReadingSchema>;

const ReadingPiezometerAndIna = ({
  reading,
  setShowModal
}: ReadingPzAndInaProps) => {
  const { structureId, instrumentType } = useParams();
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch
  } = useForm<FormReadingSchemaType>({
    resolver: zodResolver(formReadingSchema),
    defaultValues: {
      cote: reading?.cote || '',
      reading: reading?.reading || '',
      isDry: reading?.isDry || false,
      date: reading?.date ? new Date(reading?.date) : new Date(),
      observation: reading?.observation || '',
      instrument: reading?.instrument
        ? {
            value: reading?.instrument.id,
            label: reading?.instrument.name
          }
        : {
            value: '',
            label: ''
          },
      type: reading
        ? reading.cote
          ? { value: 'cote', label: translate('cote') }
          : reading.reading
            ? { value: 'reading', label: translate('reading') }
            : reading.isDry !== undefined
              ? { value: 'isDry', label: translate('Dry') }
              : { value: '', label: '' }
        : { value: '', label: '' }
    }
  });
  const selectedType = watch('type');

  const [createReading] = useMutation<
    CreatePiezometerReadingMutation,
    CreatePiezometerReadingMutationVariables
  >(CreatePiezometerReadingDocument, { awaitRefetchQueries: true });

  const [editPiezometerAndInaReading] = useMutation<
    EditPiezometerReadingMutation,
    EditPiezometerReadingMutationVariables
  >(EditPiezometerReadingDocument, { awaitRefetchQueries: true });

  const { data: listPzAndInaReadings } = useQuery<
    ListPiezometersByStructureQuery,
    ListPiezometersByStructureQueryVariables
  >(ListPiezometersByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!
      }
    }
  });

  const typeOptions = [
    { value: 'cote', label: translate('cote') },
    { value: 'reading', label: translate('reading') },
    { value: 'isDry', label: translate('Dry') }
  ];

  const selectedOptions = (() => {
    const filteredPzList =
      listPzAndInaReadings?.listPiezometersByStructure.filter(
        (item) => item.type.instrumentType.toLocaleLowerCase() === 'piezometer'
      );
    const listPiezometers = filteredPzList?.map((instrument) => ({
      value: instrument.id,
      label: instrument.name
    }));

    const listIna = listPzAndInaReadings?.listPiezometersByStructure
      .filter((item) => item.type.instrumentType.toLocaleLowerCase() === 'ina')
      .map((instrument) => ({
        value: instrument.id,
        label: instrument.name
      }));

    if (instrumentType?.toLocaleLowerCase() === 'piezometer') {
      return listPiezometers;
    } else if (instrumentType?.toLocaleLowerCase() === 'ina') {
      return listIna;
    }

    return [];
  })();

  const handleSaveReading = (data: FormReadingSchemaType) => {
    createReading({
      variables: {
        data: {
          instrumentId: data?.instrument?.value,
          instrumentName: data.instrument.label,
          cote:
            selectedType?.value === 'cote' ? parseFloat(data.cote || '') : null,
          reading:
            selectedType?.value === 'reading'
              ? parseFloat(data.reading || '')
              : null,
          isDry: selectedType?.value === 'isDry' ? data.isDry : null,
          date: new Date(data.date.setHours(0, 0, 0, 0)),
          observation: data.observation
        },
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('registeredSuccessfully'));
      },
      refetchQueries: [ListReadingByPiezometersPagDocument],
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleEditReading = (data: FormReadingSchemaType) => {
    editPiezometerAndInaReading({
      variables: {
        data: {
          id: reading!.id!,
          cote:
            selectedType?.value === 'cote' ? parseFloat(data.cote || '') : null,
          reading:
            selectedType?.value === 'reading'
              ? parseFloat(data.reading || '')
              : null,
          isDry: selectedType?.value === 'isDry' ? data.isDry : null,
          date: new Date(data.date.setHours(0, 0, 0, 0)),
          observation: data.observation
        },
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updatedSuccessfully'));
      },
      refetchQueries: [ListReadingByPiezometersPagDocument],
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <HolderForm
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          reading
            ? handleSubmit(handleEditReading)()
            : handleSubmit(handleSaveReading)();
        }
      }}
    >
      {!reading?.id && (
        <InputSelectSearch
          width="400px"
          placeholder={translate('SelectInstrument')}
          control={control}
          label={translate('Instruments')}
          options={selectedOptions || []}
          name={'instrument'}
          error={!!errors.instrument}
          errorMessage={errors?.instrument?.message}
        />
      )}
      <InputSelectSearch
        width="400px"
        placeholder={translate('typeOfDataReadingPz')}
        control={control}
        label={translate('typeOfData')}
        options={typeOptions}
        name={'type'}
        error={!!errors.type}
        errorMessage={errors?.type?.message}
      />

      {selectedType?.value === 'cote' && (
        <DivInput>
          <InputText
            width="400px"
            control={control}
            label={translate('cote')}
            name={'cote'}
            type="number"
            error={!!errors.cote}
            errorMessage={errors?.cote?.message}
          />
        </DivInput>
      )}

      {selectedType?.value === 'reading' && (
        <DivInput>
          <InputText
            width="400px"
            control={control}
            label={translate('reading')}
            name={'reading'}
            type="number"
            error={!!errors.reading}
            errorMessage={errors?.reading?.message}
          />
        </DivInput>
      )}

      {selectedType?.value === 'isDry' && (
        <InputCheckbox
          register={register}
          label={translate('Dry')}
          name={'isDry'}
          value={watch('isDry')?.valueOf() ?? undefined}
        />
      )}

      <DatepickerInput
        width="330px"
        control={control}
        label={translate('date')}
        name="date"
        placeholder={translate('date')}
        time={false}
        error={!!errors.date}
        errorMessage={errors?.date?.message}
      />
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('observation')}
          name={'observation'}
          type={'text'}
          error={!!errors.observation}
          errorMessage={errors?.observation?.message}
        />
      </DivInput>
      <HolderFooter>
        <Button
          size="large"
          text={reading ? `${translate('edit')}` : `${translate('create')}`}
          onClick={
            reading
              ? handleSubmit(handleEditReading)
              : handleSubmit(handleSaveReading)
          }
          variant={'primary'}
        />
      </HolderFooter>
    </HolderForm>
  );
};

export default ReadingPiezometerAndIna;
