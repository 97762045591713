import React from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z, ZodIssueCode } from 'zod';
import { toastfySuccess } from '../../../Toastify';
import InputText from '../../Molecules/InputText/InputText';
import Button from '../../Atoms/Button/Button';
import { PiezometerAndInaAlertLevesProps } from './PiezometerAndInaAlertLeves.interface';
import {
  DivContainer,
  DivDrained,
  DivInputs,
  DivInputsText
} from './PiezometerAndInaAlertLeves.styles';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { ListAlertsLevelByPiezometersPagDocument } from '../../../../data/graphql/query/generated/listAlertsLevelByPiezometersPag.query';
import {
  EditPiezometerAlertDocument,
  EditPiezometerAlertMutation,
  EditPiezometerAlertMutationVariables
} from '../../../../data/graphql/generated/editPiezometerAlert.mutation';
import { alertLevelDataProps } from '../../Pages/EditPiezometerAndInaPage/EditPiezometerAndInaPage.interface';
import {
  AddPiezometerInaAlertLevelsDocument,
  AddPiezometerInaAlertLevelsMutation,
  AddPiezometerInaAlertLevelsMutationVariables
} from '../../../../data/graphql/generated/addPiezometerInaAlertLevels.mutation';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const PiezometerAndInaAlertLeves = ({
  data: { id, name, drained, undrained, coteValue },
  setModalAlert
}: PiezometerAndInaAlertLevesProps<alertLevelDataProps>) => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const signals = [
    { label: 'N/A', id: '', value: '' },
    { label: '≥', id: '≥', value: '≥' },
    { label: '≤', id: '≤', value: '≤' },
    { label: '>', id: '>', value: '>' },
    { label: '<', id: '<', value: '<' }
  ];

  const drainedSchema = z
    .object({
      value1: z.string().nullable(),
      signal1: z.object({
        value: z.string().nullable(),
        label: z.string().nullable()
      }),
      fs: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .refine((val) => val.value !== '', {
          message: translate('fillRequiredFields')
        }),
      signal2: z.object({
        value: z.string().nullable(),
        label: z.string().nullable()
      }),
      value2: z.string().nullable()
    })
    .superRefine((data, ctx) => {
      const { signal1, value1, signal2, value2 } = data;

      if (signal1.value && !value1) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['value1'],
          message: translate('fillValueWhenSignalProvided')
        });
      }

      if (!signal1.value && value1) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['signal1'],
          message: translate('fillSignalWhenValueProvided')
        });
      }

      if (signal2.value && !value2) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['value2'],
          message: translate('fillValueWhenSignalProvided')
        });
      }

      if (!signal2.value && value2) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['signal2'],
          message: translate('fillSignalWhenValueProvided')
        });
      }
    });

  type drainedType = z.infer<typeof drainedSchema>;

  const alertLevelSchema = z.object({
    name: z.string().min(1, translate('fillRequiredFields')),
    drained: z.string().min(0, translate('fillRequiredFields')),
    undrained: z.string().min(0, translate('fillRequiredFields')),
    coteValue: z.string().min(0, translate('fillRequiredFields'))
  });

  type alertLevelType = z.infer<typeof alertLevelSchema>;

  const [editAlertLevel] = useMutation<
    EditPiezometerAlertMutation,
    EditPiezometerAlertMutationVariables
  >(EditPiezometerAlertDocument);

  const [addAlertLevel] = useMutation<
    AddPiezometerInaAlertLevelsMutation,
    AddPiezometerInaAlertLevelsMutationVariables
  >(AddPiezometerInaAlertLevelsDocument);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<alertLevelType>({
    resolver: zodResolver(alertLevelSchema),
    defaultValues: {
      name: name,
      drained: drained,
      undrained: undrained,
      coteValue: coteValue.toString()
    }
  });

  const {
    handleSubmit: handleSubmitDrained,
    control: controlDrained,
    formState: { errors: errorsDrained }
  } = useForm<drainedType>({
    resolver: zodResolver(drainedSchema),
    defaultValues: {
      value1: '',
      signal1: {
        label: '',
        value: ''
      },
      fs: {
        label: '',
        value: ''
      },
      signal2: {
        label: '',
        value: ''
      },
      value2: ''
    }
  });

  const handleDrained = (dataFromForm: drainedType) => {
    const { value1, signal1, value2, signal2, fs } = dataFromForm;

    if (!value1 && !signal1.value && !value2 && !signal2.value) {
      if (fs?.value === `FS ${translate('Drained')}`) {
        setValue('drained', '');
        return;
      }

      if (fs?.value === `FS ${translate('NotDrained')}`) {
        setValue('undrained', '');
        return;
      }
    }

    const levelValue =
      `${value1 || ''} ${signal1.value || ''} FS ${signal2.value || ''} ${value2 || ''}`
        .replace(/\s+/g, ' ')
        .trim();

    if (fs.value === `FS ${translate('Drained')}`) {
      setValue('drained', levelValue);
    }

    if (fs.value === `FS ${translate('NotDrained')}`) {
      setValue('undrained', levelValue);
    }
  };

  const handleEditAlertLevel = (dataFromForm: alertLevelType) => {
    editAlertLevel({
      variables: {
        data: {
          name: dataFromForm.name,
          drained: dataFromForm.drained,
          undrained: dataFromForm.undrained,
          coteValue: parseFloat(dataFromForm.coteValue),
          instrumentId: instrumentId!,
          alertId: id
        },
        structureInfo: {
          structureId: structureId!,
          associatedStructureId: ''
        }
      },
      refetchQueries: [ListAlertsLevelByPiezometersPagDocument],
      onCompleted: () => {
        toastfySuccess(translate('editedSuccessfully'));
        setModalAlert({
          show: false,
          data: {
            id: '',
            name: '',
            drained: '',
            undrained: '',
            coteValue: 0
          }
        });
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleCreateAlertLevel = (dataFromForm: alertLevelType) => {
    addAlertLevel({
      variables: {
        structureId: structureId!,
        data: {
          name: dataFromForm.name,
          drained: dataFromForm.drained,
          undrained: dataFromForm.undrained,
          coteValue: parseFloat(dataFromForm.coteValue),
          instrumentId: instrumentId!
        }
      },
      refetchQueries: [ListAlertsLevelByPiezometersPagDocument],
      onCompleted: () => {
        toastfySuccess(translate('addedSuccessfully'));
        setModalAlert({
          show: false,
          data: {
            id: '',
            name: '',
            drained: '',
            undrained: '',
            coteValue: 0
          }
        });
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <DivContainer
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (id) {
            handleSubmit(handleEditAlertLevel)();
          } else {
            handleSubmit(handleCreateAlertLevel)();
          }
        }
      }}
    >
      <DivInputsText>
        <InputText
          width="300px"
          control={control}
          label={translate('name')}
          name={'name'}
          type={'text'}
          error={!!errors.name}
          errorMessage={errors?.name?.message}
        />
      </DivInputsText>
      <DivDrained
        onKeyDown={(event) => {
          event.stopPropagation();
          if (event.key === 'Enter') {
            handleSubmitDrained(handleDrained)();
          }
        }}
      >
        <DivInputs>
          <InputText
            width="100px"
            control={controlDrained}
            label={translate('value')}
            name={'value1'}
            type={'number'}
            error={!!errorsDrained.value1}
            errorMessage={errorsDrained?.value1?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            control={controlDrained}
            placeholder={translate('signal')}
            width="100px"
            label={translate('signal')}
            options={signals}
            name="signal1"
            error={!!errorsDrained.signal1}
            errorMessage={errorsDrained?.signal1?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            control={controlDrained}
            placeholder={translate('Drained')}
            width="100px"
            label={translate('Drained')}
            options={[
              {
                label: `FS ${translate('Drained')}`,
                value: `FS ${translate('Drained')}`
              },
              {
                label: `FS ${translate('NotDrained')}`,
                value: `FS ${translate('NotDrained')}`
              }
            ]}
            name="fs"
            error={!!errorsDrained.fs}
            errorMessage={errorsDrained?.fs?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            control={controlDrained}
            placeholder={translate('signal')}
            width="100px"
            label={translate('signal')}
            options={signals}
            name="signal2"
            error={!!errorsDrained.signal2}
            errorMessage={errorsDrained?.signal2?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputText
            width="100px"
            control={controlDrained}
            label={translate('value')}
            name={'value2'}
            type={'number'}
            error={!!errorsDrained.value2}
            errorMessage={errorsDrained?.value2?.message}
          />
        </DivInputs>
      </DivDrained>
      <Button
        variant="primary"
        onClick={handleSubmitDrained(handleDrained)}
        text={translate('Add')}
      />
      <DivInputsText>
        <InputText
          width="300px"
          control={control}
          label={translate('Drained')}
          name={'drained'}
          type={'text'}
          error={!!errors.drained}
          errorMessage={errors?.drained?.message}
          disabled
        />
      </DivInputsText>
      <DivInputsText>
        <InputText
          width="300px"
          control={control}
          label={translate('NotDrained')}
          name={'undrained'}
          type={'text'}
          error={!!errors.undrained}
          errorMessage={errors?.undrained?.message}
          disabled
        />
      </DivInputsText>
      <DivInputsText>
        <InputText
          width="300px"
          control={control}
          label={translate('coteValue')}
          name={'coteValue'}
          type={'number'}
          error={!!errors.coteValue}
          errorMessage={errors?.coteValue?.message}
        />
      </DivInputsText>
      <Button
        variant="primary"
        onClick={
          id
            ? handleSubmit(handleEditAlertLevel)
            : handleSubmit(handleCreateAlertLevel)
        }
        text={translate('saveAlertLevel')}
      />
    </DivContainer>
  );
};

export default PiezometerAndInaAlertLeves;
