import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ViewModal from '../ViewModal/ViewModal';
import {
  DivContainerActions,
  DivIcon,
  DivMessageNoData,
  DivTable,
  DivTableComponents,
  DivTableHeader,
  DivTitle
} from './TableDraggableComponent.style';
import {
  SortConfigInterface,
  TableColumnsObj,
  TableComponentProps
} from './TableDraggableComponent.interface';
import { useLocalStorage } from '@uidotdev/usehooks';
import { UsersPreferences } from '../../localStorage/localStorageFunctions.types';
import { TableCell } from './components/TableCell/TableCell';
import { TableColumnSelect } from './components/TableColumnSelect/TableColumnSelect';
import { Table } from './components/Table/Table';
import { TableHead } from './components/TableHead/TableHead';
import { TableHeaderRow } from './components/TableHeaderRow/TableHeaderRow';
import { TableBody } from './components/TableBody/TableBody';
import { ActionMenu } from './components/ActionMenu/ActionMenu';
import { TableRow } from './components/TableRow/TableRow';
import { TableHeaderCell } from './components/TableHeaderCell/TableHeaderCell';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../Map/types';
import Icon from '../../Atoms/Icon/Icon';
import { IoExpandOutline, IoFilter } from 'react-icons/io5';
import { BiDownload } from 'react-icons/bi';
import { useDragTable } from '../../../../Hooks/UseDragTable/UseDragTable';
import { DataDrivenPermissionsActionsMap } from '../ActionMenu/ActionMenu.interface';
import Text from '../../Atoms/Text/Text';
import { PiArrowSquareOut } from 'react-icons/pi';
import useColorsStatus from '../../../../Hooks/useColorsStatus/useColorsStatus';

const TableDraggableComponent = <T,>({
  tableId,
  columnsArray,
  tableData,
  handleClickRow,
  filterComponent,
  paginator,
  exportTable,
  setSelectedSortKey,
  sortUrl,
  searchUrl,
  columnSelect,
  actions,
  showCountColumn,
  draggable,
  maxHeight,
  title,
  tableAction,
  fullScreenOption
}: TableComponentProps<T>) => {
  const { t: translate } = useTranslation();
  const [sortConfig, setSortConfig] = useState<SortConfigInterface>(null);
  const [searchFilterParams, setSearchFilterParams] = useSearchParams();
  const { getColorStatus } = useColorsStatus();
  const { userData } = useContext(ProjectContext) as { userData: UserData };
  const userId = userData.id;
  const tableRef = useRef<HTMLDivElement>(null);

  const handleFullScreen = () => {
    if (tableRef.current) {
      if (tableRef.current.requestFullscreen) {
        tableRef.current.requestFullscreen();
      }
    }
  };

  const [usersPreferences] = useLocalStorage<UsersPreferences>(
    'usersPreferences',
    {
      users: {}
    }
  );

  const localUnselectedColumns: string[] = usersPreferences?.users?.[userId]
    ?.tableColumns?.tables?.[tableId]?.unselectColumns || [''];

  const localOrderColumns: TableColumnsObj[] =
    usersPreferences?.users?.[userId]?.tableColumns?.tables?.[tableId]
      ?.orderColumns || columnsArray;

  const [columns, setColumns] = useState(localOrderColumns);

  const [unselectedColumns, setUnselectColumns] = useState(
    localUnselectedColumns
  );

  const {
    columnDragging,
    columnOver,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragLeave
  } = useDragTable({
    columns,
    setColumns,
    userId: userId,
    tableId
  });

  const handleSort = (key: string, sortType?: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';

    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }

    setSortConfig({ key, direction, sortType });

    if (setSelectedSortKey) {
      setSelectedSortKey({
        key: key,
        direction: direction
      });
    }

    if (sortUrl) {
      setSearchFilterParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('sortDirection', direction);
        newParams.set('sortKey', key);

        return newParams;
      });
    }
  };

  const lineCountHeader = () => (
    <TableHeaderCell
      key={'lineCount'}
      onClick={() => handleSort('lineCount', 'number')}
      draggable={false}
      variant="leftFixed"
      minWidth="35px"
    >
      <DivTableHeader />
    </TableHeaderCell>
  );

  const lineCountCell = (lineNumber: number) => (
    <TableCell key={'lineCount'} variant="leftFixed">
      {lineNumber}
    </TableCell>
  );

  useEffect(() => {
    setColumns(columnsArray);
  }, [columnsArray]);

  const handleClickTableRow = (rowData: T) => {
    const selectedText = window.getSelection()?.toString().trim();
    if (!selectedText) {
      handleClickRow ? handleClickRow(rowData) : undefined;
    }
  };

  return (
    <DivTableComponents>
      <DivContainerActions>
        {title && (
          <DivTitle>
            <Text type="h3">{translate(title)}</Text>
          </DivTitle>
        )}
        {filterComponent && (
          <DivIcon>
            <Icon
              Icon={IoFilter}
              onClick={() => {
                setSearchFilterParams((prevParams) => {
                  const params = new URLSearchParams(prevParams);
                  params.set('filter', 'true');
                  return params;
                });
              }}
              variant="small"
              tooltip={translate('Filters')}
            />
          </DivIcon>
        )}
        {columnSelect && (
          <TableColumnSelect
            tableId={tableId}
            unselectedColumns={unselectedColumns}
            setUnselectColumns={setUnselectColumns}
            columns={columns}
          />
        )}
        {searchUrl && <></>}
        {exportTable && (
          <DivIcon>
            <Icon
              Icon={BiDownload}
              onClick={() => {
                setSearchFilterParams((prevParams) => {
                  const params = new URLSearchParams(prevParams);
                  params.set('exporttable', 'true');
                  return params;
                });
              }}
              variant="small"
              tooltip={translate('ExportTable')}
            />
          </DivIcon>
        )}
        {tableAction && (
          <DivIcon>
            <Icon
              Icon={PiArrowSquareOut}
              tooltip={translate('Expand')}
              onClick={tableAction}
            />
          </DivIcon>
        )}
        {fullScreenOption && (
          <DivIcon>
            <Icon
              Icon={IoExpandOutline}
              tooltip={translate('FullScreen')}
              onClick={() => handleFullScreen()}
            />
          </DivIcon>
        )}
      </DivContainerActions>
      <DivTable ref={tableRef} $maxHeight={maxHeight}>
        <Table>
          <TableHead>
            <TableHeaderRow>
              {showCountColumn && lineCountHeader()}
              {columns
                .filter((column) => !unselectedColumns.includes(column.key))
                .map((column, index) => (
                  <TableHeaderCell
                    key={`${column.key}_${column.label}_${index}`}
                    onClick={() => handleSort(column.key, column.sortType)}
                    isSorted={sortConfig?.key === column.key}
                    isSortedDesc={
                      sortConfig?.key === column.key &&
                      sortConfig?.direction === 'descending'
                    }
                    sortType={column.sortType}
                    accessKey={column.key}
                    draggable={draggable}
                    columnOver={columnOver}
                    columnDragging={columnDragging}
                    handleDragStart={handleDragStart}
                    handleDragOver={handleDragOver}
                    handleDragEnd={handleDragEnd}
                    handleDragLeave={handleDragLeave}
                  >
                    {column.label}
                  </TableHeaderCell>
                ))}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <TableRow
                key={index}
                onClick={() => handleClickTableRow(item as T)}
                hover={!!handleClickRow}
              >
                {showCountColumn && lineCountCell(index + 1)}
                {columns
                  .filter((column) => !unselectedColumns.includes(column.key))
                  .map((column, index) => {
                    const isStatusColumn = column.key === 'status';
                    const statusValue = item[column.key] as string;
                    const cellColor =
                      isStatusColumn &&
                      statusValue &&
                      getColorStatus(item.status as string)
                        ? getColorStatus(item.status as string)
                        : undefined;

                    return (
                      <TableCell
                        key={`${column.key}_${column.label}_${index}`}
                        color={cellColor}
                      >
                        {statusValue ?? '-'}
                      </TableCell>
                    );
                  })}

                {actions && (
                  <TableCell variant="rightFixed">
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <ActionMenu
                        availableContextActions={actions}
                        dataDrivenPermissions={
                          item as unknown as DataDrivenPermissionsActionsMap
                        }
                        dataActions={item}
                      />
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {tableData.length === 0 && (
          <DivMessageNoData>{translate('noData')}</DivMessageNoData>
        )}
        <ViewModal
          showModal={searchFilterParams.get('filter') === 'true'}
          title={translate('Filters')}
          onClose={() => {
            const params = new URLSearchParams(searchFilterParams);
            params.delete('filter');
            setSearchFilterParams(params);
          }}
          component={filterComponent}
        />
        <ViewModal
          showModal={searchFilterParams.get('exporttable') === 'true'}
          title={translate('ExportTable')}
          onClose={() => {
            const params = new URLSearchParams(searchFilterParams);
            params.delete('exporttable');
            setSearchFilterParams(params);
          }}
          component={exportTable}
        />
      </DivTable>
      {paginator && paginator}
    </DivTableComponents>
  );
};

export default TableDraggableComponent;
