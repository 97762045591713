import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../../../data/graphql/base-schema';
import {
  DeleteInstrumentReadingDocument,
  DeleteInstrumentReadingMutation,
  DeleteInstrumentReadingMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentReading';
import {
  ListReadingBySurfaceMarkersPagDocument,
  ListReadingBySurfaceMarkersPagQuery,
  ListReadingBySurfaceMarkersPagQueryVariables
} from '../../../../data/graphql/query/generated/listReadingBySurfaceMarkersPag.query';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import FilterReadingsPage from '../../Organisms/FilterReadingsPage/FilterReadingsPage';
import ReadingSurfaceMarker from '../../Organisms/ReadingSurfaceMarkerForm/ReadingSurfaceMarker';
import { ReadingSurfaceMarkerFormType } from '../../Organisms/ReadingSurfaceMarkerForm/ReadingSurfaceMarker.interfaces';
import RegisterSurfaceMarkerBathReadings from '../../Organisms/RegisterSurfaceMarkerBathReadings/RegisterSurfaceMarkerBathReadings';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { DivContainerTable } from './ReadingsPageSurfaceMarker.styles';
import { ProjectContext } from '../../../../Context/ContextAPI';
import Enums from '../../../../utils/enumns';
import Paginator from '../../Molecules/Paginator/Paginator';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import { TABLE_IDS } from '../../../../utils/TableConsts';

const ReadingsPageSurfaceMarker = () => {
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editSurfaceMaker, setEditSurfaceMarker] =
    useState<ReadingSurfaceMarkerFormType | null>(null);
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');
  const [modalReadingIsOpen, setModalReadingIsOpen] = useState(false);
  const [deleting, setDeleting] = useState({
    status: false,
    readingId: '',
    instrumentId: ''
  });
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const actionsTable: AvailableContextActionsProps<
    ListReadingBySurfaceMarkersPagQuery['listReadingBySurfaceMarkersPag']['nodes'][0]
  >[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (reading) => handleEditSurfaceMarker(reading),
      canShow: 'canEdit'
    },
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (item) => {
        setDeleting({
          status: true,
          readingId: item.reading.id,
          instrumentId: item.instrumentId
        });
      },
      canShow: 'canDelete'
    }
  ];

  const {
    data: dataReadingsBySurfaceMarkers,
    loading: loadingReadingsBySurfaceMarkers
  } = useQuery<
    ListReadingBySurfaceMarkersPagQuery,
    ListReadingBySurfaceMarkersPagQueryVariables
  >(ListReadingBySurfaceMarkersPagDocument, {
    variables: {
      structureInfo,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters: {
        instrumentsId:
          (searchParams.get('instrumentId') as InputMaybe<string[]>) ||
          undefined,
        readingType:
          (searchParams.get('type') as InstrumentReadingTypeEnum) || undefined,
        status:
          (searchParams.get('status') as InstrumentStatusEnum) || undefined,
        name: searchParams.get('name') || undefined,
        endDate: searchParams.get('endDate')
          ? new Date(searchParams.get('endDate') as string)
          : undefined,
        startDate: searchParams.get('startDate')
          ? new Date(searchParams.get('startDate') as string)
          : undefined
      }
    }
  });

  const [deleteInstrument] = useMutation<
    DeleteInstrumentReadingMutation,
    DeleteInstrumentReadingMutationVariables
  >(DeleteInstrumentReadingDocument);
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  const totalPages =
    dataReadingsBySurfaceMarkers?.listReadingBySurfaceMarkersPag.pageInfo
      .totalPages || 0;

  const tableData =
    dataReadingsBySurfaceMarkers?.listReadingBySurfaceMarkersPag.nodes?.map(
      (item) => ({
        ...item,
        date:
          new Date(item.reading.date).toLocaleString().replace(',', ' -') ??
          '-',
        observation: item.reading.observation ?? '-',
        coordinateE: item.reading.coordinateE ?? '-',
        coordinateN: item.reading.coordinateN ?? '-',
        elevation: item.reading.elevation ?? '-',
        operationalStatus:
          translate(item.operationalStatus.toLowerCase()) ?? '-',
        readingType: translate(item.readingType) ?? '-',
        structureType: item.structureType ?? '-',
        displacementsElevation: item.displacements.elevation ?? '-',
        displacementscoordinateN: item.displacements.coordinateN ?? '-',
        displacementscoordinateE: item.displacements.coordinateE ?? '-',
        installLocation: item.installLocation
          ? translate(item.installLocation)
          : '-'
      })
    );

  const columnsSurfaceMarker = [
    { label: translate('name'), key: 'name' },
    { label: translate('dateAndTime'), key: 'date' },
    { label: translate('coordinateE'), key: 'coordinateE' },
    { label: translate('coordinateN'), key: 'coordinateN' },
    { label: translate('elevation'), key: 'elevation' },
    { label: translate('operationalStatus'), key: 'operationalStatus' },
    { label: translate('structureType'), key: 'structureType' },
    { label: translate('typeOfReading'), key: 'readingType' },
    { label: translate('installLocation'), key: 'installLocation' },
    {
      label: translate('displacementsElevation'),
      key: 'displacementsElevation'
    },
    {
      label: translate('displacementscoordinateN'),
      key: 'displacementscoordinateN'
    },
    {
      label: translate('displacementscoordinateE'),
      key: 'displacementscoordinateE'
    }
  ];

  const handleEditSurfaceMarker = (
    readingRole: ListReadingBySurfaceMarkersPagQuery['listReadingBySurfaceMarkersPag']['nodes'][0]
  ) => {
    const reading =
      dataReadingsBySurfaceMarkers?.listReadingBySurfaceMarkersPag.nodes?.find(
        (item) => item.reading.id === readingRole.reading.id
      );
    if (!reading) return;
    const newReading = {
      id: readingRole.reading.id,
      elevation: readingRole.reading.elevation?.toLocaleString() ?? '',
      coordinateE: readingRole.coordinateE.toString(),
      coordinateN: readingRole.coordinateN.toString(),
      date: new Date(reading.reading.date ?? Date.now()),
      observation: readingRole.observation as string,
      instrument: {
        id: readingRole.instrumentId,
        name: readingRole.name
      }
    };
    setEditSurfaceMarker(newReading);
    setShowModal(true);
  };

  const handleDeleteReading = () => {
    deleteInstrument({
      variables: {
        structureId: structureId!,
        data: {
          readingId: deleting.readingId,
          instrumentId: deleting.instrumentId
        }
      },
      onCompleted: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfyError(translate(ToastifyModel().toastifyMessage.error));
      },
      refetchQueries: [ListReadingBySurfaceMarkersPagDocument]
    });
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loadingReadingsBySurfaceMarkers}
        title={<Text type="h1">{translate('SurfaceMarker')} </Text>}
        button={
          userRole !== UserRoles.Viewer && (
            <>
              <Button
                onClick={() => {
                  setEditSurfaceMarker(null);
                  setShowModal(true);
                }}
                variant={'primary'}
                text={translate('RegisterReadings')}
              />
              <Button
                variant={'primary'}
                size="large"
                text={translate('RegisterReadingsFile')}
                onClick={() => setModalReadingIsOpen(true)}
              />
            </>
          )
        }
        content={
          <DivContainerTable>
            <TableDraggableComponent
              tableId={TABLE_IDS.READINGS_SURFACEMARKER_TABLE}
              columnsArray={columnsSurfaceMarker}
              tableData={tableData || []}
              actions={userRole !== UserRoles.Viewer ? actionsTable : undefined}
              filterComponent={<FilterReadingsPage />}
              paginator={
                <Paginator
                  options={optionsPaginatorDefault}
                  totalPages={totalPages}
                  showInUrl
                />
              }
              sortUrl
              searchUrl
              columnSelect
              showCountColumn
            />
          </DivContainerTable>
        }
      />
      <ViewModal
        showModal={showModal}
        title={
          editSurfaceMaker
            ? `${translate('EditReadings')} ${translate('SurfaceMarker')}`
            : `${translate('NewReading')} ${translate('SurfaceMarker')}`
        }
        onClose={() => {
          setEditSurfaceMarker(null);
          setShowModal(false);
        }}
        component={
          <ReadingSurfaceMarker
            reading={editSurfaceMaker}
            setShowModal={setShowModal}
          />
        }
        width="500px"
        height="600px"
      />
      <ModalConfirm
        showModal={deleting.status}
        onClose={() =>
          setDeleting({
            status: false,
            readingId: '',
            instrumentId: ''
          })
        }
        onConfirm={() => handleDeleteReading()}
        title={`${translate('Delete')} ${translate('reading')}`}
        text={translate('ConfirmDelete')}
      />

      <ViewModal
        showModal={modalReadingIsOpen}
        onClose={() => setModalReadingIsOpen(false)}
        title={translate('SurfaceMarker')}
        width="500px"
        height="550px"
        component={
          <RegisterSurfaceMarkerBathReadings onClose={setModalReadingIsOpen} />
        }
      />
    </>
  );
};

export default ReadingsPageSurfaceMarker;
