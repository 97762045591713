import Text from '../../Atoms/Text/Text';
import { DivInputs } from '../EditPiezometerAndInaPage/EditPiezometerAndInaPage.styles';
import InputText from '../../Molecules/InputText/InputText';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { z, ZodIssueCode } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Enums from '../../../../utils/enumns';
import { TiDelete } from 'react-icons/ti';
import { useState } from 'react';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputFileWithList from '../../Organisms/InputFileWithList/InputFileWithList';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import {
  ListInstrumentTypesQuery,
  ListInstrumentTypesQueryVariables,
  ListInstrumentTypesDocument
} from '../../../../data/graphql/query/generated/listIntrumentsTypes.query';
import {
  BorderContainerSpecificInfos,
  BorderContainer,
  Footer,
  HolderAlertLevel,
  HolderCommonInfos,
  HolderContainerTitle,
  HolderSpecificsInfo,
  HolderTitle,
  MainContainer
} from './RegisterWaterLevel.styles';
import {
  CreateWaterLevelDocument,
  CreateWaterLevelMutation,
  CreateWaterLevelMutationVariables
} from '../../../../data/graphql/generated/addWaterLevelInstrument';
import { ListWaterLevelByStructureDocument } from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { ListInstrumentsByStructurePagDocument } from '../../../../data/graphql/query/generated/listInstrumentsByStructurePag.query';

const alertLevelsSchema = z.object({
  name: z.string().min(1, 'fieldIsRequired'),
  value: z.string().min(1, 'fieldIsRequired')
});

type AlertLevelsType = z.infer<typeof alertLevelsSchema>;

const instrumentInfosSchema = z
  .object({
    name: z.string().min(1, 'fieldIsRequired'),
    coordinateE: z.string().min(1, 'fieldIsRequired'),
    coordinateN: z.string().min(1, 'fieldIsRequired'),
    isUTM: z.boolean(),
    installationSite: z.object({
      value: z.string().min(1, { message: 'fieldIsRequired' }),
      label: z.string()
    }),
    status: z.boolean(),
    installationDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    activationDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: 'fieldIsRequired'
      }),
    typeOfReading: z.object({
      value: z.string().min(1, { message: 'fieldIsRequired' }),
      label: z.string()
    }),
    docs: z.array(z.any()).optional(),
    alertLevels: z.array(alertLevelsSchema)
  })
  .superRefine((data, ctx) => {
    const {
      installationDate,
      activationDate,
      coordinateE,
      coordinateN,
      isUTM,
      alertLevels
    } = data;

    if (alertLevels.length === 0) {
      toastfyError('addLeastOneAlertLevel');
    }

    if (
      activationDate &&
      installationDate &&
      activationDate < installationDate
    ) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['activationDate'],
        message: 'dateActivationIsLessThanInstallationDate'
      });
    }

    if (!isUTM && (coordinateE > '90' || coordinateE < '-90')) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateE'],
        message: 'ValidationCoordinateE'
      });
    }

    if (!isUTM && (coordinateN > '180' || coordinateN < '-180')) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateN'],
        message: 'ValidationCoordinateN'
      });
    }

    if (isUTM && coordinateE < '0') {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateE'],
        message: 'coordinateUtmCannottNegative'
      });
    }

    if (isUTM && coordinateN < '0') {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['coordinateN'],
        message: 'coordinateUtmCannottNegative'
      });
    }
  });

type InstrumentInfosType = z.infer<typeof instrumentInfosSchema>;

const RegisterWaterLevel = () => {
  const { t: translate } = useTranslation();
  const [files, setFiles] = useState<FileType[]>([]);
  const { structureId } = useParams();
  const navigate = useNavigate();
  const { parseErrorMessage } = useErrorsTreatment();
  const { TypeOfInstallation, TypeOfReading } = Enums();

  const {
    control: alertsControl,
    handleSubmit: alertHandleSubmit,
    reset: alertReset,
    formState: { errors: alertErros }
  } = useForm<AlertLevelsType>({
    resolver: zodResolver(alertLevelsSchema)
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch
  } = useForm<InstrumentInfosType>({
    resolver: zodResolver(instrumentInfosSchema),
    defaultValues: {
      installationDate: undefined,
      activationDate: undefined,
      status: true,
      isUTM: true,
      installationSite: {
        value: '',
        label: ''
      },
      typeOfReading: {
        value: '',
        label: ''
      },
      alertLevels: []
    }
  });
  const {
    fields: alertLevelsData,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'alertLevels'
  });

  const [createWaterLevel] = useMutation<
    CreateWaterLevelMutation,
    CreateWaterLevelMutationVariables
  >(CreateWaterLevelDocument, {});

  const { data: listInstruments } = useQuery<
    ListInstrumentTypesQuery,
    ListInstrumentTypesQueryVariables
  >(ListInstrumentTypesDocument, {
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const waterLevelTypeId = listInstruments?.listInstrumentTypes.find(
    (type) => type.instrumentType === 'WaterLevel'
  );

  const handleAddAlertLevel = (data: AlertLevelsType) => {
    append(data);
    alertReset();
  };

  const handleRemoveAlertLevel = (index: number) => {
    remove(index);
  };

  const handleFileChange = (newFiles: FileList) => {
    const updatedFiles = Array.from(newFiles).map((file) => ({
      id: null,
      name: file.name,
      file: file,
      url: URL.createObjectURL(file)
    }));
    setFiles((prev) => [...prev, ...updatedFiles]);
  };

  const handleDeleteFile = (fileToDelete: FileType) => {
    const updatedFiles = files.filter(
      (file) => file.file !== fileToDelete.file
    );
    setFiles(updatedFiles);
  };

  const handleRegisterWaterLevel = (data: InstrumentInfosType) => {
    const operationalStatus = data.status
      ? InstrumentStatusEnum.Active
      : InstrumentStatusEnum.Inactive;

    const waterLevelGeneralInfos = {
      typeId: waterLevelTypeId?.id || '',
      name: data.name,
      coordinateE: parseFloat(data.coordinateE),
      coordinateN: parseFloat(data.coordinateN),
      installationDate: new Date(data.installationDate),
      activationDate: new Date(data.activationDate),
      isUTM: data.isUTM,
      operationalStatus: operationalStatus,
      readingType: data.typeOfReading.value as InstrumentReadingTypeEnum,
      installLocation: data.installationSite.value,
      docs: files.map((file) => file.file)
    };

    const waterLevelAlertLevels = alertLevelsData.map((alertLevel) => ({
      name: alertLevel.name,
      value: parseFloat(alertLevel.value)
    }));

    createWaterLevel({
      variables: {
        structureInfo: {
          structureId: structureId!,
          associatedStructureId: null
        },
        alertLevels: waterLevelAlertLevels,
        generalInfo: waterLevelGeneralInfos
      },
      refetchQueries: [
        {
          query: ListInstrumentsByStructurePagDocument,
          variables: {
            instrumentType: InstrumentTypeEnum.WaterLevel,
            structureId: structureId!,
            pageInfo: {
              limit: 10,
              page: 1
            },
            filters: {}
          }
        },
        {
          query: ListWaterLevelByStructureDocument,
          variables: {
            structureInfo: {
              structureId: structureId!,
              associatedStructureId: null
            }
          }
        }
      ],
      onCompleted: () => {
        toastfySuccess('successRegisterInstrument');
        navigate(`/${structureId}/monitoring/instrument/waterlevel`);
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <MainContainer>
      <HolderCommonInfos>
        <HolderContainerTitle>
          <Text color="black" type="span" weight="bold">
            {translate('basicInfos')}
          </Text>
        </HolderContainerTitle>
        <BorderContainer>
          <DivInputs>
            <InputText
              width="300px"
              control={control}
              label={translate('name')}
              name={'name'}
              type={'text'}
              error={!!errors.name}
              errorMessage={errors?.name?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputText
              width="300px"
              control={control}
              label={translate('coordinateN')}
              name={'coordinateN'}
              type={'text'}
              error={!!errors.coordinateN}
              errorMessage={errors?.coordinateN?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputText
              width="300px"
              control={control}
              label={translate('coordinateE')}
              name={'coordinateE'}
              type={'text'}
              error={!!errors.coordinateE}
              errorMessage={errors?.coordinateE?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputCheckbox
              register={register}
              label={translate('coordinateUTM')}
              name={'isUTM'}
              value={watch('isUTM')}
            />
            <InputCheckbox
              register={register}
              label={translate('Active')}
              name={'status'}
              value={watch('status')}
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              control={control}
              placeholder={translate('installationSite')}
              width="300px"
              label={translate('installationSite')}
              options={TypeOfInstallation}
              name="installationSite"
              error={!!errors.installationSite}
              errorMessage={errors?.installationSite?.message}
              isClearable
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              control={control}
              placeholder={translate('typeOfReading')}
              width="300px"
              label={translate('typeOfReading')}
              options={TypeOfReading}
              name="typeOfReading"
              error={!!errors.typeOfReading}
              errorMessage={errors?.typeOfReading?.message}
              isClearable
            />
          </DivInputs>
          <DivInputs>
            <DatepickerInput
              control={control}
              label={translate('installationDate')}
              name="installationDate"
              placeholder={translate('NoInformated')}
              time={false}
              error={!!errors.installationDate}
              errorMessage={errors?.installationDate?.message}
            />
          </DivInputs>
          <DivInputs>
            <DatepickerInput
              control={control}
              label={translate('activationDate')}
              name="activationDate"
              placeholder={translate('NoInformated')}
              time={false}
              error={!!errors.activationDate}
              errorMessage={errors?.activationDate?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputFileWithList
              name="docs"
              accept=".xlsx,.pdf,.docx,.xls,.xlsx,.doc"
              label={translate('File')}
              files={files}
              multiple
              onChange={(event) => {
                if (event.target.files) {
                  handleFileChange(event.target.files);
                }
              }}
              canDelete
              onDelete={(file) => handleDeleteFile(file)}
            />
          </DivInputs>
          <Footer>
            <Button
              variant="primary"
              onClick={handleSubmit(handleRegisterWaterLevel)}
              text={translate('RegisterInstrument')}
            />
          </Footer>
        </BorderContainer>
      </HolderCommonInfos>
      <HolderSpecificsInfo>
        <HolderContainerTitle>
          <Text color="black" type="span" weight="bold">
            {translate('instrumentInformation')}
          </Text>
        </HolderContainerTitle>
        <BorderContainerSpecificInfos
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === 'Enter') {
              alertHandleSubmit(handleAddAlertLevel)();
            }
          }}
        >
          <DivInputs>
            <InputText
              width="300px"
              control={alertsControl}
              label={translate('name')}
              name={'name'}
              type={'text'}
              error={!!alertErros.name}
              errorMessage={alertErros?.name?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputText
              width="300px"
              control={alertsControl}
              label={translate('value')}
              name={'value'}
              type={'number'}
              error={!!alertErros.value}
              errorMessage={alertErros?.value?.message}
            />
          </DivInputs>
          <Footer>
            <Button
              variant="primary"
              onClick={alertHandleSubmit(handleAddAlertLevel)}
              text={translate('newAlertLevel')}
            />
          </Footer>
          <HolderAlertLevel>
            <HolderTitle>
              <Text color="white" type="span" weight="default">
                {translate('alertLevels')}
              </Text>
            </HolderTitle>
            <TableComponent
              tableData={alertLevelsData || []}
              columns={[
                { key: 'name', label: translate('name') },
                { key: 'value', label: translate('value') }
              ]}
              actions={[
                {
                  name: translate('Delete'),
                  icon: <TiDelete />,
                  onClick: (field) => handleRemoveAlertLevel(field.id),
                  canShow: 'canDelete'
                }
              ]}
            />
          </HolderAlertLevel>
        </BorderContainerSpecificInfos>
      </HolderSpecificsInfo>
    </MainContainer>
  );
};

export default RegisterWaterLevel;
