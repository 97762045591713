import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindStabilityAnalysisGraphicByIdQueryVariables = Types.Exact<{
  stabilityAnalysisId: Types.Scalars['String']['input'];
}>;


export type FindStabilityAnalysisGraphicByIdQuery = { __typename?: 'Query', findStabilityAnalysisGraphicById: { __typename?: 'StabilityAnalysisGraphicType', graphicUrl: string, graphicName: string, graphicStatus: Types.GraphicStatusEnum, graphicInfo: { __typename?: 'StabilityAnalysisGraphicInfoType', slideFileName: string, instrumentsList: Array<string>, materialList: Array<string>, graphParams: { __typename?: 'GraphParamsType', failureDirection: Types.FailureDirectionEnum, calculationMethod: Types.CalculationMethodEnum, surfaceType: Types.SurfaceTypeEnum, searchMethod: Types.NonCircularSearchMethodEnum, analysisDate: Date, filter: { __typename?: 'GraphicFilterType', elevation?: number | null, area?: number | null, depth?: number | null, weight?: number | null } } } } };


export const FindStabilityAnalysisGraphicByIdDocument = gql`
    query FindStabilityAnalysisGraphicById($stabilityAnalysisId: String!) {
  findStabilityAnalysisGraphicById(stabilityAnalysisId: $stabilityAnalysisId) {
    graphicUrl
    graphicName
    graphicStatus
    graphicInfo {
      slideFileName
      graphParams {
        failureDirection
        calculationMethod
        surfaceType
        searchMethod
        filter {
          elevation
          area
          depth
          weight
        }
        analysisDate
      }
      instrumentsList
      materialList
    }
  }
}
    `;
export type FindStabilityAnalysisGraphicByIdQueryResult = Apollo.QueryResult<FindStabilityAnalysisGraphicByIdQuery, FindStabilityAnalysisGraphicByIdQueryVariables>;