/* eslint-disable id-length */
export const TABLE_IDS = {
  PIEZOMETER_LIST_TABLE: 'piezometerListTable',
  PLUVIOMETER_LIST_TABLE: 'pluviometerListTable',
  SURFACE_MARKER_LIST_TABLE: 'surfaceMarkerListTable',
  WATER_LEVEL_LIST_TABLE: 'waterLevelListTable',
  READINGS_PIEZOMETER_TABLE: 'readingsPiezometerTable',
  READINGS_PLUVIOMETER_TABLE: 'readingsPluviometerTable',
  READINGS_SURFACEMARKER_TABLE: 'readingsSurfaceMarkerTable',
  READINGS_WATERLEVEL_TABLE: 'readingsWaterLevelTable',
  MONITORING_DASHBOARD_READING_TABLE: 'monitoringDashboardReadingTable',
  MONITORING_DASHBOARD_EQUIPAMENT_HEALTH_TABLE:
    'monitoringDashboardEquipamentHealthTable',
  CMG_EQUIPAMENT_HEALTH_TABLE: 'cmgEquipamentHealthTable',
  CMG_READINGS_TABLE: 'cmgReadingsTable',
  INSPECTION_DASHBOARD_TABLE: 'inspectionDashboardTable',
  INSPECTION_DASHBOARD_ANOMALIES_TABLE: 'inspectionDashboardAnomaliesTable',
  CMG_INSPECTION_TABLE: 'cmgInspectionTable',
  CMG_ANOMALIES_TABLE: 'cmgAnomaliesTable',
  STABILITY_HISTORY_TABLE: 'stabilityHistoryTable',
  INSPECTIONS_TABLE: 'inspectionsTable'
} as const;

export type TableId = (typeof TABLE_IDS)[keyof typeof TABLE_IDS];
