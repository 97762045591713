import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordVideoObservationMutationVariables = Types.Exact<{
  data: Types.UpdateRecordVideoObservationInput;
}>;


export type UpdateRecordVideoObservationMutation = { __typename?: 'Mutation', updateRecordVideoObservation: string };


export const UpdateRecordVideoObservationDocument = gql`
    mutation UpdateRecordVideoObservation($data: UpdateRecordVideoObservationInput!) {
  updateRecordVideoObservation(data: $data)
}
    `;
export type UpdateRecordVideoObservationMutationFn = Apollo.MutationFunction<UpdateRecordVideoObservationMutation, UpdateRecordVideoObservationMutationVariables>;
export type UpdateRecordVideoObservationMutationResult = Apollo.MutationResult<UpdateRecordVideoObservationMutation>;
export type UpdateRecordVideoObservationMutationOptions = Apollo.BaseMutationOptions<UpdateRecordVideoObservationMutation, UpdateRecordVideoObservationMutationVariables>;