import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { PiAsteriskBold } from 'react-icons/pi';
import Select from 'react-select';
import { StyleInstrumentSelect } from '../../../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../../../Components/Toastify';
import ToastifyModel from '../../../../../Models/ToastifyModel';
import {
  AdminComplexInputs,
  AdminInput,
  DivInput,
  IconRequired,
  InputArea,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../../../Styles/Styles';
import { useListCompany } from '../../../../../data/hooks/admin-company/use-list-company';
import { useListCompanyRelatedUsersAndStructuresLazy } from '../../../../../data/hooks/admin-permission/use-list-company-related-users-and-structures';
import { StepOne, installationLocation } from '../StructuresInterface';
import InputText from '../../../../../Components/V2/Molecules/InputText/InputText';
import Text from '../../../../../Components/V2/Atoms/Text/Text';
import InputMask from '../../../../../Components/V2/Molecules/InputMask/InputMask';
import { getAddressForCep } from '../../../../../utils/getAddressForCep';
import { useQuery } from '@apollo/client';
import {
  ListUsersDocument,
  ListUsersQuery,
  ListUsersQueryVariables
} from '../../../../../data/graphql/query/generated/listUsers.query';

export function StructureStepOne({
  structureStepOneData,
  setStructureStepOneData,
  error
}: {
  structureStepOneData: StepOne;
  setStructureStepOneData: Dispatch<SetStateAction<StepOne>>;
  error: boolean;
}) {
  const { t } = useTranslation();
  const { data: listCompany } = useListCompany(true);
  const { listCompanyRelatedUsersAndStructures } =
    useListCompanyRelatedUsersAndStructuresLazy();

  const { data: usersList } = useQuery<ListUsersQuery, ListUsersQueryVariables>(
    ListUsersDocument,
    {
      variables: {
        data: {
          listAllUsers: true
        }
      }
    }
  );

  const collaborators = usersList?.listUsers?.map((user) => ({
    id: user.id,
    label: user.name,
    value: user.id
  }));

  const activeCompanies = listCompany?.listCompanies
    .filter((company: any) => company.status === true)
    .map((company: any) => ({
      id: company.id,
      label: company.name,
      value: company.id
    }));

  const companies = activeCompanies;

  const collaboratorsValue = collaborators?.find(
    (user: any) => user.id === structureStepOneData.responsibleId
  );

  const handleGetListCompanyRelatedUsersAndStructures = async (id: string) => {
    const response = await listCompanyRelatedUsersAndStructures({
      variables: {
        data: {
          id
        }
      }
    });
    toastifyLoading('Carregando...');
    if (response.data) {
      toastfyDimiss('toastLoading');
    } else if (response.error) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.error.message || t(ToastifyModel().toastifyMessage.error)
      );
    }
  };

  useEffect(() => {
    if (structureStepOneData.companyId) {
      handleGetListCompanyRelatedUsersAndStructures(
        structureStepOneData.companyId
      );
    }
  }, [structureStepOneData.companyId]);

  return (
    <InputArea>
      {
        <RequiredInput style={{ position: 'relative', width: '50%' }}>
          <Select
            styles={{
              ...StyleInstrumentSelect,
              control: (provided) => ({
                ...provided,
                border: 'none',
                borderBottom:
                  error && !structureStepOneData.companyId
                    ? '1px solid red'
                    : `1px solid ${Color.Brown1}`,
                borderRadius: 'none',
                boxSizing: 'border-box',
                boxShadow: 'none',
                padding: 'none',
                fontSize: '11pt',
                cursor: 'pointer',
                marginBottom: '13px',
                userSelect: 'none',
                background:
                  error && !structureStepOneData.companyId ? '#ffe6e6' : 'none',
                ':hover': {
                  borderBottom:
                    error && !structureStepOneData.companyId
                      ? '1px solid red'
                      : `1px solid ${Color.Brown1}`
                }
              })
            }}
            isSearchable={false}
            placeholder={t('Company')}
            hideSelectedOptions
            options={companies}
            onChange={(e: any) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                companyId: e.id
              }));
              handleGetListCompanyRelatedUsersAndStructures(e.id);
            }}
            noOptionsMessage={() => t('NoOptions')}
            maxMenuHeight={200}
            value={companies?.find(
              (company: any) => company.id === structureStepOneData.companyId
            )}
          />
        </RequiredInput>
      }
      {
        <RequiredInput style={{ position: 'relative', width: '50%' }}>
          <Select
            styles={{
              ...StyleInstrumentSelect,
              control: (provided) => ({
                ...provided,
                border: 'none',
                borderBottom:
                  error && !structureStepOneData.responsibleId
                    ? '1px solid red'
                    : `1px solid ${Color.Brown1}`,
                borderRadius: 'none',
                boxSizing: 'border-box',
                boxShadow: 'none',
                padding: 'none',
                fontSize: '11pt',
                cursor: 'pointer',
                marginBottom: '13px',
                userSelect: 'none',
                background:
                  error && !structureStepOneData.companyId ? '#ffe6e6' : 'none',
                ':hover': {
                  borderBottom:
                    error && !structureStepOneData.responsibleId
                      ? '1px solid red'
                      : `1px solid ${Color.Brown1}`
                }
              })
            }}
            isSearchable={false}
            placeholder={t('Responsible')}
            hideSelectedOptions
            options={collaborators}
            onChange={(e: any) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                responsibleId: e.id
              }));
            }}
            noOptionsMessage={() => t('NoOptions')}
            maxMenuHeight={200}
            value={collaboratorsValue === undefined ? '' : collaboratorsValue}
          />
          <Select
            styles={{
              ...StyleInstrumentSelect,
              control: (provided) => ({
                ...provided,
                border: 'none',
                borderBottom:
                  error && !structureStepOneData.type
                    ? '1px solid red'
                    : `1px solid ${Color.Brown1}`,
                borderRadius: 'none',
                boxSizing: 'border-box',
                boxShadow: 'none',
                padding: 'none',
                fontSize: '11pt',
                cursor: 'pointer',
                marginBottom: '13px',
                userSelect: 'none',
                background:
                  error && !structureStepOneData.companyId ? '#ffe6e6' : 'none',
                ':hover': {
                  borderBottom:
                    error && !structureStepOneData.type
                      ? '1px solid red'
                      : `1px solid ${Color.Brown1}`
                }
              })
            }}
            isSearchable={false}
            placeholder={t('InstallationLocation')}
            hideSelectedOptions
            options={installationLocation}
            onChange={(e: any) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                type: e.id
              }));
            }}
            noOptionsMessage={() => t('NoOptions')}
            maxMenuHeight={200}
            value={installationLocation.find((installation) => {
              return installation.id === structureStepOneData.type;
            })}
          />
        </RequiredInput>
      }
      <RequiredInput>
        <AdminInput
          value={structureStepOneData.name}
          onChange={(e) => {
            setStructureStepOneData((prev) => ({
              ...prev,
              name: e.target.value
            }));
          }}
          style={
            error && !structureStepOneData.name
              ? { borderColor: 'red', background: '#ffe6e6' }
              : {}
          }
          placeholder={t('name')}
        />
        {structureStepOneData.name.length < 3 ? (
          <PiAsteriskBold style={IconRequired} />
        ) : (
          <BsCheckLg style={IconRequired} />
        )}
      </RequiredInput>
      <Text type="h3">{t('Complex')}</Text>
      <AdminComplexInputs>
        <DivInput>
          <InputText
            label={t('name')}
            name="complexityName"
            value={structureStepOneData.complex.name}
            width="220px"
            type="text"
            onChange={(event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  name: event.target.value
                }
              }));
            }}
          />
        </DivInput>
        <DivInput>
          <InputMask
            label={t('CEP')}
            name="complexityZipCode"
            value={structureStepOneData.complex.zipCode ?? undefined}
            mask="99999-999"
            width="220px"
            onChange={async (event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  zipCode: event.target.value
                }
              }));
              const response = await getAddressForCep(event.target.value);
              if (response) {
                setStructureStepOneData((prev) => ({
                  ...prev,
                  complex: {
                    ...prev.complex,
                    address: response.logradouro,
                    neighborhood: response.bairro,
                    city: response.localidade,
                    state: response.uf
                  }
                }));
              }
            }}
          />
        </DivInput>
        <DivInput>
          <InputText
            label={t('Address')}
            name="complexityAddress"
            value={structureStepOneData.complex.address}
            type="text"
            width="220px"
            onChange={(event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  address: event.target.value
                }
              }));
            }}
          />
        </DivInput>
        <DivInput>
          <InputText
            label={t('Neighborhood')}
            name="complexityNeighborhood"
            value={structureStepOneData.complex.neighborhood}
            type="text"
            width="220px"
            onChange={(event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  neighborhood: event.target.value
                }
              }));
            }}
          />
        </DivInput>
        <DivInput>
          <InputText
            label={t('City')}
            name="complexityCity"
            value={structureStepOneData.complex.city}
            type="text"
            width="220px"
            onChange={(event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  city: event.target.value
                }
              }));
            }}
          />
        </DivInput>
        <DivInput>
          <InputText
            label={t('State')}
            name="complexityState"
            value={structureStepOneData.complex.state}
            type="text"
            width="220px"
            onChange={(event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  state: event.target.value
                }
              }));
            }}
          />
        </DivInput>
        <DivInput>
          <InputText
            label={t('Country')}
            name="complexityCountry"
            value={structureStepOneData.complex.country}
            type="text"
            width="220px"
            onChange={(event) => {
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  country: event.target.value
                }
              }));
            }}
          />
        </DivInput>
        <DivInput>
          <InputMask
            label={t('Telephone')}
            name="complexityPhone"
            value={structureStepOneData.complex.phone ?? ''}
            mask={
              structureStepOneData.complex.phone
                ?.replace(/\D/g, '')
                .slice(2, 3) === '9'
                ? '(99) 99999-9999'
                : '(99) 9999-9999'
            }
            width="220px"
            onChange={(event) => {
              const phoneValue = event.target.value;
              setStructureStepOneData((prev) => ({
                ...prev,
                complex: {
                  ...prev.complex,
                  phone: phoneValue
                }
              }));
            }}
          />
        </DivInput>
      </AdminComplexInputs>
    </InputArea>
  );
}
