import {
  DivContainerGroupText,
  DivContainerSpanCard
} from './GroupText.styles';
import Text from '../../Atoms/Text/Text';
import { GroupTextProps } from './GroupText.interfaces';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export const GroupText = ({
  title,
  value,
  maxLength,
  tradution
}: GroupTextProps) => {
  const { t: translate } = useTranslation();

  return (
    <DivContainerGroupText>
      <Text type="label">{translate(title)}</Text>
      <DivContainerSpanCard>
        <Text
          data-tooltip-id={maxLength ? value : undefined}
          data-tooltip-content={value}
          type="span"
          color="black"
        >
          {maxLength && value?.length > maxLength
            ? `${value?.slice(0, maxLength)}...`
            : tradution
              ? translate(value)
              : value}
        </Text>
      </DivContainerSpanCard>
      <Tooltip id={maxLength && value.length > maxLength ? value : undefined} />
    </DivContainerGroupText>
  );
};
