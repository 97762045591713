import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { ProjectContext } from '../../../../Context/ContextAPI';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery,
  FindDataByRecordQueryVariables
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { BUCKET_URL } from '../../../../utils/const';
import SkeletonNewAtoPage from '../../../Skeleton/newAtoPage/SkeletonNewAtoPage';
import Button from '../../Atoms/Button/Button';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import WorkScheduleTable from '../../Molecules/WorkScheduleTable/WorkScheduleTable';
import { AtoSignatureCard } from '../../Organisms/AtoSignatureCard/AtoSignatureCard';
import CardAtoInfo from '../../Organisms/CardInfo/CardInfo';
import ReportCard from '../../Organisms/ReportCard/ReportCard';
import SignatureContainer from '../../Organisms/SignatureContainer/SignatureContainer';
import {
  EquipmentDataType,
  InspectionObservationsType,
  LaborDataType,
  RecordImagesType,
  RecordVideosType,
  RedAlertPeriodType,
  WeatherConditionsType,
  WorkSafetyObservationsType
} from '../../Pages/EditAtoPage/EditAtoPage.interface';
import {
  ATOButtonsContainer,
  ATOContainer,
  PageContainer
} from './ATOScreen.styles';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import { useTranslation } from 'react-i18next';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import {
  FindGeneralInfoQuery,
  FindGeneralInfoQueryVariables,
  FindGeneralInfoDocument
} from '../../../../data/graphql/query/generated/findGeneralInfo';
import { UserData } from '../../../Map/types';

export default function ATOScreen() {
  const componentRef = useRef(null);
  const { recordId, structureId, atoId } = useParams();
  const [selectFileToShow, setSelectFileToShow] = useState<FileType | null>(
    null
  );
  const { parseErrorMessage } = useErrorsTreatment();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('ATO');

  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const { data: recordData, loading } = useQuery<
    FindDataByRecordQuery,
    FindDataByRecordQueryVariables
  >(FindDataByRecordDocument, {
    variables: {
      recordId: recordId || ''
    }
  });

  const { data: allAtoSpecificInfos } = useQuery<
    FindGeneralInfoQuery,
    FindGeneralInfoQueryVariables
  >(FindGeneralInfoDocument, {
    variables: {
      atoId: atoId ? atoId : ''
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const statusATO = allAtoSpecificInfos?.findGeneralInfo.situation;
  const statusRDO = recordData?.findDataByRecord.situation;

  const isElaboratorDefined = recordData?.findDataByRecord.userTypes.some(
    (user) => user.userType === 'Elaborator'
  );

  const isCurrentUserElaborator = recordData?.findDataByRecord.userTypes.some(
    (user) => user.userType === 'Elaborator' && user.id === userData.id
  );

  const userRolesEquivalentToElaborator = ['Editor', 'Admin'];
  const isUserPossibleElaborator =
    userRolesEquivalentToElaborator.includes(userRoleInModule);

  const isUserVerifier = recordData?.findDataByRecord.userTypes.some(
    (user) => user.userType === 'Verifier' && user.id === userData.id
  );

  const isUserApproverClient = recordData?.findDataByRecord.userTypes.some(
    (user) => user.userType === 'ClientApprover' && user.id === userData.id
  );

  const isStatusATOValid = !(statusATO === 'Done' || statusATO === 'Canceled');

  const isStatusRDOValid = !(
    statusRDO === 'Done' ||
    statusRDO === 'Canceled' ||
    (statusRDO === 'Review' && !isUserVerifier) ||
    (statusRDO === 'Approved' && !isUserApproverClient)
  );

  const canEditBasedOnElaborator = isElaboratorDefined
    ? isCurrentUserElaborator
    : isUserPossibleElaborator;

  const showEditButton =
    isStatusATOValid && isStatusRDOValid && canEditBasedOnElaborator;

  const formatRecordWorkShiftData = recordData?.findDataByRecord.workShifts.map(
    (work) => ({
      entry: new Date(work.entry).toLocaleString(),
      exit: new Date(work.exit).toLocaleString(),
      entryInterval: new Date(work.entryInterval).toLocaleString(),
      exitInterval: new Date(work.exitInterval).toLocaleString(),
      id: work.id,
      observation: work.observation,
      totalHours: work.totalHours
    })
  );

  const formatWeatherConditionData =
    recordData?.findDataByRecord.weatherConditions.map((weather) => ({
      start: new Date(weather.start).toLocaleString(),
      end: new Date(weather.end).toLocaleString(),
      weather: weather.weather,
      id: weather.id
    }));

  const formatAlertPeriodData =
    recordData?.findDataByRecord.redAlertPeriods.map((alert) => ({
      start: new Date(alert.start).toLocaleString(),
      end: new Date(alert.end).toLocaleString(),
      id: alert.id
    }));

  const handleEdit = () => {
    navigate(`/${structureId}/ato/${atoId}/edit/${recordId}`);
  };

  const handleNavigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}/records`);
  };

  const validationElaboratorReportCard =
    recordData?.findDataByRecord.elaborator != null
      ? recordData?.findDataByRecord.elaborator
      : t('toBeDefined');

  const remainingDuration = Number(recordData?.findDataByRecord.remainingTerm);
  const formatRemainingTerm = (remainingTerm: number): string => {
    if (remainingTerm < 0) {
      return `${translate('Overdue')} ${Math.abs(remainingTerm)}`;
    }

    return remainingTerm.toString();
  };

  return (
    <>
      {loading ? (
        <SkeletonNewAtoPage />
      ) : (
        <>
          <ATOButtonsContainer>
            <Button
              onClick={handleNavigateBack}
              variant="primary"
              text={translate('Back')}
              size="small"
            />
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="primary"
                  text={translate('Print')}
                  size="small"
                />
              )}
              content={() => componentRef.current}
            />
            {showEditButton && (
              <Button
                variant="primary"
                onClick={handleEdit}
                text="Edit"
                size="small"
              />
            )}
          </ATOButtonsContainer>
          <ATOContainer ref={componentRef}>
            <PageContainer>
              <ReportCard
                logoLeft={
                  recordData?.findDataByRecord.clientLogo
                    ? `${BUCKET_URL}/${recordData?.findDataByRecord.clientLogo}`
                    : ''
                }
                logoRight={
                  recordData?.findDataByRecord.responsibleCompanyLogo
                    ? `${BUCKET_URL}/${
                        recordData?.findDataByRecord.responsibleCompanyLogo
                      }`
                    : ''
                }
                title={recordData?.findDataByRecord.title || ''}
                contractNumber={recordData?.findDataByRecord.contract || ''}
                contractualTerm={
                  recordData?.findDataByRecord.contractualPeriod || ''
                }
                elapsedDuration={recordData?.findDataByRecord.elapsedTerm || ''}
                remainingDuration={formatRemainingTerm(remainingDuration)}
                responsiblePerson={validationElaboratorReportCard}
                company={recordData?.findDataByRecord.responsibleCompany || ''}
                rdoNumber={recordData?.findDataByRecord.recordNumber || ''}
                date={
                  new Date(
                    recordData?.findDataByRecord.recordDate || new Date()
                  ).toLocaleDateString() || ''
                }
              />
              <CardAtoInfo cardTitle={t('workingDay')}>
                <WorkScheduleTable
                  workShifts={formatRecordWorkShiftData || []}
                />
              </CardAtoInfo>

              <CardAtoInfo cardTitle={t('weatherCondition')}>
                <TableComponent
                  tableData={
                    formatWeatherConditionData?.map(
                      (item: WeatherConditionsType) => ({
                        end: item.end,
                        start: item.start,
                        condition: item.weather
                      })
                    ) || []
                  }
                  columns={[
                    { key: 'condition', label: `${t('condition')}` },
                    { key: 'start', label: `${t('start')}` },
                    { key: 'end', label: `${t('end')}` }
                  ]}
                />
              </CardAtoInfo>

              <CardAtoInfo cardTitle={t('PeriodOfRedAlert')}>
                <TableComponent
                  tableData={
                    formatAlertPeriodData?.map((item: RedAlertPeriodType) => ({
                      end: item.end,
                      start: item.start
                    })) || []
                  }
                  columns={[
                    { key: 'start', label: `${t('start')}` },
                    { key: 'end', label: `${t('end')}` }
                  ]}
                />
              </CardAtoInfo>

              <CardAtoInfo cardTitle={t('labor')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.labor.map(
                      (item: LaborDataType) => ({
                        description: item.description,
                        quantity: item.quantity
                      })
                    ) || []
                  }
                  columns={[
                    { key: 'quantity', label: `${t('quantity')}` },
                    { key: 'description', label: `${t('description')}` }
                  ]}
                />
              </CardAtoInfo>
              <CardAtoInfo cardTitle={t('equipments')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.equipments.map(
                      (item: EquipmentDataType) => ({
                        description: item.description,
                        quantity: item.quantity
                      })
                    ) || []
                  }
                  columns={[
                    { key: 'quantity', label: `${t('quantity')}` },
                    { key: 'description', label: `${t('description')}` }
                  ]}
                />
              </CardAtoInfo>
              <CardAtoInfo cardTitle={t('ActivitiesPerformedOnSite')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.activities.map((item) => ({
                      Activity: item.description
                    })) || []
                  }
                  columns={[{ key: 'Activity', label: `${t('Activity')}` }]}
                />
              </CardAtoInfo>
              <CardAtoInfo cardTitle={t('fiscalizationObservations')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.inspectionObservations.map(
                      (item: InspectionObservationsType) => ({
                        observation: item.observation,
                        id: item.id
                      })
                    ) || []
                  }
                  columns={[
                    { key: 'observation', label: `${t('observation')}` }
                  ]}
                />
              </CardAtoInfo>
              <CardAtoInfo cardTitle={t('workSafetyObservations')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.workSafetyObservations.map(
                      (item: WorkSafetyObservationsType) => ({
                        observation: item.observation
                      })
                    ) || []
                  }
                  columns={[
                    { key: 'observation', label: `${t('observation')}` }
                  ]}
                />
              </CardAtoInfo>
              <CardAtoInfo cardTitle={t('Photos')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.recordImages.map(
                      (item: RecordImagesType) => ({
                        image: item.image
                      })
                    ) || []
                  }
                  columns={[{ key: 'image', label: `${t('image')}` }]}
                />
              </CardAtoInfo>
              <CardAtoInfo cardTitle={t('video')}>
                <TableComponent
                  tableData={
                    recordData?.findDataByRecord.recordVideos.map(
                      (item: RecordVideosType) => ({
                        video: item.video
                      })
                    ) || []
                  }
                  columns={[{ key: 'video', label: `${t('video')}` }]}
                />
              </CardAtoInfo>
              <SignatureContainer title={t('signatureOfTheResponsible')}>
                <AtoSignatureCard
                  isEdit={false}
                  situation={recordData?.findDataByRecord.situation}
                  recordSignatures={
                    recordData?.findDataByRecord.recordSignatures
                  }
                  approver={recordData?.findDataByRecord.userTypes.find(
                    (user) => user.userType === 'ClientApprover'
                  )}
                  elaborator={recordData?.findDataByRecord.userTypes.find(
                    (user) => user.userType === 'Elaborator'
                  )}
                  verify={recordData?.findDataByRecord.userTypes.find(
                    (user) => user.userType === 'Verifier'
                  )}
                />
              </SignatureContainer>
            </PageContainer>
          </ATOContainer>
          <FileViewer
            showModal={!!selectFileToShow}
            files={[selectFileToShow!]}
            onClose={() => setSelectFileToShow(null)}
            selectedFile={selectFileToShow}
          />
        </>
      )}
    </>
  );
}
