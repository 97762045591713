import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../../../data/graphql/base-schema';
import {
  DeleteInstrumentReadingDocument,
  DeleteInstrumentReadingMutation,
  DeleteInstrumentReadingMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentReading';
import {
  ListReadingByWaterLevelPagDocument,
  ListReadingByWaterLevelPagQuery,
  ListReadingByWaterLevelPagQueryVariables
} from '../../../../data/graphql/query/generated/listReadingByWaterLevelPag.query';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import FilterReadingsPage from '../../Organisms/FilterReadingsPage/FilterReadingsPage';
import ReadingWaterLevel from '../../Organisms/ReadingWaterLevelForm/ReadingWaterLevel';
import { ReadingWaterLevelFormType } from '../../Organisms/ReadingWaterLevelForm/ReadingWaterLevel.interfaces';
import RegisterWaterLevelBathReadings from '../../Organisms/RegisterWaterLevelBathReadings/RegisterWaterLevelBathReadings';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { DivContainerTable } from './ReadingsPageWaterLevel.styles';
import { ProjectContext } from '../../../../Context/ContextAPI';
import Enums from '../../../../utils/enumns';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import Paginator from '../../Molecules/Paginator/Paginator';
import { TABLE_IDS } from '../../../../utils/TableConsts';

const ReadingsPageWaterLevel = () => {
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');

  const [modalReadingIsOpen, setModalReadingIsOpen] = useState(false);
  const [deleting, setDeleting] = useState({
    status: false,
    readingId: '',
    instrumentId: ''
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editWaterLevel, setEditWaterLevel] =
    useState<ReadingWaterLevelFormType | null>(null);

  const actionsTable: AvailableContextActionsProps<
    ListReadingByWaterLevelPagQuery['listReadingByWaterLevelPag']['nodes'][0]
  >[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (reading) => handleEditWaterLevel(reading),
      canShow: 'canEdit'
    },
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (item) => {
        setDeleting({
          status: true,
          readingId: item.readingId,
          instrumentId: item.instrumentId
        });
      },
      canShow: 'canDelete'
    }
  ];

  const {
    data: dataReadingsByWaterLevel,
    loading: loadingReadingsByWaterLevel
  } = useQuery<
    ListReadingByWaterLevelPagQuery,
    ListReadingByWaterLevelPagQueryVariables
  >(ListReadingByWaterLevelPagDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!,
        associatedStructureId: null
      },
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters: {
        instrumentsId:
          (searchParams.get('instrumentId') as InputMaybe<string[]>) ||
          undefined,
        readingType:
          (searchParams.get('type') as InstrumentReadingTypeEnum) || undefined,
        status:
          (searchParams.get('status') as InstrumentStatusEnum) || undefined,
        name: searchParams.get('name') || undefined,
        endDate: searchParams.get('endDate')
          ? new Date(searchParams.get('endDate') as string)
          : undefined,
        startDate: searchParams.get('startDate')
          ? new Date(searchParams.get('startDate') as string)
          : undefined
      }
    }
  });

  const [deleteInstrument] = useMutation<
    DeleteInstrumentReadingMutation,
    DeleteInstrumentReadingMutationVariables
  >(DeleteInstrumentReadingDocument);
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  const totalPages =
    dataReadingsByWaterLevel?.listReadingByWaterLevelPag.pageInfo.totalPages ||
    1;

  const tableData =
    dataReadingsByWaterLevel?.listReadingByWaterLevelPag.nodes?.map((item) => ({
      ...item,
      date: item.date
        ? new Date(item.date).toLocaleString().replace(',', ' -')
        : '-',
      readingType: translate(item.readingType) ?? '-',
      operationalStatus: translate(item.operationalStatus.toLowerCase()) ?? '-'
    }));

  const columnsWaterLevel = [
    { label: translate('name'), key: 'name' },
    { label: translate('dateAndTime'), key: 'date' },
    { label: translate('structureType'), key: 'structureType' },
    { label: translate('typeOfReading'), key: 'readingType' },
    { label: translate('Observation'), key: 'observation' },
    { label: translate('value'), key: 'valor' },
    { label: translate('operationalStatus'), key: 'operationalStatus' },
    { label: translate('coordinateE'), key: 'coordinateE' },
    { label: translate('coordinateN'), key: 'coordinateN' }
  ];

  const handleEditWaterLevel = (
    readingRole: ListReadingByWaterLevelPagQuery['listReadingByWaterLevelPag']['nodes'][0]
  ) => {
    const reading =
      dataReadingsByWaterLevel?.listReadingByWaterLevelPag.nodes?.find(
        (item) => item.readingId === readingRole.readingId
      );
    if (!reading) return;
    const newReading = {
      id: readingRole.readingId,
      value: readingRole.value,
      date: new Date(reading.date ?? Date.now()),
      observation: readingRole.observation as string,
      instrument: {
        id: readingRole.instrumentId,
        name: readingRole.name
      }
    };
    setEditWaterLevel(newReading);
    setShowModal(true);
  };

  const handleDeleteReading = () => {
    deleteInstrument({
      variables: {
        structureId: structureId!,
        data: {
          readingId: deleting.readingId,
          instrumentId: deleting.instrumentId
        }
      },
      onCompleted: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfyError(translate(ToastifyModel().toastifyMessage.error));
      },
      refetchQueries: [ListReadingByWaterLevelPagDocument]
    });
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loadingReadingsByWaterLevel}
        title={<Text type="h1">{translate('WaterLevel')} </Text>}
        button={
          userRole !== UserRoles.Viewer && (
            <>
              <Button
                onClick={() => {
                  setEditWaterLevel(null);
                  setShowModal(true);
                }}
                variant={'primary'}
                text={translate('RegisterReadings')}
              />
              <Button
                variant={'primary'}
                size="large"
                text={translate('RegisterReadingsFile')}
                onClick={() => setModalReadingIsOpen(true)}
              />
            </>
          )
        }
        content={
          <DivContainerTable>
            <TableDraggableComponent
              tableId={TABLE_IDS.READINGS_WATERLEVEL_TABLE}
              columnsArray={columnsWaterLevel}
              tableData={tableData || []}
              actions={userRole !== UserRoles.Viewer ? actionsTable : undefined}
              filterComponent={<FilterReadingsPage />}
              paginator={
                <Paginator
                  options={optionsPaginatorDefault}
                  totalPages={totalPages}
                  showInUrl
                />
              }
              sortUrl
              searchUrl
              columnSelect
              showCountColumn
            />
          </DivContainerTable>
        }
      />
      <ViewModal
        showModal={showModal}
        title={
          editWaterLevel
            ? `${translate('EditReadings')} ${translate('WaterLevel')}`
            : `${translate('NewReading')} ${translate('WaterLevel')}`
        }
        onClose={() => {
          setEditWaterLevel(null);
          setShowModal(false);
        }}
        component={
          <ReadingWaterLevel
            reading={editWaterLevel}
            setShowModal={setShowModal}
          />
        }
        width="500px"
        height="500px"
      />
      <ModalConfirm
        showModal={deleting.status}
        onClose={() =>
          setDeleting({
            status: false,
            readingId: '',
            instrumentId: ''
          })
        }
        onConfirm={() => handleDeleteReading()}
        title={`${translate('Delete')} ${translate('reading')}`}
        text={translate('ConfirmDelete')}
      />

      <ViewModal
        showModal={modalReadingIsOpen}
        onClose={() => setModalReadingIsOpen(false)}
        title={translate('WaterLevel')}
        width="500px"
        height="550px"
        component={
          <RegisterWaterLevelBathReadings onClose={setModalReadingIsOpen} />
        }
      />
    </>
  );
};

export default ReadingsPageWaterLevel;
