import { useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ActionPlanFilterInput,
  ActionPlanPriorityEnum,
  ActionPlanSituationEnum,
  ActionPlanTypeEnum,
  SortDirection
} from '../../../../data/graphql/base-schema';
import {
  FindActionPlanAllInfoDocument,
  FindActionPlanAllInfoQuery,
  FindActionPlanAllInfoVariables
} from '../../../../data/services/ActionPlanService';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import ActionPLanFilterComponent from '../../Organisms/ActionPlanFilters/ActionPlanFilters';
import CreateActionPlan from '../../Organisms/CreateActionPlan/CreateActionPlan';
import CardTableTemplate from '../../Templates/CardTableTemplate/CardTableTemplate';
import { ProjectContext } from '../../../../Context/ContextAPI';
import Enums from '../../../../utils/enumns';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
// import Plot from 'react-plotly.js';
// import {
//   DivContainerGraph,
//   DivContent,
//   DivGraph,
//   DivTable
// } from './ActionPlan.styled';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import Paginator from '../../Molecules/Paginator/Paginator';
import { ExportActionPlan } from '../../Organisms/ActionPlan/ExportTableActionPlan/ExportTableActionPlan';

const ActionPlan = () => {
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();
  const navigate = useNavigate();
  const { structureId } = useParams();
  const { UserRoles } = Enums();
  const [searchParams] = useSearchParams();
  const generalSearchString = searchParams.get('generalSearchString') || '';
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('ActionPlan');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const filters: ActionPlanFilterInput = {
    code: searchParams.get('code') || null,
    priority: (searchParams.get('priority') as ActionPlanPriorityEnum) || null,
    responsibleName: searchParams.get('responsibleName') || null,
    situation:
      (searchParams.get('situation') as ActionPlanSituationEnum) || null,
    timeFramePeriod: searchParams.get('timeFramePeriod')
      ? [new Date(searchParams.get('timeFramePeriod') as string)]
      : null,
    type: (searchParams.get('type') as ActionPlanTypeEnum) || null
  };

  const { data: ActionPlanData, loading } = useQuery<
    FindActionPlanAllInfoQuery,
    FindActionPlanAllInfoVariables
  >(FindActionPlanAllInfoDocument, {
    variables: {
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10'),
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      structureId: structureId ?? '',
      filters: filters,
      sortInfo:
        searchParams.get('sortDirection') && searchParams.get('sortKey')
          ? [
              {
                direction:
                  searchParams.get('sortDirection') === 'ascending'
                    ? SortDirection.ASC
                    : SortDirection.DESC,
                field: searchParams.get('sortKey') || ''
              }
            ]
          : []
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });
  const totalPages = ActionPlanData?.findActionPlanPag.pageInfo.totalPages || 0;

  const handleNavigate = (actionPlanId: string) => {
    navigate(`/${structureId}/actionplan/${actionPlanId}`);
  };

  const tableActionPlanColumns: Array<{
    key: string;
    label: string;
    sortType?: 'number' | 'text';
  }> = [
    { key: 'name', label: translate('name'), sortType: 'text' },
    { key: 'responsible', label: translate('responsible'), sortType: 'text' },
    { key: 'status', label: translate('status'), sortType: 'text' },
    { key: 'timeFrame', label: translate('timeFrame'), sortType: 'text' },
    { key: 'priority', label: translate('priorities'), sortType: 'text' },
    { key: 'createdAt', label: translate('createdAt') }
  ];

  const tableActionPlanData = ActionPlanData?.findActionPlanPag.nodes.map(
    (actionPlan) => ({
      ...actionPlan,
      name: translate(actionPlan.name),
      responsible: translate(actionPlan.responsible),
      status: translate(actionPlan.status?.toLocaleLowerCase()),
      timeFrame: actionPlan.timeFrame
        ? new Date(actionPlan.timeFrame).toLocaleDateString()
        : '-',
      priority: translate(actionPlan.priority),
      createdAt: new Date(actionPlan.createdAt).toLocaleDateString()
    })
  );

  const filteredAtoData = !generalSearchString
    ? tableActionPlanData
    : tableActionPlanData?.filter((row) =>
        Object.values(row).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(generalSearchString.toLowerCase())
        )
      );

  // const months = [
  //   '01/01/2025',
  //   '02/01/2025',
  //   '03/01/2025',
  //   '04/01/2025',
  //   '05/01/2025',
  //   '06/01/2025',
  //   '07/01/2025',
  //   '08/01/2025',
  //   '09/01/2025',
  //   '10/01/2025',
  //   '11/01/2025',
  //   '12/01/2025'
  // ];
  // const quantities = [45, 30, 50, 70, 20, 65, 90, 80, 55, 40, 75, 60];

  const {
    totalPlans,
    totalPlansDone,
    totalPlansHighPriority,
    totalPlansInProgress,
    totalPlansLate,
    totalPlansOpened
  } = ActionPlanData?.findCalculatedInfos || {
    totalPlans: '',
    totalPlansDone: '',
    totalPlansHighPriority: '',
    totalPlansInProgress: '',
    totalPlansLate: '',
    totalPlansOpened: ''
  };

  return (
    <>
      <CardTableTemplate
        loading={loading}
        title={<Text type="h1">{translate('Action plan')}</Text>}
        button={
          userRoleInModule !== UserRoles.Viewer ? (
            <Button
              onClick={() => setIsModalOpen(true)}
              text={`${translate('create')} ${translate('Action plan')}`}
              size="large"
              variant="primary"
            />
          ) : (
            <> </>
          )
        }
        cards={
          <>
            <SquareCard
              label={translate('total')}
              value={totalPlans.toString()}
            />
            <SquareCard
              label={translate('completed')}
              value={totalPlansDone.toString()}
            />
            <SquareCard
              label={translate('highPriority')}
              value={totalPlansHighPriority.toString()}
            />
            <SquareCard
              label={translate('inProgress')}
              value={totalPlansInProgress.toString()}
            />
            <SquareCard
              label={translate('overdue')}
              value={totalPlansLate.toString()}
            />
            <SquareCard
              label={translate('opened')}
              value={totalPlansOpened.toString()}
            />
          </>
        }
        content={
          // <DivContent>
          //   <DivContainerGraph>
          //     <DivGraph>
          //       <Plot
          //         data={[
          //           {
          //             x: months,
          //             y: quantities,
          //             type: 'bar',
          //             marker: { color: 'blue' }
          //           }
          //         ]}
          //         layout={{
          //           title: `${translate('pluviometer')}`,
          //           yaxis: { title: translate('quantity') },
          //           margin: { l: 40, r: 20, t: 50, b: 40 }
          //         }}
          //         config={{
          //           responsive: true,
          //           scrollZoom: true,
          //           displayModeBar: true,
          //           displaylogo: false,
          //           locale: 'pt-BR'
          //         }}
          //         style={{ width: '100%', height: '100%' }}
          //       />
          //     </DivGraph>
          //     <DivGraph>
          //       <Plot
          //         data={[
          //           {
          //             x: months,
          //             y: quantities,
          //             type: 'bar',
          //             marker: { color: 'blue' }
          //           }
          //         ]}
          //         layout={{
          //           title: `${translate('pluviometer')}`,
          //           yaxis: { title: translate('quantity') },
          //           margin: { l: 40, r: 20, t: 50, b: 40 }
          //         }}
          //         config={{
          //           responsive: true,
          //           scrollZoom: true,
          //           displayModeBar: true,
          //           displaylogo: false,
          //           locale: 'pt-BR'
          //         }}
          //         style={{ width: '100%', height: '100%' }}
          //       />
          //     </DivGraph>
          //   </DivContainerGraph>
          //   <DivTable>
          <TableDraggableComponent
            tableId="actionPlanTable"
            columnsArray={tableActionPlanColumns}
            filterComponent={<ActionPLanFilterComponent />}
            handleClickRow={(actionPlan) => handleNavigate(actionPlan.id)}
            tableData={filteredAtoData ?? []}
            paginator={
              <Paginator
                options={[
                  { value: 10, label: '10' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ]}
                totalPages={totalPages}
                showInUrl
              />
            }
            exportTable={<ExportActionPlan />}
          />
          //   </DivTable>
          // </DivContent>
        }
      />

      <ViewModal
        showModal={isModalOpen}
        title={`${translate('create')} ${translate('Action plan')}`}
        onClose={() => setIsModalOpen(false)}
        width="500px"
        height="700px"
        component={<CreateActionPlan onClose={() => setIsModalOpen(false)} />}
      />
    </>
  );
};

export default ActionPlan;
