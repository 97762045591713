import {
  AdvancedMarker,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Color } from '../../../Styles/Styles';
import { WiRaindrops } from 'react-icons/wi';
import { FaArrowUpFromGroundWater } from 'react-icons/fa6';
import { TbArrowsUpRight } from 'react-icons/tb';
import { RiTestTubeFill } from 'react-icons/ri';
import Icon from '../../V2/Atoms/Icon/Icon';
import Text from '../../V2/Atoms/Text/Text';
import { MarkerData } from '../MapGoogle';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';

interface MarkerComponentProps {
  marker: MarkerData;
}

const MarkerComponent = ({ marker }: MarkerComponentProps) => {
  const [markerRef, markerRef2] = useAdvancedMarkerRef();
  const { t: translate } = useTranslation();

  const [showModalInfo, setShowModalInfo] = useState(false);

  const getMarkerIcon = (type: string, color: string | null) => {
    const style = {
      fontSize: '1.5em',
      backgroundColor: color ? color : Color.White,
      color: color ? Color.White : Color.Brown1,
      borderRadius: '50%',
      padding: 5
    };
    const getIcon: { [key: string]: ReactElement } = {
      Pluviometer: <Icon Icon={WiRaindrops} style={style} />,
      WaterLevel: <Icon Icon={FaArrowUpFromGroundWater} style={style} />,
      SurfaceMarker: <Icon Icon={TbArrowsUpRight} style={style} />,
      Piezometer: <Icon Icon={RiTestTubeFill} style={style} />,
      Ina: <Icon Icon={RiTestTubeFill} style={style} />
    };
    return getIcon[type];
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        key={marker.id}
        position={{ lat: marker.lat, lng: marker.lng }}
        title={marker.title}
        onClick={
          marker?.infoData
            ? () => {
                setShowModalInfo(!showModalInfo);
              }
            : undefined
        }
      >
        <Pin
          scale={1}
          background={marker.color}
          borderColor={marker.color}
          glyphColor={Color.White}
        >
          {getMarkerIcon(marker.type, marker.color)}
        </Pin>
      </AdvancedMarker>

      {showModalInfo && (
        <InfoWindow
          anchor={markerRef2}
          headerDisabled={true}
          disableAutoPan={true}
          minWidth={280}
        >
          <MapPopup>
            <Text type="span" color="black">
              {marker.title || '-'}
            </Text>
            <Icon
              Icon={IoIosClose}
              variant="medium"
              onClick={() => {
                setShowModalInfo(false);
              }}
            />
          </MapPopup>
          {marker.infoData &&
            marker?.infoData.map((item, index) => (
              <MapPopup key={index}>
                <Text type="label">{translate(item.key)}:</Text>
                <Text type="span" color="black">
                  {item.value || '-'}
                </Text>
              </MapPopup>
            ))}
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerComponent;

export const MapPopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.Brown2};
  max-height: 40px;
  padding: 5px;
`;
