import styled from 'styled-components';

export const DviContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(650px, 1fr));
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  box-sizing: border-box;

  @media (min-width: 2090px) {
    grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  }
`;

export const DivInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

export const DivContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const DivTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const MapArea = styled.div`
  display: flex;
  gap: 20px;
  min-height: 370px;
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const DivGraphBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 300px;
`;
