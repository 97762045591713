import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FileType } from '../../../Molecules/FileViewer/FileViewer.interfaces';
import InputFileWithList from '../../InputFileWithList/InputFileWithList';
import Button from '../../../Atoms/Button/Button';
import {
  DivContainer,
  DivCoordinates,
  DivCoordinatesList,
  DivInput
} from './SlidesForm.styles';
import InputText from '../../../Molecules/InputText/InputText';
import InputSelectSearch from '../../../Molecules/InputSelectSearch/InputSelectSearch';
import { useQuery } from '@apollo/client';
import {
  ListSectionsByStructureDocument,
  ListSectionsByStructureQuery,
  ListSectionsByStructureQueryVariables
} from '../../../../../data/graphql/query/generated/listSectionsByStructure.query';
import { useParams } from 'react-router-dom';
import Text from '../../../Atoms/Text/Text';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_URL } from '../../../../../utils/const';
import { ProjectContext } from '../../../../../Context/ContextAPI';
import { UserData } from '../../../../../@Types/types';
import { toastfyError, toastfySuccess } from '../../../../Toastify';

const coodenatesSchema = z.object({
  xCoordinate: z.string().min(1, { message: 'fieldIsRequired' }),
  yCoordinate: z.string().min(1, { message: 'fieldIsRequired' })
});

type coodenatesSchemaType = z.infer<typeof coodenatesSchema>;

const slidesSchema = z.object({
  file: z
    .array(z.instanceof(File))
    .optional()
    .refine((val) => val && val.length > 0, {
      message: 'fieldIsRequired'
    })
    .refine((val) => val && val[0].name.split('.').pop() === 'slmd', {
      message: 'InvalidFileType'
    }),
  section: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
    .refine((val) => val && val.value, {
      message: 'fieldIsRequired'
    }),
  coodenates: z
    .array(coodenatesSchema)
    .optional()
    .nullable()
    .refine(
      (val) => {
        return val && val.length > 0;
      },
      {
        message: 'AtLeastOneCoordinateIsRequired'
      }
    )
});

type slidesSchemaType = z.infer<typeof slidesSchema>;

export const SlidesForm = () => {
  const [files, setFiles] = useState<FileType[]>([]);
  const { structureId } = useParams();
  const { t: translate } = useTranslation();
  const { userData } = useContext(ProjectContext) as { userData: UserData };

  const { data: listSections } = useQuery<
    ListSectionsByStructureQuery,
    ListSectionsByStructureQueryVariables
  >(ListSectionsByStructureDocument, {
    variables: {
      structureId: structureId!
    }
  });

  const sectiosOptions = listSections?.listSectionsByStructure?.map(
    (section) => ({
      label: section.name,
      value: section.id
    })
  );

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset: resetSlideForm,
    formState: { errors }
  } = useForm<slidesSchemaType>({
    resolver: zodResolver(slidesSchema)
  });

  const handleSave = async (data: slidesSchemaType) => {
    const formData = new FormData();
    const operations = {
      query: `
        mutation SaveSlideFile($data: SaveSlideFileInput!) {
          saveSlideFile(data: $data)
        }
      `,
      variables: {
        data: {
          sectionId: data.section!.value,
          waterTableEnd: data.coodenates
            ? data.coodenates.map((coodenates) => ({
                xCoordinate: parseFloat(coodenates.xCoordinate),
                yCoordinate: parseFloat(coodenates.yCoordinate)
              }))
            : [],
          file: null
        }
      }
    };
    formData.append('operations', JSON.stringify(operations));
    const map = {
      0: ['variables.data.file']
    };
    formData.append('map', JSON.stringify(map));
    const newFile = new File([data.file![0]], data.file![0].name, {
      type: 'application/slmd'
    });

    formData.append('0', newFile);

    try {
      const response = await axios.post(`${API_URL}/graphql`, formData, {
        headers: {
          Authorization: userData.token,
          'Access-Control-Allow-Origin': '*',
          getContentType: 'multipart/form-data'
        }
      });
      if (response.data.data) {
        toastfySuccess('registeredSuccessfully');
        resetSlideForm();
        setFiles([]);
      }

      if (response.data.errors) {
        toastfyError(response.data.errors[0].message);
      }
    } catch (error) {
      toastfyError('error');
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    if (selectedFiles![0].name.split('.').pop() !== 'slmd') {
      toastfyError('InvalidFileType');
      return;
    }

    const newSelectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    setValue(
      'file',
      newSelectedFiles.map((file) => file.file!)
    );
    setFiles(newSelectedFiles);
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'file',
      updatedFiles.map((file) => file.file!)
    );
  };

  const {
    handleSubmit: handleSubmitCoodenates,
    control: controlCoodenates,
    formState: { errors: errorsCoodenates },
    reset
  } = useForm<coodenatesSchemaType>({
    resolver: zodResolver(coodenatesSchema)
  });

  const handleSaveCoodenates = (data: coodenatesSchemaType) => {
    setValue('coodenates', [
      ...(watch('coodenates') || []),
      {
        xCoordinate: data.xCoordinate,
        yCoordinate: data.yCoordinate
      }
    ]);
    reset();
  };

  return (
    <DivContainer>
      <DivCoordinates
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmitCoodenates(handleSaveCoodenates)();
          }
        }}
      >
        <DivInput>
          <InputText
            width="335px"
            control={controlCoodenates}
            label="X Coordinate"
            name="xCoordinate"
            type="number"
            error={!!errorsCoodenates.xCoordinate}
            errorMessage={errorsCoodenates?.xCoordinate?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="335px"
            control={controlCoodenates}
            label="Y Coordinate"
            name="yCoordinate"
            type="number"
            error={!!errorsCoodenates.yCoordinate}
            errorMessage={errorsCoodenates?.yCoordinate?.message}
          />
        </DivInput>
        {!!errors.coodenates?.message && (
          <Text error={!!errors.coodenates?.message} type="span">
            {translate(errors.coodenates?.message)}
          </Text>
        )}
        <Button
          variant="primary"
          text="Add"
          onClick={handleSubmitCoodenates(handleSaveCoodenates)}
        />
        <DivCoordinatesList>
          {watch('coodenates')?.map((coodenates, index) => (
            <Text key={index} type="span">
              {`X: ${coodenates.xCoordinate} - Y: ${coodenates.yCoordinate}`}
            </Text>
          ))}
        </DivCoordinatesList>
      </DivCoordinates>

      <InputSelectSearch
        label="Section"
        name="section"
        placeholder="select"
        options={sectiosOptions || []}
        value={watch('section')!}
        control={control}
        error={!!errors.section}
        errorMessage={errors?.section?.message}
        required
      />

      <InputFileWithList
        accept="*"
        files={files}
        multiple={false}
        canDelete
        onChange={(event) => {
          if (event.target.files) {
            handleFileChange(Array.from(event.target.files));
          }
        }}
        onDelete={(file: FileType) => handleDeleteImage(file)}
        label="File"
        name="file"
        error={!!errors.file}
        errorMessage={errors?.file?.message}
      />
      <Button
        variant="primary"
        text="Save"
        onClick={handleSubmit(handleSave)}
      />
    </DivContainer>
  );
};

export default SlidesForm;
