import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { AtoObservationTypeEnum } from '../../../../data/graphql/base-schema';
import {
  DeleteRecordObservationDocument,
  DeleteRecordObservationMutation,
  DeleteRecordObservationMutationVariables
} from '../../../../data/graphql/generated/deleteRecordObservation';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AtoObservationForm from '../AtoObservationForm/AtoObservationForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoCompanyObservationProps,
  ResponsibleCompanyObservationItemType,
  ResponsibleCompanyObservationType,
  RowToEditType
} from './CardAtoInfoCompanyObservation.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

export const CardAtoInfoCompanyObservation = ({
  responsibleCompanyObservation,
  canAdd
}: CardAtoInfoCompanyObservationProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordObservation] = useMutation<
    DeleteRecordObservationMutation,
    DeleteRecordObservationMutationVariables
  >(DeleteRecordObservationDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [idForDelete, setIdForDelete] = useState<string>('');

  const tableColumns = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'observation',
      label: t('observation')
    }
  ];

  const tableData = responsibleCompanyObservation.map(
    (item: ResponsibleCompanyObservationType, index) => ({
      observation: item.observation,
      id: item.id,
      edit: true,
      delete: true,
      indexId: index + 1
    })
  );

  const actions = [
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => handleEditRow(row),
      canShow: 'edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordObservation({
      variables: {
        data: {
          type: 'ResponsibleCompanyObservation' as AtoObservationTypeEnum,
          id: id
        }
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            responsibleCompanyObservations:
              existingData.findDataByRecord.responsibleCompanyObservations.filter(
                (item: ResponsibleCompanyObservationItemType) => item.id !== id
              )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      <CardAtoInfo
        canAdd={canAdd}
        title={t('CompanyObservation')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      <ViewModal
        showModal={showModal}
        width={40}
        height={50}
        title={t('addResponsibleCompanyObservation')}
        onClose={() => setShowModal(false)}
        component={
          <AtoObservationForm
            setShowModal={setShowModal}
            recordId={recordId?.toString() || ''}
            type={'ResponsibleCompanyObservation' as AtoObservationTypeEnum}
          />
        }
      />
      <ViewModal
        showModal={showEditModal}
        width={40}
        height={50}
        title={t('editResponsibleCompanyObservation')}
        onClose={() => setShowEditModal(false)}
        component={
          <AtoObservationForm
            setShowModal={setShowEditModal}
            recordId={recordId?.toString() || ''}
            type={'ResponsibleCompanyObservation' as AtoObservationTypeEnum}
            editObservation={{
              id: rowToEdit?.id || '',
              observation: rowToEdit?.observation || '',
              type: 'ResponsibleCompanyObservation'
            }}
          />
        }
      />
      <ModalConfirm
        showModal={!!idForDelete}
        title={`${t('Delete')} ${t('Activity')}`}
        text={t('ConfirmDelete')}
        subtitle={t('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};
