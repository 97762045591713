import { PropsWithChildren } from 'react';
import { TableRowProps } from './TableRow.interface';
import { TableRowContent } from './TableRow.styles';

const TableRow = ({ children, onClick }: PropsWithChildren<TableRowProps>) => {
  const handleMouseUp = () => {
    const selectedText = window.getSelection()?.toString().trim();
    if (!selectedText) {
      if (onClick) onClick();
    }
  };

  return (
    <TableRowContent onMouseUp={handleMouseUp} $hover={!!onClick}>
      {children}
    </TableRowContent>
  );
};

export default TableRow;
