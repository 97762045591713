import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { InputFileProps } from './InputFile.interfaces';
import { ErrorStyled, InputFileStyled } from './InputFile.styles';

const InputFile = ({
  onChange,
  name,
  error,
  errorMessage,
  accept,
  label,
  register,
  ...props
}: InputFileProps) => {
  const { t: translate } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <InputFileStyled $error={error}>
      <Text error={error} type="label">
        {translate(label)}
      </Text>
      <Button
        text={`${translate('Add')} ${translate('File')}`}
        variant="primary"
        size="medium"
        onClick={handleClick}
        error={error}
      />
      <input
        style={{ display: 'none' }}
        type="file"
        name={name}
        id={name}
        accept={accept}
        onChange={onChange}
        ref={inputRef}
        {...(register && { register: (name: string) => register(name) })}
        {...props}
      />
      {error ? (
        <ErrorStyled>{translate(errorMessage)}</ErrorStyled>
      ) : (
        <ErrorStyled />
      )}
    </InputFileStyled>
  );
};

export default InputFile;
