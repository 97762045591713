import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordWorkShiftDocument,
  DeleteRecordWorkShiftMutation,
  DeleteRecordWorkShiftMutationVariables
} from '../../../../data/graphql/generated/deleteRecordWorkShift';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import WorkScheduleTable from '../../Molecules/WorkScheduleTable/WorkScheduleTable';
import AddWorkScheduleAtoForm from '../AddWorkScheduleAtoForm/AddWorkScheduleAtoForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoWorkScheduleProps,
  WorkShiftsItem
} from './CardAtoInfoWorkSchedule.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

export const CardAtoInfoWorkSchedule = ({
  canAdd,
  workShifts,
  recordDate
}: CardAtoInfoWorkScheduleProps) => {
  const { t: translate } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const [showModal, setShowModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);

  const [deleteRecordWorkShift] = useMutation<
    DeleteRecordWorkShiftMutation,
    DeleteRecordWorkShiftMutationVariables
  >(DeleteRecordWorkShiftDocument);

  const handleDeleteRow = () => {
    if (workShifts?.id) {
      deleteRecordWorkShift({
        variables: {
          deleteRecordWorkShiftId: workShifts.id
        },
        onCompleted: () => {
          toastfySuccess(translate('deletedSuccessfully'));
          setUpdateDelete(!updateDelete);
        },
        onError: (error) => {
          parseErrorMessage(error);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;
          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              workShifts: existingData.findDataByRecord.workShifts.filter(
                (item: WorkShiftsItem) => item.id !== workShifts.id
              )
            }
          };
          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      return;
    }
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  const formatTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <>
      <ViewModal
        showModal={showModal}
        width={40}
        height={50}
        title={translate('editworkSchedule')}
        onClose={() => setShowModal(false)}
        component={
          <AddWorkScheduleAtoForm
            setShowModal={setShowModal}
            recordId={recordId?.toString() || ''}
            workShifts={
              workShifts
                ? {
                    ...workShifts,
                    entry: workShifts.entry.toISOString(),
                    exit: workShifts.exit.toISOString(),
                    entryInterval: workShifts.entryInterval.toISOString(),
                    exitInterval: workShifts.exitInterval.toISOString()
                  }
                : undefined
            }
            rdoDate={recordDate || new Date()}
          />
        }
      />
      <CardAtoInfo
        title={translate('workingDay')}
        buttonLabel={workShifts?.id ? translate('edit') : translate('Add+')}
        onButtonClick={handleAddForm}
        canAdd={canAdd}
      >
        <WorkScheduleTable
          workShift={
            workShifts
              ? {
                  ...workShifts,
                  entry: formatTime(workShifts.entry),
                  exit: formatTime(workShifts.exit),
                  entryInterval: formatTime(workShifts.entryInterval),
                  exitInterval: formatTime(workShifts.exitInterval)
                }
              : undefined
          }
          onClickDelete={workShifts?.id ? () => handleDeleteRow() : undefined}
        />
      </CardAtoInfo>
    </>
  );
};
