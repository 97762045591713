import { useTranslation } from 'react-i18next';

const useColorsStatus = () => {
  const { t: translate } = useTranslation();

  const getColorStatus = (status: string) => {
    const statusColors: Record<string, string> = {
      [translate('opened')]: '#999999',
      [translate('late')]: '#fb8c00',
      [translate('done')]: '#4caf50',
      [translate('canceled')]: '#e53935',
      [translate('review')]: '#8cacff',
      [translate('progress')]: '#51aeb8',
      [translate('reproved')]: '#ff5e40',
      [translate('Attention')]: '#FB8C00CC',
      [translate('Alert')]: '#FF5E40',
      [translate('Emergency')]: '#E53935',
      [translate('pending')]: '#fb8c00',
      [translate('inProgress')]: '#51aeb8',
      [translate('completed')]: '#4caf50',
      [translate('failed')]: '#e53935',
      [translate('ATENÇÃO')]: '#FB8C00CC',
      [translate('ALERTA')]: '#FF5E40',
      [translate('EMERGÊNCIA')]: '#E53935',
      [translate('normal')]: '#4caf50'
    };

    return statusColors[status] ?? '#000000';
  };

  return { getColorStatus };
};

export default useColorsStatus;
