import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordActivitiesDocument,
  DeleteRecordActivitiesMutation,
  DeleteRecordActivitiesMutationVariables
} from '../../../../data/graphql/generated/deleteRecordActivities';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AddActivityForm from '../AddActivityForm/AddActivityForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  ActivitiesItem,
  ActivityType,
  CardAtoInfoActivitiesProps,
  EditProps,
  RowToEditTypes
} from './CardAtoInfoActivities.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

export const CardAtoInfoActivities = ({
  activity,
  canAdd
}: CardAtoInfoActivitiesProps) => {
  const { t: translate } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const [idForDelete, setIdForDelete] = useState('');
  const [edit, setEdit] = useState<EditProps>({
    show: false,
    activity: null
  });

  const [deleteRecordActivities] = useMutation<
    DeleteRecordActivitiesMutation,
    DeleteRecordActivitiesMutationVariables
  >(DeleteRecordActivitiesDocument, {});

  const columnsData = [
    { key: 'indexId', label: '' },
    { key: 'Activity', label: translate('Activity') }
  ];

  const tableData = activity.map((item: ActivityType, index) => ({
    Activity: item.description,
    images: item.images,
    videos: item.videos,
    id: item.id,
    indexId: index + 1
  }));

  const actions = [
    {
      name: translate('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditTypes) => handleEditRow(row),
      canShow: 'Edit'
    },
    {
      name: translate('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditTypes) => {
        setIdForDelete(row.id);
      },
      canShow: 'Delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    return deleteRecordActivities({
      variables: {
        deleteRecordActivitiesId: id
      },
      onCompleted: () => {
        toastfySuccess('deletedSuccessfully');
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            activities: existingData.findDataByRecord.activities.filter(
              (item: ActivitiesItem) => item.id !== id
            )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditTypes) => {
    setEdit({ show: true, activity: row });
  };

  const handleCloseEdit = () => {
    setEdit({ show: false, activity: null });
  };

  const handleAddForm = () => {
    setEdit({ show: true, activity: null });
  };

  return (
    <>
      <CardAtoInfo
        canAdd={canAdd}
        title={translate('ActivitiesPerformedOnSite')}
        buttonLabel={translate('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={columnsData}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>

      <ViewModal
        showModal={edit.show}
        width={40}
        height={50}
        title={
          edit.activity?.id
            ? translate('editActivityPerformedOnWork')
            : translate('addActivityPerformedOnWork')
        }
        onClose={handleCloseEdit}
        component={
          <AddActivityForm
            setEdit={setEdit}
            recordId={recordId?.toString() || ''}
            editActivity={{
              id: edit.activity?.id || '',
              description: edit.activity?.Activity || '',
              images: edit.activity?.images || [],
              videos: edit.activity?.videos || []
            }}
          />
        }
      />

      <ModalConfirm
        showModal={!!idForDelete}
        title={`${translate('Delete')} ${translate('Activity')}`}
        text={translate('ConfirmDelete')}
        subtitle={translate('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};
