import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

interface TextareaProps {
  $error?: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
}

export const TextareaStyled = styled.textarea<TextareaProps>`
  width: ${({ width }) => (width ? `min(${width}, 100%)` : 'min(300px, 100%)')};
  height: ${({ height }) => height || '100px'};
  resize: none;
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) =>
    props.$error ? Color.Red1 : props.disabled ? Color.Gray3 : Color.Title2};
  padding: 10px;
  position: relative;
  margin-top: 15px;
  border: 1px solid
    ${(props) =>
      props.$error ? Color.Red1 : props.disabled ? Color.Gray3 : Color.Title2};

  &:focus {
    outline: none !important;
  }

  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  }
`;
