import { useQuery } from '@apollo/client';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery,
  FindDataByRecordQueryVariables
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { BUCKET_URL } from '../../../../utils/const';
import SkeletonNewAtoPage from '../../../Skeleton/newAtoPage/SkeletonNewAtoPage';
import Icon from '../../Atoms/Icon/Icon';
import { AtoSignatureCard } from '../../Organisms/AtoSignatureCard/AtoSignatureCard';
import { CardAtoInfoActivities } from '../../Organisms/CardAtoInfoActivities/CardAtoInfoActivities';
import CardAtoInfoAlertPeriod from '../../Organisms/CardAtoInfoAlertPeriod/CardAtoInfoAlertPeriod';
import { CardAtoInfoCompanyObservation } from '../../Organisms/CardAtoInfoCompanyObservation/CardAtoInfoCompanyObservation';
import { CardAtoInfoEquipments } from '../../Organisms/CardAtoInfoEquipments/CardAtoInfoEquipments';
import { CardAtoInfoFiscalization } from '../../Organisms/CardAtoInfoFiscalization/CardAtoInfoFiscalization';
import { CardAtoInfoLabor } from '../../Organisms/CardAtoInfoLabor/CardAtoInfoLabor';
import { CardAtoInfoPhotos } from '../../Organisms/CardAtoInfoPhotos/CardAtoInfoPhotos';
import { CardAtoInfoVideos } from '../../Organisms/CardAtoInfoVideos/CardAtoInfoVideos';
import { CardAtoInfoWeather } from '../../Organisms/CardAtoInfoWeather/CardAtoInfoWeather';
import { CardAtoInfoWorkSafetyObs } from '../../Organisms/CardAtoInfoWorkSafetyObs/CardAtoInfoWorkSafetyObs';
import { CardAtoInfoWorkSchedule } from '../../Organisms/CardAtoInfoWorkSchedule/CardAtoInfoWorkSchedule';
import ReportCard from '../../Organisms/ReportCard/ReportCard';
import SignatureContainer from '../../Organisms/SignatureContainer/SignatureContainer';
import SeeMoreTemplate from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import {
  ActivityType,
  EquipmentDataType,
  InspectionObservationsType,
  LaborDataType,
  RecordImagesType,
  RecordVideosType,
  RedAlertPeriodType,
  ResponsibleCompanyObservationType,
  WeatherConditionsType,
  WorkSafetyObservationsType,
  WorkShiftsType
} from './EditAtoPage.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../Map/types';

const EditAtoPage: React.FC = () => {
  const { t } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();
  const { recordId, structureId, atoId } = useParams();

  const navigate = useNavigate();
  const [canEdit, setCanEdit] = useState(true);
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const { data: rdoFormsData, loading } = useQuery<
    FindDataByRecordQuery,
    FindDataByRecordQueryVariables
  >(FindDataByRecordDocument, {
    variables: {
      recordId: recordId ? recordId : ''
    },
    onCompleted: ({ findDataByRecord }) => {
      const userElaborator = findDataByRecord.userTypes.find(
        (user) => user.userType === 'Elaborator'
      );
      const userVerifier = findDataByRecord.userTypes.find(
        (user) => user.userType === 'Verifier'
      );
      const userApprovedClient = findDataByRecord.userTypes.find(
        (user) => user.userType === 'ClientApprover'
      );
      const RdoReview = findDataByRecord.situation === 'Review';
      const RdoApproved = findDataByRecord.situation === 'Approved';
      const RdoDone = findDataByRecord.situation === 'Done';
      const RdoCanceled = findDataByRecord.situation === 'Canceled';

      if (
        (userData.id === userElaborator?.id && RdoReview) ||
        userData.id !== userElaborator?.id ||
        (userData.id === userVerifier?.id && !RdoReview) ||
        (userData.id === userApprovedClient?.id && !RdoApproved) ||
        RdoDone ||
        RdoCanceled
      ) {
        return;
      }

      if (
        (RdoReview && userData.id === userVerifier?.id) ||
        (RdoApproved && userData.id === userApprovedClient?.id)
      ) {
        setCanEdit(false);
      }
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const workShifts = useMemo(() => {
    return rdoFormsData?.findDataByRecord.workShifts.map(
      (shift: WorkShiftsType) => ({
        entry: new Date(shift.entry),
        exit: new Date(shift.exit),
        entryInterval: new Date(shift.entryInterval),
        exitInterval: new Date(shift.exitInterval),
        id: shift.id,
        observation: shift.observation,
        totalHours: shift.totalHours
      })
    );
  }, [rdoFormsData?.findDataByRecord.workShifts]);

  const weather = useMemo(() => {
    return rdoFormsData?.findDataByRecord.weatherConditions.map(
      (condition: WeatherConditionsType) => ({
        weather: condition.weather,
        start: new Date(condition.start).toLocaleString(),
        end: new Date(condition.end).toLocaleString(),
        id: condition.id
      })
    );
  }, [rdoFormsData?.findDataByRecord.weatherConditions]);

  const redAlertPeriod: RedAlertPeriodType[] = useMemo(() => {
    return (
      rdoFormsData?.findDataByRecord.redAlertPeriods.map((condition) => ({
        start: new Date(condition.start).toLocaleString(),
        end: new Date(condition.end).toLocaleString(),
        id: condition.id
      })) || []
    );
  }, [rdoFormsData?.findDataByRecord.redAlertPeriods]);

  const laborData = useMemo(() => {
    return rdoFormsData?.findDataByRecord.labor.map((data: LaborDataType) => ({
      description: data.description,
      quantity: data.quantity,
      id: data.id
    }));
  }, [rdoFormsData?.findDataByRecord.labor]);

  const equipmentData = useMemo(() => {
    return rdoFormsData?.findDataByRecord.equipments.map(
      (data: EquipmentDataType) => ({
        description: data.description,
        quantity: data.quantity,
        id: data.id
      })
    );
  }, [rdoFormsData?.findDataByRecord.equipments]);

  const activity: ActivityType[] = useMemo(() => {
    return (
      rdoFormsData?.findDataByRecord.activities.map((data) => ({
        id: data.id,
        description: data.description,
        images: data.images.map((image) => ({
          id: image.id,
          image: image.image
        })),
        videos: data.videos.map((video) => ({
          id: video.id,
          video: video.video
        }))
      })) || []
    );
  }, [rdoFormsData?.findDataByRecord.activities]);

  const responsibleCompanyObservation = useMemo(() => {
    return rdoFormsData?.findDataByRecord.responsibleCompanyObservations.map(
      (data: ResponsibleCompanyObservationType) => ({
        id: data.id,
        observation: data.observation
      })
    );
  }, [rdoFormsData?.findDataByRecord.responsibleCompanyObservations]);

  const inspectionObservations = useMemo(() => {
    return rdoFormsData?.findDataByRecord.inspectionObservations.map(
      (data: InspectionObservationsType) => ({
        id: data.id,
        observation: data.observation
      })
    );
  }, [rdoFormsData?.findDataByRecord.inspectionObservations]);

  const workSafetyObservations = useMemo(() => {
    return rdoFormsData?.findDataByRecord.workSafetyObservations.map(
      (data: WorkSafetyObservationsType) => ({
        id: data.id,
        observation: data.observation
      })
    );
  }, [rdoFormsData?.findDataByRecord.workSafetyObservations]);

  const recordImages = useMemo(() => {
    return (
      rdoFormsData?.findDataByRecord.recordImages.map(
        (data: RecordImagesType) => ({
          id: data.id,
          image: data.image,
          observation: data.observation ?? ''
        })
      ) ?? []
    );
  }, [rdoFormsData?.findDataByRecord.recordImages]);

  const recordVideos = useMemo(() => {
    return rdoFormsData?.findDataByRecord.recordVideos.map(
      (data: RecordVideosType) => ({
        id: data.id,
        video: data.video,
        observation: data.observation ?? ''
      })
    );
  }, [rdoFormsData?.findDataByRecord.recordVideos]);

  const navigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}/view/${recordId}`);
  };

  const validationElaboratorReportCard =
    rdoFormsData?.findDataByRecord.elaborator != null
      ? rdoFormsData?.findDataByRecord.elaborator
      : t('toBeDefined');

  const remainingDuration = Number(
    rdoFormsData?.findDataByRecord.remainingTerm
  );
  const formatRemainingTerm = (remainingTerm: number): string => {
    if (remainingTerm < 0) {
      return `${t('Overdue')} ${Math.abs(remainingTerm)}`;
    }

    return remainingTerm.toString();
  };

  return (
    <>
      {loading ? (
        <SkeletonNewAtoPage />
      ) : (
        <SeeMoreTemplate
          icon={<Icon Icon={IoIosArrowBack} onClick={() => navigateBack()} />}
          title={`RDO - ${new Date(
            rdoFormsData?.findDataByRecord.recordDate || new Date()
          ).toLocaleDateString()}`}
          content={
            <>
              <ReportCard
                logoLeft={
                  rdoFormsData?.findDataByRecord.clientLogo
                    ? `${BUCKET_URL}/${rdoFormsData?.findDataByRecord.clientLogo}`
                    : ''
                }
                logoRight={
                  rdoFormsData?.findDataByRecord.responsibleCompanyLogo
                    ? `${BUCKET_URL}/${
                        rdoFormsData?.findDataByRecord.responsibleCompanyLogo
                      }`
                    : ''
                }
                title={rdoFormsData?.findDataByRecord.title || ''}
                contractNumber={rdoFormsData?.findDataByRecord.contract || ''}
                contractualTerm={
                  rdoFormsData?.findDataByRecord.contractualPeriod || ''
                }
                elapsedDuration={
                  rdoFormsData?.findDataByRecord.elapsedTerm || ''
                }
                remainingDuration={formatRemainingTerm(remainingDuration)}
                responsiblePerson={validationElaboratorReportCard}
                company={
                  rdoFormsData?.findDataByRecord.responsibleCompany || ''
                }
                rdoNumber={rdoFormsData?.findDataByRecord.recordNumber || ''}
                date={
                  new Date(
                    rdoFormsData?.findDataByRecord.recordDate || new Date()
                  ).toLocaleDateString() || ''
                }
              />
              <CardAtoInfoWorkSchedule
                canAdd={canEdit}
                workShifts={workShifts?.[0] || undefined}
                recordDate={rdoFormsData?.findDataByRecord.recordDate}
              />
              <CardAtoInfoWeather canAdd={canEdit} weather={weather || []} />
              <CardAtoInfoAlertPeriod
                canAdd={canEdit}
                redAlertPeriod={redAlertPeriod || []}
              />
              <CardAtoInfoLabor canAdd={canEdit} laborData={laborData || []} />
              <CardAtoInfoEquipments
                canAdd={canEdit}
                equipmentData={equipmentData || []}
              />
              <CardAtoInfoActivities
                canAdd={canEdit}
                activity={activity || []}
              />
              <CardAtoInfoCompanyObservation
                canAdd={canEdit}
                responsibleCompanyObservation={
                  responsibleCompanyObservation || []
                }
              />
              <CardAtoInfoFiscalization
                canAdd={canEdit}
                inspectionObservations={inspectionObservations || []}
              />
              <CardAtoInfoWorkSafetyObs
                canAdd={canEdit}
                workSafetyObservations={workSafetyObservations || []}
              />
              <CardAtoInfoPhotos
                canAdd={canEdit}
                recordImages={recordImages || []}
              />
              <CardAtoInfoVideos
                canAdd={canEdit}
                recordVideos={recordVideos || []}
              />
              <SignatureContainer title={t('signatureOfTheResponsible')}>
                <AtoSignatureCard
                  situation={rdoFormsData?.findDataByRecord.situation}
                  recordSignatures={
                    rdoFormsData?.findDataByRecord.recordSignatures
                  }
                  approver={rdoFormsData?.findDataByRecord.userTypes.find(
                    (user) => user.userType === 'ClientApprover'
                  )}
                  elaborator={rdoFormsData?.findDataByRecord.userTypes.find(
                    (user) => user.userType === 'Elaborator'
                  )}
                  verify={rdoFormsData?.findDataByRecord.userTypes.find(
                    (user) => user.userType === 'Verifier'
                  )}
                />
              </SignatureContainer>
            </>
          }
          loading={loading}
        />
      )}
    </>
  );
};

export default EditAtoPage;
