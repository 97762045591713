import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const TableATO = styled.table`
  margin: 10px 0;
  width: 100%;
  color: ${Color.Text1};
  border-collapse: collapse;
  text-align: center;
  font-size: ${FontSize.Small};
  color: ${Color.Text1};
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const TableHeaderATO = styled.th`
  background-color: ${Color.Brown3};
  color: ${Color.Brown1};
  padding: 0 !important;
  border: 1px solid ${Color.Brown1};
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const TableRowATO = styled.tr`
  th {
    padding: 8px 10px;
    padding: 0 !important;
  }
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const TableCellATOFour = styled.td`
  background-color: ${Color.Brown3};
  color: ${Color.Brown1};
  padding: 0 !important;
  border: 1px solid ${Color.Brown1};
  page-break-inside: avoid;
  break-inside: avoid;
`;

export const TableCellATOOutput = styled.td`
  background-color: ${Color.White};
  padding: 0 !important;
  border: 1px solid ${Color.Brown1};
  page-break-inside: avoid;
  break-inside: avoid;
`;

export const LabelTdATOBold = styled.span`
  font-weight: bold;
`;
