import { useQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectContext } from '../../../../Context/ContextAPI';
import {
  FindAllAtoDocument,
  FindAllAtoQuery,
  FindAllAtoQueryVariables
} from '../../../../data/graphql/query/generated/findAllAto.query';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import {
  DivContainer,
  DivHolderButtons,
  DivHolderChild
} from './AtoNotFoundStyles';
import { useTranslation } from 'react-i18next';
import Enums from '../../../../utils/enumns';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const AtoNotFound = () => {
  const navigate = useNavigate();
  const { structureId } = useParams();
  const { t: translate } = useTranslation();
  const { UserRoles } = Enums();
  const { parseErrorMessage } = useErrorsTreatment();
  const { data: allAtos } = useQuery<FindAllAtoQuery, FindAllAtoQueryVariables>(
    FindAllAtoDocument,
    {
      variables: {
        structureId: structureId || ''
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    }
  );

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };

  const userRoleInModule = getUserRoleByModule('ATO');
  const navigateToAtoAdmin = () => {
    navigate(`/${structureId}/ato/admin`);
  };

  const navigateToHomePage = () => {
    navigate(`/${structureId}`);
  };

  useEffect(() => {
    if (allAtos && allAtos.findAllAto && allAtos.findAllAto.length > 0) {
      const availableAtos = allAtos.findAllAto.filter(
        (ato) => ato.situation !== 'Done' && ato.situation !== 'Canceled'
      );

      if (availableAtos.length > 0) {
        navigate(`/${structureId}/ato/${availableAtos[0].id}`);
      }
    }
  }, [allAtos]);

  return (
    <DivContainer>
      <DivHolderChild>
        <Text color="black" type="h3">
          {translate('NoAtosRegistered')}
        </Text>
      </DivHolderChild>
      <DivHolderButtons>
        {userRoleInModule !== UserRoles.Viewer ? (
          <>
            <Text color="black" weight="bold" size="large" type="span">
              {translate('GoTo')}
            </Text>
            <Button
              onClick={navigateToAtoAdmin}
              variant={'primary'}
              text={`${translate('RegisterAto')}`}
            />
            <Button
              onClick={navigateToHomePage}
              variant={'secondary'}
              text={`${translate('BackToHome')}`}
            />
          </>
        ) : (
          <Button
            onClick={navigateToHomePage}
            variant={'secondary'}
            text={`${translate('BackToHome')}`}
          />
        )}
      </DivHolderButtons>
    </DivContainer>
  );
};

export default AtoNotFound;
