import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordImageMutationVariables = Types.Exact<{
  data: Types.SaveRecordImageInput;
}>;


export type SaveRecordImageMutation = { __typename?: 'Mutation', saveRecordImage: string };


export const SaveRecordImageDocument = gql`
    mutation SaveRecordImage($data: SaveRecordImageInput!) {
  saveRecordImage(data: $data)
}
    `;
export type SaveRecordImageMutationFn = Apollo.MutationFunction<SaveRecordImageMutation, SaveRecordImageMutationVariables>;
export type SaveRecordImageMutationResult = Apollo.MutationResult<SaveRecordImageMutation>;
export type SaveRecordImageMutationOptions = Apollo.BaseMutationOptions<SaveRecordImageMutation, SaveRecordImageMutationVariables>;