import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import { DivContainer } from './SignatureAto.styles';
import { SignatureAtoPros } from './SignatureAto.interfaces';
import { API_URL } from '../../../../utils/const';
import Image from '../../Atoms/Image/Image';

const SignatureAto = ({ signUrl }: SignatureAtoPros) => {
  const { t } = useTranslation();
  return (
    <DivContainer>
      {signUrl ? (
        <Image
          width={'200px'}
          src={`${API_URL}/${signUrl}`}
          alt={t('signature')}
        />
      ) : (
        <Text type="span" size="medium">
          {t('NotAsgnatute')}
        </Text>
      )}
    </DivContainer>
  );
};

export default SignatureAto;
