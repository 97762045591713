import { z } from 'zod';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputText from '../../Molecules/InputText/InputText';
import { useTranslation } from 'react-i18next';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { HolderFooter, HolderForm } from './ReadingWaterLevel.styles';
import {
  CreateWaterLevelReadingDocument,
  CreateWaterLevelReadingMutation,
  CreateWaterLevelReadingMutationVariables
} from '../../../../data/graphql/generated/createWaterLevelReading.mutation';
import { ListReadingByWaterLevelPagDocument } from '../../../../data/graphql/query/generated/listReadingByWaterLevelPag.query';
import {
  ListWaterLevelByStructureDocument,
  ListWaterLevelByStructureQuery,
  ListWaterLevelByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import { ReadingWaterLevelProps } from './ReadingWaterLevel.interfaces';
import {
  EditWaterLevelReadingMutation,
  EditWaterLevelReadingMutationVariables,
  EditWaterLevelReadingDocument
} from '../../../../data/graphql/generated/editWaterLevelReading.mutation';
import { DivInput } from '../ReadingSurfaceMarkerForm/ReadingSurfaceMarker.styles';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const formReadingSchema = z.object({
  instrument: z
    .object({
      value: z.string().min(1, 'fieldIsRequired'),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  value: z.string().min(1, 'fieldIsRequired'),
  date: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  observation: z.string().nullable()
});

type FormReadingSchemaType = z.infer<typeof formReadingSchema>;

const ReadingWaterLevel = ({
  reading,
  setShowModal
}: ReadingWaterLevelProps) => {
  const { structureId } = useParams();
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormReadingSchemaType>({
    resolver: zodResolver(formReadingSchema),
    defaultValues: {
      value: reading?.value?.toString() || '',
      date: reading?.date ? new Date(reading?.date) : new Date(),
      observation: reading?.observation || '',
      instrument: reading?.instrument
        ? {
            value: reading?.instrument.id,
            label: reading?.instrument.name
          }
        : {
            value: '',
            label: ''
          }
    }
  });
  const [createReading] = useMutation<
    CreateWaterLevelReadingMutation,
    CreateWaterLevelReadingMutationVariables
  >(CreateWaterLevelReadingDocument);

  const [editWaterLevelReading] = useMutation<
    EditWaterLevelReadingMutation,
    EditWaterLevelReadingMutationVariables
  >(EditWaterLevelReadingDocument);

  const { data: listWaterLevelsReadings } = useQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!
      }
    }
  });

  const listWaterLevelOpetions =
    listWaterLevelsReadings?.listWaterLevelByStructure.map((instrument) => ({
      value: instrument.id!,
      label: instrument.name
    }));

  const handleSaveReading = (data: FormReadingSchemaType) => {
    createReading({
      variables: {
        data: {
          instrumentId: data.instrument.value,
          instrumentName: data.instrument.label,
          value: parseFloat(data.value),
          date: new Date(data.date.setHours(0, 0, 0, 0)),
          observation: data.observation
        },
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('registeredSuccessfully'));
      },
      refetchQueries: [ListReadingByWaterLevelPagDocument],
      awaitRefetchQueries: true,
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleEditReading = (data: FormReadingSchemaType) => {
    const readingData = {
      id: reading?.id ?? '',
      value: parseFloat(data.value),
      date: new Date(data.date.setHours(0, 0, 0, 0)),
      observation: data.observation
    };
    editWaterLevelReading({
      variables: {
        data: readingData,
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updatedSuccessfully'));
      },
      refetchQueries: [ListReadingByWaterLevelPagDocument],
      awaitRefetchQueries: true,
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <HolderForm
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          reading
            ? handleSubmit(handleEditReading)()
            : handleSubmit(handleSaveReading)();
        }
      }}
    >
      {!reading?.id && (
        <InputSelectSearch
          width="400px"
          placeholder={translate('SelectInstrument')}
          control={control}
          label={translate('Instruments')}
          options={listWaterLevelOpetions || []}
          name={'instrument'}
          error={!!errors.instrument}
          errorMessage={errors?.instrument?.message}
          disabled={!!reading?.id}
        />
      )}
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('reading')}
          name={'value'}
          type={'number'}
          error={!!errors.value}
          errorMessage={errors?.value?.message}
        />
      </DivInput>
      <DatepickerInput
        width="330px"
        control={control}
        label={translate('date')}
        name="date"
        placeholder={translate('date')}
        time={false}
        error={!!errors.date}
        errorMessage={errors?.date?.message}
      />
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('observation')}
          name={'observation'}
          type={'text'}
          error={!!errors.observation}
          errorMessage={errors?.observation?.message}
        />
      </DivInput>
      <HolderFooter>
        <Button
          size="large"
          text={reading ? `${translate('edit')}` : `${translate('create')}`}
          onClick={
            reading
              ? handleSubmit(handleEditReading)
              : handleSubmit(handleSaveReading)
          }
          variant={'primary'}
        />
      </HolderFooter>
    </HolderForm>
  );
};

export default ReadingWaterLevel;
