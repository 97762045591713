import { ApolloProvider } from '@apollo/client';
import { APIProvider } from '@vis.gl/react-google-maps';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import LogoGS3 from '../src/Images/logoGS3.svg';
import ATOScreen from './Components/V2/Pages/ATOScreen/ATOScreen.tsx';
import ActionPlan from './Components/V2/Pages/ActionPlan/ActionPlan.tsx';
import ActionPlanGeneratedPage from './Components/V2/Pages/ActionPlanGeneratedPage/ActionPlanGeneratedPage.tsx';
import ActionPlanHistoryPage from './Components/V2/Pages/ActionPlanHistoryPage/ActionPlanHistoryPage.tsx';
import ActionPlanImagesPage from './Components/V2/Pages/ActionPlanImagesPage/ActionPlanImagesPage.tsx';
import ActionPlanTeamPage from './Components/V2/Pages/ActionPlanTeamPage/ActionPlanTeamPage.tsx';
import AtoAdminPage from './Components/V2/Pages/AtoAdminPage/AtoAdminPage.tsx';
import AtoDashboardPage from './Components/V2/Pages/AtoDashboardPage/AtoDashboardPage.tsx';
import AtoNotFound from './Components/V2/Pages/AtoNotFound/AtoNotFound.tsx';
import AtoPhotos from './Components/V2/Pages/AtoPhotos/AtoPhotos.tsx';
import AtoRelatorios from './Components/V2/Pages/AtoRelatorio/AtoRelatorio.tsx';
import AtoVideos from './Components/V2/Pages/AtoVideos/AtoVideos.tsx';
import EditAtoPage from './Components/V2/Pages/EditAtoPage/EditAtoPage.tsx';
import EditInstrumentPage from './Components/V2/Pages/EditInstrument/EditInstrument.tsx';
import GISTMScreen from './Components/V2/Pages/GISTM/GISTMScreen.tsx';
import GeneralPage from './Components/V2/Pages/GeneralPage/GeneralPage.tsx';
import PSBScreen from './Components/V2/Pages/PSB/PSBScreen.tsx';
import ReadingsPage from './Components/V2/Pages/ReadingsPage/ReadingsPage.tsx';
import SectionGraph from './Components/V2/Pages/SectionGraph/SectionGraph.tsx';
import { Provider } from './Context/ContextAPI.tsx';
import Ato from './Modules/Admin/ATO/Ato.tsx';
import Clients from './Modules/Admin/Clients/Clients.tsx';
import Notifications from './Modules/Admin/Notifications/Notifications.tsx';
import Permissions from './Modules/Admin/Permissions/Permissions.tsx';
import Structure from './Modules/Admin/Structure/Structure.tsx';
import Users from './Modules/Admin/Users/Users.tsx';
import InspectionsScreen from './Modules/Inspection/InspectionsScreen.tsx';
import InstrumentDataListPage from './Components/V2/Pages/InstrumentDataListPage/InstrumentDataListPage.tsx';
import { MonitoringScreen } from './Modules/Monitoring/MonitoringScreen.tsx';
import AdminScreen from './Screens/AdminScreen/AdminScreen.tsx';
import LoginScreen from './Screens/LoginScreen/LoginScreen.tsx';
import MainScreen from './Screens/MainScreen/MainScreen.tsx';
import ResetPasswordScreen from './Screens/ResetPasswordScreen/ResetPasswordScreen.tsx';
import GlobalStyle, { DefaultLogo } from './Styles/Styles.ts';
import { client } from './data/apollo.client.ts';
import PrivateRoute from './utils/PrivateRoute.tsx';
import { GOOGLE_MAPS_TOKEN } from './utils/const';
import RegisterInstrumentPage from './Components/V2/Pages/RegisterInstrument/RegisterInstrumentPage.tsx';
import InstrumentGraphPage from './Components/V2/Pages/InstrumentGraphPage/InstrumentGraphPage.tsx';
import MonitoringDashboard from './Components/V2/Pages/Monitoring/MonitoringDashboard/MonitoringDashboard.tsx';
import EquipamentHealthCMGPage from './Components/V2/Pages/Monitoring/CMG/EquipamentHealthCMGPage/EquipamentHealthCMGPage.tsx';
import ReadingsCMGPage from './Components/V2/Pages/Monitoring/CMG/ReadingsCMGPage/ReadingsCMGPage.tsx';
import InspectionDashboradPage from './Components/V2/Pages/Inspection/InspectionDashboradPage/InspectionDashboradPage.tsx';
import AnomaliesCMGPage from './Components/V2/Pages/Inspection/AnomaliesCMGPage/AnomaliesCMGPage.tsx';
import StabilityHistoryPage from './Components/V2/Pages/StabilityAnalysis/StabilityHistory/StabilityHistoryPage.tsx';
import StabilityPage from './Components/V2/Pages/StabilityAnalysis/StabilityPage/StabilityPage.tsx';
import InspectionPage from './Components/V2/Pages/Inspection/InspectionPage/InspectionPage.tsx';

export const App: React.FC = () => {
  return (
    <Provider>
      <BrowserRouter>
        <GlobalStyle />
        <ToastContainer />
        <APIProvider
          apiKey={GOOGLE_MAPS_TOKEN}
          libraries={['drawing']}
          onLoad={() => console.log('Maps API has loaded.')}
        >
          <ApolloProvider client={client}>
            <AppRoutes />
          </ApolloProvider>
        </APIProvider>
      </BrowserRouter>
    </Provider>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {['/', 'Login'].map((path, index) => (
        <Route key={index} path={path} element={<LoginScreen />} />
      ))}
      <Route path={'/resetPassword'} element={<ResetPasswordScreen />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<MainScreen />}>
          <Route index element={<DefaultLogo src={LogoGS3} />} />
          <Route path="/:structureId" element={<DefaultLogo src={LogoGS3} />} />
          <Route path="/:structureId/general" element={<GeneralPage />} />
          <Route
            path="/:structureId/monitoring"
            element={<MonitoringDashboard />}
          />
          <Route
            path="/:structureId/monitoring/map"
            element={<MonitoringScreen />}
          />
          <Route
            path="/:structureId/monitoring/cmg/readings"
            element={<ReadingsCMGPage />}
          />
          <Route
            path="/:structureId/monitoring/cmg/equipmenthealth"
            element={<EquipamentHealthCMGPage />}
          />
          <Route
            path="/:structureId/monitoring/instrument/:instrumentType"
            element={<InstrumentDataListPage />}
          />
          <Route
            path="/:structureId/monitoring/readings/:instrumentType"
            element={<ReadingsPage />}
          />
          <Route
            path="/:structureId/monitoring/graphs"
            element={<InstrumentGraphPage />}
          />
          <Route
            path="/:structureId/monitoring/sectionGraphs"
            element={<SectionGraph />}
          />
          <Route
            path="/:structureId/monitoring/instrument/:instrumentType/view/:instrumentId"
            element={<EditInstrumentPage />}
          />
          <Route
            path="/:structureId/monitoring/instrument/:instrumentType/edit/:instrumentId"
            element={<EditInstrumentPage />}
          />
          <Route
            path="/:structureId/monitoring/instrument/:instrumentType/register"
            element={<RegisterInstrumentPage />}
          />
          {/* <Route
            path="/:structureId/inspections"
            element={<InspectionDashboradPage />}
          /> */}
          <Route
            path="/:structureId/inspections"
            element={<InspectionsScreen />}
          />
          <Route
            path="/:structureId/inspections/inspections"
            element={<InspectionPage />}
          />
          <Route
            path="/:structureId/inspections/cmg/anomalies"
            element={<AnomaliesCMGPage />}
          />
          <Route path="/:structureId/actionplan" element={<ActionPlan />} />
          <Route
            path="/:structureId/actionplan/:actionPlanId"
            element={<ActionPlanGeneratedPage />}
          />
          <Route
            path="/:structureId/actionplan/:actionPlanId/images"
            element={<ActionPlanImagesPage />}
          />
          <Route
            path="/:structureId/actionplan/:actionPlanId/history"
            element={<ActionPlanHistoryPage />}
          />
          <Route
            path="/:structureId/actionplan/:actionPlanId/team"
            element={<ActionPlanTeamPage />}
          />
          <Route path="/:structureId/ato" element={<AtoNotFound />} />
          <Route path="/:structureId/ato/admin" element={<AtoAdminPage />} />
          <Route
            path="/:structureId/ato/:atoId"
            element={<AtoDashboardPage />}
          />
          <Route
            path="/:structureId/ato/:atoId/records"
            element={<AtoRelatorios />}
          />
          <Route
            path="/:structureId/ato/:atoId/videos"
            element={<AtoVideos />}
          />
          <Route
            path="/:structureId/ato/:atoId/fotos"
            element={<AtoPhotos />}
          />
          <Route
            path="/:structureId/ato/:atoId/view/:recordId"
            element={<ATOScreen />}
          />
          <Route
            path="/:structureId/ato/:atoId/edit/:recordId"
            element={<EditAtoPage />}
          />
          <Route path="/:structureId/psb" element={<PSBScreen />} />
          <Route path="/:structureId/psb/:psbId" element={<PSBScreen />} />
          <Route path="/:structureId/gistm" element={<GISTMScreen />} />
          <Route
            path="/:structureId/gistm/:gistmId"
            element={<GISTMScreen />}
          />
          <Route
            path="/:structureId/stabilityanalysis"
            element={<StabilityPage />}
          />
          <Route
            path="/:structureId/stabilityanalysis/history"
            element={<StabilityHistoryPage />}
          />
          <Route
            path="/:structureId/stabilityanalysis/history/:stabilityId"
            element={<StabilityHistoryPage />}
          />
        </Route>
        <Route path="/admin" element={<AdminScreen />}>
          <Route index element={<DefaultLogo src={LogoGS3} />} />
          <Route path="/admin/clients" element={<Clients />} />
          <Route path="/admin/structures" element={<Structure />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/permissions" element={<Permissions />} />
          <Route path="/admin/notifications" element={<Notifications />} />
          <Route path="/admin/ato" element={<Ato />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default App;
