import styled from 'styled-components';

export const ContainerImagesNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 100px;
  gap: 5px;
  box-sizing: border-box;
  flex-direction: column;
`;

export const DivInput = styled.div`
  display: flex;
  gap: 30px;
`;
