import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

interface AccordionContentProps {
  $isOpen: boolean;
}

interface AccordionHeaderProps {
  $unread: boolean;
}

export const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const AccordionHeader = styled.div<AccordionHeaderProps>`
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${({ $unread }) =>
    $unread ? 'rgba(0, 0, 0, 0.1)' : `${Color.Brown3}`};

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  }
`;

export const AccordionTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #8b7346;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
`;

export const AccordionContent = styled.div<AccordionContentProps>`
  padding: ${({ $isOpen }) => ($isOpen ? '20px 10px' : '0px')};
  box-sizing: border-box;
  background-color: #fff;
  font-size: 14px;
  color: #8b7346;
  height: ${({ $isOpen }) => ($isOpen ? 'auto' : '0px')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  overflow: hidden;
  transform: ${({ $isOpen }) => ($isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  transition: all 0.3s ease-in-out;
  will-change: height, opacity, transform;
`;

export const AccordionItem = styled.div`
  border-bottom: 1px solid #d1b38a;
  &:last-child {
    border-bottom: none;
  }
`;
