import { useTranslation } from 'react-i18next';
import { TABLE_IDS } from '../../../../../../utils/TableConsts';
import TableDraggableComponent from '../../../../Molecules/TableDraggable/TableDraggableComponent';
import { DivContainer } from './EquipamentHealthCMGPage.styles';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  FindInstrumentsInfoToGmcDocument,
  FindInstrumentsInfoToGmcQuery,
  FindInstrumentsInfoToGmcQueryVariables
} from '../../../../../../data/graphql/query/generated/findInstrumentsInfoToGMC.query';
import FilterEquipamentHealthCMGPage from '../FilterEquipamentHealthCMGPage/FilterEquipamentHealthCMGPage';
import { TableColumnsObj } from '../../../../Molecules/TableDraggable/TableDraggableComponent.interface';

const EquipamentHealthCMGPage = () => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();

  const { data: instrumentsInfo } = useQuery<
    FindInstrumentsInfoToGmcQuery,
    FindInstrumentsInfoToGmcQueryVariables
  >(FindInstrumentsInfoToGmcDocument, {
    variables: {
      structureId: structureId!
    },
    fetchPolicy: 'no-cache',
    pollInterval: 1800000
  });

  const { instruments } = instrumentsInfo?.findInstrumentsInfoToGMC || {
    instruments: []
  };

  const equipamentHealthColumns: TableColumnsObj[] = [
    { key: 'instrumentName', label: translate('Instrument'), sortType: 'text' },
    { key: 'readingDate', label: translate('dateAndTime'), sortType: 'text' },
    { key: 'lat', label: translate('Latitute'), sortType: 'text' },
    { key: 'lon', label: translate('Longitude'), sortType: 'text' },
    { key: 'instrumentType', label: translate('Type'), sortType: 'text' },
    { key: 'status', label: translate('situation'), sortType: 'text' },
    { key: 'battery', label: translate('battery'), sortType: 'text' },
    {
      key: 'auxBattery',
      label: translate('auxiliaryBattery'),
      sortType: 'text'
    },
    {
      key: 'loggerTemperature',
      label: translate('temperature'),
      sortType: 'text'
    },
    {
      key: 'signalStrength',
      label: translate('signalStrength'),
      sortType: 'text'
    },
    { key: 'thermistor', label: translate('thermistor'), sortType: 'text' }
  ];

  const equipamentHealthData = instruments
    .filter((instrument) => {
      const instrumentName = searchParams.get('instrumentName');
      const readingDate = searchParams.get('readingDate');
      const battery = searchParams.get('battery');
      const auxiliaryBattery = searchParams.get('auxiliaryBattery');
      const temperature = searchParams.get('temperature');
      const signalStrength = searchParams.get('signalStrength');
      const thermistor = searchParams.get('thermistor');
      const hasReading = searchParams.get('hasReading') || 'true';

      return (!instrumentName ||
        instrument.instrumentName
          .toLocaleLowerCase()
          .includes(instrumentName?.toLocaleLowerCase() || '')) &&
        (!readingDate ||
          (instrument.readingDate &&
            new Date(instrument.readingDate)
              .toLocaleDateString()
              .toLocaleLowerCase()
              .includes(
                new Date(readingDate).toLocaleDateString().toLocaleLowerCase()
              ))) &&
        (!battery ||
          instrument.battery
            ?.toString()
            .toLocaleLowerCase()
            .includes(battery.toLocaleLowerCase())) &&
        (!auxiliaryBattery ||
          instrument.auxBattery
            ?.toString()
            .toLocaleLowerCase()
            .includes(auxiliaryBattery.toLocaleLowerCase())) &&
        (!temperature ||
          instrument.loggerTemperature
            ?.toString()
            .toLocaleLowerCase()
            .includes(temperature.toLocaleLowerCase())) &&
        (!signalStrength ||
          instrument.signalStrength
            ?.toString()
            .toLocaleLowerCase()
            .includes(signalStrength.toLocaleLowerCase())) &&
        (!thermistor ||
          instrument.thermistor
            ?.toString()
            .toLocaleLowerCase()
            .includes(thermistor.toLocaleLowerCase())) &&
        hasReading === 'true'
        ? instrument.reading !== null
        : hasReading === 'false'
          ? instrument.reading === null
          : true;
    })
    .sort((instrumentA, instrumentB) => {
      const sortDirection = searchParams.get('sortDirection') || 'ascending';
      const sortKey =
        (searchParams.get('sortKey') as keyof typeof instrumentA) ||
        'readingDate';
      if (instrumentA[sortKey] == null) return 1;
      if (instrumentB[sortKey] == null) return -1;

      if (sortDirection === 'ascending') {
        return instrumentA[sortKey] > instrumentB[sortKey] ? 1 : -1;
      } else {
        return instrumentA[sortKey] < instrumentB[sortKey] ? 1 : -1;
      }
    })
    .map((instrument) => ({
      ...instrument,
      readingDate: instrument.readingDate
        ? new Date(instrument.readingDate).toLocaleString().replace(',', ' -')
        : '-',
      status: translate(instrument.situation || 'normal'),
      lat: instrument.lat.toFixed(6),
      lon: instrument.lon.toFixed(6)
    }));
  return (
    <DivContainer>
      <TableDraggableComponent
        tableId={TABLE_IDS.CMG_EQUIPAMENT_HEALTH_TABLE}
        title="equipmentHealth"
        columnsArray={equipamentHealthColumns}
        sortUrl
        tableData={equipamentHealthData || []}
        columnSelect
        showCountColumn
        maxHeight="calc(100vh - 250px)"
        fullScreenOption={true}
        filterComponent={<FilterEquipamentHealthCMGPage />}
      />
    </DivContainer>
  );
};

export default EquipamentHealthCMGPage;
