import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCirclePlay } from 'react-icons/fa6';
import { MdPhotoCamera } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectContext } from '../../../../Context/ContextAPI';
import {
  FindAllAtoDocument,
  FindAllAtoQuery,
  FindAllAtoQueryVariables
} from '../../../../data/graphql/query/generated/findAllAto.query';
import {
  FindGeneralInfoDocument,
  FindGeneralInfoQuery,
  FindGeneralInfoQueryVariables
} from '../../../../data/graphql/query/generated/findGeneralInfo';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { GroupText } from '../../Molecules/GroupText/GroupText';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ContentActionCard from '../../Organisms/ContentActionCard/ContentActionCard';
import DashboardTemplate from '../../Templates/DashboardTemplate/DashboardTemplate';
import { HolderIconAndValueTable } from '../AtoAdminPage/AtoAdminPageStyles';
import {
  CardDataInterface,
  contractInfosInterface
} from './AtoDashboard.interfaces';
import { HolderPictures } from './AtoDashboardStyles';
import Enums from '../../../../utils/enumns';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

export interface listRecordsTableDataProps {
  date: string;
  number: string;
  situation: string;
  imagesCount: JSX.Element;
  videosCount: JSX.Element;
  id: string;
}

const CardList: React.FC<{ data: CardDataInterface[] }> = ({ data }) => (
  <>
    {data.map((item, index) => (
      <SquareCard key={index} label={item.title} value={item.value} />
    ))}
  </>
);

const GroupInfos: React.FC<{ data: contractInfosInterface[] }> = ({ data }) => (
  <>
    {data.map((item, index) => (
      <GroupText key={index} title={item.title} value={item.value} />
    ))}
  </>
);

const AtoDashboardPage = () => {
  const { t: translate } = useTranslation();
  const { UserRoles } = Enums();
  const { atoId, structureId } = useParams<{
    atoId: string;
    structureId: string;
  }>();
  const navigate = useNavigate();
  const { parseErrorMessage } = useErrorsTreatment();

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('ATO');

  const { data: allAtos } = useQuery<FindAllAtoQuery, FindAllAtoQueryVariables>(
    FindAllAtoDocument,
    {
      variables: {
        structureId: structureId || ''
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    }
  );

  const { data: allAtoSpecificInfos, loading } = useQuery<
    FindGeneralInfoQuery,
    FindGeneralInfoQueryVariables
  >(FindGeneralInfoDocument, {
    variables: {
      atoId: atoId ? atoId : ''
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const atoTitle = `${allAtoSpecificInfos?.findGeneralInfo.title} -
   ${allAtoSpecificInfos?.findGeneralInfo.contract}`;

  const contractInfos: contractInfosInterface[] = [
    {
      title: `${translate('contractualPeriod')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.contractualPeriod as string
    },
    {
      title: `${translate('dueDate')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.remainingTerm as string
    },
    {
      title: `${translate('estimatedEndDate')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.finalDate
        ? new Date(
            allAtoSpecificInfos.findGeneralInfo.finalDate
          ).toLocaleDateString()
        : ''
    },
    {
      title: `${translate('startDate')}:`,
      value: allAtoSpecificInfos?.findGeneralInfo.initialDate
        ? new Date(
            allAtoSpecificInfos.findGeneralInfo.initialDate
          ).toLocaleDateString()
        : ''
    }
  ];

  const dataTable = [
    { key: 'date', label: `${translate('Date')}` },
    { key: 'number', label: `${translate('Number')}` },
    { key: 'status', label: 'Status' },
    { key: 'imagesCount', label: `${translate('Photos')}` },
    { key: 'videosCount', label: 'Vídeos' }
  ];

  const cardInfos: CardDataInterface[] = [
    {
      title: `${translate('reports')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.recordsCount}`
    },
    {
      title: `${translate('occurrences')}/
      ${translate('paralization')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.occurrencesCount}`
    },
    {
      title: `${translate('observations')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.observationsCount}`
    },
    {
      title: `${translate('Photos')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.imagesCount}`
    },
    {
      title: `${translate('activities')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.activitiesCount}`
    },
    {
      title: `${translate('video')}`,
      value: `${allAtoSpecificInfos?.findGeneralInfo.videosCount}`
    }
  ];

  const listRecordsTableData =
    allAtoSpecificInfos?.findGeneralInfo.recentRecords.map((record) => ({
      ...record,
      date: new Date(record.date).toLocaleDateString(),
      number: record.recordNumber,
      imagesCount: (
        <HolderIconAndValueTable>
          <MdPhotoCamera /> {record.imagesCount}
        </HolderIconAndValueTable>
      ),
      videosCount: (
        <HolderIconAndValueTable>
          <FaCirclePlay /> {record.videosCount}
        </HolderIconAndValueTable>
      ),
      status: translate(record.situation.toLowerCase()),
      id: record.id
    }));

  const navigateToRecords = () => {
    navigate('records');
  };

  const navigateToRecord = (record: listRecordsTableDataProps) => {
    navigate(`/${structureId}/ato/${atoId}/view/${record.id}`);
  };

  const navigateToAtoAdmin = () => {
    navigate(`/${structureId}/ato/admin`);
  };

  useEffect(() => {
    if (allAtos && allAtos.findAllAto.length === 0) {
      navigate(`/${structureId}/ato`);
    }
  }, [allAtos, structureId, navigate]);
  return (
    <DashboardTemplate
      header={
        <>
          <Text type="h2">{atoTitle}</Text>
          {userRoleInModule !== UserRoles.Viewer ? (
            <Button
              onClick={navigateToAtoAdmin}
              variant="secondary"
              text={`${translate('atoDashboard')}`}
            />
          ) : (
            <></>
          )}
        </>
      }
      cardContent={<CardList data={cardInfos} />}
      infosContent={
        <ContentActionCard title={`${translate('deadlineInfo')}`}>
          <HolderPictures>
            <GroupInfos data={contractInfos} />
          </HolderPictures>
        </ContentActionCard>
      }
      tableContent={
        <ContentActionCard
          action={() => navigateToRecords()}
          title={`${translate('recentReports')}`}
        >
          <TableComponent
            tableData={listRecordsTableData || []}
            columns={dataTable}
            onRow={(row: listRecordsTableDataProps | any) =>
              navigateToRecord(row)
            }
          />
        </ContentActionCard>
      }
      loading={loading}
    />
  );
};

export default AtoDashboardPage;
