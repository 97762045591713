import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import {
  InputSelectSearchProps,
  OptionType
} from './InputSelectSearch.interfaces';
import {
  ErrorStyled,
  InputContainer,
  InputStyled,
  StyleSelect
} from './InputSelectSearch.styles';

const InputSelectSearch = ({
  label,
  options,
  error,
  errorMessage,
  name,
  control,
  hideSelectedOptions,
  width,
  placeholder,
  value,
  onChange,
  disabled,
  required,
  isClearable,
  menuPlacement,
  isMulti
}: InputSelectSearchProps) => {
  const { t: translate } = useTranslation();

  const renderSelect = (field: any) => (
    <StyleSelect
      {...field}
      id={name}
      width={width}
      error={error}
      placeholder={translate(placeholder)}
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      isDisabled={disabled}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
      value={field.value || []}
      isMulti={isMulti}
      onChange={(option) => {
        field.onChange(option);
        if (onChange) {
          onChange(option as OptionType & OptionType[]);
        }
      }}
    />
  );

  return (
    <InputContainer>
      <Text
        type="label"
        color={disabled ? 'disabled' : 'brown'}
        htmlFor={name}
        error={error}
      >
        {translate(label)} {required && '*'}
      </Text>
      <InputStyled>
        {control ? (
          <Controller
            name={name || ''}
            control={control}
            defaultValue={null}
            render={({ field }) => renderSelect(field)}
          />
        ) : (
          renderSelect({ value, onChange })
        )}
      </InputStyled>
      {error ? (
        <ErrorStyled>{translate(errorMessage)}</ErrorStyled>
      ) : (
        <ErrorStyled />
      )}
    </InputContainer>
  );
};

export default InputSelectSearch;
