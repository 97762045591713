import { z } from 'zod';
import InputText from '../../../Molecules/InputText/InputText';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputSelectSearch from '../../../Molecules/InputSelectSearch/InputSelectSearch';
import DatepickerInput from '../../../Molecules/DatepickerInput/DatepickerInput';
import Button from '../../../Atoms/Button/Button';
import { useSearchParams } from 'react-router-dom';
import {
  DivContainerButtons,
  DivContainerForm
} from './StabilityHistoryTableFilter.styles';
import { useTranslation } from 'react-i18next';

const filterSchema = z.object({
  sectionName: z.string().optional().nullable(),
  graphicStatus: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional(),
  slideFileName: z.string(),
  analysisDate: z.date().optional().nullable()
});

type filterSchemaType = z.infer<typeof filterSchema>;

const StabilityHistoryTableFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t: translate } = useTranslation();

  const optionsStatus = [
    { value: 'pending', label: translate('pending') },
    { value: 'inProgress', label: translate('inProgress') },
    { value: 'completed', label: translate('completed') },
    { value: 'failed', label: translate('failed') }
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<filterSchemaType>({
    resolver: zodResolver(filterSchema),
    defaultValues: {
      sectionName: searchParams.get('sectionName') || '',
      graphicStatus:
        optionsStatus.find(
          (option) => option.value === searchParams.get('graphicStatus')
        ) || null,
      slideFileName: searchParams.get('slideFileName') || '',
      analysisDate: searchParams.get('analysisDate')
        ? new Date(searchParams.get('analysisDate')!)
        : null
    }
  });

  const handleFilter = (data: filterSchemaType) => {
    const { slideFileName, analysisDate, graphicStatus, sectionName } = data;

    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      slideFileName && newParams.set('slideFileName', slideFileName);
      analysisDate &&
        newParams.set('analysisDate', analysisDate?.toISOString());
      graphicStatus && newParams.set('graphicStatus', graphicStatus?.value);
      sectionName && newParams.set('sectionName', sectionName);
      newParams.delete('filter');
      if (
        newParams.get('currentPage') &&
        parseInt(newParams.get('currentPage')!) > 1
      ) {
        newParams.set('currentPage', '1');
      }

      return newParams;
    });
  };

  const handleCleanFilter = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete('slideFileName');
      newParams.delete('analysisDate');
      newParams.delete('graphicStatus');
      newParams.delete('sectionName');
      newParams.set('currentPage', '1');
      return newParams;
    });
    reset({
      sectionName: '',
      graphicStatus: null,
      slideFileName: '',
      analysisDate: null
    });
  };

  return (
    <>
      <DivContainerForm
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(handleFilter)();
          }
        }}
      >
        <DatepickerInput
          control={control}
          label="Date"
          name="analysisDate"
          width="180px"
          placeholder="NoInformated"
          time={false}
          error={!!errors.analysisDate}
          errorMessage={errors?.analysisDate?.message}
          enableMinMaxDateSelection={true}
          maxDate={new Date()}
        />
        <InputText
          label="Section"
          name="sectionName"
          control={control}
          error={!!errors.sectionName}
          errorMessage={errors.sectionName?.message}
          type="text"
        />
        <InputSelectSearch
          label="Status"
          name="graphicStatus"
          control={control}
          placeholder="select"
          error={!!errors.graphicStatus}
          errorMessage={errors.graphicStatus?.message}
          options={optionsStatus}
        />
        <InputText
          label="Slide"
          name="slideFileName"
          control={control}
          error={!!errors.slideFileName}
          errorMessage={errors.slideFileName?.message}
          type="text"
        />
      </DivContainerForm>
      <DivContainerButtons>
        <Button text="clean" variant="primary" onClick={handleCleanFilter} />
        <Button
          text="ToFilter"
          variant="primary"
          onClick={handleSubmit(handleFilter)}
        />
      </DivContainerButtons>
    </>
  );
};

export default StabilityHistoryTableFilter;
