import styled, { CSSProperties } from 'styled-components';
import { Color, FontSize } from '../../Styles/Styles';

export const MapMenuStyle = styled.div`
  position: absolute;
  right: 0;
  min-width: 220px;
  width: max-content;
  height: max-content;
  top: 100px;
  z-index: 1;
  color: ${Color.Brown1};
  box-sizing: border-box;

  div {
    display: flex;
    flex-direction: column;
  }

  section {
    padding: 5px 15px 15px 20px;
    background-color: ${Color.Brown3}cf;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  span {
    margin: 3px;
    cursor: pointer;
    width: max-content;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 5px;
  }
`;

export const MenuTitle = styled.span`
  color: ${Color.Brown1};
  font-size: ${FontSize.XMedium};
  align-self: center;
  font-weight: bold;
`;

export const checkBoxStyle: CSSProperties = {
  top: 5,
  fontSize: 20
};

export const CustomMarkerStyle: CSSProperties = {
  fontSize: '1.5em',
  backgroundColor: Color.White,
  color: Color.Brown1,
  borderRadius: '50%',
  padding: 5,
  cursor: 'pointer'
};

export const ToggleMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  span {
    margin: 0 !important;
    span {
      width: 45px;
    }
  }
`;

export const ContainerToggleMenu = styled.div`
  font-size: 14px;
  width: 45px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

export const disabledCheckBoxStyle: CSSProperties = {
  cursor: 'not-allowed',
  color: 'gray'
};
