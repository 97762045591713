import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { client } from '../../../../data/apollo.client';
import {
  SaveRecordWorkShiftDocument,
  SaveRecordWorkShiftMutation,
  SaveRecordWorkShiftMutationVariables
} from '../../../../data/graphql/generated/saveRecordWorkShift';
import {
  UpdateRecordWorkShiftDocument,
  UpdateRecordWorkShiftMutation,
  UpdateRecordWorkShiftMutationVariables
} from '../../../../data/graphql/generated/updateRecordWorkShift';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import {
  WorkScheduleProps,
  WorkShiftsItem
} from './AddWorkScheduleAtoForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderDepartureTime,
  HolderEntryTime,
  HolderForm
} from './AddWorkScheduleAtoForm.styles';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const WorkScheduleSchema = z.object({
  entryExitBeginning: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  entryExitEnding: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  intervalBeginning: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  intervalEnding: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    })
});

type WorkScheduleSchemaType = z.infer<typeof WorkScheduleSchema>;

const AddWorkScheduleAtoForm = ({
  setShowModal,
  workShifts,
  rdoDate,
  recordId
}: WorkScheduleProps) => {
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();

  const [saveWorkShift] = useMutation<
    SaveRecordWorkShiftMutation,
    SaveRecordWorkShiftMutationVariables
  >(SaveRecordWorkShiftDocument);

  const [updateWorkShift] = useMutation<
    UpdateRecordWorkShiftMutation,
    UpdateRecordWorkShiftMutationVariables
  >(UpdateRecordWorkShiftDocument);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<WorkScheduleSchemaType>({
    resolver: zodResolver(WorkScheduleSchema),
    defaultValues: workShifts
      ? {
          entryExitBeginning: new Date(workShifts.entry),
          entryExitEnding: new Date(workShifts.exit),
          intervalBeginning: new Date(workShifts.entryInterval),
          intervalEnding: new Date(workShifts.exitInterval)
        }
      : {
          entryExitBeginning: new Date(rdoDate),
          entryExitEnding: new Date(rdoDate),
          intervalBeginning: new Date(rdoDate),
          intervalEnding: new Date(rdoDate)
        }
  });

  const handleSubmitWorkSchedule = (data: WorkScheduleSchemaType) => {
    const {
      entryExitBeginning,
      entryExitEnding,
      intervalBeginning,
      intervalEnding
    } = data;

    if (workShifts?.id) {
      updateWorkShift({
        variables: {
          data: {
            observation: '',
            id: workShifts?.id,
            exitInterval: intervalEnding,
            exit: entryExitEnding,
            entryInterval: intervalBeginning,
            entry: entryExitBeginning
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess('editedSuccessfully');
        },
        onError: (error) => {
          parseErrorMessage(error);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              workShifts: existingData.findDataByRecord.workShifts.map(
                (item: WorkShiftsItem) =>
                  item.id === workShifts?.id
                    ? {
                        ...item,
                        observation: '',
                        id: workShifts?.id,
                        exitInterval: intervalEnding,
                        exit: entryExitEnding,
                        entryInterval: intervalBeginning,
                        entry: entryExitBeginning,
                        __typename: 'AtoRecordWorkShiftType'
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      saveWorkShift({
        variables: {
          data: {
            entry: entryExitBeginning,
            exit: entryExitEnding,
            entryInterval: intervalBeginning,
            exitInterval: intervalEnding,
            observation: '',
            recordId: recordId || ''
          }
        },
        onCompleted: async () => {
          await client.refetchQueries({
            include: [
              {
                query: FindDataByRecordDocument,
                variables: {
                  recordId: recordId
                },
                awaitRefetchQueries: true
              }
            ]
          });
          setShowModal(false);
          toastfySuccess('registeredSuccessfully');
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
    }
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <HolderEntryTime>
          <DatepickerInput
            placeholder={translate('entryExitBeginning')}
            enableMinMaxDateSelection={true}
            minDate={
              workShifts ? new Date(workShifts.entry) : new Date(rdoDate)
            }
            maxDate={
              workShifts ? new Date(workShifts.entry) : new Date(rdoDate)
            }
            time={true}
            showTimeOnly={true}
            error={!!errors.entryExitBeginning}
            errorMessage={errors.entryExitBeginning?.message}
            control={control}
            name="entryExitBeginning"
            label={translate('entryExitBeginning')}
          />
          <DatepickerInput
            placeholder={translate('entryExitEnding')}
            enableMinMaxDateSelection={true}
            minDate={workShifts ? new Date(workShifts.exit) : new Date(rdoDate)}
            maxDate={workShifts ? new Date(workShifts.exit) : new Date(rdoDate)}
            time={true}
            showTimeOnly={true}
            error={!!errors.entryExitEnding}
            errorMessage={errors.entryExitEnding?.message}
            control={control}
            name="entryExitEnding"
            label={translate('entryExitEnding')}
          />
        </HolderEntryTime>
        <HolderDepartureTime>
          <DatepickerInput
            placeholder={translate('intervalBeginning')}
            enableMinMaxDateSelection={true}
            minDate={
              workShifts
                ? new Date(workShifts.entryInterval)
                : new Date(rdoDate)
            }
            maxDate={
              workShifts
                ? new Date(workShifts.entryInterval)
                : new Date(rdoDate)
            }
            time={true}
            showTimeOnly={true}
            error={!!errors.intervalBeginning}
            errorMessage={errors.intervalBeginning?.message}
            control={control}
            name="intervalBeginning"
            label={translate('intervalBeginning')}
          />
          <DatepickerInput
            placeholder={translate('intervalEnding')}
            enableMinMaxDateSelection={true}
            minDate={
              workShifts ? new Date(workShifts.exitInterval) : new Date(rdoDate)
            }
            maxDate={
              workShifts ? new Date(workShifts.exitInterval) : new Date(rdoDate)
            }
            time={true}
            showTimeOnly={true}
            error={!!errors.intervalEnding}
            errorMessage={errors.intervalEnding?.message}
            control={control}
            name="intervalEnding"
            label={translate('intervalEnding')}
          />
        </HolderDepartureTime>
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={translate('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={translate('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitWorkSchedule)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddWorkScheduleAtoForm;
