import { useLazyQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import {
  ListPiezometersByStructureDocument,
  ListPiezometersByStructureQuery,
  ListPiezometersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listPiezometersByStructure.query';
import {
  ListPluviometersByStructureDocument,
  ListPluviometersByStructureQuery,
  ListPluviometersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listPluviometersByStructure.query';
import {
  ListSurfaceMarkersByStructureDocument,
  ListSurfaceMarkersByStructureQuery,
  ListSurfaceMarkersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';
import {
  ListWaterLevelByStructureDocument,
  ListWaterLevelByStructureQuery,
  ListWaterLevelByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import Button from '../../Atoms/Button/Button';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import {
  DivContainerButtons,
  DivContainerForm,
  DivInputs,
  MainContainer
} from './FilterReadingsPage.styles';
import InputText from '../../Molecules/InputText/InputText';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';

const FilterFormType = z
  .object({
    instrumentId: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    type: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    status: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    name: z.string().optional(),
    startDate: z.date().nullable().optional(),
    endDate: z.date().nullable().optional()
  })
  .superRefine((data, ctx) => {
    const { startDate, endDate } = data;
    if (startDate && endDate && startDate > endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'validationDate',
        path: ['startDate']
      });
    }

    if (startDate && !endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'NeedStartDateAndEndDate',
        path: ['endDate']
      });
    }

    if (!startDate && endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'NeedStartDateAndEndDate',
        path: ['startDate']
      });
    }
  });

type FilterFormValues = z.infer<typeof FilterFormType>;

const FilterReadingsPage = () => {
  const { structureId, instrumentType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t: translate } = useTranslation();

  const [listInstruments, setListInstruments] = useState<OptionType[]>([]);

  const status: OptionType[] = [
    { label: translate('active'), value: InstrumentStatusEnum.Active },
    { label: translate('inactive'), value: InstrumentStatusEnum.Inactive }
  ];

  const type: OptionType[] = [
    {
      label: translate('Automatic'),
      value: InstrumentReadingTypeEnum.Automatic
    },
    { label: translate('Manual'), value: InstrumentReadingTypeEnum.Manual }
  ];

  const [listPiezometer] = useLazyQuery<
    ListPiezometersByStructureQuery,
    ListPiezometersByStructureQueryVariables
  >(ListPiezometersByStructureDocument);

  const [listPluviometer] = useLazyQuery<
    ListPluviometersByStructureQuery,
    ListPluviometersByStructureQueryVariables
  >(ListPluviometersByStructureDocument);

  const [listWaterLevel] = useLazyQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument);

  const [listSurfaceMarker] = useLazyQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument);

  useEffect(() => {
    if (instrumentType === InstrumentTypeEnum.Piezometer.toLowerCase()) {
      listPiezometer({
        variables: {
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: null
          }
        },
        onCompleted: (data) => {
          const piezometers = data?.listPiezometersByStructure.filter(
            (item) => item.type.instrumentType === 'Piezometer'
          );
          const piezometersData =
            piezometers?.map((item) => ({
              label: item.name,
              value: item.id
            })) || [];

          setListInstruments(piezometersData);
        }
      });
    } else if (instrumentType === InstrumentTypeEnum.Ina.toLowerCase()) {
      listPiezometer({
        variables: {
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: null
          }
        },
        onCompleted: (data) => {
          const ina = data?.listPiezometersByStructure.filter(
            (item) => item.type.instrumentType === 'Ina'
          );
          const inaData =
            ina?.map((item) => ({
              label: item.name,
              value: item.id
            })) || [];

          setListInstruments(inaData);
        }
      });
    } else if (
      instrumentType === InstrumentTypeEnum.Pluviometer.toLowerCase()
    ) {
      listPluviometer({
        variables: {
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: null
          }
        },
        onCompleted: (data) => {
          const pluviometer =
            data?.listPluviometersByStructure.map((item) => ({
              label: item.name,
              value: item.id ?? ''
            })) || [];

          setListInstruments(pluviometer);
        }
      });
    } else if (instrumentType === InstrumentTypeEnum.WaterLevel.toLowerCase()) {
      listWaterLevel({
        variables: {
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: null
          }
        },
        onCompleted: (data) => {
          const waterLevel =
            data?.listWaterLevelByStructure.map((item) => ({
              label: item.name,
              value: item.id ?? ''
            })) || [];

          setListInstruments(waterLevel);
        }
      });
    } else if (
      instrumentType === InstrumentTypeEnum.SurfaceMarker.toLowerCase()
    ) {
      listSurfaceMarker({
        variables: {
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: null
          }
        },
        onCompleted: (data) => {
          const surfaceMarker =
            data?.listSurfaceMarkersByStructure.map((item) => ({
              label: item.name,
              value: item.id ?? ''
            })) || [];

          setListInstruments(surfaceMarker);
        }
      });
    }
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType),
    defaultValues: {
      instrumentId: searchParams.get('instrumentId')
        ? {
            value: searchParams.get('instrumentId')!,
            label: searchParams.get('instrumentId')!
          }
        : undefined,
      type: searchParams.get('type')
        ? {
            value: searchParams.get('type')!,
            label: searchParams.get('type')!
          }
        : undefined,
      status: searchParams.get('status')
        ? {
            value: searchParams.get('status')!,
            label: searchParams.get('status')!
          }
        : undefined,
      name: searchParams.get('name') ?? '',
      startDate: searchParams.get('startDate')
        ? new Date(searchParams.get('startDate')!)
        : null,
      endDate: searchParams.get('endDate')
        ? new Date(searchParams.get('endDate')!)
        : null
    }
  });

  const handleFilterSubmit = (data: FilterFormValues) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Date) {
          newSearchParams.set(key, value.toISOString());
        } else if (typeof value === 'object' && value !== null) {
          newSearchParams.set(key, value.value);
        } else {
          newSearchParams.set(key, value.toString());
        }
      } else {
        newSearchParams.delete(key);
      }
    });

    newSearchParams.delete('filter');
    setSearchParams(newSearchParams);
  };

  const handleClearFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('instrumentId');
    newSearchParams.delete('type');
    newSearchParams.delete('status');

    setSearchParams(newSearchParams);
    setValue('instrumentId', { value: '', label: '' });
    setValue('type', { value: '', label: '' });
    setValue('status', { value: '', label: '' });
  };

  return (
    <MainContainer>
      <DivContainerForm
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSubmit(handleFilterSubmit)();
          }
        }}
      >
        <DivInputs>
          <InputSelectSearch
            errorMessage={errors.instrumentId?.message}
            label={translate('Instrument')}
            name="instrumentId"
            placeholder={translate('Instrument')}
            options={listInstruments}
            width="300px"
            control={control}
            error={!!errors.instrumentId}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            errorMessage={errors.type?.message}
            label={translate('typeOfReading')}
            placeholder={translate('typeOfReading')}
            name="type"
            options={type}
            width="300px"
            control={control}
            error={!!errors.type}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            errorMessage={errors.status?.message}
            label={translate('Status')}
            placeholder={translate('Status')}
            name="status"
            options={status}
            width="300px"
            control={control}
            error={!!errors.status}
          />
        </DivInputs>
        <DivInputs>
          <InputText
            type="text"
            label={translate('name')}
            name="name"
            control={control}
            error={!!errors.name}
            errorMessage={errors.name?.message}
          />
        </DivInputs>
        <DivInputs>
          <DatepickerInput
            name="startDate"
            label={translate('startDate')}
            placeholder={translate('startDate')}
            control={control}
            error={!!errors.startDate}
            errorMessage={errors.startDate?.message}
            time={false}
          />
        </DivInputs>
        <DivInputs>
          <DatepickerInput
            name="endDate"
            label={translate('EndDate')}
            placeholder={translate('EndDate')}
            control={control}
            error={!!errors.endDate}
            errorMessage={errors.endDate?.message}
            time={false}
          />
        </DivInputs>
      </DivContainerForm>
      <DivContainerButtons>
        <Button
          variant="secondary"
          size="medium"
          text={translate('cleanFilers')}
          onClick={handleClearFilters}
        />
        <Button
          variant="primary"
          size="medium"
          text={translate('Filtrar')}
          onClick={handleSubmit(handleFilterSubmit)}
        />
      </DivContainerButtons>
    </MainContainer>
  );
};

export default FilterReadingsPage;
