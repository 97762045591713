import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import * as z from 'zod';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { ActionPlanPriorityEnum } from '../../../../data/graphql/base-schema';
import {
  AssociateActionPlansDocument,
  AssociateActionPlansMutation,
  AssociateActionPlansMutationVariables
} from '../../../../data/graphql/generated/associateActionPlans.mutation';
import {
  UpdateActionPlanDocument,
  UpdateActionPlanMutation,
  UpdateActionPlanMutationVariables
} from '../../../../data/graphql/generated/updateActionPlan.mutation';
import {
  FindAllActionPlansDocument,
  FindAllActionPlansQuery,
  FindAllActionPlansQueryVariables
} from '../../../../data/graphql/query/generated/findAllActionPlans.query';
import { FindActionPlanAllInfoDocument } from '../../../../data/services/ActionPlanService';
import { API_URL } from '../../../../utils/const';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { PictureGallery } from '../../Molecules/PictureGallery/PictureGallery';
import ActionPlanApprovalFlow from '../../Organisms/ActionPlanApprovalFlow/ActionPlanApprovalFlow';
import ActionPlanComments from '../../Organisms/ActionPlanComments/ActionPlanComments';
import ActionPlanReprovalFlow from '../../Organisms/ActionPlanReprovalFlow/ActionPlanReprovalFlow';
import FourStepsTemplate from '../../Templates/FourStepsTemplate/FourStepsTemplate';
import { OptionItem } from './ActionPlanGeneratedPage.interface';
import {
  DivButtonApproveFlow,
  DivButtonHeader,
  DivButtonsPictures,
  DivContentImageAndDescription,
  DivContainerInputs,
  DivSaveButton,
  CardImages,
  CardDescription,
  HolderCardsTitle,
  CardInputs,
  HolderInputs,
  DivCommentsSection,
  CardSendComment,
  HolderComment,
  DivContainerLink
} from './ActionPlanGeneratedPage.styles';
import {
  FindActionPlanDataDocument,
  FindActionPlanDataQuery,
  FindActionPlanDataQueryVariables
} from '../../../../data/graphql/query/generated/findActionPlanData.query';
import {
  FindActionPlanResponsibleDocument,
  FindActionPlanResponsibleQuery,
  FindActionPlanResponsibleQueryVariables
} from '../../../../data/graphql/query/generated/findActionPlanResponsible.query';
import Enums from '../../../../utils/enumns';
import { UserData } from '../../../../@Types/types';
import { GroupText } from '../../Molecules/GroupText/GroupText';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const ActionPlanGeneratedPage = () => {
  const { t: translate } = useTranslation();
  const { structureId, actionPlanId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const navigate = useNavigate();

  const { getUserRoleByModule, userData } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
    userData: UserData;
  };
  const userRoleInModule = getUserRoleByModule('ActionPlan');
  const { UserRoles } = Enums();

  const [isReproval, setIsReproval] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [approveData, setApproveData] = useState({
    timeFrame: new Date(),
    responsibleId: '',
    priority: ActionPlanPriorityEnum.Low
  });

  const [getActionPlanData, { data: actionPlanData, loading }] = useLazyQuery<
    FindActionPlanDataQuery,
    FindActionPlanDataQueryVariables
  >(FindActionPlanDataDocument);

  const [getActionPlanResponsible, { data: actionPlanResponsible }] =
    useLazyQuery<
      FindActionPlanResponsibleQuery,
      FindActionPlanResponsibleQueryVariables
    >(FindActionPlanResponsibleDocument);

  useEffect(() => {
    if (userRoleInModule === UserRoles.Admin) {
      getActionPlanResponsible({
        variables: {
          structureId: structureId!
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
    }
  }, [userRoleInModule]);

  const [updateActionPlan] = useMutation<
    UpdateActionPlanMutation,
    UpdateActionPlanMutationVariables
  >(UpdateActionPlanDocument);

  const { data: actionPlansData } = useQuery<
    FindAllActionPlansQuery,
    FindAllActionPlansQueryVariables
  >(FindAllActionPlansDocument, {
    variables: {
      structureId: structureId!
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });

  const [associateActionPlans] = useMutation<
    AssociateActionPlansMutation,
    AssociateActionPlansMutationVariables
  >(AssociateActionPlansDocument);

  const associatedActionPlans: OptionItem[] =
    actionPlansData?.findAllActionPlans.map((actionPlan) => ({
      label: actionPlan.code,
      value: actionPlan.id
    })) || [];

  const urlsPicturesData = actionPlanData?.findActionPlanData.images.map(
    (url) => ({
      url: `${API_URL}/${url}`,
      name: url,
      id: url,
      file: undefined
    })
  );

  const userResponsible =
    actionPlanData?.findActionPlanData.responsible?.id === userData.id;

  const userTeamMenber = actionPlanData?.findActionPlanData.teamMembers.some(
    (teamMember) => teamMember.id === userData.id
  );

  const actionPlanConcluded =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'done';

  const actionPlanProgess =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'progress';

  const actionPlanLate =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'late';

  const actionPlanCancelled =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'canceled';

  const actionPlanReproved =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'reproved';

  const actionPlanOpen =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'opened';

  const actionPlanReview =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'review';

  const actionPlanSchema = z.object({
    priorities: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val?.value, {
        message: translate('fieldIsRequired')
      }),
    timeFrame: z
      .date()
      .nullable()
      .refine((val) => val, {
        message: translate('fieldIsRequired')
      }),
    responsible: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val?.value, {
        message: translate('fieldIsRequired')
      })
  });

  type actionPlanSchemaType = z.infer<typeof actionPlanSchema>;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<actionPlanSchemaType>({
    resolver: zodResolver(actionPlanSchema),
    defaultValues: async () => {
      const responseActionPlanData = await getActionPlanData({
        variables: {
          actionPlanId: actionPlanId!
        }
      });

      if (
        !responseActionPlanData.data ||
        actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
          'opened'
      ) {
        return {
          priorities: {
            value: '',
            label: ''
          },
          timeFrame: null,
          responsible: {
            value: '',
            label: ''
          }
        };
      }

      return {
        priorities: {
          value: responseActionPlanData.data.findActionPlanData.priority || '',
          label: translate(
            responseActionPlanData.data.findActionPlanData.priority || ''
          )
        },
        timeFrame: new Date(
          responseActionPlanData.data.findActionPlanData.timeFrame || new Date()
        ),
        responsible: {
          value:
            responseActionPlanData.data?.findActionPlanData?.responsible?.id ||
            '',
          label:
            responseActionPlanData.data?.findActionPlanData?.responsible
              ?.name || ''
        }
      };
    }
  });

  const linkSchema = z.object({
    link: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      })
  });

  type linkSchemaType = z.infer<typeof linkSchema>;

  const {
    handleSubmit: handleSubmitLink,
    control: controlLink,
    formState: { errors: errorsLink }
  } = useForm<linkSchemaType>({
    resolver: zodResolver(linkSchema)
  });

  const priorityOptions = [
    {
      label: translate('High'),
      value: 'High'
    },
    {
      label: translate('Medium'),
      value: 'Medium'
    },
    {
      label: translate('Low'),
      value: 'Low'
    }
  ];

  const selectResponsibleData: OptionItem[] =
    actionPlanResponsible?.findActionPlanResponsible.map((responsible) => ({
      label: responsible.name,
      value: responsible.id
    })) ?? [];

  const handleSaveFlow = (data: actionPlanSchemaType) => {
    if (userRoleInModule === UserRoles.Admin) {
      updateActionPlan({
        variables: {
          data: {
            actionPlanId: actionPlanId ?? '',
            priority: data.priorities!.value as ActionPlanPriorityEnum,
            timeFrame: data.timeFrame!,
            responsibleId: data.responsible!.value
          }
        },
        onCompleted: () => {
          toastfySuccess(
            `${translate('Action plan')} ${translate('editedSuccessfully')}`
          );
        },
        onError: (error) => {
          parseErrorMessage(error);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindActionPlanDataDocument,
            variables: {
              actionPlanId: actionPlanId ?? ''
            }
          }) as FindActionPlanDataQuery | undefined;
          const updatedData = {
            ...existingData,
            findActionPlanData: {
              ...existingData?.findActionPlanData,
              priority: data?.updateActionPlan
            }
          };
          cache.writeQuery({
            query: FindActionPlanDataDocument,
            variables: {
              actionPlanId: actionPlanId ?? ''
            },
            data: updatedData
          });
        }
      });
    }
  };

  const handleSaveAssociatedActionPlan = (data: linkSchemaType) => {
    associateActionPlans({
      variables: {
        parentId: data.link.value,
        actionPlanId: actionPlanId ?? ''
      },
      onCompleted: () => {
        toastfySuccess(
          `${translate('Action plan')} ${translate('associatedSuccessfully')}`
        );
        navigate(`/${structureId}/actionplan/`);
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      refetchQueries: [
        {
          query: FindActionPlanAllInfoDocument,
          variables: {
            structureId: structureId!,
            pageInfo: {
              limit: 10,
              page: 1
            },
            filters: {
              code: null,
              priority: null,
              responsibleName: null,
              situation: null,
              timeFramePeriod: null,
              type: null
            },
            sortInfo: [
              {
                direction: null,
                field: ''
              }
            ]
          }
        }
      ],
      awaitRefetchQueries: true
    });
  };

  const handleNavigateBack = () => {
    navigate(`/${structureId}/actionplan`);
  };

  const handleApproveFlow = (data: actionPlanSchemaType) => {
    setIsApproval(true);
    setApproveData({
      timeFrame: data.timeFrame!,
      responsibleId: data.responsible!.value,
      priority: data.priorities!.value as ActionPlanPriorityEnum
    });
  };

  return (
    <>
      <FourStepsTemplate
        loading={loading}
        icon={
          <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
        }
        title={`${actionPlanData?.findActionPlanData.code}
        - ${translate(actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase())}`}
        button={
          userRoleInModule === UserRoles.Admin || userResponsible ? (
            <DivButtonHeader>
              {!actionPlanOpen && (
                <Button
                  variant={'secondary'}
                  text={translate('team')}
                  size="small"
                  onClick={() =>
                    navigate(`/${structureId}/actionplan/${actionPlanId}/team`)
                  }
                />
              )}
              <Button
                variant={'secondary'}
                text={translate('history')}
                size="small"
                onClick={() =>
                  navigate(`/${structureId}/actionplan/${actionPlanId}/history`)
                }
              />
            </DivButtonHeader>
          ) : (
            <></>
          )
        }
        contentPictures={
          <DivContentImageAndDescription>
            <CardImages>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('picturesOfAnomaly')}</Text>
              </HolderCardsTitle>

              <PictureGallery
                imageWidth="80%"
                files={urlsPicturesData ?? []}
                imagesToShow={3}
              />
              {actionPlanData &&
                actionPlanData?.findActionPlanData.imagesCount > 3 && (
                  <DivButtonsPictures>
                    <Button
                      variant="secondary"
                      text={translate('seeAll')}
                      size="small"
                      onClick={() =>
                        navigate(
                          `/${structureId}/actionplan/${actionPlanId}/images`
                        )
                      }
                    />
                  </DivButtonsPictures>
                )}
            </CardImages>
            <CardDescription>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('description')}</Text>
              </HolderCardsTitle>
              <Text type={'span'} color="black">
                {actionPlanData?.findActionPlanData.description}
              </Text>
            </CardDescription>
          </DivContentImageAndDescription>
        }
        inputs={
          <DivContainerInputs>
            <CardInputs>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('actionPlanManagement')}</Text>
              </HolderCardsTitle>
              {userRoleInModule === UserRoles.Admin ? (
                <HolderInputs>
                  <InputSelectSearch
                    errorMessage={errors.responsible?.message}
                    label={translate('responsible')}
                    placeholder={translate('select')}
                    name="responsible"
                    options={selectResponsibleData}
                    width="300px"
                    error={!!errors.responsible}
                    control={control}
                    disabled={
                      actionPlanConcluded ||
                      actionPlanCancelled ||
                      actionPlanReproved ||
                      actionPlanReview ||
                      userRoleInModule !== UserRoles.Admin
                    }
                  />
                  <InputSelectSearch
                    errorMessage={errors.priorities?.message}
                    label={translate('priorities')}
                    placeholder={translate('select')}
                    name="priorities"
                    options={priorityOptions}
                    width="300px"
                    error={!!errors.priorities}
                    control={control}
                    disabled={
                      actionPlanConcluded ||
                      actionPlanCancelled ||
                      actionPlanReproved ||
                      actionPlanReview ||
                      userRoleInModule !== UserRoles.Admin
                    }
                  />
                  <DatepickerInput
                    label={translate('timeFrame')}
                    placeholder={translate('select')}
                    error={!!errors.timeFrame}
                    errorMessage={errors.timeFrame?.message}
                    name="timeFrame"
                    control={control}
                    time={false}
                    disabled={
                      actionPlanConcluded ||
                      actionPlanCancelled ||
                      actionPlanReproved ||
                      actionPlanReview ||
                      userRoleInModule !== UserRoles.Admin
                    }
                  />
                </HolderInputs>
              ) : (
                <HolderInputs>
                  <GroupText
                    title={translate('responsible')}
                    value={
                      actionPlanData?.findActionPlanData.responsible?.name ??
                      translate('NoDataAvailable')
                    }
                  />
                  <GroupText
                    title={translate('priorities')}
                    value={translate(
                      actionPlanData?.findActionPlanData.priority ??
                        translate('NoDataAvailable')
                    )}
                  />
                  <GroupText
                    title={translate('timeFrame')}
                    value={
                      actionPlanData?.findActionPlanData.timeFrame
                        ? new Date(
                            actionPlanData?.findActionPlanData.timeFrame
                          ).toLocaleDateString()
                        : translate('NoDataAvailable')
                    }
                  />
                </HolderInputs>
              )}
              <DivSaveButton>
                {actionPlanConcluded ||
                actionPlanCancelled ||
                actionPlanReproved ||
                actionPlanReview ||
                actionPlanOpen ||
                userRoleInModule !== UserRoles.Admin ? (
                  <></>
                ) : (
                  <Button
                    variant={'primary'}
                    text="Save"
                    size="small"
                    onClick={handleSubmit(handleSaveFlow)}
                  />
                )}
              </DivSaveButton>
            </CardInputs>
            {userRoleInModule === UserRoles.Admin && actionPlanOpen && (
              <DivContainerLink>
                <InputSelectSearch
                  errorMessage={errorsLink.link?.message}
                  label={translate('link')}
                  placeholder={translate('select')}
                  name="link"
                  options={associatedActionPlans}
                  width="300px"
                  error={!!errorsLink.link}
                  control={controlLink}
                />
                <Button
                  variant={'primary'}
                  text="link"
                  size="small"
                  onClick={handleSubmitLink(handleSaveAssociatedActionPlan)}
                />
              </DivContainerLink>
            )}
          </DivContainerInputs>
        }
        commentContent={
          <DivCommentsSection>
            <CardSendComment>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('actionPlanComments')}</Text>
              </HolderCardsTitle>
              <HolderComment>
                <ActionPlanComments
                  disabled={
                    actionPlanConcluded ||
                    actionPlanCancelled ||
                    (!userResponsible &&
                      !userTeamMenber &&
                      userRoleInModule !== UserRoles.Admin)
                  }
                  actionPlanData={actionPlanData}
                />
              </HolderComment>
            </CardSendComment>
          </DivCommentsSection>
        }
        buttons={
          actionPlanConcluded ||
          actionPlanCancelled ||
          userRoleInModule === UserRoles.Viewer ? (
            <></>
          ) : (
            <>
              {userRoleInModule === UserRoles.Admin &&
                (actionPlanReview || actionPlanOpen) && (
                  <DivButtonApproveFlow>
                    <Button
                      variant="primary"
                      text="approve"
                      size="small"
                      onClick={handleSubmit(handleApproveFlow)}
                    />
                    <Button
                      variant="danger"
                      text="reprove"
                      size="small"
                      onClick={() => setIsReproval(true)}
                    />
                  </DivButtonApproveFlow>
                )}

              {isApproval && (
                <ActionPlanApprovalFlow
                  setIsApproval={setIsApproval}
                  situation={actionPlanData?.findActionPlanData.situation ?? ''}
                  timeFrame={approveData?.timeFrame ?? new Date().toISOString()}
                  responsibleId={approveData?.responsibleId ?? ''}
                  priority={approveData?.priority ?? ' '}
                />
              )}

              {isReproval && (
                <ActionPlanReprovalFlow
                  setIsReproval={setIsReproval}
                  situation={actionPlanData?.findActionPlanData.situation ?? ''}
                />
              )}
              {(actionPlanProgess || actionPlanLate || actionPlanReproved) &&
                userResponsible && (
                  <Button
                    variant="primary"
                    text="requestApproval"
                    size="medium"
                    onClick={() => setIsApproval(true)}
                  />
                )}
            </>
          )
        }
      />
    </>
  );
};

export default ActionPlanGeneratedPage;
