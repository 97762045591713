import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface InputStyledProps {
  $hasValue: boolean;
}

export const InputContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? `max(${width}, 100%)` : 'max(300px, 100%)')};
`;

export const InputStyled = styled.div<InputStyledProps>`
  width: 100%;
  position: relative;
  label {
    position: absolute;
    top: ${({ $hasValue }) => ($hasValue ? '-15px' : '10px')};
    left: 5px;
    pointer-events: none;
    transition: all 0.2s ease;
  }
  input:focus ~ label {
    top: -15px;
  }
`;

export const ErrorStyled = styled.span`
  position: absolute;
  left: 5px;
  font-size: ${FontSize.Xsmall};
  color: ${Color.Red1};
`;
