import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  CreateSurfaceMarkersReadingDocument,
  CreateSurfaceMarkersReadingMutation,
  CreateSurfaceMarkersReadingMutationVariables
} from '../../../../data/graphql/generated/createSurfaceMarkerReading.mutation';
import { ListReadingBySurfaceMarkersPagDocument } from '../../../../data/graphql/query/generated/listReadingBySurfaceMarkersPag.query';
import {
  ListSurfaceMarkersByStructureDocument,
  ListSurfaceMarkersByStructureQuery,
  ListSurfaceMarkersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';
import { ONE_MEGABYTE } from '../../../../utils/const';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import {
  BathReadingsValues,
  CSVRow,
  DataItem,
  RegisterSurfaceMarkerBathReadingsProps
} from './RegisterSurfaceMarkerBathReadings.interface';
import {
  DivButtons,
  DivContainer
} from './RegisterSurfaceMarkerBathReadings.styles';

const bathReadingsSchema = z.object({
  instruments: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  files: z
    .array(z.instanceof(File), { message: 'fieldIsRequired' })
    .refine((val) => val.every((file) => file.size <= 5 * ONE_MEGABYTE), {
      message: 'veryBigFile'
    })
});

type bathReadingsSchemaType = z.infer<typeof bathReadingsSchema>;

const RegisterSurfaceMarkerBathReadings = ({
  onClose
}: RegisterSurfaceMarkerBathReadingsProps) => {
  const { structureId } = useParams();
  const { t: translate } = useTranslation();

  const [uploadedData, setUploadedData] = useState<any | null>(null);
  const [files, setFiles] = useState<FileType[]>([]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<bathReadingsSchemaType>({
    resolver: zodResolver(bathReadingsSchema)
  });

  const definedHeaders = [
    'date',
    'coordinatee',
    'coordinaten',
    'elevation',
    'observation'
  ];

  const [createReading] = useMutation<
    CreateSurfaceMarkersReadingMutation,
    CreateSurfaceMarkersReadingMutationVariables
  >(CreateSurfaceMarkersReadingDocument);

  const { data: listSurfaceMarkers } = useQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!,
        associatedStructureId: null
      }
    }
  });

  const instrumentsData = listSurfaceMarkers?.listSurfaceMarkersByStructure.map(
    (item) => ({
      label: item.name || '',
      value: item.id || ''
    })
  );

  async function handleSaveSurfaceMarker(
    bathReadingsFormData: bathReadingsSchemaType
  ) {
    const hasNonEmptyValues = (item: DataItem): boolean =>
      Object.values(item).some((value) => value?.length > 0);

    const truncateDate = (dateStr: string | undefined): string | undefined =>
      dateStr && dateStr.length === 29 ? dateStr.substring(0, 19) : dateStr;

    const filteredData = uploadedData.filter(hasNonEmptyValues);

    const preparedData = filteredData.map((item: BathReadingsValues) => ({
      ...item,
      date: truncateDate(item.date)
    }));

    const readingsData = preparedData.map((item: DataItem) => ({
      date: item.date.replace(/^["\\]|["\\]$/g, ''),
      instrumentId: bathReadingsFormData.instruments?.value ?? null,
      instrumentName: bathReadingsFormData.instruments?.label ?? '',
      observation: item.observation,
      coordinateE: Number(item.coordinateE),
      coordinateN: Number(item.coordinateN),
      elevation: Number(item.elevation)
    }));

    createReading({
      variables: {
        structureInfo: {
          structureId: structureId!,
          associatedStructureId: null
        },
        data: readingsData
      },
      onCompleted: (response) => {
        if (!response.createSurfaceMarkersReading[0]?.error) {
          toastfySuccess(translate('successRegisterReading'));
          onClose(false);
        } else {
          toastfyError(
            translate(response.createSurfaceMarkersReading[0]?.error || '')
          );
          onClose(false);
        }
      },
      onError: ({ message }) => {
        if (message) return toastfyError(message);
      },
      refetchQueries: [ListReadingBySurfaceMarkersPagDocument],
      awaitRefetchQueries: true
    });
  }

  const handleDownload = async () => {
    try {
      const fileUrl = '/Excel/registerSurfaceMarkersReadings.csv';
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileName = 'registerSurfaceMarkersReadings.csv';
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastfyError(ToastifyModel().toastifyMessage.error);
    }
  };

  const handleFileChange = (files: File[]) => {
    const file = files[0];
    setFiles([
      {
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      }
    ]);
    setValue('files', [file]);

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) =>
      processCSV(event.target?.result as string);
    reader.onerror = () => toastfyError(ToastifyModel().toastifyMessage.error);
    reader.readAsText(file);
  };

  const processCSV = (csvContent: string) => {
    const rows = csvContent
      .split('\n')
      .map((row) => row.trim())
      .filter(Boolean);
    const headers = parseHeaders(rows[0]);

    if (!validateHeaders(headers)) {
      toastfyError(ToastifyModel().toastifyMessage.error);
      return;
    }

    const data = rows
      .slice(1)
      .map((row) => parseRow(row, headers))
      .filter(Boolean);
    setUploadedData(data as CSVRow[]);
  };

  const parseHeaders = (headerRow: string): string[] =>
    headerRow.split(';').map((header) => header.trim().toLowerCase());

  const validateHeaders = (headers: string[]): boolean =>
    definedHeaders.every((requiredHeader) => headers.includes(requiredHeader));

  const parseRow = (row: string, headers: string[]): CSVRow | null => {
    const values = row.split(';').map((value) => value.trim());
    const rowObj: CSVRow = {};

    headers.forEach((header, index) => {
      if (definedHeaders.includes(header)) {
        rowObj[header] = values[index] || '';
      }
    });

    return Object.keys(rowObj).length > 0 ? rowObj : null;
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  return (
    <DivContainer>
      <InputSelectSearch
        errorMessage={errors.instruments?.message}
        label={translate('Instruments')}
        name="instruments"
        placeholder={translate('Instrument')}
        options={instrumentsData ?? []}
        width="300px"
        error={!!errors.instruments}
        control={control}
      />
      <Button
        variant={'secondary'}
        icon={MdDownload}
        text={`${translate('download')} ${translate('template')}`}
        onClick={handleDownload}
      />
      <InputFileWithList
        register={register}
        name="files"
        errorMessage={errors.files?.message}
        error={!!errors.files}
        label=""
        multiple={false}
        files={files}
        onChange={(event) => {
          if (!event.target.files || event.target.files.length === 0) return;
          handleFileChange(Array.from(event.target.files));
        }}
        onDelete={(file: FileType) => handleDeleteImage(file)}
        accept={'.csv'}
      />
      <DivButtons>
        <Button
          variant={'secondary'}
          text={translate('cancel')}
          size="small"
          onClick={() => onClose(false)}
        />
        <Button
          variant={'primary'}
          text={translate('Send')}
          size="small"
          onClick={handleSubmit(handleSaveSurfaceMarker)}
        />
      </DivButtons>
    </DivContainer>
  );
};

export default RegisterSurfaceMarkerBathReadings;
