import { IoIosClose } from 'react-icons/io';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { ViewModalProps } from './ViewModal.interface';
import {
  DivContainerViewModalBody,
  DivModalContainer,
  DivViewModal,
  DivViewModalContent,
  DivViewModalHeader
} from './ViewModal.styles';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

const ViewModal = ({
  width,
  height,
  title,
  onClose,
  style,
  component,
  showModal,
  newPortal
}: ViewModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [showModal]);

  const modalContent = (
    <DivModalContainer
      $showModal={showModal}
      tabIndex={0}
      ref={ref}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
          onClose();
        }
      }}
    >
      <DivViewModal
        style={style}
        width={width}
        height={height}
        $showModal={showModal}
      >
        <DivViewModalContent>
          <DivViewModalHeader>
            <Text type="h4" size="medium">
              {translate(title)}
            </Text>
            <Icon
              Icon={IoIosClose}
              variant="large"
              onClick={() => {
                onClose();
              }}
            />
          </DivViewModalHeader>
          <DivContainerViewModalBody $showModal={showModal}>
            {showModal && component}
          </DivContainerViewModalBody>
        </DivViewModalContent>
      </DivViewModal>
    </DivModalContainer>
  );

  return newPortal
    ? ReactDOM.createPortal(modalContent, document.body)
    : modalContent;
};

export default ViewModal;
