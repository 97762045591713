import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ListSectionsByStructureDocument,
  ListSectionsByStructureQuery,
  ListSectionsByStructureQueryVariables
} from '../../../../../data/graphql/query/generated/listSectionsByStructure.query';
import Button from '../../../Atoms/Button/Button';
import Text from '../../../Atoms/Text/Text';
import DatepickerInput from '../../../Molecules/DatepickerInput/DatepickerInput';
import SimpleContentTemplate from '../../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import {
  ContentPage,
  DivButton,
  DivGraph,
  DivGraphNotGenerated,
  DivInputs,
  HolderForm,
  MainContainer
} from './StabilityPage.styles';
import { z } from 'zod';
import InputSelectSearch from '../../../Molecules/InputSelectSearch/InputSelectSearch';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputText from '../../../Molecules/InputText/InputText';
import {
  FindStructureMaterialsQuery,
  FindStructureMaterialsQueryVariables,
  FindStructureMaterialsDocument
} from '../../../../../data/graphql/query/generated/findStructureMaterials';
import {
  CalculationMethodEnum,
  FailureDirectionEnum,
  GenerateStabilityAnalysisGraphInput,
  GraphParamsInput,
  InstrumentTypeEnum,
  NonCircularSearchMethodEnum,
  SurfaceTypeEnum
} from '../../../../../data/graphql/base-schema';
import {
  GenerateStabilityAnalysisGraphMutation,
  GenerateStabilityAnalysisGraphMutationVariables,
  GenerateStabilityAnalysisGraphDocument
} from '../../../../../data/graphql/generated/generateStabilityAnalysisGraph';
import { toastfySuccess } from '../../../../Toastify';
import {
  FindSectionInstrumentsQuery,
  FindSectionInstrumentsQueryVariables,
  FindSectionInstrumentsDocument
} from '../../../../../data/graphql/query/generated/findSectionInstruments';
import { OptionType } from '../../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import useErrorsTreatment from '../../../../../Hooks/useErrorsTreatment';
import { useContext, useState } from 'react';
import { ProjectContext } from '../../../../../Context/ContextAPI';
import Enums from '../../../../../utils/enumns';
import ViewModal from '../../../Molecules/ViewModal/ViewModal';
import MaterialsForm from '../../../Organisms/StabilityAnalysis/MaterialsForm/MaterialsForm';
import SlidesForm from '../../../Organisms/StabilityAnalysis/SlidesForm/SlidesForm';
import { FindAllStabilityAnalysisGraphicPagDocument } from '../../../../../data/graphql/query/generated/findAllStabilityAnalysisGraphicPag';
import {
  FindSectionSlideFileDocument,
  FindSectionSlideFileQuery,
  FindSectionSlideFileQueryVariables
} from '../../../../../data/graphql/query/generated/findSectionSlideFile';
import { BUCKET_URL } from '../../../../../utils/const';
import Image from '../../../Atoms/Image/Image';
import {
  FindLastGraphicBySectionDocument,
  FindLastGraphicBySectionQuery,
  FindLastGraphicBySectionQueryVariables
} from '../../../../../data/graphql/query/generated/findLastGraphicBySection.query';
import Icon from '../../../Atoms/Icon/Icon';
import { MdImageNotSupported } from 'react-icons/md';

const formAnalysisSchema = z.object({
  section: z.object({
    value: z.string(),
    label: z.string()
  }),
  failureDirection: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  calculationMethod: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  surfaceType: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  searchMethod: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  elevation: z.string().optional(),
  area: z.string().optional(),
  depth: z.string().optional(),
  weight: z.string().optional(),
  slides: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .optional()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  materials: z
    .array(
      z.object({
        value: z.string(),
        label: z.string()
      })
    )
    .optional()
    .nullable()
    .refine((val) => val && val.length > 0, {
      message: 'fieldIsRequired'
    }),
  instruments: z
    .array(
      z.object({
        value: z.string(),
        label: z.string()
      })
    )
    .min(1, { message: 'fieldIsRequired' }),
  datePeriod: z
    .date()
    .nullable()
    .optional()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }).refine((val) => val && val <= new Date(), {
      message: 'dateMustBeLessThanCurrentDate'
    })
});

type formAnalysisSchemaType = z.infer<typeof formAnalysisSchema>;

const StabilityPage = () => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const navigate = useNavigate();
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Stabilityanalysis');

  const [showModalMaterials, setShowModalMaterials] = useState(false);
  const [showModalSlides, setShowModalSlides] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm<formAnalysisSchemaType>({
    resolver: zodResolver(formAnalysisSchema),
    defaultValues: {
      failureDirection: {
        value: FailureDirectionEnum.leftToRight,
        label: translate('leftToRight')
      },
      calculationMethod: {
        value: CalculationMethodEnum.bishopSimplified,
        label: translate('bishopSimplified')
      },
      surfaceType: {
        value: SurfaceTypeEnum.nonCircular,
        label: translate('nonCircular')
      },
      searchMethod: {
        value: NonCircularSearchMethodEnum.cuckooSearch,
        label: translate('cuckooSearch')
      },
      datePeriod: undefined
    }
  });

  const [generateGraph] = useMutation<
    GenerateStabilityAnalysisGraphMutation,
    GenerateStabilityAnalysisGraphMutationVariables
  >(GenerateStabilityAnalysisGraphDocument);

  const { data: listSections, loading } = useQuery<
    ListSectionsByStructureQuery,
    ListSectionsByStructureQueryVariables
  >(ListSectionsByStructureDocument, {
    variables: {
      structureId: structureId!
    }
  });

  const [getInstruments, { data: lisInstruments }] = useLazyQuery<
    FindSectionInstrumentsQuery,
    FindSectionInstrumentsQueryVariables
  >(FindSectionInstrumentsDocument);

  const { data: listMaterials } = useQuery<
    FindStructureMaterialsQuery,
    FindStructureMaterialsQueryVariables
  >(FindStructureMaterialsDocument, {
    variables: {
      structureId: structureId!
    }
  });

  const [getSlideFile, { data: slideFile }] = useLazyQuery<
    FindSectionSlideFileQuery,
    FindSectionSlideFileQueryVariables
  >(FindSectionSlideFileDocument);

  const sectiosOptions = listSections?.listSectionsByStructure?.map(
    (section) => ({
      label: section.name,
      value: section.id
    })
  );

  const instrumentsOptions = lisInstruments?.findSectionInstruments?.map(
    (instrument) => ({
      label: instrument.instrumentName,
      value: instrument.id
    })
  );

  const slidesOptions = slideFile?.findSectionSlideFile
    ? [
        {
          label: slideFile.findSectionSlideFile.slideFileName,
          value: slideFile.findSectionSlideFile.id
        }
      ]
    : [];

  const materialsOptions = listMaterials?.findStructureMaterials?.map(
    (material) => ({
      label: material.materialName,
      value: material.id
    })
  );

  const { data: lastGraphic } = useQuery<
    FindLastGraphicBySectionQuery,
    FindLastGraphicBySectionQueryVariables
  >(FindLastGraphicBySectionDocument, {
    variables: {
      sectionId: watch('section')?.value
    }
  });

  const optionsFaluireDirection = [
    {
      value: FailureDirectionEnum.leftToRight,
      label: translate('leftToRight')
    },
    { value: FailureDirectionEnum.rightToLeft, label: translate('rightToLeft') }
  ];

  const optionsCalculationMethod = [
    {
      value: CalculationMethodEnum.bishopSimplified,
      label: translate('bishopSimplified')
    },
    {
      value: CalculationMethodEnum.corpsOfEngineers1,
      label: translate('corpsOfEngineers1')
    },
    {
      value: CalculationMethodEnum.corpsOfEngineers2,
      label: translate('corpsOfEngineers2')
    },
    {
      value: CalculationMethodEnum.gleMorgensternPrice,
      label: translate('gleMorgensternPrice')
    },
    {
      value: CalculationMethodEnum.janbuCorrected,
      label: translate('janbuCorrected')
    },
    {
      value: CalculationMethodEnum.janbuSimplified,
      label: translate('janbuSimplified')
    },
    {
      value: CalculationMethodEnum.loweKarafiath,
      label: translate('loweKarafiath')
    },
    {
      value: CalculationMethodEnum.ordinaryFellenius,
      label: translate('ordinaryFellenius')
    },
    { value: CalculationMethodEnum.sarma, label: translate('sarma') },
    { value: CalculationMethodEnum.spencer, label: translate('spencer') }
  ];

  const optionsSurfaceType = [
    {
      value: SurfaceTypeEnum.circular,
      label: translate('circular')
    },
    {
      value: SurfaceTypeEnum.nonCircular,
      label: translate('nonCircular')
    },
    {
      value: SurfaceTypeEnum.userDefined,
      label: translate('userDefined')
    }
  ];

  const optionsSearchMethod = [
    {
      value: NonCircularSearchMethodEnum.autoRefineSearch,
      label: translate('autoRefineSearch')
    },
    {
      value: NonCircularSearchMethodEnum.blockSearch,
      label: translate('blockSearch')
    },
    {
      value: NonCircularSearchMethodEnum.cuckooSearch,
      label: translate('cuckooSearch')
    },
    {
      value: NonCircularSearchMethodEnum.particleSwarmSearch,
      label: translate('particleSwarmSearch')
    },
    {
      value: NonCircularSearchMethodEnum.pathSearch,
      label: translate('pathSearch')
    },
    {
      value: NonCircularSearchMethodEnum.simulatedAnnealing,
      label: translate('simulatedAnnealing')
    }
  ];

  function toNumberOrNull(value?: string) {
    if (!value || value.trim() === '') {
      return null;
    }

    const parsed = parseFloat(value);
    return isNaN(parsed) ? null : parsed;
  }

  const handleSectionChange = (selectedSection: OptionType) => {
    getSlideFile({
      variables: { sectionId: selectedSection.value.toString() }
    });
    setValue('instruments', []);
    setValue('datePeriod', undefined);
    setValue('materials', []);
    setValue('slides', undefined);
    setValue('elevation', '');
    setValue('area', '');
    setValue('depth', '');
    setValue('weight', '');
  };

  const navigateTo = () => {
    navigate(`/${structureId}/stabilityanalysis/history`);
  };

  const handleChangeDate = (date: Date) => {
    getInstruments({
      variables: {
        data: {
          sectionId: watch('section').value,
          instrumentsType: [
            InstrumentTypeEnum.Piezometer,
            InstrumentTypeEnum.Ina
          ],
          readingDate: date
        }
      },
      nextFetchPolicy: 'no-cache'
    });
    setValue('instruments', []);
  };

  const handleGenerateGraph = (dataForm: formAnalysisSchemaType) => {
    const graphParams: GraphParamsInput = {
      failureDirection: dataForm.failureDirection
        ?.value as FailureDirectionEnum,
      calculationMethod: dataForm.calculationMethod
        ?.value as CalculationMethodEnum,
      surfaceType: dataForm.surfaceType?.value as SurfaceTypeEnum,
      searchMethod: dataForm.searchMethod?.value as NonCircularSearchMethodEnum,
      analysisDate: dataForm.datePeriod!,
      filter: {
        elevation: toNumberOrNull(dataForm.elevation),
        area: toNumberOrNull(dataForm.area),
        depth: toNumberOrNull(dataForm.depth),
        weight: toNumberOrNull(dataForm.weight)
      }
    };

    const preparedGenerateGraphData: GenerateStabilityAnalysisGraphInput = {
      sectionId: watch('section').value,
      materialList: dataForm.materials!.map((material) => material.value),
      instrumentsList: dataForm.instruments.map(
        (instrument) => instrument.value
      ),
      slideFileId: dataForm.slides!.value!,
      graphParams: graphParams
    };
    generateGraph({
      variables: {
        data: preparedGenerateGraphData
      },
      onCompleted: () => {
        toastfySuccess(translate('AnalysisRequestedSuccessfully'));
      },
      refetchQueries: [
        {
          query: FindAllStabilityAnalysisGraphicPagDocument,
          variables: {
            structureId: structureId!,
            filters: {
              analysisDate: undefined,
              graphicStatus: undefined,
              sectionName: undefined,
              slideFileName: undefined
            },
            pageInfo: {
              limit: 10,
              page: 1
            },
            sortInfo: []
          }
        }
      ],
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loading}
        title={<Text type="h1">{translate('Stability')}</Text>}
        button={
          <>
            {userRoleInModule === UserRoles.Admin && (
              <>
                <Button
                  variant="primary"
                  text={translate('materials')}
                  onClick={() => setShowModalMaterials(true)}
                />
                <Button
                  variant="primary"
                  text={translate('slides')}
                  onClick={() => setShowModalSlides(true)}
                />
              </>
            )}
            <Button
              variant="primary"
              text={translate('history')}
              onClick={navigateTo}
            />
          </>
        }
        content={
          <MainContainer>
            <ContentPage>
              <InputSelectSearch
                placeholder={translate('Sections')}
                width="230px"
                control={control}
                label={translate('SelectSection')}
                options={sectiosOptions || []}
                name="section"
                value={watch('section')}
                onChange={(selectedSection) =>
                  handleSectionChange(selectedSection)
                }
              />
              {watch('section')?.value && (
                <>
                  <Text type="h3" color="black">
                    {translate('Filters')}
                  </Text>
                  <HolderForm>
                    <DivInputs>
                      <InputSelectSearch
                        placeholder={translate('failureDirection')}
                        width="315px"
                        control={control}
                        label={translate('failureDirection')}
                        options={optionsFaluireDirection}
                        name="failureDirection"
                        error={!!errors.failureDirection}
                        errorMessage={errors?.failureDirection?.message}
                        value={watch('failureDirection')}
                        disabled
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputSelectSearch
                        placeholder={translate('calculationMethod')}
                        width="315px"
                        control={control}
                        label={translate('calculationMethod')}
                        options={optionsCalculationMethod}
                        name="calculationMethod"
                        error={!!errors.calculationMethod}
                        errorMessage={errors?.calculationMethod?.message}
                        value={watch('calculationMethod')}
                        disabled
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputSelectSearch
                        placeholder={translate('surfaceType')}
                        width="315px"
                        control={control}
                        label={translate('surfaceType')}
                        options={optionsSurfaceType}
                        name="surfaceType"
                        error={!!errors.surfaceType}
                        errorMessage={errors?.surfaceType?.message}
                        value={watch('surfaceType')}
                        disabled
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputSelectSearch
                        placeholder={translate('searchMethod')}
                        width="315px"
                        control={control}
                        label={translate('searchMethod')}
                        options={optionsSearchMethod}
                        name="searchMethod"
                        error={!!errors.searchMethod}
                        errorMessage={errors?.searchMethod?.message}
                        value={watch('searchMethod')}
                        disabled
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputText
                        width="215px"
                        control={control}
                        label={translate('elevation')}
                        name="elevation"
                        type="number"
                        error={!!errors.elevation}
                        errorMessage={errors?.elevation?.message}
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputText
                        width="215px"
                        control={control}
                        label={translate('Area')}
                        name="area"
                        type="number"
                        error={!!errors.area}
                        errorMessage={errors?.area?.message}
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputText
                        width="215px"
                        control={control}
                        label={translate('depth')}
                        name="depth"
                        type="number"
                        error={!!errors.depth}
                        errorMessage={errors?.depth?.message}
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputText
                        width="215px"
                        control={control}
                        label={translate('Weight')}
                        name="weight"
                        type="number"
                        error={!!errors.weight}
                        errorMessage={errors?.weight?.message}
                      />
                    </DivInputs>
                  </HolderForm>
                  <Text type="h3" color="black">
                    {translate('materials')}
                  </Text>
                  <HolderForm>
                    <DivInputs>
                      <InputSelectSearch
                        control={control}
                        name="materials"
                        label={translate('materials')}
                        errorMessage={errors?.materials?.message}
                        options={materialsOptions || []}
                        isMulti
                        isClearable={false}
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputSelectSearch
                        placeholder={translate('slideFile')}
                        width="315px"
                        control={control}
                        label={translate('Slides')}
                        options={slidesOptions}
                        name="slides"
                        error={!!errors.slides}
                        errorMessage={errors?.slides?.message}
                      />
                    </DivInputs>
                  </HolderForm>
                  <Text type="h3" color="black">
                    {translate('Instruments')}
                  </Text>
                  <HolderForm>
                    <DivInputs>
                      <DatepickerInput
                        control={control}
                        label={translate('Date')}
                        name="datePeriod"
                        width="180px"
                        placeholder={translate('NoInformated')}
                        time={false}
                        error={!!errors.datePeriod}
                        errorMessage={errors?.datePeriod?.message}
                        onChange={(date: Date | undefined) => {
                          if (date) return handleChangeDate(date);
                        }}
                        enableMinMaxDateSelection={true}
                        maxDate={new Date()}
                      />
                    </DivInputs>
                    <DivInputs>
                      <InputSelectSearch
                        control={control}
                        name="instruments"
                        label={translate('Instruments')}
                        errorMessage={errors?.instruments?.message}
                        options={instrumentsOptions || []}
                        disabled={!watch('datePeriod')}
                        isMulti
                        isClearable={false}
                      />
                    </DivInputs>
                  </HolderForm>
                  <DivButton>
                    <Button
                      variant={'primary'}
                      text={translate('GenerateGraph')}
                      onClick={handleSubmit(handleGenerateGraph)}
                    />
                  </DivButton>
                  <DivGraph>
                    <Text type="h3" color="black">
                      {translate('lastGraphicGenerated')}
                    </Text>
                    {lastGraphic?.findLastGraphicBySection ? (
                      <Image
                        src={`${BUCKET_URL}/${lastGraphic.findLastGraphicBySection}`}
                        alt={'graphicImage'}
                        objectFit="contain"
                        height="90%"
                        width="90%"
                      />
                    ) : (
                      <DivGraphNotGenerated>
                        <Icon Icon={MdImageNotSupported} variant="large" />
                        <Text type="span">{translate('notDenerated')}</Text>
                      </DivGraphNotGenerated>
                    )}
                  </DivGraph>
                </>
              )}
            </ContentPage>
          </MainContainer>
        }
      />

      <ViewModal
        showModal={showModalMaterials}
        onClose={() => setShowModalMaterials(false)}
        title="materials"
        width="500px"
        height="300px"
        component={<MaterialsForm />}
      />

      <ViewModal
        showModal={showModalSlides}
        onClose={() => setShowModalSlides(false)}
        title="Slides"
        width="500px"
        height="800px"
        component={<SlidesForm />}
      />
    </>
  );
};

export default StabilityPage;
