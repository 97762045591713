import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputText from '../../Molecules/InputText/InputText';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  AlertLevelType,
  createAlertLevelSchema
} from '../../Pages/RegisterPiezometer/piezometerSchemas';

import {
  HolderForm,
  MainFormContainer,
  MainFormFooter
} from './EditAnmALertLevels.styles';

interface EditAlertLevelModalProps {
  onSave: (data: AlertLevelType, index: number) => void;
  alertLevel: AlertLevelType;
  index: number;
}

const EditAlertLevelModal: React.FC<EditAlertLevelModalProps> = ({
  onSave,
  alertLevel,
  index
}) => {
  const { t: translate } = useTranslation();

  const alertLevelSchema = createAlertLevelSchema();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<AlertLevelType>({
    resolver: zodResolver(alertLevelSchema),
    defaultValues: { ...alertLevel }
  });

  const onSubmit = (data: AlertLevelType) => {
    onSave(data, index);
  };

  return (
    <MainFormContainer
      onKeyDown={(event) => {
        {
          if (event.key === 'Enter') {
            handleSubmit(onSubmit)();
          }
        }
      }}
    >
      <HolderForm>
        <InputText
          width="400px"
          control={control}
          label={translate('name')}
          name="name"
          type="text"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
        />
        <InputText
          width="400px"
          control={control}
          label={translate('coteValue')}
          name="coteValue"
          type="number"
          error={!!errors.coteValue}
          errorMessage={errors?.coteValue?.message}
        />
        <InputText
          width="400px"
          control={control}
          label={`FS ${translate('Drained')}`}
          name="drained"
          type="text"
          error={!!errors.drained}
          errorMessage={errors?.drained?.message}
        />
        <InputText
          width="400px"
          control={control}
          label={`FS ${translate('NotDrained')}`}
          name="undrained"
          type="text"
          error={!!errors.undrained}
          errorMessage={errors?.undrained?.message}
        />
      </HolderForm>

      <MainFormFooter>
        <Button
          onClick={handleSubmit(onSubmit)}
          text={translate('Save')}
          variant="primary"
        />
      </MainFormFooter>
    </MainFormContainer>
  );
};

export default EditAlertLevelModal;
