import { z } from 'zod';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputText from '../../../../Molecules/InputText/InputText';
import DatepickerInput from '../../../../Molecules/DatepickerInput/DatepickerInput';
import Button from '../../../../Atoms/Button/Button';
import {
  DivButton,
  DivContainer,
  DivInput,
  DviContent
} from './FilterEquipamentHealthCMGPage.styles';
import { useTranslation } from 'react-i18next';
import InputSelectSearch from '../../../../Molecules/InputSelectSearch/InputSelectSearch';

const FilterEquipamentHealthCMGPageSchema = z.object({
  instrumentName: z.string().optional().nullable(),
  readingDate: z.date().optional().nullable(),
  battery: z.string().optional().nullable(),
  auxiliaryBattery: z.string().optional().nullable(),
  temperature: z.string().optional().nullable(),
  signalStrength: z.string().optional().nullable(),
  thermistor: z.string().optional().nullable(),
  hasReading: z
    .object({
      value: z.string().optional().nullable(),
      label: z.string().optional().nullable()
    })
    .optional()
    .nullable()
});

export type FilterEquipamentHealthCMGPage = z.infer<
  typeof FilterEquipamentHealthCMGPageSchema
>;

const FilterEquipamentHealthCMGPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t: translate } = useTranslation();

  const hasReadingOptions = [
    { value: 'true', label: translate('Yes') },
    { value: 'false', label: translate('No') },
    { value: 'all', label: translate('all') }
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FilterEquipamentHealthCMGPage>({
    resolver: zodResolver(FilterEquipamentHealthCMGPageSchema),
    defaultValues: {
      instrumentName: searchParams.get('instrumentName') || '',
      readingDate: searchParams.get('readingDate')
        ? new Date(searchParams.get('readingDate')!)
        : undefined,
      battery: searchParams.get('battery') || '',
      auxiliaryBattery: searchParams.get('auxiliaryBattery') || '',
      temperature: searchParams.get('temperature') || '',
      signalStrength: searchParams.get('signalStrength') || '',
      thermistor: searchParams.get('thermistor') || '',
      hasReading: searchParams.get('hasReading')
        ? hasReadingOptions.find(
            (option) => option.value === searchParams.get('hasReading')
          )
        : null
    }
  });

  const handleFilters = (data: FilterEquipamentHealthCMGPage) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      data.instrumentName &&
        newParams.set('instrumentName', data.instrumentName);
      data.readingDate &&
        newParams.set('readingDate', data.readingDate.toISOString());
      data.battery && newParams.set('battery', data.battery);
      data.auxiliaryBattery &&
        newParams.set('auxiliaryBattery', data.auxiliaryBattery);
      data.temperature && newParams.set('temperature', data.temperature);
      data.signalStrength &&
        newParams.set('signalStrength', data.signalStrength);
      data.thermistor && newParams.set('thermistor', data.thermistor);
      data.hasReading?.value &&
        newParams.set('hasReading', data.hasReading.value);
      newParams.delete('filter');
      return newParams;
    });
  };

  const handleClearFilters = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete('instrumentName');
      newParams.delete('readingDate');
      newParams.delete('status');
      newParams.delete('battery');
      newParams.delete('auxiliaryBattery');
      newParams.delete('temperature');
      newParams.delete('signalStrength');
      newParams.delete('thermistor');
      newParams.delete('hasReading');
      return newParams;
    });
    reset({
      instrumentName: '',
      readingDate: null,
      battery: '',
      auxiliaryBattery: '',
      temperature: '',
      signalStrength: '',
      thermistor: '',
      hasReading: null
    });
  };

  return (
    <DivContainer>
      <DviContent
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(handleFilters)();
          }
        }}
      >
        <DivInput>
          <InputText
            width="300px"
            label="Instrument"
            type="text"
            control={control}
            name="instrumentName"
            error={!!errors.instrumentName}
            errorMessage={errors.instrumentName?.message}
          />
        </DivInput>
        <DivInput>
          <DatepickerInput
            label="ReadingDate"
            control={control}
            name="readingDate"
            placeholder="NoInformated"
            time={false}
            error={!!errors.readingDate}
            errorMessage={errors.readingDate?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            label="battery"
            type="text"
            control={control}
            name="battery"
            error={!!errors.battery}
            errorMessage={errors.battery?.message}
          />
        </DivInput>
        <DivInput>
          <InputSelectSearch
            width="300px"
            label="hasReading"
            placeholder="select"
            options={hasReadingOptions}
            control={control}
            name="hasReading"
            error={!!errors.hasReading}
            errorMessage={errors.hasReading?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            label="auxiliaryBattery"
            type="text"
            control={control}
            name="auxiliaryBattery"
            error={!!errors.auxiliaryBattery}
            errorMessage={errors.auxiliaryBattery?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            label="temperature"
            type="text"
            control={control}
            name="temperature"
            error={!!errors.temperature}
            errorMessage={errors.temperature?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            label="signalStrength"
            type="text"
            control={control}
            name="signalStrength"
            error={!!errors.signalStrength}
            errorMessage={errors.signalStrength?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            label="thermistor"
            type="text"
            control={control}
            name="thermistor"
            error={!!errors.thermistor}
            errorMessage={errors.thermistor?.message}
          />
        </DivInput>
      </DviContent>
      <DivButton>
        <Button
          variant="primary"
          size="small"
          text="clean"
          onClick={handleClearFilters}
        />
        <Button
          variant="primary"
          size="small"
          text="Filter"
          onClick={handleSubmit(handleFilters)}
        />
      </DivButton>
    </DivContainer>
  );
};

export default FilterEquipamentHealthCMGPage;
