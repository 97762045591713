import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindStructureMaterialsQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;

export type FindStructureMaterialsQuery = {
  __typename?: 'Query';
  findStructureMaterials: Array<{
    __typename?: 'StructureMaterialsType';
    id: string;
    materialName: string;
  }>;
};

export const FindStructureMaterialsDocument = gql`
  query FindStructureMaterials($structureId: String!) {
    findStructureMaterials(structureId: $structureId) {
      id
      materialName
    }
  }
`;

export type FindStructureMaterialsQueryResult = Apollo.QueryResult<
  FindStructureMaterialsQuery,
  FindStructureMaterialsQueryVariables
>;
