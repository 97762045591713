import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GenerateStabilityAnalysisGraphMutationVariables = Types.Exact<{
  data: Types.GenerateStabilityAnalysisGraphInput;
}>;

export type GenerateStabilityAnalysisGraphMutation = {
  __typename?: 'Mutation';
  generateStabilityAnalysisGraph: string;
};

export const GenerateStabilityAnalysisGraphDocument = gql`
  mutation GenerateStabilityAnalysisGraph(
    $data: GenerateStabilityAnalysisGraphInput!
  ) {
    generateStabilityAnalysisGraph(data: $data)
  }
`;

export type GenerateStabilityAnalysisGraphMutationFn = Apollo.MutationFunction<
  GenerateStabilityAnalysisGraphMutation,
  GenerateStabilityAnalysisGraphMutationVariables
>;

export type GenerateStabilityAnalysisGraphMutationResult =
  Apollo.MutationResult<GenerateStabilityAnalysisGraphMutation>;

export type GenerateStabilityAnalysisGraphMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateStabilityAnalysisGraphMutation,
    GenerateStabilityAnalysisGraphMutationVariables
  >;
