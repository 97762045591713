import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordWeatherConditionsDocument,
  DeleteRecordWeatherConditionsMutation,
  DeleteRecordWeatherConditionsMutationVariables
} from '../../../../data/graphql/generated/deleteRecordWeatherConditions';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import CardAtoInfo from '../CardInfo/CardInfo';
import WeatherConditionForm from '../WeatherConditionForm/WeatherConditionForm';
import {
  CardAtoInfoWeatherProps,
  EditProps,
  RowToEditType,
  WeatherConditionItemType,
  WeatherConditionsType
} from './CardAtoInfoWeather.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

export const CardAtoInfoWeather = ({
  weather,
  canAdd
}: CardAtoInfoWeatherProps) => {
  const { t: translate } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const [edit, setEdit] = useState<EditProps>({
    show: false,
    weather: null
  });
  const [updateDelete, setUpdateDelete] = useState(false);
  const [idForDelete, setIdForDelete] = useState('');

  const [deleteRecordWeatherConditions] = useMutation<
    DeleteRecordWeatherConditionsMutation,
    DeleteRecordWeatherConditionsMutationVariables
  >(DeleteRecordWeatherConditionsDocument);

  const tableColumns = [
    { key: 'indexId', label: '', sortType: 'number' },
    { key: 'condition', label: translate('condition'), sortType: 'text' },
    { key: 'start', label: translate('start'), sortType: 'text' },
    { key: 'end', label: translate('End'), sortType: 'text' }
  ];

  const tableData = weather.map((item: WeatherConditionsType, index) => ({
    ...item,
    condition: translate(item.weather),
    indexId: index + 1
  }));

  const actions = [
    {
      name: translate('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: translate('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordWeatherConditions({
      variables: {
        deleteRecordWeatherConditionsId: id
      },
      onCompleted: () => {
        toastfySuccess('deletedSuccessfully');
        setUpdateDelete(!updateDelete);
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            weatherConditions:
              existingData.findDataByRecord.weatherConditions.filter(
                (item: WeatherConditionItemType) => item.id !== id
              )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setEdit({ show: true, weather: row });
  };

  const handleCloseEdit = () => {
    setEdit({ show: false, weather: null });
  };

  const handleAddForm = () => {
    setEdit({ show: true, weather: null });
  };

  return (
    <>
      <CardAtoInfo
        canAdd={canAdd}
        title={translate('weatherCondition')}
        buttonLabel={translate('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
          showInUrl={false}
        />
      </CardAtoInfo>

      <ViewModal
        showModal={edit.show}
        width={40}
        height={50}
        title={
          edit.weather?.id
            ? translate('editWeatherCondition')
            : translate('addWeatherCondition')
        }
        onClose={handleCloseEdit}
        component={
          <WeatherConditionForm
            setEdit={setEdit}
            recordId={recordId?.toString() || ''}
            idWeatherCondition={edit.weather?.id}
          />
        }
      />

      <ModalConfirm
        showModal={!!idForDelete}
        title={`${translate('Delete')} ${translate('Activity')}`}
        text={translate('ConfirmDelete')}
        subtitle={translate('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};
