import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindPluviometerReadingDataChartToGmcQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  days: Types.Scalars['Float']['input'];
}>;

export type FindPluviometerReadingDataChartToGmcQuery = {
  __typename?: 'Query';
  findPluviometerReadingDataChartToGMC: Array<{
    __typename?: 'FoundPluviometerReadingsLastDaysToGMCType';
    reading: number;
    date: Date;
  }>;
};

export const FindPluviometerReadingDataChartToGmcDocument = gql`
  query FindPluviometerReadingDataChartToGMC(
    $structureId: String!
    $days: Float!
  ) {
    findPluviometerReadingDataChartToGMC(
      structureId: $structureId
      days: $days
    ) {
      reading
      date
    }
  }
`;

export type FindPluviometerReadingDataChartToGmcQueryResult =
  Apollo.QueryResult<
    FindPluviometerReadingDataChartToGmcQuery,
    FindPluviometerReadingDataChartToGmcQueryVariables
  >;
