import { Body, Container, Content, Footer, Head } from './ModalConfirm.styles';
import Text from '../../Atoms/Text/Text';
import Button from '../../Atoms/Button/Button';
import { ModalConfirmProps } from './ModalConfirm.interfaces';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

const ModalConfirm = ({
  onClose,
  onConfirm,
  title,
  text,
  subtitle,
  showModal,
  newPortal = true
}: ModalConfirmProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (showModal) {
      ref.current?.focus();
    }
  }, [showModal]);

  const modal = (
    <Container
      ref={ref}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          onClose();
        }

        if (event.key === 'Enter') {
          onConfirm();
        }
      }}
      $showModal={showModal}
    >
      <Content $showModal={showModal}>
        <Head>
          <Text type="h3">{translate(title)}</Text>
        </Head>
        <Body>
          <Text type="label" size="medium">
            {translate(text)}
          </Text>
          {subtitle && (
            <Text type="label" size="small">
              {translate(subtitle)}
            </Text>
          )}
        </Body>
        <Footer>
          <Button onClick={onClose} text="Cancel" variant="secondary" />
          <Button onClick={onConfirm} text="Confirm" variant="primary" />
        </Footer>
      </Content>
    </Container>
  );

  return newPortal ? ReactDOM.createPortal(modal, document.body) : modal;
};

export default ModalConfirm;
