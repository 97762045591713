import Skeleton from 'react-loading-skeleton';
import { DashboardTemplateProps } from './DashboardTemplate.interface';
import {
  ContainerDashboard,
  DivContainerInfos,
  HolderCards,
  HolderContentPage,
  HolderHeader,
  HolderTable,
  HolderVideos
} from './DashboardTemplate.styles';

const DashboardTemplate = ({
  header,
  infosContent,
  cardContent,
  tableContent,
  loading
}: DashboardTemplateProps) => {
  return (
    <>
      {loading ? (
        <ContainerDashboard>
          <HolderHeader>
            <Skeleton width="300px" height="48px" />
            <Skeleton width="200px" height="48px" />
          </HolderHeader>
          <HolderContentPage>
            <DivContainerInfos>
              <Skeleton width="100px" height="48px" />
              <Skeleton width="100px" height="48px" />
              <Skeleton width="100px" height="48px" />
              <Skeleton width="100px" height="48px" />
            </DivContainerInfos>
          </HolderContentPage>
        </ContainerDashboard>
      ) : (
        <ContainerDashboard>
          <HolderHeader>{header}</HolderHeader>
          <HolderContentPage>
            <DivContainerInfos>{infosContent}</DivContainerInfos>
            <HolderCards>{cardContent}</HolderCards>
          </HolderContentPage>
          <HolderTable>{tableContent}</HolderTable>
        </ContainerDashboard>
      )}
    </>
  );
};

export default DashboardTemplate;
