import { styled } from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const TableATO = styled.table`
  color: ${Color.Text1};
  border-collapse: collapse;
  text-align: center;
  font-size: ${FontSize.Small};
  color: ${Color.Text1};
  display: flex;
  flex-direction: column;
`;
export const TableHeaderATO = styled.thead`
  background-color: ${Color.Brown3};
  color: ${Color.Brown1};
  padding: 0 !important;
  border: 1px solid ${Color.Brown1};
`;

export const TableBodyATO = styled.tbody``;

export const TableRowATO = styled.tr`
  th {
    width: 3000px;
    padding: 0 !important;
  }
`;

export const TableCellATO = styled.td`
  border: 1px solid ${Color.Brown1};
  width: 3000px;
`;

export const TableCellATOBold = styled.td`
  border: 1px solid ${Color.Brown1};
  font-weight: bold;
  width: 3000px;
`;

export const TableCellATOFour = styled.td`
  border: 1px solid ${Color.Brown1};
  width: 3000px;
`;

export const TableCellATOTwo = styled.td`
  border: 1px solid ${Color.Brown1};
  width: 3000px;
`;

export const TableCellATOOne = styled.td`
  border: 1px solid ${Color.Brown1};
  width: 3000px;
`;

export const LabelTdATOBold = styled.span`
  font-weight: bold;
  width: 3000px;
`;

export const ATOContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 5px 30px 30px 30px;
  box-sizing: border-box;
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  padding: 5px 20% 40px 20%;
  box-sizing: border-box;
  @media print {
    padding: 0px;
  }
`;

export const ATOButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
`;

export const HolderCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 3px;
`;

export const HolderDescriptions = styled.div`
  width: 100%;
`;

export const ActivityDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.Brown1};
  padding: 10px 5px 10px 5px;
`;

export const HolderCardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const HolderCardContent = styled.div`
  width: 100%;
  border: 1px solid ${Color.Brown1};
  padding: 5px;
`;

export const TitleATOSpan = styled.span`
  display: 'flex';
  color: ${Color.Brown1};
  width: '100%';
  justify-content: 'center';
  padding: '15px 0';
  font-weight: 800;
`;

export const SpanTitle = styled.span`
  display: flex;
  align-items: flex-start;
  width: 100%;
  color: ${Color.Text1};
  font-size: ${FontSize.XMedium};
  font-weight: bold;
  padding-bottom: 15px;
`;
