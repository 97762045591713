import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveGeneralCommentsMutationVariables = Types.Exact<{
  data: Types.SaveInspectionGeneralCommentsInput;
}>;

export type SaveGeneralCommentsMutation = {
  __typename?: 'Mutation';
  saveGeneralComments: string;
};

export const SaveGeneralCommentsDocument = gql`
  mutation SaveGeneralComments($data: SaveInspectionGeneralCommentsInput!) {
    saveGeneralComments(data: $data)
  }
`;

export type SaveGeneralCommentsMutationFn = Apollo.MutationFunction<
  SaveGeneralCommentsMutation,
  SaveGeneralCommentsMutationVariables
>;

export type SaveGeneralCommentsMutationResult =
  Apollo.MutationResult<SaveGeneralCommentsMutation>;

export type SaveGeneralCommentsMutationOptions = Apollo.BaseMutationOptions<
  SaveGeneralCommentsMutation,
  SaveGeneralCommentsMutationVariables
>;
