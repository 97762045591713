import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface InputProps {
  $error?: boolean;
  width?: string;
  disabled?: boolean;
}

export const InputStyled = styled.input<InputProps>`
  display: block;
  width: ${({ width }) => (width ? `max(${width}, 100%)` : 'max(300px, 100%)')};
  padding: 10px;
  border: none;
  background: transparent;
  font-size: ${FontSize.Small};
  border-bottom: 1px solid
    ${(props) =>
      props.$error ? Color.Red1 : props.disabled ? Color.Gray3 : Color.Brown1};
  color: ${(props) =>
    props.$error ? Color.Red1 : props.disabled ? Color.Gray3 : Color.Brown1};
  user-select: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  box-sizing: border-box;

  &::placeholder {
    color: ${(props) =>
      props.$error ? Color.Red1 : props.disabled ? Color.Gray3 : Color.Brown1};
  }
`;
