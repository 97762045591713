import styled from 'styled-components';

export const SignatureAtoContainer = styled.div`
  color: black;
  width: 100%;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const SignatureCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  width: 100%;
`;

export const HolderCardChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px 0px 50px;
  width: 100%;
`;

export const HolderSignature = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;
