import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoMdEye } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordImageDocument,
  DeleteRecordImageMutation,
  DeleteRecordImageMutationVariables
} from '../../../../data/graphql/generated/deleteRecordImage';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { BUCKET_URL } from '../../../../utils/const';
import { toastfySuccess } from '../../../Toastify';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AddPhotoAtoForm from '../AddPhotoAtoForm/AddPhotoAtoForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoPhotosProp,
  RecordImageItem,
  RecordImagesType,
  RowToEditType
} from './CardAtoInfoPhotos.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

export const CardAtoInfoPhotos = ({
  recordImages,
  canAdd
}: CardAtoInfoPhotosProp) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordImage] = useMutation<
    DeleteRecordImageMutation,
    DeleteRecordImageMutationVariables
  >(DeleteRecordImageDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [file, setFile] = useState<FileType | null>(null);
  const [idForDelete, setIdForDelete] = useState<string>('');

  const tableColumns = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'image',
      label: t('image')
    },
    {
      key: 'observation',
      label: t('Observation')
    }
  ];

  const tableData = recordImages.map((item: RecordImagesType, index) => ({
    image: item.image,
    id: item.id,
    indexId: index + 1,
    observation: item.observation
  }));

  const actions = [
    {
      name: t('View'),
      icon: IoMdEye,
      onClick: (row: RowToEditType) => onTapOpenPhoto(row),
      canShow: 'view'
    },
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordImage({
      variables: {
        deleteRecordImageId: id
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            recordImages: existingData.findDataByRecord.recordImages.filter(
              (item: RecordImageItem) => item.id !== id
            )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  const onTapOpenPhoto = (row: RowToEditType) => {
    setFile({
      id: row.id,
      name: row.image,
      url: `${BUCKET_URL}/${row.image}`
    });
  };

  return (
    <>
      <CardAtoInfo
        canAdd={canAdd}
        title={t('Photos')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      <FileViewer
        showModal={!!file}
        files={[file as FileType]}
        onClose={() => setFile(null)}
        selectedFile={file}
      />
      <ViewModal
        showModal={showModal}
        width={40}
        height={50}
        title={t('addphoto')}
        onClose={() => setShowModal(false)}
        component={
          <AddPhotoAtoForm
            setShowModal={setShowModal}
            recordId={recordId?.toString() || ''}
          />
        }
      />
      <ViewModal
        showModal={showEditModal}
        width={40}
        height={50}
        title={t('editphoto')}
        onClose={() => setShowEditModal(false)}
        component={
          <AddPhotoAtoForm
            setShowModal={setShowEditModal}
            recordId={recordId?.toString() || ''}
            editPhoto={{
              id: rowToEdit?.id || '',
              description: rowToEdit?.observation || '',
              name: rowToEdit?.image || '',
              url: `${BUCKET_URL}/${rowToEdit?.image}`
            }}
          />
        }
      />
      <ModalConfirm
        showModal={!!idForDelete}
        title={`${t('Delete')} ${t('Activity')}`}
        text={t('ConfirmDelete')}
        subtitle={t('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};
