import { useTranslation } from 'react-i18next';
import EditInstrumentTemplate from '../../Templates/EditInstrumentTemplate/EditInstrumentTamplate';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { IoIosArrowBack } from 'react-icons/io';
import RegisterPiezometer from '../RegisterPiezometer/RegisterPiezometer';
import RegisterWaterLevel from '../RegisterWaterLevel/RegisterWaterLevelPage';
import RegisterPluviometer from '../RegisterPluviometer/RegisterPluviometer';
import RegisterSurfaceMarker from '../RegisterSurfaceMarker/RegisterSurfaceMarker';

const RegisterInstrumentPage = () => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentType } = useParams();
  const navigate = useNavigate();
  const instrumentTypeTitle =
    instrumentType === 'ina'
      ? instrumentType?.toLocaleUpperCase()
      : instrumentType?.split('')[0].toLocaleUpperCase() +
        instrumentType!.slice(1);

  const componentMap: { [key: string]: React.ReactNode } = {
    piezometer: <RegisterPiezometer />,
    ina: <RegisterPiezometer />,
    waterlevel: <RegisterWaterLevel />,
    pluviometer: <RegisterPluviometer />,
    surfacemarker: <RegisterSurfaceMarker />
  };

  const handleNavigateInstrumentPage = () => {
    navigate(`/${structureId}/monitoring/instrument/${instrumentType}`);
  };

  return (
    <EditInstrumentTemplate
      header={
        <>
          <Icon
            Icon={IoIosArrowBack}
            variant="large"
            onClick={handleNavigateInstrumentPage}
          />
          <Text weight="bold" type="h2">
            {translate('Register')} {translate(`${instrumentTypeTitle}`)}
          </Text>
        </>
      }
      content={componentMap[instrumentType || '']}
    />
  );
};

export default RegisterInstrumentPage;
