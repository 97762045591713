import { PropsWithChildren } from 'react';
import { Color } from '../../../../Styles/Styles';
import { TextProps } from './Text.interfaces';
import {
  H1Styled,
  H2Styled,
  H3Styled,
  H4Styled,
  H5Styled,
  LabelStyled,
  PStyled,
  SpanStyled
} from './Text.styles';

const COMPONENT_MAP: { [key: string]: React.ElementType } = {
  h1: H1Styled,
  h2: H2Styled,
  h3: H3Styled,
  h4: H4Styled,
  h5: H5Styled,
  p: PStyled,
  span: SpanStyled,
  label: LabelStyled
};

const variantSizeMap: { [key: string]: string } = {
  small: '12px',
  medium: '16px',
  large: '20px'
};

const variantWeightMap: { [key: string]: string } = {
  bold: '700',
  semiBold: '600',
  default: '500'
};

const variantColorMap: { [key: string]: string } = {
  brown: `${Color.Brown1}`,
  black: `${Color.Text1}`,
  disabled: `${Color.Disabled}`,
  white: `${Color.White}`
};

const Text = ({
  type,
  error,
  children,
  color,
  size,
  weight,
  disabled,
  ...props
}: PropsWithChildren<TextProps>) => {
  const Component = COMPONENT_MAP[type];
  return (
    <Component
      $error={error}
      weight={variantWeightMap[weight || 'default']}
      fontSize={variantSizeMap[size || 'medium']}
      color={variantColorMap[color || 'brown']}
      disabled={disabled}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Text;
