import MapGoogleDraw from '../../Components/Map/MapGoogleDraw';
import { MonitoringContainer } from './MonitoringScreenStyle';

export const MonitoringScreen = () => {
  return (
    <MonitoringContainer>
      <MapGoogleDraw />
    </MonitoringContainer>
  );
};
