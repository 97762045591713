import Text from '../../Atoms/Text/Text';
import { SquareCardProps } from './SquareCard.interfaces';
import {
  DivContainerCard,
  DivContainerLabelCard,
  DivContainerSpanCard
} from './SquareCard.styles';

const SquareCard = ({ label, value }: SquareCardProps) => {
  return (
    <DivContainerCard>
      <DivContainerLabelCard>
        <Text type="label" size="small" color="black">
          {label}
        </Text>
      </DivContainerLabelCard>
      <DivContainerSpanCard>
        <Text type="span" size="large">
          {value}
        </Text>
      </DivContainerSpanCard>
    </DivContainerCard>
  );
};

export default SquareCard;
