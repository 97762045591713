import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveActivityImagesMutationVariables = Types.Exact<{
  data: Types.SaveActivityImagesInput;
}>;


export type SaveActivityImagesMutation = { __typename?: 'Mutation', saveActivityImages: Array<{ __typename?: 'ActivityImagesType', image: string, id: string }> };


export const SaveActivityImagesDocument = gql`
    mutation SaveActivityImages($data: SaveActivityImagesInput!) {
  saveActivityImages(data: $data) {
    image
    id
  }
}
    `;
export type SaveActivityImagesMutationFn = Apollo.MutationFunction<SaveActivityImagesMutation, SaveActivityImagesMutationVariables>;
export type SaveActivityImagesMutationResult = Apollo.MutationResult<SaveActivityImagesMutation>;
export type SaveActivityImagesMutationOptions = Apollo.BaseMutationOptions<SaveActivityImagesMutation, SaveActivityImagesMutationVariables>;