import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { checkBoxStyle } from '../../../../Components/Map/MapStyle';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';
import { MssGenericDataTitleEnum } from '../../../../data/graphql/base-schema';
import { DrainageChannelTable } from './DrainageChannelTable';

export function DrainageChannel({
  setIsSelectedDrainageChannel,
  isSelectedDrainageChannel,
  setStepStates,
  stepStates,
  error,
  inspectionId
}: {
  setIsSelectedDrainageChannel: Dispatch<SetStateAction<boolean>>;
  isSelectedDrainageChannel: boolean;
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: any;
  error: boolean;
  inspectionId: string;
}) {
  const { t }: any = useTranslation();

  const topics = [
    {
      subTitle: 'Topo',
      topic: 'Estado de limpeza',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Topo',
      topic: 'Condições estruturais',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 1 (901 - 894)',
      topic: 'Estado de limpeza',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 1 (901 - 894)',
      topic: 'Condições estruturais',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 2 (894 - 890)',
      topic: 'Estado de limpeza',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 2 (894 - 890)',
      topic: 'Condições estruturais',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 3 (890 - 875)',
      topic: 'Estado de limpeza',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 3 (890 - 875)',
      topic: 'Condições estruturais',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 4 (875 - 866)',
      topic: 'Estado de limpeza',
      title: MssGenericDataTitleEnum.DrainageChannel
    },
    {
      subTitle: 'Trecho 4 (875 - 866)',
      topic: 'Condições estruturais',
      title: MssGenericDataTitleEnum.DrainageChannel
    }
  ];

  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t(MssGenericDataTitleEnum.DrainageChannel)}</span>
        <span
          id="drainageChannel"
          onClick={() =>
            setIsSelectedDrainageChannel(!isSelectedDrainageChannel)
          }
        >
          {t('DoesNotApply/NonExistent')}{' '}
          {isSelectedDrainageChannel ? (
            <MdCheckBox style={checkBoxStyle} />
          ) : (
            <MdCheckBoxOutlineBlank style={checkBoxStyle} />
          )}
        </span>
      </IdentifiesHeader>
      {!isSelectedDrainageChannel && (
        <TableComponentStyle>
          <thead>
            <tr>
              <th>{''}</th>
              <th>{t('Value')}</th>
              <th>{t('Observation')}</th>
              <th>{t('Image')}</th>
            </tr>
          </thead>
          <tbody>
            {topics.map((item, index: number) => (
              <DrainageChannelTable
                setState={setStepStates}
                state={stepStates}
                topic={item.topic}
                subTitle={item.subTitle}
                error={error}
                key={index}
                inspectionId={inspectionId}
              />
            ))}
          </tbody>
        </TableComponentStyle>
      )}
    </IdentifiesContainer>
  );
}
