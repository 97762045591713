import styled from 'styled-components';

export const MainFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 20px;
  box-sizing: border-box;
  gap: 10px;
`;

export const HolderForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const MainFormFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
