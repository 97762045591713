import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ContentPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const HolderForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  box-sizing: border-box;
`;

export const DivInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(100%, 315px);
  min-height: 40px;
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
`;

export const AnimatedContainer = styled.div`
  opacity: 0;
  transform: translateY(-5px);
  animation: fadeInUp 0.3s forwards ease-in-out;

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const DivHeaderStability = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  p {
    padding: 0;
  }
`;

export const DivGraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  gap: 10px;
`;

export const DivGraphNotGenerated = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 100px;
`;
