import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  FindAllInspectionsByStructureDocument,
  FindAllInspectionsByStructureQuery,
  FindAllInspectionsByStructureQueryVariables
} from '../../../../../data/graphql/query/generated/findAllInspectionsByStructure.query';
import TableDraggableComponent from '../../../Molecules/TableDraggable/TableDraggableComponent';
import { TABLE_IDS } from '../../../../../utils/TableConsts';
import { useTranslation } from 'react-i18next';
import { MdOutlineDownload } from 'react-icons/md';
import { FaCheck, FaTrash } from 'react-icons/fa6';
import SimpleContentTemplate from '../../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import Text from '../../../Atoms/Text/Text';
import Button from '../../../Atoms/Button/Button';
import { AttributionInspection } from '../../../../../Modules/Inspection/Attribution/AttributionInspection';
import { useState } from 'react';
import Icon from '../../../Atoms/Icon/Icon';
import { IoClose } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { DivContainer } from './InspectionPage.styles';
import { API_URL, BUCKET_URL } from '../../../../../utils/const';
import { toastfyError } from '../../../../Toastify';
import { AvailableContextActionsProps } from '../../../Molecules/TableDraggable/components/ActionMenu/ActionMenu.interface';
import {
  DeleteInspectionDocument,
  DeleteInspectionMutation,
  DeleteInspectionMutationVariables
} from '../../../../../data/graphql/generated/deleteInspection.mutation';
import ModalConfirm from '../../../Molecules/ModalConfirm/ModalConfirm';

const InspectionPage = () => {
  const { structureId, inspectionId } = useParams();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [editing, setEditing] = useState<boolean>(false);
  const [showInspectionsModal, setShowInspectionsModal] =
    useState<boolean>(false);
  const [inspectionId2, setInspectionId] = useState('');
  const [selectedInspection, setSelectedInspection] = useState<
    | FindAllInspectionsByStructureQuery['findAllInspectionsByStructure'][0]
    | null
  >(null);
  const [deleteModal, setDeleteModal] = useState<string | null>(null);

  const [deleteUserInspection] = useMutation<
    DeleteInspectionMutation,
    DeleteInspectionMutationVariables
  >(DeleteInspectionDocument);

  const { data: inspectionsList, loading } = useQuery<
    FindAllInspectionsByStructureQuery,
    FindAllInspectionsByStructureQueryVariables
  >(FindAllInspectionsByStructureDocument, {
    variables: {
      structureId: structureId!
    }
  });

  const handleDeleteInspection = async (id: string) => {
    deleteUserInspection({
      variables: {
        inspectionId: id
      },
      refetchQueries: [
        {
          query: FindAllInspectionsByStructureDocument,
          variables: {
            structureId: structureId!
          }
        }
      ],
      onCompleted: () => {
        setDeleteModal(null);
      },
      onError: (error) => {
        console.error(error);
      }
    });
  };

  const inspectionsColumns = [
    { key: 'systemId', label: 'ID' },
    { key: 'responsible', label: translate('responsible') },
    { key: 'dateTable', label: translate('date') },
    { key: 'conservationStateCalc', label: translate('ConservationState') },
    { key: 'systemPdfIcon', label: translate('InspectionForm') },
    { key: 'userPdfIcon', label: translate('FieldSheet') }
  ];

  const inspectionsTableData =
    inspectionsList?.findAllInspectionsByStructure.map((inspection) => ({
      ...inspection,
      dateTable: new Date(inspection.date).toLocaleDateString(),
      systemPdfIcon: inspection.systemPdf ? (
        <Icon Icon={FaCheck} color="green" />
      ) : (
        <Icon Icon={IoClose} color="red" />
      ),
      userPdfIcon: inspection.userPdf ? (
        <Icon Icon={FaCheck} color="green" />
      ) : (
        <Icon Icon={IoClose} color="red" />
      )
    })) as Array<{ [key: string]: any }>;

  const actionsTable: AvailableContextActionsProps<
    FindAllInspectionsByStructureQuery['findAllInspectionsByStructure'][0]
  >[] = [
    {
      name: 'Edit',
      onClick: (row) => {
        setEditing(true);
        setShowInspectionsModal(true);
        setInspectionId(row!.id);
        setSelectedInspection({
          ...row!,
          date: new Date(row!.date)
        });
        navigate({
          pathname: `/${structureId}/inspections/inspections/${row!.id}`,
          search: searchParams.toString()
        });
      },
      icon: <MdOutlineDownload />,
      canShow: 'downloadPDF'
    },
    {
      name: 'downloadPDF',
      onClick: (row) => {
        handleDownloadFilePDFInspection(row);
      },
      icon: <MdOutlineDownload />,
      canShow: 'downloadPDF'
    },
    {
      name: 'downloadSystemPDF',
      onClick: (row) => {
        handleDownloadFile(row);
      },
      icon: <MdOutlineDownload />,
      canShow: 'downloadPDF'
    },
    {
      name: 'Delete',
      onClick: (row) => {
        setDeleteModal(row.id);
      },
      icon: <FaTrash />,
      canShow: 'delete'
    }
  ];

  const handleDownloadFilePDFInspection = async (inspection: any) => {
    try {
      const response = await fetch(`${API_URL}/${inspection.systemPdf}`);
      if (!response.ok) {
        toastfyError(translate('FailedtoDownloadFile: ') + response.statusText);
        return;
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Inspection_${inspection.systemId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastfyError(translate('FailedtoDownloadFile: '));
    }
  };

  const handleDownloadFile = async (inspection: any) => {
    try {
      const url = `${BUCKET_URL}/${inspection.userPdf}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Inspection_${inspection.systemId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastfyError(translate('FailedtoDownloadFile File'));
    }
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loading}
        button={
          <>
            <Button
              variant="primary"
              size="large"
              text="RegisterNewInspection"
              onClick={() => {
                setEditing(false);
                setInspectionId('');
                setSelectedInspection(null);
                setShowInspectionsModal(true);
                navigate({
                  pathname: `/${structureId}/inspections/inspections/new`,
                  search: searchParams.toString()
                });
              }}
            />
          </>
        }
        title={
          <>
            <Icon
              Icon={IoIosArrowBack}
              onClick={() => {
                navigate(`/${structureId}/inspections`);
              }}
            />
            <Text weight="bold" type="h2">
              {translate('Inspections')}
            </Text>
          </>
        }
        content={
          <DivContainer>
            <TableDraggableComponent
              tableId={TABLE_IDS.INSPECTIONS_TABLE}
              columnsArray={inspectionsColumns}
              tableData={inspectionsTableData || []}
              actions={actionsTable}
              columnSelect
              showCountColumn
              maxHeight="650px"
            />
          </DivContainer>
        }
      />

      {showInspectionsModal && (
        <AttributionInspection
          setShowModal={setShowInspectionsModal}
          editing={editing}
          inspectionId={inspectionId2}
          setInspectionId={setInspectionId}
          selectedInspection={selectedInspection}
        />
      )}

      <ModalConfirm
        showModal={!!deleteModal}
        onClose={() => setDeleteModal(null)}
        onConfirm={() => handleDeleteInspection(deleteModal!)}
        title="deleteInspection"
        text="ConfirmDelete"
        subtitle="ActionCannotBeUndone"
      />
    </>
  );
};

export default InspectionPage;
