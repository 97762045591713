import { z } from 'zod';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputText from '../../Molecules/InputText/InputText';
import { useTranslation } from 'react-i18next';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { ReadingSurfaceMarkerProps } from './ReadingSurfaceMarker.interfaces';
import {
  ListSurfaceMarkersByStructureDocument,
  ListSurfaceMarkersByStructureQuery,
  ListSurfaceMarkersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';
import { ListReadingBySurfaceMarkersPagDocument } from '../../../../data/graphql/query/generated/listReadingBySurfaceMarkersPag.query';
import {
  CreateSurfaceMarkersReadingDocument,
  CreateSurfaceMarkersReadingMutation,
  CreateSurfaceMarkersReadingMutationVariables
} from '../../../../data/graphql/generated/createSurfaceMarkerReading.mutation';
import {
  DivInput,
  HolderFooter,
  HolderForm
} from './ReadingSurfaceMarker.styles';
import {
  EditSurfaceMarkersReadingMutation,
  EditSurfaceMarkersReadingMutationVariables,
  EditSurfaceMarkersReadingDocument
} from '../../../../data/graphql/generated/editSurfaceMarkerReading.mutation';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const formReadingSchema = z.object({
  instrument: z
    .object({
      value: z.string().min(1, 'fieldIsRequired'),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  elevation: z.string().min(1, 'fieldIsRequired'),
  date: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  observation: z.string().nullable(),
  coordinateE: z.string().min(1, 'fieldIsRequired'),
  coordinateN: z.string().min(1, 'fieldIsRequired')
});

type FormReadingSchemaType = z.infer<typeof formReadingSchema>;

const ReadingSurfaceMarker = ({
  reading,
  setShowModal
}: ReadingSurfaceMarkerProps) => {
  const { structureId } = useParams();
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormReadingSchemaType>({
    resolver: zodResolver(formReadingSchema),
    defaultValues: {
      elevation: reading?.elevation || '',
      coordinateE: reading?.coordinateE?.toString() || '',
      coordinateN: reading?.coordinateN?.toString() || '',
      date: reading?.date ? new Date(reading?.date) : new Date(),
      observation: reading?.observation || '',
      instrument: reading?.instrument
        ? {
            value: reading?.instrument.id,
            label: reading?.instrument.name
          }
        : {
            value: '',
            label: ''
          }
    }
  });
  const [createReading] = useMutation<
    CreateSurfaceMarkersReadingMutation,
    CreateSurfaceMarkersReadingMutationVariables
  >(CreateSurfaceMarkersReadingDocument);

  const [editSurfaceMarkerReading] = useMutation<
    EditSurfaceMarkersReadingMutation,
    EditSurfaceMarkersReadingMutationVariables
  >(EditSurfaceMarkersReadingDocument);

  const { data: listSurfaceMarkerReadings } = useQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!
      }
    }
  });

  const listSurfaceMarkerOptions =
    listSurfaceMarkerReadings?.listSurfaceMarkersByStructure?.map(
      (instrument) => ({
        value: instrument.id!,
        label: instrument.name
      })
    );

  const handleSaveReading = (data: FormReadingSchemaType) => {
    createReading({
      variables: {
        data: {
          instrumentId: data.instrument.value,
          instrumentName: data.instrument.label,
          elevation: parseFloat(data.elevation),
          date: new Date(data.date.setHours(0, 0, 0, 0)),
          observation: data.observation,
          coordinateE: parseFloat(data.coordinateE),
          coordinateN: parseFloat(data.coordinateN)
        },
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('registeredSuccessfully'));
      },
      refetchQueries: [ListReadingBySurfaceMarkersPagDocument],
      awaitRefetchQueries: true,
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleEditReading = (data: FormReadingSchemaType) => {
    const readingData = {
      id: reading?.id ?? '',
      elevation: parseFloat(data.elevation),
      date: new Date(data.date.setHours(0, 0, 0, 0)),
      observation: data.observation,
      coordinateE: parseFloat(data.coordinateE),
      coordinateN: parseFloat(data.coordinateN)
    };

    editSurfaceMarkerReading({
      variables: {
        data: readingData,
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updatedSuccessfully'));
      },

      refetchQueries: [ListReadingBySurfaceMarkersPagDocument],
      awaitRefetchQueries: true,
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <HolderForm
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          reading
            ? handleSubmit(handleEditReading)()
            : handleSubmit(handleSaveReading)();
        }
      }}
    >
      {!reading?.id && (
        <InputSelectSearch
          width="400px"
          placeholder={translate('SelectInstrument')}
          control={control}
          label={translate('Instruments')}
          options={listSurfaceMarkerOptions || []}
          name={'instrument'}
          error={!!errors.instrument}
          errorMessage={errors?.instrument?.message}
          disabled={!!reading?.id}
        />
      )}
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('coordinateN')}
          name={'coordinateN'}
          type={'number'}
          error={!!errors.coordinateN}
          errorMessage={errors?.coordinateN?.message}
        />
      </DivInput>
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('coordinateE')}
          name={'coordinateE'}
          type={'number'}
          error={!!errors.coordinateE}
          errorMessage={errors?.coordinateE?.message}
        />
      </DivInput>
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('elevation')}
          name={'elevation'}
          type={'number'}
          error={!!errors.elevation}
          errorMessage={errors?.elevation?.message}
        />
      </DivInput>
      <DatepickerInput
        width="330px"
        control={control}
        label={translate('date')}
        name="date"
        placeholder={translate('date')}
        time={false}
        error={!!errors.date}
        errorMessage={errors?.date?.message}
      />
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('observation')}
          name={'observation'}
          type={'text'}
          error={!!errors.observation}
          errorMessage={errors?.observation?.message}
        />
      </DivInput>
      <HolderFooter>
        <Button
          size="large"
          text={reading ? `${translate('edit')}` : `${translate('create')}`}
          onClick={
            reading
              ? handleSubmit(handleEditReading)
              : handleSubmit(handleSaveReading)
          }
          variant={'primary'}
        />
      </HolderFooter>
    </HolderForm>
  );
};

export default ReadingSurfaceMarker;
