/* eslint-disable id-length */
import { useContext, useState } from 'react';
import Plot from 'react-plotly.js';
import { SelectedOption } from './PhotoChart.interface';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FindDataToChartDocument,
  FindDataToChartQuery,
  FindDataToChartQueryVariables
} from '../../../../data/graphql/query/generated/findDataToChart.query';
import {
  DivGraphContainer,
  DivFlexColumns,
  DivOptionsChart,
  DivOptionsContainer,
  DivOptionsSelectChart,
  DivInputsContainer
} from './PhotoChart.style';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import {
  ListSectionsByStructureDocument,
  ListSectionsByStructureQuery,
  ListSectionsByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSectionsByStructure.query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { Data, Image, Layout, Shape } from 'plotly.js';
import { BUCKET_URL } from '../../../../utils/const';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import Skeleton from 'react-loading-skeleton';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../Map/types';
import {
  SaveDataToSectionChartDocument,
  SaveDataToSectionChartMutation,
  SaveDataToSectionChartMutationVariables
} from '../../../../data/graphql/generated/saveDataToSectionChart';
import Button from '../../Atoms/Button/Button';
import {
  UpdateSectionChartParametersDocument,
  UpdateSectionChartParametersMutation,
  UpdateSectionChartParametersMutationVariables
} from '../../../../data/graphql/generated/updateSectionChartParameters.mutation';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const PhotoChart = () => {
  const { parseErrorMessage } = useErrorsTreatment();
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const [selectedSection, setSelectedSection] = useState<SelectedOption>({
    label: '',
    value: ''
  });
  const [chartData, setChartData] = useState<Data[]>([]);
  const [layoutChart, setLayoutChart] = useState<Partial<Layout>>({
    xaxis: {
      title: translate('Distance(m)'),
      color: 'black',
      range: [0, 3000],
      dtick: 500
    },
    yaxis: {
      title: translate('Distance(m)'),
      color: 'black',
      range: [500, 1100],
      dtick: 100
    }
  });
  const [imageGrapgh, setImageGraph] = useState<Partial<Image>>({
    x: 0,
    y: 1,
    xref: 'x',
    yref: 'y',
    sizex: 1,
    sizey: 1,
    sizing: 'stretch',
    xanchor: 'left',
    yanchor: 'top',
    layer: 'below'
  });
  const [instruments, setInstruments] = useState<
    {
      id: string;
      name: string;
      x: number;
    }[]
  >([]);

  const { data: sections } = useQuery<
    ListSectionsByStructureQuery,
    ListSectionsByStructureQueryVariables
  >(ListSectionsByStructureDocument, {
    variables: {
      structureId: structureId ?? ''
    }
  });

  const sectionOptions =
    sections?.listSectionsByStructure.map((section) => ({
      label: section.name,
      value: section.id
    })) ?? [];

  const [getFindDataToChart, { data, loading }] = useLazyQuery<
    FindDataToChartQuery,
    FindDataToChartQueryVariables
  >(FindDataToChartDocument);

  const [saveDataToSectionChart] = useMutation<
    SaveDataToSectionChartMutation,
    SaveDataToSectionChartMutationVariables
  >(SaveDataToSectionChartDocument);

  const [updateSectionChartParameters] = useMutation<
    UpdateSectionChartParametersMutation,
    UpdateSectionChartParametersMutationVariables
  >(UpdateSectionChartParametersDocument);

  const handleOnChangeSection = (option: SelectedOption) => {
    setSelectedSection(option);
    getFindDataToChart({
      variables: {
        setionId: option.value
      },
      onCompleted: ({ findDataToChart }) => {
        if (findDataToChart.sectionImage === null) {
          toastfyError(translate('SectionWithoutImage'));
          return;
        }

        // Adiciona dados ao state de instruments para salvar/alterar
        setInstruments(
          findDataToChart.piezometers.map((piezometer) => {
            return {
              id: piezometer.id,
              name: piezometer.name,
              x: piezometer.readings[0].x ?? 0
            };
          })
        );

        // Adiciona dados ao state do grafico que lida com a imagem
        setImageGraph((prev) => ({
          ...prev,
          source: `${BUCKET_URL}/${findDataToChart.sectionImage}`,
          sizex: findDataToChart.imageWidth || 0,
          sizey: findDataToChart.imageHeight || 0,
          y: findDataToChart.imageHeight || 0
        }));

        // Adiciona o titulo do grafico com o nome da seção
        setLayoutChart((prev) => ({
          ...prev,
          title: `${translate('Section')} - ${findDataToChart.sectionName}`
        }));

        // Pega os niveis de alerta de todos os instrumentos
        const alertLevels = findDataToChart.piezometers
          .map(({ alertLevels }) => {
            return alertLevels.map((alertLevel) => {
              return {
                x: alertLevel.x,
                y: alertLevel.y,
                name: translate(alertLevel.name),
                color:
                  alertLevel.name === 'EMERGÊNCIA'
                    ? 'red'
                    : alertLevel.name === 'ATENÇÃO'
                      ? 'yellow'
                      : 'orange'
              };
            });
          })
          .flat();

        // Pega os niveis de agua de todos os instrumentos
        const waterLevels = findDataToChart.piezometers
          .map(({ readings, name }) => {
            return readings.map((reading) => ({
              x: reading.x,
              y: reading.quota,
              name: name,
              color: 'blue'
            }));
          })
          .flat();

        // Formata os dados do nivel de alerta para o grafico
        const formattedData: Data[] = Object.values(
          alertLevels.reduce((acc: { [key: string]: any }, item) => {
            if (!acc[item.name]) {
              acc[item.name] = {
                x: [],
                y: [],
                type: 'scatter',
                mode: 'lines+markers',
                name: item.name,
                line: {
                  color: item.color,
                  width: 2
                }
              };
            }

            acc[item.name].x.push(item.x);
            acc[item.name].y.push(item.y);
            return acc;
          }, {})
        );

        // Formata os dados do nivel de agua para o grafico
        const formattedWaterLevels: Data = {
          name: translate('WaterLevel'),
          x: [
            0,
            ...waterLevels.map((item) => item.x),
            findDataToChart.imageWidth
          ] as number[],
          y: [
            findDataToChart.piezometers
              .map(({ readings }) => {
                return readings.map((reading) => reading.quota);
              })
              .flat()
              .shift(),
            ...waterLevels.map((item) => item.y),
            findDataToChart.piezometers
              .map(({ readings }) => {
                return readings.map((reading) => reading.quota);
              })
              .flat()
              .pop()
          ] as number[],
          type: 'scatter',
          mode: 'lines+markers',
          line: {
            color: 'blue',
            width: 2
          }
        };

        // Formata os dados dos instrumentos para o grafico
        const formattedInstruments: Data[] = findDataToChart.piezometers.map(
          ({ name, readings, alertLevels }) => ({
            x: [readings[0]?.x].filter(
              (x): x is number => x !== null && x !== undefined
            ),
            y: [readings[0]?.y].filter(
              (y): y is number => y !== null && y !== undefined
            ),
            name: name,
            mode: 'markers',
            marker: {
              size: 12
            },
            type: 'scatter',
            color: 'rgba(255, 255, 255, 0.8)',
            text: [
              `${translate('name')}: ${name}<br>` +
                `${translate('bottomCote')}: ${readings[0]?.bottomQuote}<br>` +
                `${translate('topCote')}: ${readings[0]?.topQuote}<br>` +
                `${translate('cote')}: ${readings[0]?.quota}` +
                alertLevels
                  .map(
                    (alertLevel) =>
                      `<br>${translate(alertLevel.name)}: ${alertLevel.y}`
                  )
                  .join('')
            ],
            hovertemplate: '<b>%{text}</b><extra></extra>'
          })
        );

        // Cria a forma do "instrumento" para cada instrumento
        const shapes: Partial<Shape>[] = findDataToChart.piezometers.flatMap(
          ({ readings, alertLevels, name }) => {
            const instrumentX = readings[0]?.x;
            const bottomQuote = readings[0]?.bottomQuote;
            const topQuote = readings[0]?.topQuote;
            const quota = readings[0]?.quota;

            // Criar o retângulo principal do instrumento
            const mainRectangle: Partial<Shape> = {
              name: name,
              type: 'rect',
              xref: 'x',
              yref: 'y',
              x0: (instrumentX ?? 0) - 5,
              x1: (instrumentX ?? 0) + 5,
              y0: bottomQuote,
              y1: topQuote,
              line: { color: 'black', width: 2 },
              fillcolor: 'rgba(0,0,0,0)'
            };

            // Criar o retângulo azul para a parte abaixo do nível de água
            const waterRectangle: Partial<Shape> = {
              name: name,
              type: 'rect',
              xref: 'x',
              yref: 'y',
              x0: (instrumentX ?? 0) - 5,
              x1: (instrumentX ?? 0) + 5,
              y0: quota,
              y1: bottomQuote,
              line: { color: 'blue', width: 0 },
              fillcolor: 'rgba(0,0,255,0.2)'
            };

            // Criar as linhas de divisão dos níveis de alerta
            // TODO: Verificar quanto aos niveis de alerta que não são EMERGÊNCIA, ATENÇÃO e ALERTA
            const divisionLines: Partial<Shape>[] = alertLevels.map(
              (alertLevel) => ({
                name: name,
                type: 'line',
                xref: 'x',
                yref: 'y',
                x0: (instrumentX ?? 0) - 5,
                x1: (instrumentX ?? 0) + 5,
                y0: alertLevel.y,
                y1: alertLevel.y,
                line: {
                  color:
                    alertLevel.name === translate('EMERGÊNCIA')
                      ? 'red'
                      : alertLevel.name === translate('ATENÇÃO')
                        ? 'yellow'
                        : 'orange',
                  width: 3,
                  dash: 'dash'
                }
              })
            );

            return [mainRectangle, waterRectangle, ...divisionLines];
          }
        );

        // Atualiza o layout do grafico com os shapes criados
        setLayoutChart((prev) => ({
          ...prev,
          shapes: [...shapes]
        }));

        // Atualiza o state do grafico com os dados formatados
        setChartData([
          ...formattedData,
          formattedWaterLevels,
          ...formattedInstruments
        ]);
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleChangeAxisX = ({
    value,
    index: dataIndex,
    name
  }: {
    value: string;
    index: number;
    name: string;
  }) => {
    // Atualiza o shape do instrumento que alterou o eixo x
    const updatedShapes = layoutChart.shapes?.map((shape) => {
      if (shape.name === name) {
        return {
          ...shape,
          x0: Number(value) - 5,
          x1: Number(value) + 5
        };
      }

      if (shape.name === translate('WaterLevel')) {
        return {
          ...shape,
          x: [Number(value)]
        };
      }

      return shape;
    });

    setLayoutChart((prev) => ({
      ...prev,
      shapes: updatedShapes
    }));

    const updatedData = chartData.map((data) => {
      // Atualiza o x do instrumento que alterou o eixo x
      if (data.name === name) {
        return {
          ...data,
          x: [Number(value)]
        };
      }

      // Atualiza o x do nivel d'agua referente ao instrumento que alterou o eixo x
      if (data.name === translate('WaterLevel')) {
        return {
          ...data,
          x: data.x.map((axisX: number, index: number) =>
            index === dataIndex + 1 ? Number(value) : axisX
          )
        };
      }

      // Atualiza o x dos niveis de alerta referente ao instrumento que alterou o eixo x
      if (
        data.name === translate('EMERGÊNCIA') ||
        data.name === translate('ATENÇÃO') ||
        data.name === translate('ALERTA')
      ) {
        return {
          ...data,
          x: data.x.map((axisX: number, index: number) =>
            index === dataIndex ? Number(value) : axisX
          )
        };
      }

      return data;
    });

    const updatedInstruments = instruments.map((instrument, index) => {
      return {
        ...instrument,
        x: index === dataIndex ? Number(value) : instrument.x
      };
    });
    setInstruments(updatedInstruments);
    setChartData(updatedData);
  };

  const handleChangeWidthImage = (value: string) => {
    // Atualiza a largura da imagem
    setImageGraph((prev) => ({
      ...prev,
      sizex: Number(value)
    }));

    // Atualiza o ultimo x do nivel d'agua para acompanhar o fim da imagem
    const waterLevels = chartData.find(
      (item) => item.name === translate('WaterLevel')
    );
    if (waterLevels) {
      setChartData((prev) => {
        return prev.map((item) => {
          if (item.name === translate('WaterLevel')) {
            return {
              ...item,
              x: [0, ...item.x.slice(1, -1), Number(value)]
            };
          }

          return item;
        });
      });
    }
  };

  const handleSaveSectionChart = () => {
    // verifica se existe algum instrumento que não foi salvo
    const instrumentsNewSave = data?.findDataToChart.piezometers
      .map((piezometer) => piezometer.readings[0].x)
      .includes(null);

    if (instrumentsNewSave) {
      saveDataToSectionChart({
        variables: {
          sectionId: selectedSection.value,
          parameters: {
            imageHeight: imageGrapgh.sizey || 0,
            imageWidth: imageGrapgh.sizex || 0,
            inferiorPointY: Number(imageGrapgh.y) || 0,
            instruments: instruments.map((instrument) => ({
              id: instrument.id,
              x: instrument.x
            }))
          }
        },
        refetchQueries: [
          {
            query: FindDataToChartDocument,
            variables: {
              setionId: selectedSection.value
            }
          }
        ],
        onCompleted: () => {
          toastfySuccess(translate('saving'));
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
      return;
    }

    updateSectionChartParameters({
      variables: {
        sectionId: selectedSection.value,
        parameters: {
          imageHeight: imageGrapgh.sizey || 0,
          imageWidth: imageGrapgh.sizex || 0,
          inferiorPointY: Number(imageGrapgh.y) || 0,
          instruments: instruments.map((instrument) => ({
            id: instrument.id,
            x: instrument.x
          }))
        }
      },
      refetchQueries: [
        {
          query: FindDataToChartDocument,
          variables: {
            setionId: selectedSection.value
          }
        }
      ],
      onCompleted: () => {
        toastfySuccess(translate('saving'));
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <DivOptionsContainer>
      <DivFlexColumns>
        <DivOptionsSelectChart>
          <InputSelectSearch
            label={translate('Sections')}
            name="sections"
            options={sectionOptions}
            width="170px"
            placeholder={translate('SelectSection')}
            value={selectedSection}
            onChange={(event) => {
              handleOnChangeSection({
                value: String(event.value),
                label: event.label
              });
            }}
          />
        </DivOptionsSelectChart>
        {userData.role.toLocaleLowerCase() === 'owner' && data && (
          <DivOptionsChart>
            <Text type="label" color="black" weight="bold">
              {translate('Image')}
            </Text>
            <DivInputsContainer>
              <InputText
                name="ImageAxisY"
                label={translate('ImageAxisY')}
                type={'number'}
                onChange={(event) => {
                  // atualiza a onde do y a imagem vai começar
                  setImageGraph((prev) => ({
                    ...prev,
                    y: Number(event.target.value)
                  }));
                }}
                value={String(imageGrapgh.y)}
              />
            </DivInputsContainer>
            <DivInputsContainer>
              <InputText
                name="ImageWidth"
                label={translate('ImageWidth')}
                type={'number'}
                onChange={(event) => {
                  handleChangeWidthImage(event.target.value);
                }}
                value={String(imageGrapgh.sizex)}
              />{' '}
            </DivInputsContainer>
            <DivInputsContainer>
              <InputText
                name="ImageHeight"
                label={translate('ImageHeight')}
                type={'number'}
                onChange={(event) => {
                  // atualiza a altura da imagem
                  setImageGraph((prev) => ({
                    ...prev,
                    sizey: Number(event.target.value)
                  }));
                }}
                value={String(imageGrapgh.sizey)}
              />
            </DivInputsContainer>
            <Text type="h5" color="black">
              {translate('changeCoordinates')}
            </Text>
            {data?.findDataToChart.piezometers.map((piezometer, index) => (
              <DivInputsContainer key={index}>
                <InputText
                  name={`coordinates${index}`}
                  label={piezometer.name}
                  type="number"
                  value={String(
                    instruments.find((item) => item.id === piezometer.id)?.x
                  )}
                  onChange={(event) =>
                    handleChangeAxisX({
                      value: event.target.value,
                      index,
                      name: piezometer.name
                    })
                  }
                />
              </DivInputsContainer>
            ))}
            <Button
              variant="primary"
              text={translate('Save')}
              onClick={handleSaveSectionChart}
            />
          </DivOptionsChart>
        )}
      </DivFlexColumns>
      <DivGraphContainer>
        {loading && <Skeleton height={500} />}
        {data && !loading && (
          <Plot
            data={chartData}
            style={{ width: '90%', height: '90%' }}
            layout={{
              ...layoutChart,
              images: [imageGrapgh]
            }}
            config={{
              responsive: true,
              scrollZoom: true,
              displayModeBar: true,
              displaylogo: false,
              locale: 'pt-BR'
            }}
          />
        )}
      </DivGraphContainer>
    </DivOptionsContainer>
  );
};

export default PhotoChart;
