import { useSearchParams, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import Enums from '../../../../utils/enumns';
import InputText from '../../Molecules/InputText/InputText';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import Button from '../../Atoms/Button/Button';
import {
  HolderInputsForm,
  HolderInputsFormFooter,
  MainContainer
} from './ListInstrumentsFilter.styles';
import { DivInputs } from '../../Pages/EditPiezometerAndInaPage/EditPiezometerAndInaPage.styles';

const FilterFormType = z.object({
  name: z.string().optional(),
  depth: z.string().optional(),
  activationDate: z.date().nullable().optional(),
  deactivationDate: z.date().nullable().optional(),
  installationDate: z.date().nullable().optional(),
  operationalStatus: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional(),
  bottomCote: z.string().optional(),
  topCote: z.string().optional(),
  coordinateE: z.string().optional(),
  coordinateN: z.string().optional(),
  installLocation: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional(),
  readingType: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
});

type FilterFormValues = z.infer<typeof FilterFormType>;

const ListInstrumentsFilter = () => {
  const { TypeOfInstallation, TypeOfReading } = Enums();
  const { t: translate } = useTranslation();
  const { instrumentType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const operationalStatusOptions = [
    { value: 'Active', label: translate('Active') },
    { value: 'Inactive', label: translate('Inactive') }
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType),
    defaultValues: {
      name: searchParams.get('name') || '',
      depth: searchParams.get('depth') || '',
      activationDate: searchParams.get('activationDate')
        ? new Date(searchParams.get('activationDate')!)
        : null,
      deactivationDate: searchParams.get('deactivationDate')
        ? new Date(searchParams.get('deactivationDate')!)
        : null,
      installationDate: searchParams.get('installationDate')
        ? new Date(searchParams.get('installationDate')!)
        : null,
      operationalStatus: searchParams.get('operationalStatus')
        ? {
            value: searchParams.get('operationalStatus')!,
            label: translate(searchParams.get('operationalStatus')!)
          }
        : null,
      bottomCote: searchParams.get('bottomCote') || '',
      topCote: searchParams.get('topCote') || '',
      coordinateE: searchParams.get('coordinateE') || '',
      coordinateN: searchParams.get('coordinateN') || '',
      installLocation: searchParams.get('installLocation')
        ? {
            value: searchParams.get('installLocation')!,
            label: searchParams.get('installLocation')!
          }
        : null,
      readingType: searchParams.get('readingType')
        ? {
            value: searchParams.get('readingType')!,
            label: searchParams.get('readingType')!
          }
        : null
    }
  });

  const handleFilterSubmit = (data: FilterFormValues) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Date) {
          newSearchParams.set(key, value.toISOString());
        } else if (typeof value === 'object' && value !== null) {
          newSearchParams.set(key, value.value);
        } else {
          newSearchParams.set(key, value.toString());
        }
      } else {
        newSearchParams.delete(key);
      }
    });
    newSearchParams.delete('filter');
    setSearchParams(newSearchParams);
  };

  const handleClearFilters = () => {
    reset({
      name: '',
      activationDate: null,
      deactivationDate: null,
      installationDate: null,
      operationalStatus: null,
      bottomCote: '',
      topCote: '',
      coordinateE: '',
      coordinateN: '',
      installLocation: null,
      readingType: null,
      depth: ''
    });
    const newSearchParams = new URLSearchParams(searchParams.toString());
    Object.keys(FilterFormType.shape).forEach((key) => {
      newSearchParams.delete(key);
    });
    setSearchParams(newSearchParams);
  };

  return (
    <MainContainer>
      <HolderInputsForm
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(handleFilterSubmit)();
          }
        }}
      >
        <DivInputs>
          <InputText
            width="300px"
            label={translate('name')}
            type="text"
            control={control}
            name="name"
            error={!!errors.name}
            errorMessage={errors?.name?.message}
          />
        </DivInputs>
        {instrumentType === 'piezometer' && (
          <>
            <DivInputs>
              <InputText
                width="300px"
                label={translate('depth')}
                type="text"
                control={control}
                name="depth"
                error={!!errors.depth}
                errorMessage={errors?.depth?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputText
                width="300px"
                label={translate('bottomCote')}
                type="text"
                control={control}
                name="bottomCote"
                error={!!errors.bottomCote}
                errorMessage={errors?.bottomCote?.message}
              />
            </DivInputs>
            <DivInputs>
              <InputText
                width="300px"
                label={translate('topCote')}
                type="text"
                control={control}
                name="topCote"
                error={!!errors.topCote}
                errorMessage={errors?.topCote?.message}
              />
            </DivInputs>
          </>
        )}
        <DivInputs>
          <InputText
            width="300px"
            label={translate('coordinateE')}
            type="text"
            control={control}
            name="coordinateE"
            error={!!errors.coordinateE}
            errorMessage={errors?.coordinateE?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputText
            width="300px"
            label={translate('coordinateN')}
            type="text"
            control={control}
            name="coordinateN"
            error={!!errors.coordinateN}
            errorMessage={errors?.coordinateN?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            control={control}
            placeholder={translate('operationalStatus')}
            width="300px"
            label={translate('operationalStatus')}
            options={operationalStatusOptions}
            name="operationalStatus"
            error={!!errors.operationalStatus}
            errorMessage={errors?.operationalStatus?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            control={control}
            placeholder={translate('installationSite')}
            width="300px"
            label={translate('installationSite')}
            options={TypeOfInstallation}
            name="installLocation"
            error={!!errors.installLocation}
            errorMessage={errors?.installLocation?.message}
          />
        </DivInputs>
        <DivInputs>
          <InputSelectSearch
            control={control}
            placeholder={translate('readingType')}
            width="300px"
            label={translate('readingType')}
            options={TypeOfReading}
            name="readingType"
            error={!!errors.readingType}
            errorMessage={errors?.readingType?.message}
          />
        </DivInputs>
        <DivInputs>
          <DatepickerInput
            label={translate('installationDate')}
            control={control}
            name="installationDate"
            placeholder={translate('installationDate')}
            time={false}
            error={!!errors.installationDate}
            errorMessage={errors?.installationDate?.message}
          />
        </DivInputs>
        <DivInputs>
          <DatepickerInput
            label={translate('activationDate')}
            control={control}
            name="activationDate"
            placeholder={translate('activationDate')}
            time={false}
            error={!!errors.activationDate}
            errorMessage={errors?.activationDate?.message}
          />
        </DivInputs>

        <DivInputs>
          <DatepickerInput
            label={translate('deactivationDate')}
            control={control}
            name="deactivationDate"
            placeholder={translate('deactivationDate')}
            time={false}
            error={!!errors.deactivationDate}
            errorMessage={errors?.deactivationDate?.message}
          />
        </DivInputs>
      </HolderInputsForm>
      <HolderInputsFormFooter>
        <Button
          variant="secondary"
          size="medium"
          text={translate('cleanFilers')}
          onClick={handleClearFilters}
        />
        <Button
          variant="primary"
          size="medium"
          text={translate('Filtrar')}
          onClick={handleSubmit(handleFilterSubmit)}
        />
      </HolderInputsFormFooter>
    </MainContainer>
  );
};

export default ListInstrumentsFilter;
