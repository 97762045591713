import styled from 'styled-components';

export const ContainerDashboard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 5px 30px 30px 30px;
  box-sizing: border-box;
  overflow: auto;
`;

export const HolderHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HolderContentPage = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 2%;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const DivContainerInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  text-align: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HolderCards = styled.div`
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HolderTable = styled.div`
  width: 100%;
`;

export const HolderVideos = styled.div`
  width: 100%;
`;
