import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles.ts';

interface TextProps {
  $error?: boolean;
  fontSize?: string;
  weight?: string;
  color?: string;
}

export const H1Styled = styled.h1<TextProps>`
  font-size: 36px;
  color: ${(props) => props.color};
`;

export const H2Styled = styled.h2<TextProps>`
  font-size: 30px;
  color: ${(props) => props.color};
`;

export const H3Styled = styled.h3<TextProps>`
  font-size: 22px;
  color: ${(props) => props.color};
`;

export const H4Styled = styled.h4<TextProps>`
  font-size: 20px;
  color: ${(props) => props.color};
`;

export const H5Styled = styled.h5<TextProps>`
  font-size: 18px;
  color: ${(props) => props.color};
`;

export const LabelStyled = styled.label<TextProps>`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.$error ? Color.Red1 : props.color)};
`;

export const SpanStyled = styled.span<TextProps>`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.$error ? Color.Red1 : props.color)};
`;

export const PStyled = styled.p<TextProps>`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => (props.$error ? Color.Red1 : props.color)};
`;
