import styled from 'styled-components';

interface SearchProps {
  $isExpanded: boolean;
}

export const SearchContainer = styled.div<SearchProps>`
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
  width: ${(props) => (props.$isExpanded ? '240px' : '40px')};
  height: 40px;
`;

export const SearchInput = styled.div<SearchProps>`
  border: none;
  outline: none;
  background-color: transparent;
  width: ${(props) => (props.$isExpanded ? '200px' : '0')};
  transition: width 0.3s ease-in-out;
  margin-left: ${(props) => (props.$isExpanded ? '10px' : '0')};
  padding: ${(props) => (props.$isExpanded ? '5px 0' : '0')};
  opacity: ${(props) => (props.$isExpanded ? '1' : '0')};
  display: ${(props) => (props.$isExpanded ? 'block' : 'none')};
`;
