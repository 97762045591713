import { useQuery } from '@apollo/client';
import React from 'react';
import { CiVideoOff } from 'react-icons/ci';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  FindAllAtoVideosDocument,
  FindAllAtoVideosQuery,
  FindAllAtoVideosQueryVariables
} from '../../../../data/graphql/generated/findAllAtoVideos';
import { BUCKET_URL } from '../../../../utils/const';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import { VideosGallery } from '../../Molecules/VideosGallery/VideosGallery';
import ContentActionCard from '../../Organisms/ContentActionCard/ContentActionCard';
import SeeMoreTemplate from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import { ContainerImagesNotFound, DivInput } from './AtoVideos.styles';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import { useTranslation } from 'react-i18next';

const AtoVideos = () => {
  const { atoId, structureId } = useParams<{
    atoId: string;
    structureId: string;
  }>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const { t: translate } = useTranslation();

  const searchValue = searchParams.get('search') || '';
  const { data: getAllAtoVideos, loading } = useQuery<
    FindAllAtoVideosQuery,
    FindAllAtoVideosQueryVariables
  >(FindAllAtoVideosDocument, {
    variables: {
      atoId: atoId ? atoId : ''
    },
    onError: (error) => {
      parseErrorMessage(error);
    }
  });
  const listAllAtoVideos = getAllAtoVideos?.findAllAtoVideos
    .filter((video) => video.recordNumber.includes(searchValue))
    .map((video) => {
      const formattedVideos = video.videos.map((video) => ({
        id: video.video,
        url: `${BUCKET_URL}/${video.video}`,
        name: video.video
      }));
      return (
        <ContentActionCard key={video.recordNumber} title={video.recordNumber}>
          <VideosGallery videos={formattedVideos} />
        </ContentActionCard>
      );
    });

  const content =
    (listAllAtoVideos || []).length > 0 ? (
      listAllAtoVideos
    ) : (
      <ContainerImagesNotFound>
        <CiVideoOff color="black" size={40} />
        <Text type="h4">{translate('VideosNotSearch')}</Text>
      </ContainerImagesNotFound>
    );

  const navigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}/records`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ search: event.target.value });
  };

  return (
    <SeeMoreTemplate
      icon={<Icon Icon={IoIosArrowBack} onClick={() => navigateBack()} />}
      title={translate('video')}
      header={
        <DivInput>
          <InputText
            label={translate('RdoNumber')}
            type="number"
            value={searchValue}
            onChange={handleSearchChange}
            name="search"
          />
        </DivInput>
      }
      content={content}
      loading={loading}
    />
  );
};

export default AtoVideos;
