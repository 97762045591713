import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindLastGraphicBySectionQueryVariables = Types.Exact<{
  sectionId: Types.Scalars['String']['input'];
}>;

export type FindLastGraphicBySectionQuery = {
  __typename?: 'Query';
  findLastGraphicBySection: string;
};

export const FindLastGraphicBySectionDocument = gql`
  query FindLastGraphicBySection($sectionId: String!) {
    findLastGraphicBySection(sectionId: $sectionId)
  }
`;

export type FindLastGraphicBySectionQueryResult = Apollo.QueryResult<
  FindLastGraphicBySectionQuery,
  FindLastGraphicBySectionQueryVariables
>;
