import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';

import { useMutation } from '@apollo/client';
import {
  DeleteRecordRedAlertPeriodDocument,
  DeleteRecordRedAlertPeriodMutation,
  DeleteRecordRedAlertPeriodMutationVariables
} from '../../../../data/graphql/generated/deleteRecordRedAlertPeriod';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AlertPeriodForm from '../AlertPeriodForm/AlertPeriodForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoAlertPeriodProps,
  EditProps,
  RedAlertPeriodItem,
  RedAlertPeriodType,
  RowToEditType
} from './CardAtoInfoAlertPeriod.interface';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const CardAtoInfoAlertPeriod = ({
  redAlertPeriod,
  canAdd
}: CardAtoInfoAlertPeriodProps) => {
  const { t: translate } = useTranslation();
  const { recordId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const [updateDelete, setUpdateDelete] = useState(false);
  const [edit, setEdit] = useState<EditProps>({
    show: false,
    alertPeriod: null
  });
  const [idForDelete, setIdForDelete] = useState<string>('');

  const [deleteRedAlertPeriod] = useMutation<
    DeleteRecordRedAlertPeriodMutation,
    DeleteRecordRedAlertPeriodMutationVariables
  >(DeleteRecordRedAlertPeriodDocument);

  const tableColumns = [
    { key: 'indexId', label: '' },
    { key: 'start', label: translate('start') },
    { key: 'end', label: translate('End') }
  ];

  const tableData = redAlertPeriod.map((item: RedAlertPeriodType, index) => ({
    ...item,
    indexId: index + 1
  }));

  const actions = [
    {
      name: translate('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: translate('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRedAlertPeriod({
      variables: {
        deleteRecordRedAlertPeriodId: id
      },
      onCompleted: () => {
        toastfySuccess('deletedSuccessfully');
        setUpdateDelete(!updateDelete);
      },
      onError: (error) => {
        parseErrorMessage(error);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            redAlertPeriods:
              existingData.findDataByRecord.redAlertPeriods.filter(
                (item: RedAlertPeriodItem) => {
                  return item.id !== id;
                }
              )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setEdit({ show: true, alertPeriod: row });
  };

  const handleCloseEdit = () => {
    setEdit({ show: false, alertPeriod: null });
  };

  const handleAddForm = () => {
    setEdit({ show: true, alertPeriod: null });
  };

  return (
    <>
      <CardAtoInfo
        title={translate('PeriodOfRedAlert')}
        buttonLabel={translate('Add+')}
        onButtonClick={handleAddForm}
        canAdd={canAdd}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      <ViewModal
        showModal={edit.show}
        width={40}
        height={70}
        title={
          edit.alertPeriod?.id
            ? translate('editRedAlertPeriod')
            : translate('addRedAlertPeriod')
        }
        onClose={handleCloseEdit}
        component={
          <AlertPeriodForm
            setEdit={setEdit}
            idAlertPeriod={edit.alertPeriod?.id}
          />
        }
      />

      <ModalConfirm
        showModal={!!idForDelete}
        title={`${translate('Delete')} ${translate('Activity')}`}
        text={translate('ConfirmDelete')}
        subtitle={translate('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};

export default CardAtoInfoAlertPeriod;
