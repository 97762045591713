import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindSectionInstrumentsQueryVariables = Types.Exact<{
  data: Types.SectionInstrumentsInput;
}>;


export type FindSectionInstrumentsQuery = { __typename?: 'Query', findSectionInstruments: Array<{ __typename?: 'SectionInstrumentsType', id: string, instrumentName: string }> };


export const FindSectionInstrumentsDocument = gql`
    query FindSectionInstruments($data: SectionInstrumentsInput!) {
  findSectionInstruments(data: $data) {
    id
    instrumentName
  }
}
    `;
export type FindSectionInstrumentsQueryResult = Apollo.QueryResult<FindSectionInstrumentsQuery, FindSectionInstrumentsQueryVariables>;