import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindDataByInspectionQueryVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
}>;

export type FindDataByInspectionQuery = {
  __typename?: 'Query';
  findDataByInspection: {
    __typename?: 'InspectionDataType';
    generalComments?: {
      __typename?: 'InspectionGeneralCommentsType';
      comment: string;
      images: Array<{
        __typename?: 'InspectionFileType';
        id: string;
        fileName: string;
        url: string;
      }>;
    } | null;
    accessSituation: Array<{
      __typename?: 'InspectionGenericDataType';
      observation?: string | null;
      answer: Types.AnswerEnum;
      topic: string;
      title: Types.TitleEnum;
      id: string;
      images?: Array<{
        __typename?: 'InspectionDataImageType';
        id: string;
        image: string;
        status: boolean;
        latitude?: number | null;
        longitude?: number | null;
      }> | null;
    }>;
    reservoir: Array<{
      __typename?: 'InspectionGenericDataType';
      observation?: string | null;
      answer: Types.AnswerEnum;
      topic: string;
      title: Types.TitleEnum;
      id: string;
      images?: Array<{
        __typename?: 'InspectionDataImageType';
        id: string;
        image: string;
        status: boolean;
        latitude?: number | null;
        longitude?: number | null;
      }> | null;
    }>;
    extravasator: Array<{
      __typename?: 'InspectionGenericDataType';
      observation?: string | null;
      answer: Types.AnswerEnum;
      title: Types.TitleEnum;
      id: string;
      topic: string;
      images?: Array<{
        __typename?: 'InspectionDataImageType';
        id: string;
        image: string;
        status: boolean;
        latitude?: number | null;
        longitude?: number | null;
      }> | null;
    }>;
    instrumentation: Array<{
      __typename?: 'InspectionGenericDataType';
      observation?: string | null;
      answer: Types.AnswerEnum;
      topic: string;
      title: Types.TitleEnum;
      id: string;
      images?: Array<{
        __typename?: 'InspectionDataImageType';
        id: string;
        image: string;
        status: boolean;
        latitude?: number | null;
        longitude?: number | null;
      }> | null;
    }>;
    waterWastePumpingPiping: Array<{
      __typename?: 'InspectionGenericDataType';
      observation?: string | null;
      answer: Types.AnswerEnum;
      topic: string;
      title: Types.TitleEnum;
      id: string;
      images?: Array<{
        __typename?: 'InspectionDataImageType';
        id: string;
        image: string;
        status: boolean;
        latitude?: number | null;
        longitude?: number | null;
      }> | null;
    }>;
    conservationState: Array<{
      __typename?: 'InspectionConservationStateType';
      id: string;
      title: Types.ConservationStateTitleEnum;
      topic: string;
      note: Types.ConservationStateNoteEnum;
    }>;
    massiveStructureShoulder: {
      __typename?: 'MassiveStructureShoulderDataType';
      coating?: {
        __typename?: 'MssGenericDataWithoutTopicType';
        observation?: string | null;
        answer: Types.AnswerEnum;
        title: Types.MssGenericDataTitleEnum;
        id: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      } | null;
      surfaceErosions?: {
        __typename?: 'MssGenericDataWithoutTopicType';
        observation?: string | null;
        answer: Types.AnswerEnum;
        title: Types.MssGenericDataTitleEnum;
        id: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      } | null;
      drainageChannel?: Array<{
        __typename?: 'MssGenericDataWithTopicType';
        id: string;
        title: Types.MssGenericDataTitleEnum;
        subTitle?: string | null;
        answer: Types.AnswerEnum;
        observation?: string | null;
        topic: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
      internalDrainage?: Array<{
        __typename?: 'MssGenericDataWithTopicType';
        topic: string;
        observation?: string | null;
        answer: Types.AnswerEnum;
        title: Types.MssGenericDataTitleEnum;
        id: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
      presenceOfTreesAnimalsInsects?: Array<{
        __typename?: 'MssGenericDataWithTopicType';
        topic: string;
        observation?: string | null;
        answer: Types.AnswerEnum;
        title: Types.MssGenericDataTitleEnum;
        id: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
      surfaceDrainage?: Array<{
        __typename?: 'MssGenericDataWithTopicType';
        topic: string;
        observation?: string | null;
        answer: Types.AnswerEnum;
        title: Types.MssGenericDataTitleEnum;
        id: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
      identifiedCracks?: Array<{
        __typename?: 'MssIdentifiedCracksWithImageType';
        id: string;
        name: string;
        length: string;
        opening: string;
        longitudinalTransverse: string;
        observation?: string | null;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
      identifiedDeformations?: Array<{
        __typename?: 'MssIdentifiedDeformationsWithImageType';
        observation?: string | null;
        local: string;
        id: string;
        identifiedAnomaly: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
      identifiedWaterLeaks?: Array<{
        __typename?: 'MssIdentifiedWaterLeaksWithImageType';
        observation?: string | null;
        visibleSolidTransport: string;
        local: string;
        name: string;
        id: string;
        images?: Array<{
          __typename?: 'InspectionDataImageType';
          id: string;
          image: string;
          status: boolean;
          latitude?: number | null;
          longitude?: number | null;
        }> | null;
      }> | null;
    };
  };
};

export const FindDataByInspectionDocument = gql`
  query FindDataByInspection($inspectionId: String!) {
    findDataByInspection(inspectionId: $inspectionId) {
      generalComments {
        comment
        images {
          id
          fileName
          url
        }
      }
      accessSituation {
        images {
          id
          image
          status
          latitude
          longitude
        }
        observation
        answer
        topic
        title
        id
      }
      reservoir {
        images {
          id
          image
          status
          latitude
          longitude
        }
        observation
        answer
        topic
        title
        id
      }
      extravasator {
        images {
          id
          image
          status
          latitude
          longitude
        }
        observation
        answer
        title
        id
        topic
      }
      instrumentation {
        images {
          id
          image
          status
          latitude
          longitude
        }
        observation
        answer
        topic
        title
        id
      }
      waterWastePumpingPiping {
        images {
          id
          image
          status
          latitude
          longitude
        }
        observation
        answer
        topic
        title
        id
      }
      conservationState {
        id
        title
        topic
        note
      }
      massiveStructureShoulder {
        coating {
          images {
            id
            image
            status
            latitude
            longitude
          }
          observation
          answer
          title
          id
        }
        surfaceErosions {
          images {
            id
            image
            status
            latitude
            longitude
          }
          observation
          answer
          title
          id
        }
        drainageChannel {
          images {
            id
            image
            status
            latitude
            longitude
          }
          id
          title
          subTitle
          answer
          observation
          topic
        }
        internalDrainage {
          images {
            id
            image
            status
            latitude
            longitude
          }
          topic
          observation
          answer
          title
          id
        }
        presenceOfTreesAnimalsInsects {
          images {
            id
            image
            status
            latitude
            longitude
          }
          topic
          observation
          answer
          title
          id
        }
        surfaceDrainage {
          images {
            id
            image
            status
            latitude
            longitude
          }
          topic
          observation
          answer
          title
          id
        }
        identifiedCracks {
          images {
            id
            image
            status
            latitude
            longitude
          }
          id
          name
          length
          opening
          longitudinalTransverse
          observation
        }
        identifiedDeformations {
          images {
            id
            image
            status
            latitude
            longitude
          }
          observation
          local
          id
          identifiedAnomaly
        }
        identifiedWaterLeaks {
          images {
            id
            image
            status
            latitude
            longitude
          }
          observation
          visibleSolidTransport
          local
          name
          id
        }
      }
    }
  }
`;

export type FindDataByInspectionQueryResult = Apollo.QueryResult<
  FindDataByInspectionQuery,
  FindDataByInspectionQueryVariables
>;
