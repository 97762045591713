import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveAtoRecordActivityVideoMutationVariables = Types.Exact<{
  data: Types.SaveActivityVideoInput;
}>;


export type SaveAtoRecordActivityVideoMutation = { __typename?: 'Mutation', saveAtoRecordActivityVideo: string };


export const SaveAtoRecordActivityVideoDocument = gql`
    mutation SaveAtoRecordActivityVideo($data: SaveActivityVideoInput!) {
  saveAtoRecordActivityVideo(data: $data)
}
    `;
export type SaveAtoRecordActivityVideoMutationFn = Apollo.MutationFunction<SaveAtoRecordActivityVideoMutation, SaveAtoRecordActivityVideoMutationVariables>;
export type SaveAtoRecordActivityVideoMutationResult = Apollo.MutationResult<SaveAtoRecordActivityVideoMutation>;
export type SaveAtoRecordActivityVideoMutationOptions = Apollo.BaseMutationOptions<SaveAtoRecordActivityVideoMutation, SaveAtoRecordActivityVideoMutationVariables>;