import { Map, Marker } from '@vis.gl/react-google-maps';
import { useStructureSummary } from '../../data/hooks/use-structure-summary.query';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  DEFAULT_CENTER_BRAZIL,
  DEFAULT_MAP_ID_FOR_API_CONTEXT,
  DEFAULT_MAP_ZOOM,
  WITH_CENTER_MAP_ZOOM
} from './mapGoogle.constants';
import MarkerComponent from './MapGoogleComponents/MarkerComponent';

export interface MarkerData {
  id: string;
  title: string;
  lat: number;
  lng: number;
  color: string | null;
  type: string;
  infoData?: { key: string; value: string | null }[];
}

const MapGoogle = ({ markersData }: { markersData?: MarkerData[] }) => {
  const { structureId } = useParams();
  const { data: structure, loading } = useStructureSummary(
    '',
    structureId || ''
  );

  const { latitude, longitude } = structure?.getStructureSummary?.summary || {};

  const shouldRenderCenterPosition = latitude && longitude;
  const defaultZoom = shouldRenderCenterPosition
    ? WITH_CENTER_MAP_ZOOM
    : DEFAULT_MAP_ZOOM;
  const defaultCenter = shouldRenderCenterPosition
    ? { lat: latitude!, lng: longitude! }
    : DEFAULT_CENTER_BRAZIL;

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <Map
          mapId={DEFAULT_MAP_ID_FOR_API_CONTEXT}
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          mapTypeId={window.google?.maps?.MapTypeId?.SATELLITE}
          streetViewControl={false}
        >
          {markersData?.map((marker) => (
            <>
              <Marker
                position={{ lat: latitude!, lng: longitude! }}
                title={structure?.getStructureSummary?.name}
              />
              <MarkerComponent key={marker.id} marker={marker} />
            </>
          ))}
        </Map>
      )}
    </>
  );
};

export default MapGoogle;
