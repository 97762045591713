import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  FindStabilityAnalysisGraphicByIdQuery,
  FindStabilityAnalysisGraphicByIdQueryVariables,
  FindStabilityAnalysisGraphicByIdDocument
} from '../../../../../data/graphql/query/generated/findStabilityAnalysisGraphById';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DivInfo,
  HolderImage,
  HolderInfosData,
  MainModalContainer
} from './GraphicStability.styles';
import Image from '../../../Atoms/Image/Image';
import { BUCKET_URL } from '../../../../../utils/const';
import useErrorsTreatment from '../../../../../Hooks/useErrorsTreatment';
import { GroupText } from '../../../Molecules/GroupText/GroupText';
import Icon from '../../../Atoms/Icon/Icon';
import Text from '../../../Atoms/Text/Text';
import { MdImageNotSupported } from 'react-icons/md';
import FileViewer from '../../../Molecules/FileViewer/FileViewer';
import { useState } from 'react';
import { FileType } from '../../../Molecules/FileViewer/FileViewer.interfaces';

const GraphicStability = () => {
  const { t: translate } = useTranslation();
  const { structureId, stabilityId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();
  const navigate = useNavigate();

  const [image, setImage] = useState<FileType[]>([]);

  const { data: findGraphicById } = useQuery<
    FindStabilityAnalysisGraphicByIdQuery,
    FindStabilityAnalysisGraphicByIdQueryVariables
  >(FindStabilityAnalysisGraphicByIdDocument, {
    variables: {
      stabilityAnalysisId: stabilityId!
    },
    onError: (error) => {
      parseErrorMessage(error);
      navigate(`/${structureId}/stabilityanalysis/history`);
    }
  });

  const { graphicInfo, graphicName, graphicStatus, graphicUrl } =
    findGraphicById?.findStabilityAnalysisGraphicById ?? {};

  const { graphParams, instrumentsList, materialList, slideFileName } =
    graphicInfo ?? {};

  const { analysisDate, calculationMethod, failureDirection, filter } =
    graphParams ?? {};

  const { area, depth, elevation, weight } = filter ?? {};

  return (
    <MainModalContainer>
      <HolderInfosData>
        <DivInfo>
          <GroupText title="name" value={graphicName ?? '-'} />
        </DivInfo>
        <DivInfo>
          <GroupText title="status" value={graphicStatus ?? '-'} tradution />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="failureDirection"
            value={failureDirection ?? '-'}
            tradution
          />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="calculationMethod"
            value={calculationMethod ?? '-'}
            tradution
          />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="Date"
            value={
              analysisDate ? new Date(analysisDate).toLocaleDateString() : '-'
            }
          />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="searchMethod"
            value={
              findGraphicById?.findStabilityAnalysisGraphicById.graphicInfo
                .graphParams.searchMethod ?? '-'
            }
            tradution
          />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="surfaceType"
            value={
              findGraphicById?.findStabilityAnalysisGraphicById.graphicInfo
                .graphParams.surfaceType ?? '-'
            }
            tradution
          />
        </DivInfo>
        <DivInfo>
          <GroupText title="Area" value={area?.toString() ?? '-'} />
        </DivInfo>
        <DivInfo>
          <GroupText title="depth" value={depth?.toString() ?? '-'} />
        </DivInfo>
        <DivInfo>
          <GroupText title="elevation" value={elevation?.toString() ?? '-'} />
        </DivInfo>
        <DivInfo>
          <GroupText title="Weight" value={weight?.toString() ?? '-'} />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="Instruments"
            value={
              instrumentsList
                ?.map((instrument) => {
                  return translate(instrument);
                })
                .join(', ') ?? '-'
            }
          />
        </DivInfo>
        <DivInfo>
          <GroupText
            title="materials"
            value={
              materialList
                ?.map((material) => {
                  return translate(material);
                })
                .join(', ') ?? '-'
            }
          />
        </DivInfo>
        <DivInfo>
          <GroupText title="slideFile" value={slideFileName ?? '-'} />
        </DivInfo>
      </HolderInfosData>
      <HolderImage>
        {graphicUrl ? (
          <Image
            src={`${BUCKET_URL}/${graphicUrl}`}
            alt={'graphicImage'}
            objectFit="contain"
            height="90%"
            width="90%"
            onClick={() =>
              setImage([
                {
                  id: null,
                  name: slideFileName ?? '',
                  url: `${BUCKET_URL}/${graphicUrl}`
                }
              ])
            }
          />
        ) : (
          <>
            <Icon Icon={MdImageNotSupported} variant="large" />
            <Text type="span">{translate('notDenerated')}</Text>
          </>
        )}
      </HolderImage>

      <FileViewer
        showModal={image.length > 0}
        files={image}
        onClose={() => setImage([])}
        selectedFile={image[0]}
        newPortal={true}
      />
    </MainModalContainer>
  );
};

export default GraphicStability;
