import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindInstrumentsInfoToGmcQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;

export type FindInstrumentsInfoToGmcQuery = {
  __typename?: 'Query';
  findInstrumentsInfoToGMC: {
    __typename?: 'ListInstrumentsInfoGMCType';
    instrumentsCount: number;
    attentionCount: number;
    emergencyCount: number;
    alertCount: number;
    instruments: Array<{
      __typename?: 'InstrumentsToGMCType';
      instrumentId: string;
      instrumentName: string;
      lat: number;
      lon: number;
      instrumentType: Types.InstrumentTypeEnum;
      situation?: string | null;
      readingDate?: Date | null;
      battery?: number | null;
      loggerTemperature?: number | null;
      signalStrength?: number | null;
      auxBattery?: number | null;
      thermistor?: number | null;
      reading?: number | null;
      operationalStatus?: Types.InstrumentStatusEnum | null;
    }>;
  };
};

export const FindInstrumentsInfoToGmcDocument = gql`
  query FindInstrumentsInfoToGMC($structureId: String!) {
    findInstrumentsInfoToGMC(structureId: $structureId) {
      instrumentsCount
      attentionCount
      emergencyCount
      alertCount
      instruments {
        instrumentId
        instrumentName
        lat
        lon
        instrumentType
        situation
        readingDate
        battery
        loggerTemperature
        signalStrength
        auxBattery
        thermistor
        reading
        operationalStatus
      }
    }
  }
`;

export type FindInstrumentsInfoToGmcQueryResult = Apollo.QueryResult<
  FindInstrumentsInfoToGmcQuery,
  FindInstrumentsInfoToGmcQueryVariables
>;
