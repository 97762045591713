import { useState } from 'react';
import {
  AccordionContainer,
  AccordionItem,
  AccordionHeader,
  AccordionTitle,
  AccordionContent,
  DivText
} from './NotificationAccordion.styles';
import {
  AccordionProps,
  ItemAccordion
} from './NotiicationAccordion.interfaces';
import { useTranslation } from 'react-i18next';
import Icon from '../../Atoms/Icon/Icon';
import { IoNotificationsSharp, IoRemove } from 'react-icons/io5';
import { BiPlus } from 'react-icons/bi';
import Text from '../../Atoms/Text/Text';

const NotificationAccordion = ({ notifications, onClick }: AccordionProps) => {
  const { t: translate } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number, notification: ItemAccordion) => {
    setOpenIndex(openIndex === index ? null : index);
    onClick(notification);
  };

  return (
    <AccordionContainer>
      {notifications.map((notification, index) => (
        <AccordionItem key={index}>
          <AccordionHeader
            $unread={notification.unread}
            onClick={() => handleToggle(index, notification)}
          >
            <AccordionTitle>
              <Icon
                Icon={IoNotificationsSharp}
                color={notification.unread ? 'gray' : 'brown1'}
              />
              <DivText>
                <Text
                  type="span"
                  weight={notification.unread ? 'default' : 'bold'}
                  color={notification.unread ? 'black' : 'brown'}
                >
                  {translate('Notification')}
                </Text>
                <Text type="span" size="small" color="black">
                  {notification.createdAt}
                </Text>
              </DivText>
            </AccordionTitle>
            <Icon
              Icon={openIndex === index ? IoRemove : BiPlus}
              color={notification.unread ? 'gray' : 'brown1'}
            />
          </AccordionHeader>
          <AccordionContent $isOpen={openIndex === index}>
            <div dangerouslySetInnerHTML={{ __html: notification.body }} />
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionContainer>
  );
};

export default NotificationAccordion;
