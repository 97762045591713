import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import InputText from '../../../Molecules/InputText/InputText';
import Button from '../../../Atoms/Button/Button';
import { DivButton, DivContainer, DivInput } from './MaterialsForm.styles';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  SaveStructureMaterialDocument,
  SaveStructureMaterialMutation,
  SaveStructureMaterialMutationVariables
} from '../../../../../data/graphql/generated/saveStructureMaterial';
import { toastfySuccess } from '../../../../Toastify';
import useErrorsTreatment from '../../../../../Hooks/useErrorsTreatment';
import { FindStructureMaterialsDocument } from '../../../../../data/graphql/query/generated/findStructureMaterials';

const materialsSchema = z.object({
  name: z.string().min(1, { message: 'fieldIsRequired' })
});

type materialsSchemaType = z.infer<typeof materialsSchema>;

export const MaterialsForm = () => {
  const { structureId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const [saveMaterial] = useMutation<
    SaveStructureMaterialMutation,
    SaveStructureMaterialMutationVariables
  >(SaveStructureMaterialDocument);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<materialsSchemaType>({
    resolver: zodResolver(materialsSchema)
  });

  const handleSave = (data: materialsSchemaType) => {
    saveMaterial({
      variables: {
        data: {
          structureId: structureId!,
          materialName: data.name
        }
      },
      refetchQueries: [
        {
          query: FindStructureMaterialsDocument,
          variables: { structureId: structureId! }
        }
      ],
      onCompleted: () => {
        toastfySuccess('registeredSuccessfully');
        reset();
      },
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <DivContainer
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSubmit(handleSave)();
        }
      }}
    >
      <DivInput>
        <InputText
          width="215px"
          control={control}
          label="Name"
          name="name"
          type="text"
          error={!!errors.name}
          errorMessage={errors?.name?.message}
        />
      </DivInput>
      <DivButton>
        <Button
          variant={'primary'}
          text={'Save'}
          onClick={handleSubmit(handleSave)}
        />
      </DivButton>
    </DivContainer>
  );
};

export default MaterialsForm;
