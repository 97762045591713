import { z } from 'zod';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputText from '../../Molecules/InputText/InputText';
import { useTranslation } from 'react-i18next';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { ReadingPluviometerProps } from './ReadingPluviometer.interfaces';
import {
  CreatePluviometerReadingMutation,
  CreatePluviometerReadingMutationVariables,
  CreatePluviometerReadingDocument
} from '../../../../data/graphql/generated/createPluviometerReading.mutation';
import { ListReadingByPluviometerPagDocument } from '../../../../data/graphql/query/generated/listReadingByPluviometerPag.query';
import {
  ListPluviometersByStructureDocument,
  ListPluviometersByStructureQuery,
  ListPluviometersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listPluviometersByStructure.query';
import { HolderFooter, HolderForm } from './ReadingPluviometer.styles';
import {
  EditPluviometerReadingMutation,
  EditPluviometerReadingMutationVariables,
  EditPluviometerReadingDocument
} from '../../../../data/graphql/generated/editPluviometerReading.mutation';
import { DivInput } from '../ReadingSurfaceMarkerForm/ReadingSurfaceMarker.styles';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';

const formReadingSchema = z.object({
  instrument: z
    .object({
      value: z.string().min(1, 'fieldIsRequired'),
      label: z.string()
    })
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  rainfall: z.string().min(1, 'fieldIsRequired'),
  date: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  observation: z.string().nullable()
});

type FormReadingSchemaType = z.infer<typeof formReadingSchema>;

const ReadingPluviometer = ({
  reading,
  setShowModal
}: ReadingPluviometerProps) => {
  const { structureId } = useParams();
  const { t: translate } = useTranslation();
  const { parseErrorMessage } = useErrorsTreatment();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormReadingSchemaType>({
    resolver: zodResolver(formReadingSchema),
    defaultValues: {
      rainfall: reading?.rainfall || '',
      date: reading?.date ? new Date(reading?.date) : new Date(),
      observation: reading?.observation || '',
      instrument: reading?.instrument
        ? {
            value: reading?.instrument.id,
            label: reading?.instrument.name
          }
        : {
            value: '',
            label: ''
          }
    }
  });

  const [createReading] = useMutation<
    CreatePluviometerReadingMutation,
    CreatePluviometerReadingMutationVariables
  >(CreatePluviometerReadingDocument);

  const [editPluviometerReading] = useMutation<
    EditPluviometerReadingMutation,
    EditPluviometerReadingMutationVariables
  >(EditPluviometerReadingDocument);

  const { data: listPluviometerReadings } = useQuery<
    ListPluviometersByStructureQuery,
    ListPluviometersByStructureQueryVariables
  >(ListPluviometersByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId ? structureId : ''
      }
    }
  });

  const listPluviometerOptions =
    listPluviometerReadings?.listPluviometersByStructure.map((instrument) => ({
      value: instrument.id!,
      label: instrument.name
    }));

  const handleSaveReading = (data: FormReadingSchemaType) => {
    createReading({
      variables: {
        data: {
          instrumentId: data.instrument.value,
          instrumentName: data.instrument.label,
          rainfall: parseFloat(data.rainfall),
          date: new Date(data.date.setHours(0, 0, 0, 0)),
          observation: data.observation
        },
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('registeredSuccessfully'));
      },
      refetchQueries: [ListReadingByPluviometerPagDocument],
      awaitRefetchQueries: true,
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  const handleEditReading = (data: FormReadingSchemaType) => {
    editPluviometerReading({
      variables: {
        data: {
          id: reading!.id!,
          rainfall: parseFloat(data.rainfall),
          date: new Date(data.date.setHours(0, 0, 0, 0)),
          observation: data.observation
        },
        structureInfo: {
          structureId: structureId!
        }
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updatedSuccessfully'));
      },
      refetchQueries: [ListReadingByPluviometerPagDocument],
      awaitRefetchQueries: true,
      onError: (error) => {
        parseErrorMessage(error);
      }
    });
  };

  return (
    <HolderForm
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          reading
            ? handleSubmit(handleEditReading)()
            : handleSubmit(handleSaveReading)();
        }
      }}
    >
      {!reading?.id && (
        <InputSelectSearch
          width="400px"
          placeholder={translate('SelectInstrument')}
          control={control}
          label={translate('Instruments')}
          options={listPluviometerOptions || []}
          name={'instrument'}
          error={!!errors.instrument}
          errorMessage={errors?.instrument?.message}
        />
      )}
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('rainfall')}
          name={'rainfall'}
          type={'number'}
          error={!!errors.rainfall}
          errorMessage={errors?.rainfall?.message}
        />
      </DivInput>
      <DatepickerInput
        width="330px"
        control={control}
        label={translate('date')}
        name="date"
        placeholder={translate('date')}
        time={false}
        error={!!errors.date}
        errorMessage={errors?.date?.message}
      />
      <DivInput>
        <InputText
          width="400px"
          control={control}
          label={translate('observation')}
          name={'observation'}
          type={'text'}
          error={!!errors.observation}
          errorMessage={errors?.observation?.message}
        />
      </DivInput>
      <HolderFooter>
        <Button
          size="large"
          text={reading ? `${translate('edit')}` : `${translate('create')}`}
          onClick={
            reading
              ? handleSubmit(handleEditReading)
              : handleSubmit(handleSaveReading)
          }
          variant={'primary'}
        />
      </HolderFooter>
    </HolderForm>
  );
};

export default ReadingPluviometer;
