import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import InputSelectSearch from '../InputSelectSearch/InputSelectSearch';
import { OptionType, PaginatorProps } from './Paginator.interfaces';
import { DivContainer, DivContent } from './Paginator.styles';

const Paginator = ({
  options,
  showInUrl,
  totalPages,
  pageState
}: PaginatorProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { totalForPage, currentPage } = showInUrl
    ? {
        totalForPage: searchParams.get('totalForPage')
          ? {
              value: parseInt(searchParams.get('totalForPage') || '10'),
              label: searchParams.get('totalForPage')
            }
          : { value: 10, label: '10' },
        currentPage: parseInt(searchParams.get('currentPage') || '1')
      }
    : {
        totalForPage: pageState?.totalForPageValue,
        currentPage: pageState?.currentPage || 1
      };

  const handlePageChange = (newPage: number) => {
    if (showInUrl) {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('currentPage', newPage.toString());
        return newParams;
      });
    } else if (pageState) {
      newPage > currentPage ? pageState.nextPage() : pageState.previousPage();
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleSetCountForPage = (value: OptionType) => {
    if (showInUrl) {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('totalForPage', value.value.toString());
        newParams.set('currentPage', '1');
        return newParams;
      });
    } else if (pageState && pageState.totalForPage) {
      pageState.totalForPage(value);
    }
  };

  return (
    <DivContainer>
      <DivContent>
        <Icon
          Icon={IoIosArrowBack}
          onClick={handlePreviousPage}
          variant="small"
        />
        <Text type="span">
          {currentPage === 0 ? 1 : currentPage} {t('of')}{' '}
          {totalPages || pageState?.totalPages}
        </Text>
        <Icon
          Icon={IoIosArrowForward}
          onClick={handleNextPage}
          variant="small"
        />
        {totalForPage && (
          <>
            <Text type="span"> {`${t('linesPerPage')}:`} </Text>
            <InputSelectSearch
              label=""
              name="countForPage"
              options={options}
              hideSelectedOptions
              width="40px"
              value={totalForPage}
              menuPlacement="top"
              onChange={handleSetCountForPage}
            />
          </>
        )}
      </DivContent>
    </DivContainer>
  );
};

export default Paginator;
