import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../Components/Forms/FormInspectionsStyle';
import {
  ConservationStateNoteEnum,
  ConservationStateTitleEnum,
  SaveConservationStateInput
} from '../../../data/graphql/base-schema';
import {
  ContainerSeventhStep,
  InspectionTextArea,
  RegisterArea,
  SectionSevenStep
} from '../InspectionsStyle';
import InputFileWithList from '../../../Components/V2/Organisms/InputFileWithList/InputFileWithList';
import { FileType } from '../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';
import { useMutation } from '@apollo/client';
import {
  DeleteGeneralCommentsFileMutation,
  DeleteGeneralCommentsFileMutationVariables,
  DeleteGeneralCommentsFileDocument
} from '../../../data/graphql/generated/deleteGeneralCommentsFile.mutation';
import { toastfySuccess } from '../../../Components/Toastify';

const VALUES_NOTE = {
  Zero: 0,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Ten: 10
};

const NOTE_VALUES = {
  0: 'Zero',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  10: 'Ten'
};

const TITLES = [
  ConservationStateTitleEnum.ReliabilityExtravasationStructures,
  ConservationStateTitleEnum.Percolation,
  ConservationStateTitleEnum.Deformations,
  ConservationStateTitleEnum.SlopeAndParametersDeterioration,
  ConservationStateTitleEnum.SurfaceDrainage
];

const CARDS = [
  [
    {
      topic: 'ReliabilityExtravasationStructures_0',
      value: 0,
      title: 'ReliabilityExtravasationStructures'
    },
    {
      topic: 'ReliabilityExtravasationStructures_3',
      value: 3,
      title: 'ReliabilityExtravasationStructures'
    },
    {
      topic: 'ReliabilityExtravasationStructures_6',
      value: 6,
      title: 'ReliabilityExtravasationStructures'
    },
    {
      topic: 'ReliabilityExtravasationStructures_10',
      value: 10,
      title: 'ReliabilityExtravasationStructures'
    }
  ],
  [
    {
      topic: 'Percolation_0',
      value: 0,
      title: 'Percolation'
    },
    {
      topic: 'Percolation_3',
      value: 3,
      title: 'Percolation'
    },
    {
      topic: 'Percolation_6',
      value: 6,
      title: 'Percolation'
    },
    {
      topic: 'Percolation_10',
      value: 10,
      title: 'Percolation'
    }
  ],
  [
    {
      topic: 'Deformations_0',
      value: 0,
      title: 'Deformations'
    },
    {
      topic: 'Deformations_2',
      value: 2,
      title: 'Deformations'
    },
    {
      topic: 'Deformations_6',
      value: 6,
      title: 'Deformations'
    },
    {
      topic: 'Deformations_10',
      value: 10,
      title: 'Deformations'
    }
  ],
  [
    {
      topic: 'SlopeAndParametersDeterioration_0',
      value: 0,
      title: 'SlopeAndParametersDeterioration'
    },
    {
      topic: 'SlopeAndParametersDeterioration_2',
      value: 2,
      title: 'SlopeAndParametersDeterioration'
    },
    {
      topic: 'SlopeAndParametersDeterioration_6',
      value: 6,
      title: 'SlopeAndParametersDeterioration'
    },
    {
      topic: 'SlopeAndParametersDeterioration_10',
      value: 10,
      title: 'SlopeAndParametersDeterioration'
    }
  ],
  [
    {
      topic: 'SurfaceDrainage_0',
      value: 0,
      title: 'SurfaceDrainage'
    },
    {
      topic: 'SurfaceDrainage_2',
      value: 2,
      title: 'SurfaceDrainage'
    },
    {
      topic: 'SurfaceDrainage_4',
      value: 4,
      title: 'SurfaceDrainage'
    },
    {
      topic: 'SurfaceDrainage_5',
      value: 5,
      title: 'SurfaceDrainage'
    }
  ]
];

interface Card {
  id?: string;
  title: string;
  value: number;
  topic: string;
  note?: ConservationStateNoteEnum;
}

export function SeventhStepInspection({
  stepSevenStates,
  setStepSevenStates,
  setGeneralComments,
  generalComments,
  generalFiles,
  setGeneralFiles
}: {
  stepSevenStates: SaveConservationStateInput[];
  setStepSevenStates: Dispatch<SetStateAction<SaveConservationStateInput[]>>;
  setGeneralComments: Dispatch<SetStateAction<string>>;
  generalComments: string;
  generalFiles: FileType[];
  setGeneralFiles: Dispatch<SetStateAction<FileType[]>>;
}) {
  const [deleteGenralCommentsFile] = useMutation<
    DeleteGeneralCommentsFileMutation,
    DeleteGeneralCommentsFileMutationVariables
  >(DeleteGeneralCommentsFileDocument, {
    onCompleted: () => {
      toastfySuccess(translate('DeletedSuccessfully'));
    }
  });
  const { t: translate } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<{
    [key: string]: Card;
  }>(
    stepSevenStates.reduce(
      (acc, curr) => {
        acc[curr.title] = {
          title: curr.title,
          value: VALUES_NOTE[curr.note],
          topic: curr.topic,
          note: curr.note
        };
        return acc;
      },
      {} as {
        [key: string]: Card;
      }
    )
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const files = Array.from(fileList).map((file) => ({
        id: null,
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      }));
      setGeneralFiles([...generalFiles, ...files]);
    }
  };

  const handleDeleteFile = (fileSelect: FileType) => {
    if (fileSelect.id) {
      deleteGenralCommentsFile({
        variables: { fileId: fileSelect.id }
      });
      setGeneralFiles((prev) =>
        prev.filter((file) => file.id !== fileSelect.id)
      );
    } else {
      setGeneralFiles((prev) =>
        prev.filter((file) => file.file !== fileSelect.file)
      );
    }
  };

  const handleChanceCard = (card: Card) => {
    setSelectedCard((prev) => ({
      ...prev,
      [card.title]: {
        title: card.title,
        value: card.value,
        id: card.id,
        topic: card.topic,
        note: NOTE_VALUES[card.value]
      }
    }));
    const updatedState = stepSevenStates.map((item) => {
      if (item.title === card.title) {
        return {
          ...item,
          note: NOTE_VALUES[card.value],
          topic: card.topic
        };
      }

      return item;
    });
    setStepSevenStates(updatedState);
  };

  const sum = Object.values(selectedCard).reduce(
    (acc, curr) => acc + curr.value,
    0
  );

  return (
    <ContainerSeventhStep>
      {TITLES.map((title, index) => (
        <Title style={{ textAlign: 'center' }} key={index}>
          {translate(title)}
        </Title>
      ))}
      {CARDS.map((card, cardIndex: number) => (
        <SectionSevenStep key={cardIndex}>
          {card.map((item: Card) => (
            <div
              key={item.value}
              className={
                selectedCard[item.title]?.value === item.value ? 'selected' : ''
              }
              onClick={() => handleChanceCard(item)}
            >
              <label htmlFor={`${item.value.toString()}${cardIndex}`}>
                {translate(item.topic)}
              </label>
              <p>{item.value}</p>
            </div>
          ))}
        </SectionSevenStep>
      ))}
      <RegisterArea style={{ gridColumn: 'span 5' }}>
        <span style={{ margin: '25px' }}>∑EC = {sum}</span>
        <span>{translate('ConservationStateGeralObservation')}</span>
        <InspectionTextArea
          placeholder={translate('Observation')}
          spellCheck={false}
          style={{ width: '90%', height: '150px' }}
          value={generalComments}
          onChange={(event) => setGeneralComments(event.target.value)}
        />
        <InputFileWithList
          name="files"
          accept="image/*"
          label={translate('file')}
          files={generalFiles}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleFileChange(event);
          }}
          onDelete={(file: FileType) => {
            handleDeleteFile(file);
          }}
          canDelete={true}
          multiple
        />
      </RegisterArea>
    </ContainerSeventhStep>
  );
}
