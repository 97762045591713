import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../../../data/graphql/base-schema';
import {
  DeleteInstrumentReadingDocument,
  DeleteInstrumentReadingMutation,
  DeleteInstrumentReadingMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentReading';
import {
  ListReadingByPluviometerPagDocument,
  ListReadingByPluviometerPagQuery,
  ListReadingByPluviometerPagQueryVariables
} from '../../../../data/graphql/query/generated/listReadingByPluviometerPag.query';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import FilterReadingsPage from '../../Organisms/FilterReadingsPage/FilterReadingsPage';
import ReadingPluviometer from '../../Organisms/ReadingPluviometerForm/ReadingPluviometer';
import { ReadingPluviometerFormType } from '../../Organisms/ReadingPluviometerForm/ReadingPluviometer.interfaces';
import RegisterPluviometerBathReadings from '../../Organisms/RegisterPluviometerBathReadings/RegisterPluviometerBathReadings';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { DivContainerTable } from './ReadingPagePluviometer.styles';
import { ProjectContext } from '../../../../Context/ContextAPI';
import Enums from '../../../../utils/enumns';
import Paginator from '../../Molecules/Paginator/Paginator';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import { TABLE_IDS } from '../../../../utils/TableConsts';
import ExportTableReadingPluviometer from '../../Organisms/ExportTableReadingPluviometer/ExportTableReadingPluviometer';

const ReadingsPagePluviometer = () => {
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editPluviometer, setEditPluviometer] =
    useState<ReadingPluviometerFormType | null>(null);
  const [modalReadingIsOpen, setModalReadingIsOpen] = useState(false);
  const [deleting, setDeleting] = useState({
    status: false,
    readingId: '',
    instrumentId: ''
  });
  const actionsTable: AvailableContextActionsProps<
    ListReadingByPluviometerPagQuery['listReadingByPluviometerPag']['nodes'][0]
  >[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (reading) => handleEditPluviometer(reading),
      canShow: 'canEdit'
    },
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (item) => {
        setDeleting({
          status: true,
          readingId: item.readingId,
          instrumentId: item.instrumentId
        });
      },
      canShow: 'canDelete'
    }
  ];

  const [deleteInstrument] = useMutation<
    DeleteInstrumentReadingMutation,
    DeleteInstrumentReadingMutationVariables
  >(DeleteInstrumentReadingDocument);

  const {
    data: dataReadingsByPluviometer,
    loading: loadingReadingsByPluviometer
  } = useQuery<
    ListReadingByPluviometerPagQuery,
    ListReadingByPluviometerPagQueryVariables
  >(ListReadingByPluviometerPagDocument, {
    variables: {
      structureInfo: {
        structureId: structureId || '',
        associatedStructureId: null
      },
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters: {
        instrumentsId:
          (searchParams.get('instrumentId') as InputMaybe<string[]>) ||
          undefined,
        readingType:
          (searchParams.get('type') as InstrumentReadingTypeEnum) || undefined,
        status:
          (searchParams.get('status') as InstrumentStatusEnum) || undefined,
        name: searchParams.get('name') || undefined,
        endDate: searchParams.get('endDate')
          ? new Date(searchParams.get('endDate') as string)
          : undefined,
        startDate: searchParams.get('startDate')
          ? new Date(searchParams.get('startDate') as string)
          : undefined
      }
    }
  });
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  const totalPages =
    dataReadingsByPluviometer?.listReadingByPluviometerPag.pageInfo
      .totalPages || 1;

  const tableData =
    dataReadingsByPluviometer?.listReadingByPluviometerPag.nodes?.map(
      (item) => ({
        ...item,
        date: item.date
          ? new Date(item.date).toLocaleString().replace(',', ' -')
          : '-',
        readingType: translate(item.readingType),
        operationalStatus: translate(item.operationalStatus.toLowerCase()),
        installLocation: item.installLocation
          ? translate(item.installLocation)
          : '-'
      })
    );

  const columnsPluviometer = [
    { key: 'name', label: translate('name') },
    { key: 'date', label: translate('dateAndTime') },
    { key: 'observation', label: translate('Observation') },
    { key: 'rainfall', label: translate('Pluviometry') },
    { key: 'structureType', label: translate('structureType') },
    { key: 'installLocation', label: translate('installLocation') },
    { key: 'readingType', label: translate('typeOfReading') },
    { key: 'operationalStatus', label: translate('operationalStatus') },
    { key: 'coordinateE', label: translate('coordinateE') },
    { key: 'coordinateN', label: translate('coordinateN') }
  ];

  const columnsExport = [
    { key: 'name', label: translate('name') },
    { key: 'date', label: translate('date') },
    { key: 'isAutomatic', label: translate('readingType') },
    { key: 'operationalStatus', label: translate('operationalStatus') },
    { key: 'installationDate', label: translate('installationDate') },
    { key: 'installLocation', label: translate('installLocation') },
    { key: 'observation', label: translate('observation') },
    { key: 'rainfall', label: translate('rainfall') }
  ];

  const handleEditPluviometer = (
    readingRole: ListReadingByPluviometerPagQuery['listReadingByPluviometerPag']['nodes'][0]
  ) => {
    const reading =
      dataReadingsByPluviometer?.listReadingByPluviometerPag.nodes?.find(
        (item) => item.readingId === readingRole.readingId
      );
    if (!reading) return;
    const newReading = {
      id: readingRole.readingId,
      rainfall: readingRole.rainfall.toString(),
      date: new Date(reading.date ?? Date.now()),
      observation: readingRole.observation!,
      instrument: {
        id: readingRole.instrumentId,
        name: readingRole.name
      }
    };
    setEditPluviometer(newReading);
    setShowModal(true);
  };

  const handleDeleteReading = () => {
    deleteInstrument({
      variables: {
        structureId: structureId!,
        data: {
          readingId: deleting.readingId,
          instrumentId: deleting.instrumentId
        }
      },
      onCompleted: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfyError(translate(ToastifyModel().toastifyMessage.error));
      },
      refetchQueries: [ListReadingByPluviometerPagDocument]
    });
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loadingReadingsByPluviometer}
        title={<Text type="h1">{translate('Pluviometer')} </Text>}
        button={
          userRole !== UserRoles.Viewer && (
            <>
              <Button
                onClick={() => {
                  setEditPluviometer(null);
                  setShowModal(true);
                }}
                variant={'primary'}
                text={translate('RegisterReadings')}
              />
              <Button
                variant={'primary'}
                size="large"
                text={translate('RegisterReadingsFile')}
                onClick={() => setModalReadingIsOpen(true)}
              />
            </>
          )
        }
        content={
          <DivContainerTable>
            <TableDraggableComponent
              tableId={TABLE_IDS.READINGS_PLUVIOMETER_TABLE}
              columnsArray={columnsPluviometer}
              tableData={tableData || []}
              actions={userRole !== UserRoles.Viewer ? actionsTable : undefined}
              filterComponent={<FilterReadingsPage />}
              paginator={
                <Paginator
                  options={optionsPaginatorDefault}
                  totalPages={totalPages}
                  showInUrl
                />
              }
              exportTable={
                <ExportTableReadingPluviometer columns={columnsExport} />
              }
              sortUrl
              searchUrl
              columnSelect
              showCountColumn
            />
          </DivContainerTable>
        }
      />
      <ViewModal
        showModal={showModal}
        title={
          editPluviometer
            ? `${translate('EditReadings')} ${translate('pluviometer')}`
            : `${translate('NewReading')} ${translate('pluviometer')}`
        }
        onClose={() => {
          setEditPluviometer(null);
          setShowModal(false);
        }}
        component={
          <ReadingPluviometer
            reading={editPluviometer}
            setShowModal={setShowModal}
          />
        }
        width="500px"
        height="500px"
      />
      <ModalConfirm
        showModal={deleting.status}
        onClose={() =>
          setDeleting({
            status: false,
            readingId: '',
            instrumentId: ''
          })
        }
        onConfirm={() => handleDeleteReading()}
        title={`${translate('Delete')} ${translate('reading')}`}
        text={translate('ConfirmDelete')}
      />

      <ViewModal
        showModal={modalReadingIsOpen}
        onClose={() => setModalReadingIsOpen(false)}
        title={translate('Pluviometer')}
        width="500px"
        height="550px"
        component={
          <RegisterPluviometerBathReadings
            onClose={() => setModalReadingIsOpen(false)}
          />
        }
      />
    </>
  );
};

export default ReadingsPagePluviometer;
