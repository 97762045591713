import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllStabilityAnalysisGraphicPagQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  filters?: Types.InputMaybe<Types.StabilityAnalysisFilterInput>;
  pageInfo: Types.PageInput;
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
}>;

export type FindAllStabilityAnalysisGraphicPagQuery = {
  __typename?: 'Query';
  findAllStabilityAnalysisGraphicPag: {
    __typename?: 'PaginatedListStabilityAnalysisGraphicType';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      page: number;
      offset: number;
      limit: number;
      totalPages: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<{
      __typename?: 'ListAllStabilityAnalysisGraphicType';
      analysisDate: Date;
      sectionName: string;
      graphicStatus: Types.GraphicStatusEnum;
      graphicId: string;
      graphicName?: string | null;
      slideFileName: string;
      graphicUrl?: string | null;
      createdAt: Date;
    }>;
  };
};

export const FindAllStabilityAnalysisGraphicPagDocument = gql`
  query FindAllStabilityAnalysisGraphicPag(
    $structureId: String!
    $filters: StabilityAnalysisFilterInput
    $pageInfo: PageInput!
    $sortInfo: [SortInput!]
  ) {
    findAllStabilityAnalysisGraphicPag(
      structureId: $structureId
      filters: $filters
      pageInfo: $pageInfo
      sortInfo: $sortInfo
    ) {
      count
      pageInfo {
        page
        offset
        limit
        totalPages
        hasNextPage
        hasPreviousPage
      }
      nodes {
        analysisDate
        sectionName
        graphicStatus
        graphicId
        graphicName
        slideFileName
        graphicUrl
        createdAt
      }
    }
  }
`;

export type FindAllStabilityAnalysisGraphicPagQueryResult = Apollo.QueryResult<
  FindAllStabilityAnalysisGraphicPagQuery,
  FindAllStabilityAnalysisGraphicPagQueryVariables
>;
