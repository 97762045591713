import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';
import { ViewModalStyleProps } from './ViewModal.interface';

export const DivModalContainer = styled.div<{ $showModal: boolean }>`
  opacity: ${(props) => (props.$showModal ? 1 : 0)};
  background-color: rgba(0, 0, 0, ${(props) => (props.$showModal ? 0.5 : 0)});
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: ${(props) => (props.$showModal ? 'auto' : 'none')};
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease;
`;

export const DivViewModal = styled.div<ViewModalStyleProps>`
  position: fixed;
  background-color: ${Color.White};
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: ${(props) =>
    props.width ? `min(90%, ${props.width})` : 'min(90%, 768px)'};
  height: ${(props) =>
    props.height ? `min(${props.height}, 90%)` : 'min(90%, 500px)'};
  transform: ${(props) => (props.$showModal ? 'scale(1)' : 'scale(0.5)')};
  opacity: ${(props) => (props.$showModal ? 1 : 0)};
  z-index: 100;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
`;

export const DivViewModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DivViewModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid ${Color.Brown3};

  button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${Color.Brown1};
  }
`;

export const DivVolumeContainer = styled.div`
  color: ${Color.Text1};
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleVolume = styled.span`
  padding: 15px 0;
  font-weight: bold;
`;

export const DivContainerViewModalBody = styled.div<{ $showModal: boolean }>`
  opacity: ${(props) => (props.$showModal ? 1 : 0)};
  transform: ${(props) => (props.$showModal ? 'scale(1)' : 'scale(0.9)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100% - 50px);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
`;
