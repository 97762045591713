import React from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z, ZodIssueCode } from 'zod';
import {
  EditPiezometerControlCoteDocument,
  EditPiezometerControlCoteMutation,
  EditPiezometerControlCoteMutationVariables
} from '../../../../data/graphql/generated/editPiezometerControlCote.mutation';
import { ListControlQuotasByPiezometersPagDocument } from '../../../../data/graphql/query/generated/listControlQuotasByPiezometersPag.query';
import { toastfySuccess } from '../../../Toastify';
import InputText from '../../Molecules/InputText/InputText';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import Button from '../../Atoms/Button/Button';
import { EditPiezometerAndInaControlQuotasProps } from './EditPiezometerAndInaControlQuotas.interface';
import {
  DivContainer,
  DivInput,
  DivInputs
} from './EditPiezometerAndInaControlQuotas.styles';
import { controlQuotasDataProps } from '../../Pages/EditPiezometerAndInaPage/EditPiezometerAndInaPage.interface';
import {
  SavePiezometerControlQuotaDocument,
  SavePiezometerControlQuotaMutation,
  SavePiezometerControlQuotaMutationVariables
} from '../../../../data/graphql/generated/savePiezometerControlQuota.mutation';
import useErrorsTreatment from '../../../../Hooks/useErrorsTreatment';
import { ListPiezometersByStructureDocument } from '../../../../data/graphql/query/generated/listPiezometersByStructure.query';

const EditPiezometerAndInaControlQuotas = ({
  data: { id, topCote, bottomCote, referenceDate },
  setModalControlQuotas
}: EditPiezometerAndInaControlQuotasProps<controlQuotasDataProps>) => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentId } = useParams();
  const { parseErrorMessage } = useErrorsTreatment();

  const controlQuotasSchema = z
    .object({
      topCote: z.string().refine((val) => val !== '', {
        message: translate('fillRequiredFields')
      }),
      bottomCote: z.string().refine((val) => val !== '', {
        message: translate('fillRequiredFields')
      }),
      referenceDate: z
        .date()
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fillRequiredFields')
        })
    })
    .superRefine(({ topCote, bottomCote, referenceDate }, ctx) => {
      if (parseFloat(topCote) < parseFloat(bottomCote)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['topCote'],
          message: translate('topQuotaSmallerThanBottomQuota')
        });
      }

      if (referenceDate && referenceDate > new Date()) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['referenceDate'],
          message: translate('dateGreaterThanCurrentDate')
        });
      }
    });

  type controlQuotasType = z.infer<typeof controlQuotasSchema>;

  const [editControlQuotas] = useMutation<
    EditPiezometerControlCoteMutation,
    EditPiezometerControlCoteMutationVariables
  >(EditPiezometerControlCoteDocument);

  const [addControlQuotas] = useMutation<
    SavePiezometerControlQuotaMutation,
    SavePiezometerControlQuotaMutationVariables
  >(SavePiezometerControlQuotaDocument);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<controlQuotasType>({
    resolver: zodResolver(controlQuotasSchema),
    defaultValues: {
      topCote: topCote?.toString(),
      bottomCote: bottomCote?.toString(),
      referenceDate: referenceDate ? new Date(referenceDate) : undefined
    }
  });

  const handleQuotasControl = (dataFromForm: controlQuotasType) => {
    if (id) {
      editControlQuotas({
        variables: {
          data: {
            topCote: parseFloat(dataFromForm.topCote),
            referenceDate: dataFromForm.referenceDate,
            bottomCote: parseFloat(dataFromForm.bottomCote),
            instrumentId: instrumentId!,
            controlId: id
          },
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: ''
          }
        },
        refetchQueries: [
          ListControlQuotasByPiezometersPagDocument,
          {
            query: ListPiezometersByStructureDocument,
            variables: {
              structureInfo: {
                structureId: structureId,
                associatedStructureId: null
              }
            }
          }
        ],
        onCompleted: () => {
          toastfySuccess(translate('editedSuccessfully'));
          setModalControlQuotas({
            show: false,
            data: {
              id: '',
              topCote: 0,
              bottomCote: 0,
              referenceDate: new Date()
            }
          });
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
    } else {
      addControlQuotas({
        variables: {
          data: {
            topCote: parseFloat(dataFromForm.topCote),
            bottomCote: parseFloat(dataFromForm.bottomCote),
            referenceDate: dataFromForm.referenceDate
          },
          instrumentId: instrumentId!
        },
        refetchQueries: [
          ListControlQuotasByPiezometersPagDocument,
          {
            query: ListPiezometersByStructureDocument,
            variables: {
              structureInfo: {
                structureId: structureId,
                associatedStructureId: null
              }
            }
          }
        ],
        onCompleted: () => {
          toastfySuccess(translate('registeredSuccessfully'));
          setModalControlQuotas({
            show: false,
            data: {
              id: '',
              topCote: undefined,
              bottomCote: undefined,
              referenceDate: undefined
            }
          });
        },
        onError: (error) => {
          parseErrorMessage(error);
        }
      });
    }
  };

  return (
    <DivContainer
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit(handleQuotasControl)();
        }
      }}
    >
      <DivInputs>
        <DivInput>
          <InputText
            width="300px"
            control={control}
            label={translate('topCote')}
            name={'topCote'}
            type={'number'}
            error={!!errors.topCote}
            errorMessage={errors?.topCote?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            control={control}
            label={translate('bottomCote')}
            name={'bottomCote'}
            type={'number'}
            error={!!errors.bottomCote}
            errorMessage={errors?.bottomCote?.message}
          />
        </DivInput>
        <DivInput>
          <DatepickerInput
            control={control}
            label={translate('ReferenceDate')}
            name="referenceDate"
            placeholder={translate('NoInformated')}
            time={false}
            error={!!errors.referenceDate}
            errorMessage={errors?.referenceDate?.message}
          />
        </DivInput>
      </DivInputs>
      <Button
        variant="primary"
        size="large"
        onClick={handleSubmit(handleQuotasControl)}
        text={translate('saveControlCotes')}
      />
    </DivContainer>
  );
};

export default EditPiezometerAndInaControlQuotas;
