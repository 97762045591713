import { InputStyled } from './Input.styles';
import { InputProps } from './Input.interfaces';

const Input = ({ error, name, width, register, ...props }: InputProps) => {
  return (
    <InputStyled
      $error={error}
      width={width}
      {...(register ? register(name) : {})}
      {...props}
    />
  );
};

export default Input;
